import {
  Button,
  Col,
  Empty,
  Input,
  Modal,
  Popover,
  Radio,
  Row,
  Space,
  Tabs,
  Tag,
  Typography,
  Select,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { CustomService } from "../../services/dataService";
import { useMutation, useQuery } from "react-query";
import RawDataPlot from "../workoutFileAnalysis/d3ChartV2";
import { SetCard } from "./SetCard";
import SetDetailView from "./setDetailView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamation,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import WorkoutGraph from "./workoutGraph";
import { LogView, SetDebugView } from "./replayWorkout";

const SimWorkoutView = ({}) => {
  const [state, setState] = useState({
    simulationId: null,
    workoutId: null,
  });

  const simulationItems = useQuery(
    ["simulations"],
    () => {
      return CustomService.simulations({}).then((res) => {
        return res;
      });
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const workout = useQuery(
    ["simulationWorkout", state.workoutId],
    () => {
      return CustomService.simulationWorkout({
        workout_id: state.workoutId,
        simulation_id: state.simulationId,
      })
    },
    {
      enabled: state.workoutId && state.simulationId ? true : false,
    }
  );

  return (
    <div>
      <Row gutter={12}>
        <Col span={8}>
          <Select
            style={{ width: "100%" }}
            onChange={(value) => {
              setState({ ...state, simulationId: value });
            }}
          >
            {simulationItems.data?.map((sim) => {
              return <Select.Option value={sim}>{sim}</Select.Option>;
            })}
          </Select>
        </Col>
        <Col span={8}>
          <Input.Search
            style={{ width: "100%" }}
            placeholder="WorkoutId"
            onSearch={(e) => {
              setState({ ...state, workoutId: e });
            }}
          ></Input.Search>
        </Col>
      </Row>
      <div>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Sim Sets" key="1">
            {workout.data?.sets  != undefined ? (
              <SetDebugView
                sets={workout.data?.sets}
                userId={workout.data?.user_id}
              />
            ) : null}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Logs" key="3">
            {workout.data?.logs  != undefined ? (
              <div
                style={{
                  maxHeight: "70vh",
                  overflow: "auto",
                  width: "100%",
                }}
              >
                {workout.data?.logs?.map((log, index) => {
                  return <LogView key={index} log={log} />;
                })}
              </div>
            ) : null}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Timeline" key="4">
            {workout.data?.logs != undefined ? (
              <div
                style={{
                  maxHeight: "70vh",
                  overflow: "auto",
                  width: "100%",
                }}
              >
                <WorkoutGraph
                  allSets={workout.data?.sets}
                  logs={workout.data?.logs}
                  detectingSets={workout.data?.all_detecting_states}
                  workoutEndTime={workout.data?.end_time}
                  workoutStartTime={workout.data?.start_time}
                />
              </div>
            ) : null}
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default SimWorkoutView;
