import React, { useState } from "react";

import {
  Button,
  Drawer,
  Row,
  PageHeader,
  Table,
  Typography,
  Input,
} from "antd";

const columns = [
  {
    title: "Name",
    dataIndex: "name", 
    width: "10%"
  },
  {
    title: "New Data",
    dataIndex: "NEW_DATA",
    align: "center",
    width: "10%",
    sorter: {
      compare: (a, b) => a.NEW_DATA - b.NEW_DATA,
      multiple: 3,
    },
  },
  {
    title: "Labelled",
    dataIndex: "LABELLED",
    align: "center",
    width: "10%",
    sorter: {
      compare: (a, b) => a.LABELLED - b.LABELLED,
      multiple: 3,
    },
  },
  {
    title: "Automatic",
    dataIndex: "AUTOMATIC",
    align: "center",
    width: "10%",
    sorter: {
      compare: (a, b) => a.AUTOMATIC - b.AUTOMATIC,
      multiple: 3,
    },
  },
  {
    title: "Pending",
    dataIndex: "PENDING",
    align: "center",
    width: "10%",
    sorter: {
      compare: (a, b) => a.PENDING - b.PENDING,
      multiple: 2,
    },
  },
  {
    title: "Unsure",
    dataIndex: "unsure",
    sorter: {
      compare: (a, b) => a.unsure - b.unsure,
      multiple: 2,
    },
  },
  {
    title: "Rejected",
    dataIndex: "ARCHIVED",
    align: "center",
    width: "10%",
    sorter: {
      compare: (a, b) => a.ARCHIVED - b.ARCHIVED,
      multiple: 2,
    },
  },
  
  {
    title: "Failed",
    dataIndex: "IMPORT_FAILED",
    align: "center",
    width: "10%",    sorter: {
      compare: (a, b) => a.IMPORT_FAILED - b.IMPORT_FAILED,
      multiple: 2,
    },
  },
  {
    title: "Verified",
    dataIndex: "verified",
    align: "center",
    width: "10%",
    sorter: {
      compare: (a, b) => a.verified - b.verified,
      multiple: 2,
    },
  },
  {
    title: "Rep. Verified",
    dataIndex: "rep_verified",
    align: "center",
    width: "10%",
    sorter: {
      compare: (a, b) => a.IMPORT_FAILED - b.IMPORT_FAILED,
      multiple: 2,
    },
  },
  {
    title: "Total",
    dataIndex: "total",
    align: "center",
    width: "10%",
    sorter: {
      compare: (a, b) => a.total - b.total,
      multiple: 1,
    },
  },
];

const BatchStatus = (props) => {
  const [state, setState] = useState({});
  return (
    <React.Fragment>
      <div className="page-content">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography.Title level={5}>Exercise Stats:</Typography.Title>
          <Input.Search
            style={{ width: "200px" }}
            onChange={(e) =>
              setState((prev) => ({ ...prev, search: e.target.value }))
            }
          />
        </div>
        <Table
          columns={columns}
          dataSource={
            state.search
              ? props.data.filter((data) => data.name.includes(state.search))
              : props.data
          }
          pagination={false}
          scroll={{ y: 350 }}
        />
      </div>
    </React.Fragment>
  );
};

export default BatchStatus;
