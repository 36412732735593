import React, { useState, useRef, useEffect } from "react";
import {
  ConstantItemService,
  WorkoutFileService,
  WorkoutLabelService,
  ModelServices,
} from "../../services/dataService";
import moment from "moment";
import { useHotkeys } from "react-hotkeys-hook";
import {
  Button,
  Drawer,
  Tag,
  Spin,
  PageHeader,
  Tabs,
  notification,
  message,
  InputNumber,
  Row,
  Space,
  Slider,
  Divider,
  Col,
  Typography,
  Input,
  Popconfirm,
  Popover,
  Empty,
  Switch,
} from "antd";
import {
  RightOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  VideoCameraOutlined,
  DeleteOutlined,
  SaveOutlined,
  AlertOutlined,
  WarningOutlined,
  CloseCircleOutlined,
  MoreOutlined,
  SafetyOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useQuery, useMutation, useQueryClient } from "react-query";
import ReactPlayer from "react-player";
import RepLabelChart from "./labelGraph";
import { Link, useHistory } from "react-router-dom";
import { UserContext } from "../../contexts";
import createPersistedState from "use-persisted-state";
import { useContext } from "react";
import { exStageMapping, defaultStages } from "../../constants";
import EditExercise from "./editExerciseName";
import ChangeHistory from "./history";
import { KEY_TO_LABEL } from "../../constants";
import ModelPrediction from "./modelPrediction";
import { PlaybackNStageControls, StageControl } from "./helpers";
import RepDetailsView from "./repDetailsView";
import { AUTO_LABEL_EXERCISES } from "./constant";

const useWorkoutListViewState = createPersistedState("workoutListViewState");
const playbackRateState = createPersistedState("playbackRateState");
const activeTabState = createPersistedState("activeTabState");
const persistedControlConfig = createPersistedState("controlConfig");
// const RepDetailsView = (props) => {
//   return (
//     <>
//       <Typography.Title level={5}>Rep Segments</Typography.Title>
//       <div style={{ height: "360px", overflowY: "auto" }}>

//         {props.segments.map((item, idx) => (
//           <div
//             style={{
//               borderBottom:
//                 idx != props.segments.length - 1 ? "solid gray 1px" : null,
//             }}
//             key={idx}
//           >
//             <Row>
//               <Col span={4}>
//                 <Typography.Title level={5}>#{idx}</Typography.Title>
//               </Col>

//               <Col span={4}>
//                 <>
//                   <div>
//                     <Typography.Text type="secondary">Label</Typography.Text>
//                   </div>{" "}
//                   <div>{item.label}</div>{" "}
//                 </>
//               </Col>
//               <Col span={4}>
//                 <>
//                   <div>
//                     <Typography.Text type="secondary">Start</Typography.Text>
//                   </div>{" "}
//                   <div>{item.start}</div>{" "}
//                 </>
//               </Col>
//               <Col span={4}>
//                 <>
//                   <div>
//                     <Typography.Text type="secondary">End</Typography.Text>
//                   </div>{" "}
//                   <div>{item.end ? item.end : ""}</div>{" "}
//                 </>
//               </Col>
//               <Col span={6}>
//                 <Button type="link">
//                   <VideoCameraOutlined />
//                 </Button>
//                 {idx == props.segments.length - 1 && !props.isLabelled ? (
//                   <Button type="link">
//                     <DeleteOutlined onClick={props.undoLastStage} />
//                   </Button>
//                 ) : null}
//               </Col>
//             </Row>
//           </div>
//         ))}
//       </div>
//     </>
//   );
// };

const getMoreButtons = (props) => {
  const _getMoreButton = () => {
    return (
      <span key="more">
        <Popover
          placement="topRight"
          content={
            <div>
              <Space
                direction="vertical"
                seperator={<Divider style={{ margin: "5px 0px" }} />}
              >
                <Button
                  type="link"
                  disabled={
                    !(
                      props.isVerified ||
                      props.isUnsure ||
                      props.fileStatus == "ARCHIVED"
                    )
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    props.resetFlags.mutate();
                  }}
                >
                  Reset Flags (Archived/Verified)
                </Button>

                <Button
                  type="link"
                  onClick={(e) => {
                    e.preventDefault();
                    props.showHistory();
                  }}
                >
                  Show History
                </Button>
                <Button
                  type="link"
                  onClick={(e) => {
                    e.preventDefault();
                    // props.showHistory();
                    props.autoLabel();
                  }}
                >
                  Auto label
                </Button>
                <div>
                  <span style={{ margin: "0rem 1rem" }}>Auto move to next</span>
                  <Switch
                    checked={props.controlConfig.autoMove}
                    onChange={() => props.autoMoveToggle()}
                  ></Switch>
                </div>
              </Space>
            </div>
          }
        >
          <Button type="text" icon={<MoreOutlined />}></Button>
        </Popover>
      </span>
    );
  };
  return [
    !(props.isVerified || props.fileStatus == "ARCHIVED") ? (
      <Button
        // type="link"
        onClick={(e) => {
          e.preventDefault();
          props.onEditExercise();
        }}
      >
        Edit Exercise
      </Button>
    ) : null,
    (props.user?.role == "admin" || props.user?.role == "trainer") &&
    props.fileStatus != "ARCHIVED" &&
    !props.formType ? (
      <Button
        style={{ color: "orange", borderColor: "orange" }}
        onClick={() => props.formFeedback.mutate("MEDIOCRE")}
        // icon={}
        key="avg_form"
        loading={props.formFeedback.isLoading}
      >
        Avg. Form
      </Button>
    ) : null,
    (props.user?.role == "admin" || props.user?.role == "trainer") &&
    props.fileStatus != "ARCHIVED" &&
    !props.isVerified ? (
      <Button
        style={{ color: "green", borderColor: "green" }}
        onClick={() => props.markVerified.mutate()}
        icon={<SafetyOutlined />}
        key="verfiy"
        loading={props.markVerified.isLoading}
      >
        Ex Verify
      </Button>
    ) : null,
    props.user?.role == "admin" &&
    props.fileStatus != "ARCHIVED" &&
    (props.labelStatus == "LABELLED" || props.labelStatus == "AUTOMATIC") &&
    !props.isRepCountVerified &&
    props.user.role != "trainer" ? (
      <Button
        style={{ color: "green", borderColor: "green" }}
        onClick={() => props.markVerified.mutate(true)}
        icon={<SafetyOutlined />}
        key="rep-verfiy"
        loading={props.markVerified.isLoading}
      >
        Rep Verify
      </Button>
    ) : null,
    props.labelStatus == "PENDING" &&
    !props.isUnsure &&
    !(props.isVerified || props.fileStatus == "ARCHIVED") ? (
      <Button
        style={{ color: "orange", borderColor: "orange" }}
        onClick={() => props.markUnsure.mutate()}
        icon={<WarningOutlined />}
        key="unsure"
      >
        Unsure
      </Button>
    ) : null,
    (props.user?.role == "admin" || props.user?.role == "trainer") &&
    !(props.fileStatus == "ARCHIVED") ? (
      <Button
        danger
        onClick={() => props.markReject.mutate()}
        icon={<CloseCircleOutlined />}
        key="reject"
        loading={props.markReject.isLoading}
      >
        Archive
      </Button>
    ) : null,
    (props.labelStatus == "PENDING" ||
      props.labelStatus == "NEEDS_RELABEL" ||
      props.repEditMode) &&
    props.fileStatus == "ACTIVE" &&
    !props.isLocked &&
    props.user.role != "trainer" ? (
      <Button
        onClick={() => props.saveAnnotation.mutate()}
        icon={<SaveOutlined />}
        key="save"
      >
        Save
      </Button>
    ) : null,
    props.labelStatus == "LABELLED" || props.labelStatus == "AUTOMATIC" ? (
      <Button
        onClick={() => props.markRelabel.mutate()}
        // icon={<SaveOutlined />}
        key="needsRelabel"
      >
        Needs Re-label?
      </Button>
    ) : null,
    props.user?.role == "admin" &&
    props.status == "LABELLED" &&
    props.fileStatus != "ARCHIVED" ? (
      <Popconfirm
        title={
          "This will erase existing labels please make sure you want to do this.!!!"
        }
        onConfirm={() => props.relabel()}
        key="relabel"
      >
        <Button icon={<AlertOutlined />}>Reset Labels</Button>
      </Popconfirm>
    ) : null,
    <span key="Next">
      {!props.workoutFileDetail.isLoading &&
      props.workoutFileDetail.data &&
      props.workoutFileDetail.data.next_item ? (
        <Link
          to={`/label/${props.workoutFileDetail.data.next_item}`}
          key="Next"
        >
          <Button type="primary">
            Next <RightOutlined />
          </Button>
        </Link>
      ) : null}
    </span>,
    _getMoreButton(),
  ];
};

const WorkoutFileDetail = (props) => {
  const queryClient = useQueryClient();
  const user = useContext(UserContext);
  const [activeTabKey, setActiveTab] = activeTabState("rep_segmentation");
  const [controlConfig, setControlConfig] = persistedControlConfig({
    autoMove: false,
  });
  const [nextStageMapping, setStages] = useState({
    0: 1,
    1: 2,
    2: 3,
    3: 0,
    [-1]: 0,
    [-2]: 0,
  });
  // const [filter, updateFilter] = useState({
  //   filter: { page: 1 },
  // });

  const [nextFileFilters, _] = useWorkoutListViewState({ filter: { page: 1 } });

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries("workout_file");
      queryClient.invalidateQueries("workout_label");
    };
  }, []);

  const autoRepPredict = useMutation(
    () =>
      ModelServices.autoRepPredict({
        workout_id: props.match.params.id,
      }),
    {
      onSuccess: (data) => {
        populateAutoLabels(data.segments);
        // setState((prev) => ({ ...prev, predData: data }));
      },
    }
  );

  const autoMoveToggle = () =>
    setControlConfig((prev) => ({ ...prev, autoMove: !prev.autoMove }));

  const workoutFileDetail = useQuery(
    ["workout_file", props.match.params.id, nextFileFilters.filter],
    () =>
      WorkoutFileService.get(props.match.params.id, {
        search: nextFileFilters.query,
        ...nextFileFilters.filter,
      }),
    {
      staleTime: Infinity,
      onSuccess: (data) => {},
    }
  );

  useEffect(() => {
    if (exStageMapping[workoutFileDetail.data?.exercise]) {
      setStages(() => ({
        ...exStageMapping[workoutFileDetail.data?.exercise],
      }));
    }
    updateState((prev) => ({
      ...prev,
      fileStatus: workoutFileDetail.data?.status,
    }));
  }, [workoutFileDetail.data]);

  const history = useHistory();
  useHotkeys(
    "ctrl+s",
    () => {
      console.log("Save PRESSED");
    },
    { filterPreventDefault: true }
  );

  useHotkeys(
    "p, space",
    (e) => {
      e.preventDefault();
      updateState((prevState) => ({
        ...prevState,
        playing: !prevState.playing,
      }));
    },
    { filterPreventDefault: true }
  );

  useHotkeys(
    "ctrl+n, shift+n",
    () => {
      var url =
        !workoutFileDetail.isLoading &&
        workoutFileDetail.data &&
        workoutFileDetail.data.next_item
          ? `/label/${workoutFileDetail.data.next_item}`
          : null;
      if (url) {
        history.push(url);
      }
    },
    { filterPreventDefault: true },
    [workoutFileDetail]
  );

  const player = useRef(null);
  const [playbackRate, setPlaybackRate] = playbackRateState(1);
  const [state, updateState] = useState({
    playbackRate: null,
    playing: false,
    played: 0,
    annotations: {
      currentState: 0,
      segments: [],
      currentSegment: { start: 0, end: null, label: 0 },
    },
    labels: [],
    currentStage: 0,
    currentTime: 0,
    isLabelled: null,
    status: null,
  });

  const loadFeedback = (data) => {
    const totalDuration = Math.max(data.label_data.map((item) => item.end));
    const labels = [];
    data.label_data.map((item) => {
      labels.push({ x: item.start, y: item.label });
      labels.push({ x: item.end, y: item.label });
    });
    updateState((prevState) => ({
      ...prevState,
      annotations: {
        currentState: 0,
        currentSegment: { start: 0, end: null, label: 0 },
        segments: data.label_data,
      },
      labels: labels,
      isLabelled: data.status == "LABELLED",
      isAutoLabelled: data.is_autolabel,
      labelStatus: data.status,
      comments: data.comments,
    }));

    // debugger
    if (
      data.status != "LABELLED" &&
      AUTO_LABEL_EXERCISES.indexOf(workoutFileDetail.data?.exercise) > -1
    ) {
      // autoRepPredict.mutate();
      // TO BE ENABLED LATER
    }
    return data;
  };

  const populateAutoLabels = (label_data) => {
    const labels = [];
    label_data.map((item) => {
      labels.push({ x: item.start, y: item.label });
      labels.push({ x: item.end, y: item.label });
    });
    updateState((prevState) => ({
      ...prevState,
      labels: labels,
      annotations: {
        ...prevState.annotations,
        segments: label_data,
        auto_segments: label_data,
      },
      label_data: [...label_data],
      repEditMode: true,
      isAutoLabelled: true,
    }));
  };

  const toggleEditExercise = (moveToNext) => {
    updateState((prev) => ({
      ...prev,
      editMode: prev.editMode ? false : true,
    }));

    if (moveToNext && controlConfig.autoMove ) {
      var url =
        !workoutFileDetail.isLoading &&
        workoutFileDetail.data &&
        workoutFileDetail.data.next_item
          ? `/label/${workoutFileDetail.data.next_item}`
          : null;
      if (url) {
        history.push(url);
      }
    }
  };

  const toggleShowHistory = () => {
    updateState((prev) => ({
      ...prev,
      showHistory: prev.showHistory ? false : true,
    }));
  };

  const workoutLabel = useQuery(
    ["workout_label", workoutFileDetail.data?.label_id],
    () => WorkoutLabelService.get(workoutFileDetail.data?.label_id),
    {
      staleTime: Infinity,
      enabled: workoutFileDetail.isLoading
        ? false
        : workoutFileDetail.data?.label_id != null,
      // onSuccess: loadFeedback,
    }
  );

  useEffect(() => {
    if (workoutLabel.data) {
      console.log("DATA: ", workoutLabel.data);
      loadFeedback(workoutLabel.data);
    }
  }, [workoutLabel.data]);

  const saveAnnotation = useMutation(
    async () => {
      var data = {
        label_data: state.annotations.segments,
        status: state.annotations.segments.length > 0 ? "LABELLED" : "PENDING",
        unsure: false,
        comments: "",
        autolabel_resolution: state.isAutoLabelled
          ? state.autoLabelResolution
            ? state.autoLabelResolution
            : "ACCEPTED"
          : "",
      };
      return WorkoutLabelService.update(workoutFileDetail.data.label_id, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("workout_file");
        queryClient.invalidateQueries("workout_label");

        notification["success"]({
          message: "Annotations Updated",
          duration: 0.5,
        });
        var url =
          !workoutFileDetail.isLoading &&
          workoutFileDetail.data &&
          workoutFileDetail.data.next_item
            ? `/label/${workoutFileDetail.data.next_item}`
            : null;
        updateState((prev) => ({ ...prev, repEditMode: false }));
        if (url) {
          history.push(url);
        }
      },
      onError: (error) => {
        notification["error"]({
          message: "Request Failed!!!",
          description: JSON.stringify(error.response.data),
          duration: 0.5,
        });
      },
    }
  );

  const markUnsure = useMutation(
    async () => {
      var data = { unsure: true, comments: state.comments };
      return WorkoutLabelService.update(workoutFileDetail.data.label_id, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("workout_file");
        queryClient.invalidateQueries("workout_label");
        notification["success"]({
          message: "File Marked Unsure",
          duration: 0.5,
        });
      },
      onError: (error) => {
        notification["error"]({
          message: "Request Failed!!!",
          description: JSON.stringify(error.response.data),
          duration: 0.5,
        });
      },
    }
  );

  const markReject = useMutation(
    async () => {
      if (state.comments) {
        var fileData = { status: "ARCHIVED" };
        var labelData = {
          unsure: false,
          is_verified: false,
          comments: state.comments,
          status: "ARCHIVED",
        };
        return Promise.all([
          WorkoutFileService.update(workoutFileDetail.data.id, fileData),
          WorkoutLabelService.update(
            workoutFileDetail.data.label_id,
            labelData
          ),
        ]);
      } else {
        message.error("Please add comments");
      }
    },
    {
      onSuccess: () => {
        if (state.comments) {
          queryClient.invalidateQueries("workout_file");
          queryClient.invalidateQueries("workout_label");
          notification["success"]({
            message: "File Archived",
            duration: 0.5,
          });
        }
      },
      onError: (error) => {
        notification["error"]({
          message: "Request Failed!!!",
          description: JSON.stringify(error.response.data),
          duration: 0.5,
        });
      },
    }
  );

  const markVerified = useMutation(
    async (repCount) => {
      // var fileData = {"status": "VERIFIED"}
      // var fileData = {
      //   status: repCount
      //     ? "ACTIVE"
      //     : workoutLabel.data?.status == "AUTOMATIC"
      //     ? "AUTOMATIC"
      //     : "ACTIVE",
      // };

      var data = {
        is_verified: !repCount ? true : workoutLabel.data?.is_verified,
        is_rep_count_verified: repCount
          ? true
          : workoutLabel.data?.is_rep_count_verified,
        unsure: false,
        comments: state.comments,
        // status:
        //   state.annotations.segments.length > 0 &&
        //   workoutLabel.data?.is_rep_count_verified
        //     ? "LABELLED"
        //     : state.annotations.segments.length > 0 &&
        //       !workoutLabel.data?.is_rep_count_verified
        //     ? "AUTOMATIC"
        //     : "PENDING",
      };
      return WorkoutLabelService.update(workoutFileDetail.data.label_id, data);
    },
    {
      onSuccess: (data) => {
        if (data.message) {
          message.success(data.message, 2);
        }
        queryClient.invalidateQueries("workout_file");
        queryClient.invalidateQueries("workout_label");
        notification["success"]({
          message: "File Verified",
          duration: 0.5,
        });
        // Check if auto move is enabled
        if (controlConfig.autoMove) {
          var url =
            !workoutFileDetail.isLoading &&
            workoutFileDetail.data &&
            workoutFileDetail.data.next_item
              ? `/label/${workoutFileDetail.data.next_item}`
              : null;
          if (url) {
            history.push(url);
          }
        }
      },
      onError: (error) => {
        notification["error"]({
          message: "Request Failed!!!",
          description: JSON.stringify(error.response.data),
          duration: 0.5,
        });
      },
    }
  );

  const notificationWithInvalidate = (message, type, invalidate) => {
    if (invalidate) {
      queryClient.invalidateQueries("workout_file");
      queryClient.invalidateQueries("workout_label");
    }
    if (message) {
      notification[type]({
        message: message,
        duration: 0.5,
      });
    }
  };
  const formFeedback = useMutation(
    async (formType) => {
      var data = {
        form_type: formType,
        comments: state.comments,
      };
      return WorkoutLabelService.update(workoutFileDetail.data.label_id, data);
    },
    {
      onSuccess: () => {
        notificationWithInvalidate("Feedback Added", "success", true);
      },
      onError: (error) => {
        notificationWithInvalidate("Request Failed", "error", false);
      },
    }
  );

  const resetFlags = useMutation(
    async () => {
      var fileData = { status: "ACTIVE" };
      var data = {
        is_verified: false,
        is_rep_count_verified: false,
        unsure: false,
        comments: state.comments,
        form_type: "",
        status: state.annotations.segments.length > 0 ? "LABELLED" : "PENDING",
      };
      return Promise.all([
        WorkoutFileService.update(workoutFileDetail.data.id, fileData),
        WorkoutLabelService.update(workoutFileDetail.data.label_id, data),
      ]);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("workout_file");
        queryClient.invalidateQueries("workout_label");
        notification["success"]({
          message: "Success",
          duration: 0.5,
        });
      },
      onError: (error) => {
        notification["error"]({
          message: "Request Failed!!!",
          description: JSON.stringify(error.response.data),
          duration: 0.5,
        });
      },
    }
  );

  const markRelabel = useMutation(
    async () => {
      if (state.comments) {
        var data = {
          is_rep_count_verified: false,
          unsure: false,
          comments: state.comments,
          status: "NEEDS_RELABEL",
          // status: state.annotations.segments.length > 0 ? "LABELLED" : "PENDING",
        };
        return WorkoutLabelService.update(
          workoutFileDetail.data.label_id,
          data
        );
      } else {
        message.error("Please add comments");
        return;
      }
    },
    {
      onSuccess: () => {
        if (state.comments) {
          queryClient.invalidateQueries("workout_file");
          queryClient.invalidateQueries("workout_label");
          notification["success"]({
            message: "Success",
            duration: 0.5,
          });
        }
      },
      onError: (error) => {
        notification["error"]({
          message: "Request Failed!!!",
          description: JSON.stringify(error.response.data),
          duration: 0.5,
        });
      },
    }
  );

  const autoLabelMutation = useMutation(
    async () => {
      return WorkoutFileService.autoLabel(workoutFileDetail.data.id);
    },
    {
      onMutate: () => {
        message.loading("Computing Autolabel...", 0);
      },
      onSuccess: (data) => {
        message.destroy();
        let labels = [];
        data.rep_progression.map((item) => {
          labels.push({ x: item.start, y: item.label });
          labels.push({ x: item.end, y: item.label });
        });
        notification["success"]({
          message: "Success",
          duration: 2,
        });
        updateState((prevState) => ({
          ...prevState,
          annotations: {
            currentState: 0,
            currentSegment: { start: 0, end: null, label: 0 },
            segments: data.rep_progression,
          },
          isLabelled: false,
          isAutoLabelled: true,
          autoLabelResolution: "",
          labelStatus: "AUTOMATIC",
          labels: labels,
          comments: data.comments,
        }));
        debugger;
      },
      onError: (error) => {
        message.destroy();
        notification["error"]({
          message: "Request Failed!!!",
          description: JSON.stringify(error.response.data),
          duration: 2,
        });
      },
    }
  );

  const onReady = () => {
    updateState((prevState) => ({
      ...prevState,
      playbackRate: playbackRate || 0.5,
      totalDuration: Math.floor(player.current.getDuration() * 1000),
    }));
  };

  const onStart = () => {
    updateState((prevState) => ({
      ...prevState,
      playbackRate: playbackRate,
      playing: true,
    }));
  };

  const onPlay = () => {
    updateState((prevState) => ({
      ...prevState,
      playbackRate: playbackRate,
      playing: true,
    }));
  };

  const onPause = () => {
    updateState((prevState) => ({
      ...prevState,
      playbackRate: playbackRate,
      playing: false,
    }));
  };

  const onProgress = (data) => {
    var labels = state.labels;
    console.log("Progress", data);
    if (!state.isLabelled && !state.isAutoLabelled) {
      labels.push({
        x: data.played * state.totalDuration,
        y: state.currentStage,
      });
      updateState((prevState) => ({
        ...prevState,
        played: data.played,
        labels: labels,
      }));
    } else {
      var currentStage = (
        workoutLabel.data?.label_data.length > 0
          ? workoutLabel.data?.label_data
          : state.label_data
      ).filter(
        (item) =>
          item.start / state.totalDuration < data.played &&
          data.played < item.end / state.totalDuration
      )[0];

      updateState((prevState) => ({
        ...prevState,
        played: data.played,
        currentStage:
          currentStage && currentStage.label != undefined
            ? currentStage.label
            : state.currentStage,
      }));
    }
  };

  const switchAnnotationState = () => {
    var currentAnnotation = JSON.parse(JSON.stringify(state.annotations));
    var currentSegment = currentAnnotation.currentSegment;
    currentSegment["end"] = Math.floor(state.totalDuration * state.played);
    currentSegment["label"] = state.currentStage;
    currentAnnotation.segments.push(currentSegment);
    var newCurrentSegment = {
      start: currentSegment["end"] + 1,
      end: null,
      label: state.currentStage,
    };
    currentAnnotation["currentSegment"] = newCurrentSegment;
    updateState((prevState) => ({
      ...prevState,
      currentStage: nextStageMapping[state.currentStage],
      annotations: currentAnnotation,
      playing: false,
    }));

    debugger;
  };

  useHotkeys(
    "ctrl+r, shift+r, l",
    () => {
      switchAnnotationState();
    },
    { filterPreventDefault: false },
    [state]
  );

  const resetAnnotations = (nextLabel) => {
    updateState((prevState) => ({
      ...prevState,
      currentStage: nextLabel,
      annotations: {
        currentState: 0,
        segments: [],
        currentSegment: { start: 0, end: null, label: 0 },
      },
      labels: [],
      currentStage: 0,
      currentTime: 0,
      playing: false,
      isLabelled: false,
      repEditMode: false,
      isAutoLabelled: false,
      labelStatus: "PENDING",
      fileStatus: "ACTIVE",
      autoLabelResolution: state.isAutoLabelled ? "REJECTED" : "",
    }));
    player.current.seekTo(0);
  };

  const onPrevious = (by = 35) => {
    const newPos = state.played - by / state.totalDuration;
    player.current.seekTo(newPos);
    const labels = state.labels;
    if (!state.isLabelled && !state.isAutoLabelled) {
      const newLabels = labels.filter((item) => item.x < newPos);
      updateState((prevState) => ({ ...prevState, labels: newLabels }));
    }
  };

  const onNext = (by = 35) => {
    const newPos = state.played + by / state.totalDuration;
    if (newPos <= 1) {
      player.current.seekTo(newPos);
    }
  };

  const undoLastStage = () => {
    const segments = state.annotations.segments;
    const lastSegment = segments[segments.length - 1];
    const newSegments = segments.slice(0, -1);
    // debugger
    const newPos = lastSegment.start / state.totalDuration;
    player.current.seekTo(newPos);
    const labels = state.labels.filter((item) => item.x <= lastSegment.start);
    let currentStage = 0;
    if (newSegments.length > 0) {
      currentStage =
        nextStageMapping[newSegments[newSegments.length - 1].label];
    }

    const currentSegment = lastSegment;
    currentSegment["end"] = null;
    currentSegment["label"] = currentStage;
    updateState((prevState) => ({
      ...prevState,
      labels: labels,
      currentStage: currentStage,
      annotations: {
        ...state.annotations,
        currentSegment: currentSegment,
        segments: newSegments,
      },
      autoLabelResolution: state.isAutoLabelled ? "MODIFIED" : "",
    }));
  };

  const saveStage = (stage) => {
    const segments = state.annotations.segments;
    const newStart = stage["start"];
    const newEnd = stage["end"];
    const label = parseInt(stage["label"]);

    const newSegments = [];
    for (let segment of segments) {
      // Condition to check no  overlap
      if (segment.end < newStart || segment.start > newEnd) {
        // If full overlap
        newSegments.push(segment);
      } else if (segment.start > newStart && segment.end < newEnd) {
        continue;
      } else if (segment.start < newStart && segment.end > newEnd) {
        const prevSegment = { ...segment };
        prevSegment.end = newStart;
        const nextSegment = { ...segment };
        nextSegment.start = newEnd;
        newSegments.push(prevSegment);
        newSegments.push({ start: newStart, end: newEnd, label: label });
        newSegments.push(nextSegment);
      } else if (segment.start < newStart) {
        segment.end = newStart;
        newSegments.push(segment);
        newSegments.push({ start: newStart, end: newEnd, label: label });
      } else if (segment.end > newEnd) {
        segment.start = newEnd;
        // newSegments.push({ start: newStart, end: newEnd, label: label });
        newSegments.push(segment);
      }
    }

    const labels = [];
    newSegments.map((item) => {
      labels.push({ x: item.start, y: item.label });
      labels.push({ x: item.end, y: item.label });
    });
    updateState((prevState) => ({
      ...prevState,
      annotations: {
        ...state.annotations,
        segments: [...newSegments],
      },
      currentStage: nextStageMapping[prevState.currentStage],
      labels: [...labels],
      autoLabelResolution: state.isAutoLabelled ? "MODIFIED" : "",
    }));
  };

  const changeStageLabel = (idx, newLabel) => {
    const segments = state.annotations.segments;
    const updatedSegment = { ...segments[idx] };
    updatedSegment.label = newLabel;
    segments[idx] = updatedSegment;
    const labels = [];
    segments.map((item) => {
      labels.push({ x: item.start, y: item.label });
      labels.push({ x: item.end, y: item.label });
    });
    updateState((prevState) => ({
      ...prevState,
      annotations: {
        ...state.annotations,
        segments: [...segments],
      },
      labels: [...labels],
      repEditMode: true,
    }));
  };

  // const updateEditWindow = (editWindow) => {
  //   updateState((prev) => ({
  //     ...prev,
  //     editWindow: editWindow,
  //   }));
  // };

  useHotkeys(
    "left",
    () => {
      onPrevious();
    },
    { filterPreventDefault: true },
    [onPrevious]
  );
  useHotkeys(
    "right",
    () => {
      onNext();
    },
    { filterPreventDefault: true },
    [onNext]
  );
  useHotkeys(
    "shift+right",
    () => {
      onNext(250);
    },
    { filterPreventDefault: true },
    [onNext]
  );

  useHotkeys(
    "shift+p",
    () => {
      onNext(2000);
    },
    { filterPreventDefault: true },
    [onNext]
  );

  useHotkeys(
    "shift+o",
    () => {
      onNext(-2000);
    },
    { filterPreventDefault: true },
    [onNext]
  );

  useHotkeys(
    "shift+left",
    () => {
      onPrevious(250);
    },
    { filterPreventDefault: true },
    [onNext]
  );

  useHotkeys(
    "shift+a",
    () => {
      if (
        (user?.role == "admin" || user?.role == "trainer") &&
        !(
          markReject.isLoading ||
          // workoutLabel.data?.is_verified ||
          state.fileStatus == "ARCHIVED"
        )
      ) {
        markReject.mutate();
      } else {
        message.error("Invalid Request!!");
      }
    },
    { filterPreventDefault: true },
    [markReject]
  );

  useHotkeys(
    "shift+v",
    () => {
      if (
        (user?.role == "admin" || user?.role == "trainer") &&
        !(
          (
            markVerified.isLoading ||
            workoutLabel.data?.is_verified ||
            state.fileStatus == "ARCHIVED"
          )
          // state.status == "ARCHIVED"
        )
      ) {
        markVerified.mutate();
      } else {
        message.error("Invalid Request!! Reset all flags first.");
      }
    },
    { filterPreventDefault: true },
    [markVerified]
  );

  useHotkeys(
    "shift+q",
    () => {
      if (
        (user?.role == "admin" || user?.role == "trainer") &&
        !(
          (markVerified.isLoading || workoutLabel.data?.is_rep_count_verified)
          // state.status == "ARCHIVED"
        )
      ) {
        markVerified.mutate(true);
      } else {
        message.error("Invalid Request!!");
      }
    },
    { filterPreventDefault: true },
    [markVerified]
  );

  useHotkeys(
    "shift+e",
    () => {
      toggleEditExercise();
    },
    { filterPreventDefault: true },
    [toggleEditExercise]
  );

  // console.log("state.fileStatus", state.fileStatus, state.annotations.segments);

  return (
    <React.Fragment>
      <div className="page-content">
        {!(workoutLabel.isLoading && workoutFileDetail.isLoading) ? (
          <PageHeader
            ghost={false}
            // onBack={() => window.history.back()}
            title={
              <div style={{ whiteSpace: "break-spaces" }}>
                <span
                  style={{
                    color:
                      workoutFileDetail.data?.status == "ARCHIVED"
                        ? "red"
                        : workoutLabel.data?.is_rep_count_verified &&
                          workoutLabel.data?.is_verified
                        ? "#389e0d"
                        : null,
                  }}
                >
                  {!workoutFileDetail.isLoading && workoutFileDetail.data
                    ? `#ID: ${workoutFileDetail.data.id}`
                    : null}
                  {workoutLabel.data?.is_rep_count_verified &&
                  workoutLabel.data?.is_verified ? (
                    <SafetyOutlined style={{ marginLeft: "1rem" }} />
                  ) : null}
                </span>

                <Row>
                  <Tag
                    color={workoutLabel.data?.is_verified ? "green" : null}
                    key="exercise"
                  >
                    {!workoutFileDetail.isLoading && workoutFileDetail.data
                      ? workoutFileDetail.data.exercise
                      : ""}{" "}
                    {workoutLabel.data?.is_verified ? (
                      <SafetyOutlined style={{ marginLeft: "0.25rem" }} />
                    ) : null}
                  </Tag>
                  {!workoutFileDetail.isLoading &&
                  workoutLabel.data?.is_rep_count_verified &&
                  !workoutFileDetail.data?.status == "ARCHIVED" ? (
                    <Tag color={"green"} key="rep-verified">
                      Rep. Verified
                      <SafetyOutlined style={{ marginLeft: "0.25rem" }} />
                    </Tag>
                  ) : null}
                  {workoutFileDetail.data?.status == "ARCHIVED" ? (
                    <Tag color="red" key="Archived">
                      Archived
                    </Tag>
                  ) : workoutFileDetail.data?.feedback_status ==
                    "NEEDS_RELABEL" ? (
                    <Tag key="NEEDS_RELABEL" color="warning">
                      Needs Relabling
                    </Tag>
                  ) : workoutFileDetail.data?.feedback_status == "LABELLED" ? (
                    <Tag key="Labelled">Labelled</Tag>
                  ) : workoutFileDetail.data?.feedback_status == "PENDING" ? (
                    <Tag key="Pending">Pending</Tag>
                  ) : null}
                  {workoutLabel.data?.unsure ? (
                    <Tag key="Unsure">Unsure</Tag>
                  ) : null}
                  {workoutLabel.data?.username ? (
                    <Tag key="username">
                      Labeller: {workoutLabel.data?.username}
                    </Tag>
                  ) : null}
                  {/* {lock_files['1'][Object.values(lock_files['0']).indexOf(workoutFileDetail.data?.exercise)] == true ? (
                    <Tag color="red">{'Locked Exercise: True'}</Tag>
                  ) : null} */}
                  {workoutLabel.data?.verified_by ? (
                    <Tag key="ex_verified">
                      <UserOutlined /> Ex. Verified :{" "}
                      {workoutLabel.data?.verified_by}
                    </Tag>
                  ) : null}
                  {workoutLabel.data?.rep_verified_by ? (
                    <Tag key="rep_verified">
                      <UserOutlined /> Rep. Verified :{" "}
                      {workoutLabel.data?.rep_verified_by}
                    </Tag>
                  ) : null}
                  {workoutLabel.data?.form_type ? (
                    <Tag key="form_type">
                      Form : {workoutLabel.data?.form_type}
                    </Tag>
                  ) : null}

                  {/* {workoutLabel.data?.is_verified ? (
                    <Tag color="green" >
                      Verified
                    </Tag>
                  ) : null} */}
                  {/* {workoutLabel.data?.username ? (
                  <Tag color="green" key="warning">
                    {workoutLabel.data?.username}
                  </Tag>
                ) : null} */}
                  {workoutFileDetail.data?.tags
                    .filter((item) => item.key.indexOf("WorkoutTagKeys") < 0)
                    .map((tag) => (
                      <Tag
                        key={tag.key}
                        color={
                          tag.key == "RIGHT_HAND" && tag.value == "True"
                            ? "green"
                            : null
                        }
                        style={{
                          display: tag.key == "BATCH" ? "none" : "block",
                        }}
                      >
                        {KEY_TO_LABEL[tag.key] ?? tag.key}: {tag.value}
                      </Tag>
                    ))}
                  {/* {workoutLabel.data?.is_verified ? (
                    <Tag color="green" key="verified">
                      Verified
                    </Tag>
                  ) : null} */}
                </Row>
              </div>
            }
            extra={getMoreButtons({
              markVerified,
              markReject,
              markUnsure,
              markRelabel,
              saveAnnotation,
              autoMoveToggle,
              formType: workoutLabel.data?.form_type,
              user: user,
              resetFlags: resetFlags,
              poconfirmVisible: state.popConfirm,
              isVerified: workoutLabel.data?.is_verified,
              isRepCountVerified: workoutLabel.data?.is_rep_count_verified,
              isUnsure: workoutLabel.data?.is_unsure,
              fileStatus: state.fileStatus,
              labelStatus: state.labelStatus,
              controlConfig: controlConfig,
              relabel: () => resetAnnotations(),
              formFeedback: formFeedback,
              workoutFileDetail: workoutFileDetail,
              onEditExercise: toggleEditExercise,
              showHistory: toggleShowHistory,
              repEditMode: state.repEditMode,
              autoLabel: autoLabelMutation.mutate,
              isLocked: workoutFileDetail.data?.ex_locked,
            })}
            onBack={() => window.history.back()}
          ></PageHeader>
        ) : null}

        {workoutFileDetail.isLoading ? (
          <Spin />
        ) : (
          <>
            <Row>
              <Col span={14}>
                <div
                  style={{
                    position: "absolute",
                    width: "640px",
                    height: "360px",
                  }}
                >
                  <p
                    style={{
                      textAlign: "right",
                      fontSize: "48px",
                      margin: "1rem 1rem 0rem 1rem",
                    }}
                  >
                    {state.currentStage}
                  </p>
                  <div
                    style={{
                      textAlign: "right",
                      margin: "0rem 1rem 1rem 1rem",
                    }}
                  >
                    <div>
                      <Typography.Text type="secondary">
                        Current Time
                      </Typography.Text>
                    </div>
                    <div>
                      {Math.ceil(state.played * state.totalDuration)}/
                      {state.totalDuration}
                    </div>
                  </div>
                </div>
                <ReactPlayer
                  ref={player}
                  url={workoutFileDetail.data.video_url}
                  controls={true}
                  playbackRate={playbackRate}
                  playing={state.playing}
                  onStart={onStart}
                  onPlay={onPlay}
                  onPause={onPause}
                  onReady={onReady}
                  onProgress={onProgress}
                  width="640px"
                  height="360px"
                  progressInterval={20}
                />

                <PlaybackNStageControls
                  fileState={state.fileStatus}
                  isLabelled={state.isLabelled}
                  isAutoLabelled={state.isAutoLabelled}
                  played={state.played}
                  totalDuration={state.totalDuration}
                  playbackRate={playbackRate}
                  setPlaybackRate={setPlaybackRate}
                  updatePlayBackRate={(newValue) =>
                    updateState((prevState) => ({
                      ...prevState,
                      playbackRate: newValue,
                    }))
                  }
                  onPrevious={onPrevious}
                  currentPlayBackState={state.playing}
                  updatePlaybackState={(playing) =>
                    updateState((prevState) => ({
                      ...prevState,
                      playing: playing,
                    }))
                  }
                  onNext={onNext}
                  changeState={(newState) =>
                    updateState((prevState) => ({
                      ...prevState,
                      currentStage: newState,
                    }))
                  }
                  editMode={state.repEditMode}
                  enableEditMode={() =>
                    updateState((prev) => ({ ...prev, repEditMode: true }))
                  }
                  saveStage={saveStage}
                  currentStage={state.currentStage}
                  switchAnnotationState={switchAnnotationState}
                  resetAnnotations={resetAnnotations}
                  clearAll={resetAnnotations}
                />

                <>
                  {(state.fileStatus == "ACTIVE" || state.isAutoLabelled) &&
                  (!workoutFileDetail.data?.ex_locked || state.isLabelled) ? (
                    <RepLabelChart
                      state={state}
                      data={state.labels}
                      time={state.played * state.totalDuration}
                      // time={state.played}
                      stage={state.currentStage}
                    />
                  ) : workoutFileDetail.data?.ex_locked ? (
                    <div style={{ width: "640px" }}>
                      <Empty description="Exercise Locked"></Empty>
                    </div>
                  ) : null}
                </>
              </Col>
              <Col span={10}>
                <Tabs
                  onChange={(e) => setActiveTab(e)}
                  activeKey={activeTabKey}
                >
                  <Tabs.TabPane tab="Rep Segment" key="rep_segmentation">
                    <RepDetailsView
                      segments={state.annotations.segments || []}
                      isLabelled={state.isLabelled}
                      undoLastStage={undoLastStage}
                      changeStageLabel={changeStageLabel}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Model Prediction" key="model_prediction">
                    <ModelPrediction
                      workoutId={props.match.params.id}
                      pointer={state.played}
                      totalDuration={state.totalDuration}
                    />
                  </Tabs.TabPane>
                </Tabs>

                <div>
                  <Typography.Text type="secondary">Comments</Typography.Text>
                </div>
                <Input.TextArea
                  value={state.comments}
                  onChange={(e) =>
                    updateState((prevState) => ({
                      ...prevState,
                      comments: e.target.value,
                    }))
                  }
                  style={{ height: "100px" }}
                />
              </Col>
            </Row>
          </>
        )}
        {!workoutFileDetail.isLoading && workoutFileDetail.data ? (
          <EditExercise
            onClose={toggleEditExercise}
            visible={state.editMode}
            exercise={workoutFileDetail.data.exercise}
            rightHand={
              workoutFileDetail.data.tags.filter(
                (item) => item.key == "RIGHT_HAND"
              )[0]?.value == "True"
            }
            workoutFileId={props.match.params.id}
          />
        ) : null}
        {!workoutFileDetail.isLoading && workoutFileDetail.data ? (
          <ChangeHistory
            onClose={toggleShowHistory}
            visible={state.showHistory}
            changeHistory={workoutFileDetail.data?.history}
            workoutFileId={props.match.params.id}
          />
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default WorkoutFileDetail;
