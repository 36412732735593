import React from "react";
import * as d3 from "d3";

import { ActualSets, PredSets } from "../../interfaces/modelComparison";
import { exercises } from "../workoutFileAnalysis/constant";
const textures = require('textures');
const lineTexture = textures.default.lines();
type TimelineProps = {
  actualSets: ActualSets[];
  predictedSetsV1: PredSets[];
  predictedSetsV2: PredSets[];
  v1SetMap: any;
  v2SetMap: any;
  onSelectSet?: (startTimestamp: number, endTimestamp: number) => void;
};
const WorkoutTimeline = (props: TimelineProps) => {
  const ref = React.useRef(null);

  const [state, setState] = React.useState<{
    selectedSet: any;
  }>({
    selectedSet: null,
  });

  const onClick = (d: any) => {
    if (!props.onSelectSet) {
      return;
    }
    
    props.onSelectSet(d.start_timestamp || d.creation_date - 60, d.end_timestamp || d.creation_date);
    setState((prev) => ({ ...prev, selectedSet: d }));
  };

  const onMouseOver = (d: any) => {
    // debugger
    // console.log("mouse over");
        let tip = d3.select("body").selectAll(".tooltip") as d3.Selection<
        HTMLDivElement,
        unknown,
        null,
        undefined
        >;
    
        // if (tip.empty()) {
        //   tip = d3
        //     .select("body")
        //     .append("div")
        //     .attr("class", "tooltip")
        //     .style("opacity", 0) ;
        // }
        // Show tooltip
        // d3.select(tooltipRef.current)
          tip
            .style("opacity", 1)
            .style("left", d3.event.pageX + 10 + "px")
            .style("top", d3.event.pageY + 10 + "px")
            .style("background-color", "white")
            .style("border", "solid")
            .style("border-width", "1px")
            .style("border-radius", "5px")
            .style("padding", "10px")
            .style("pointer-events", "none")
            .html(
              `
            <div> Detection State </div>
            <div>Start Time: ${d.start_time || d.start_timestamp}</div>
            <div>End Time: ${d.end_time || d.end_timestamp}</div>
            <div>Exercise: ${d.exercise} ${d.score}</div>
            <div>Reps: ${d.num_reps || d.rep_count}</div>
            </div>
            `
            );
      };
  

  const onMouseMove = (d: any) => {
    // console.log("mouse move");
    // d3.select(tooltipRef.current)
    let tip = d3.select("body").selectAll(".tooltip") as d3.Selection<
    HTMLDivElement,
    unknown,
    null,
    undefined
    >;
    tip
      .style("left", d3.event.pageX + 10 + "px")
      .style("top", d3.event.pageY + 10 + "px");
    
  }

  const onMouseLeave = (d: any) => {
    // console.log("mouse leave");
    // d3.select(tooltipRef.current)
    let tip = d3.select("body").selectAll(".tooltip") as d3.Selection<
    HTMLDivElement,
    unknown,
    null,
    undefined
    >;
    tip.style("opacity", 0);
  }

  const plotTimeline = () => {
    let exerciseColors = d3
      .scaleOrdinal()
      .domain(exercises)
      .range(d3.schemeCategory10);

    d3.select(ref.current).selectAll("*").remove();

    const v1SetMap = props.v1SetMap || {}
    const v2SetMap = props.v2SetMap || {}

    let  startTime = d3.min(
      props.actualSets,
      (d: ActualSets) => d.start_time || d.creation_date
    );

    let startTime2 = d3.min(
      props.predictedSetsV1,
      (d: PredSets) => d.start_timestamp
    );

    let startTime3 = d3.min(
      props.predictedSetsV2,
      (d: PredSets) => d.start_timestamp
    );

    startTime = Math.min(startTime || Infinity, startTime2 || Infinity, startTime3||Infinity);
    
    const endTime = d3.max(
      props.actualSets,
      (d: ActualSets) => d.end_time || d.creation_date
    );
    if (!startTime || !endTime) {
      return;
    }
    const duration = endTime - startTime;

    const margin = { top: 10, right: 30, bottom: 30, left: 60 };
    const width = (duration / 50) * 50 - margin.left - margin.right;
    const height = 380 - margin.top - margin.bottom;

    const svg = d3
      .select(ref.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom + 50)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`)
      
    const x = d3.scaleTime().domain([startTime, endTime]).range([0, width]);
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x));

    let v1Sets = props.predictedSetsV1;
    let v2Sets = props.predictedSetsV2;

    // Plot v1 sets/v2 sets and actual sets on the same timeline on the same y-axis

    let y = d3
      .scaleOrdinal()
      .domain(["v1", "v2", "actual"])
      .range([0, 120, 240]);

    // Plot Rectangles for v1 sets
    svg
      .append("g")
      .selectAll("rect")
      .data(v1Sets)
      .enter()
      .append("rect")
      .attr("x", (d: PredSets) => x(d.start_timestamp))
      .attr("y", y("v1") as number)
      .attr("width", (d: PredSets) => x(d.end_timestamp) - x(d.start_timestamp))
      .attr("height", 100)
      .style("fill", (d) => exerciseColors(d.exercise) as string)
      .style("stroke", (d) => v1SetMap[d.id] ? "none" : "red" )
      .on("mouseover", onMouseOver)
        .on("mousemove", onMouseMove)
        .on("mouseleave", onMouseLeave)
        .on("click", onClick);
      
        

    // Plot Rectangles for v2 sets
    svg.call(lineTexture);

    svg
      .append("g")
      .attr('fill', lineTexture.url())

      .selectAll("rect")
      .data(v2Sets)
      .enter()
      .append("rect")
      .attr("x", (d: PredSets) => x(d.start_timestamp))
      .attr("y", y("v2") as number)
      .attr("width", (d: PredSets) => x(d.end_timestamp) - x(d.start_timestamp))
      .attr("height", 100)
      .style("fill", (d) => exerciseColors(d.exercise) as string)
      .style("stroke", (d) => v2SetMap[d.id] ? "none" : "red" )

      .on("mouseover", onMouseOver)
        .on("mousemove", onMouseMove)
        .on("mouseleave", onMouseLeave)
        .on("click", onClick);
        ;
    console.log(lineTexture.url())
    // Plot Rectangles for actual sets
    svg
      .append("g")
      .attr('fill', lineTexture.url())

      .selectAll("rect")
      .data(props.actualSets)
      .enter()
      .append("rect")
      .attr("x", (d: ActualSets) => x(d.start_time || d.creation_date))
      .attr("y", y("actual") as number)
      .attr(
        "width",
        (d: ActualSets) =>
          x(d.end_time || d.creation_date) -
          x(d.start_time || d.creation_date - 5)
      )
      .attr("height", 100)
      .style("fill", (d) => d.start_time ? exerciseColors(d.exercise) as string :lineTexture.url())
      .style("stroke", (d) => exerciseColors(d.exercise) as string )
      .style("stroke-width", (d) => d.start_time ? 0 : 2)
      .on("mouseover", onMouseOver)
        .on("mousemove", onMouseMove)
        .on("mouseleave", onMouseLeave)
        .on("click", onClick);

    // Add the X Axis
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x));

    // Add the Y Axis
    svg.append("g").call(d3.axisLeft(y as d3.AxisScale<string>));


    
  };

  React.useEffect(() => {
    plotTimeline();
  }, [props.actualSets, props.predictedSetsV1, props.predictedSetsV2]);

  return (
    <div>
         <div  className="tooltip">
        <div></div>
      </div>
      <h3>Timeline</h3>
      <div
        style={{
          overflowY: "auto",
        }}
      >
        <div ref={ref}></div>
      </div>
    </div>
  );
};

export default WorkoutTimeline;
