import { PageHeader, Tabs } from "antd";
import React, { useState } from "react";
import CalibrationConfig from "./calibrationConfig";
import ImportView from "../../admin/importView";
import { ImportRequestList } from "../../admin";
import { AdminAllServices } from "../../../services/dataService";
import { useQuery } from "react-query";
import CalibrationUsers from "./calibrationUsers";

const AdminPanel = () => {
  const [state, updateState] = useState({
    filters: {},
    search: ""
  });
  const importRequests = useQuery(
    ["import_requests", state.filters],
    () => AdminAllServices.listImportRequests(state.filters),
    { staleTime: Infinity }
  );
  return (
    <div>
      <PageHeader title="Admin" />
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Calibration Config" key="1">
          <CalibrationConfig />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Import Logs" key="2">
          <ImportRequestList
            onSearch={(query: string) =>
              updateState((prev) => ({
                ...prev,
                filters: { ...prev.filters, search: query },
              }))
            }
            data={importRequests.data}
            isLoading={importRequests.isLoading}
            onImport={() =>
              updateState((prev) => ({ ...prev, importViewVisible: true }))
            }
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Calibration Users" key="3">
          <CalibrationUsers
            
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default AdminPanel;
