import React, { useState, useRef, useEffect } from "react";
import {
  ConstantItemService,
  WorkoutFileService,
  WorkoutLabelService,
  ModelServices,
  CalibrationFileServices,
} from "../../../services/dataService";
import moment from "moment";
import { useHotkeys } from "react-hotkeys-hook";
import {
  Button,
  Drawer,
  Tag,
  Spin,
  PageHeader,
  Tabs,
  notification,
  message,
  InputNumber,
  Row,
  Space,
  Slider,
  Divider,
  Col,
  Typography,
  Input,
  Popconfirm,
  Popover,
  Empty,
  Switch,
} from "antd";
import {
  RightOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  VideoCameraOutlined,
  DeleteOutlined,
  SaveOutlined,
  AlertOutlined,
  WarningOutlined,
  CloseCircleOutlined,
  MoreOutlined,
  SafetyOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useQuery, useMutation, useQueryClient } from "react-query";
import ReactPlayer from "react-player";
import RepLabelChart from "./labelGraph";
import { Link, useHistory } from "react-router-dom";
import { UserContext } from "../../../contexts";
import createPersistedState from "use-persisted-state";
import { useContext } from "react";
import { exStageMapping, defaultStages } from "../../../constants";
import EditExercise from "./editExerciseName";
import ChangeHistory from "./history";
import { KEY_TO_LABEL } from "../../../constants";
import ModelPrediction from "./modelPrediction";
import { PlaybackNStageControls, StageControl } from "./helpers";
import RepDetailsView from "./repDetailsView";
import { AUTO_LABEL_EXERCISES } from "./constant";

const useWorkoutListViewState = createPersistedState("workoutListViewState");
const playbackRateState = createPersistedState("playbackRateState");
const activeTabState = createPersistedState("activeTabState");
const persistedControlConfig = createPersistedState("controlConfig");


const useLabelFilters = createPersistedState("labelFilters");

const getMoreButtons = (props) => {
  const _getMoreButton = () => {
    return (
      <span key="more">
        <Popover
          placement="topRight"
          content={
            <div>
              <Space
                direction="vertical"
                seperator={<Divider style={{ margin: "5px 0px" }} />}
              >
                <Button
                  type="link"
                  disabled={
                    !(
                      props.isVerified ||
                      props.isUnsure ||
                      props.fileStatus == "ARCHIVED"
                    )
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    props.resetFlags.mutate();
                  }}
                >
                  Reset Flags (Archived/Verified)
                </Button>

                {/* <Button
                  type="link"
                  onClick={(e) => {
                    e.preventDefault();
                    props.showHistory();
                  }}
                >
                  Show History
                </Button> */}
                {/* <Button
                  type="link"
                  onClick={(e) => {
                    e.preventDefault();
                    // props.showHistory();
                    props.autoLabel();
                  }}
                >
                  Auto label
                </Button> */}
                <div>
                  <span style={{ margin: "0rem 1rem" }}>Auto move to next</span>
                  <Switch
                    checked={props.controlConfig.autoMove}
                    onChange={() => props.autoMoveToggle()}
                  ></Switch>
                </div>
              </Space>
            </div>
          }
        >
          <Button type="text" icon={<MoreOutlined />}></Button>
        </Popover>
      </span>
    );
  };
  return [
    props.labelStatus == "unlabelled" && !props.isUnsure ? (
      <Button
        style={{ color: "orange", borderColor: "orange" }}
        onClick={() => props.markUnsure.mutate()}
        icon={<WarningOutlined />}
        key="unsure"
      >
        Unsure
      </Button>
    ) : null,
    props.labelStatus != "labelled" ? (
      <Popconfirm
        title={
          "This will erase existing labels please make sure you want to do this.!!!"
        }
        onConfirm={() => props.relabel()}
        key="relabel"
      >
        <Button icon={<AlertOutlined />}>Reset Labels</Button>
      </Popconfirm>
    ) : null,
    <Button
      onClick={() => props.saveAnnotation.mutate()}
      icon={<SaveOutlined />}
      key="save"
      disabled={props.saveDisabled}
    >
      Save
    </Button>,

    
    <span key="Next">
      {!props.calibrationDetail.isLoading &&
      props.nextFile?.data &&
      props.nextFile?.data.id ? (
        <Link
          to={`/v2/label/${props.nextFile.data?.id}${window.location.search}`}
          key="Next"
        >
          <Button type="primary" disabled={
props.nextFile.data?.id == null || props.nextFile.data?.id == undefined
          }>
            Next <RightOutlined />
          </Button>
        </Link>
      ) : null}
    </span>,
    _getMoreButton(),
  ];
};

const LabelDetail = (props) => {
  const queryClient = useQueryClient();
  const user = useContext(UserContext);
  const [activeTabKey, setActiveTab] = activeTabState("rep_segmentation");
  const [controlConfig, setControlConfig] = persistedControlConfig({
    autoMove: false,
  });
  const [nextStageMapping, setStages] = useState({
    0: 1,
    1: 2,
    2: 3,
    3: 0,
    [-1]: 0,
    [-2]: 0,
  });

  


  const id = props.match.params.id;

  const [nextFileFilters, _] = useState({ filter: { page: 1 } });
  const [calibrationFilters, _ch] = useState({});

  const nextFile = useQuery(
    ["workout_file", id, calibrationFilters],
    () =>
      CalibrationFileServices.getNextFile(id, {
        search: calibrationFilters.search,
        ...calibrationFilters,
        ex_verification_status: "verified"
      }),
    {
      // staleTime: Infinity,
    }
  );

  useEffect(() => {
    // Load query params
    const urlParams = new URLSearchParams(window.location.search);
    const exercise = urlParams.get("exercise");
    const ex_verification_status = urlParams.get("ex_verification_status");
    const search = urlParams.get("search");
    const labelling_status = urlParams.get("labelling_status");
    const import_status = urlParams.get("import_status");
    const kfold_tag = urlParams.get("kfold_tag");
    const include_custom = urlParams.get("include_custom");
    const is_archived = urlParams.get("is_archived");
    const labelling_by = urlParams.get("labelling_by");
    if (
      exercise ||
      ex_verification_status ||
      search ||
      labelling_status ||
      import_status ||
      kfold_tag ||
      include_custom ||
      is_archived ||
      labelling_by
    ) {
      _ch({
        ...nextFileFilters,
        search: search || "",
        exercise: exercise || "",
        ex_verification_status: ex_verification_status || "",
        labelling_status: labelling_status || "",
        import_status: import_status || "",
        kfold_tag: kfold_tag || "",
        include_custom: include_custom ? include_custom == "true" : null,
        is_archived: is_archived ? is_archived == "true" : null,
        labelling_by: labelling_by || "",
      });
    }
  }, []);

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries("workout_file");
      queryClient.invalidateQueries("workout_label");
    };
  }, []);

  const autoRepPredict = useMutation(
    () =>
      ModelServices.autoRepPredict({
        workout_id: props.match.params.id,
      }),
    {
      onSuccess: (data) => {
        populateAutoLabels(data.segments);
        // setState((prev) => ({ ...prev, predData: data }));
      },
    }
  );

  const autoMoveToggle = () =>
    setControlConfig((prev) => ({ ...prev, autoMove: !prev.autoMove }));

  // const workoutFileDetail = useQuery(
  //   ["workout_file", props.match.params.id, nextFileFilters.filter],
  //   () =>
  //     WorkoutFileService.get(props.match.params.id, {
  //       search: nextFileFilters.query,
  //       ...nextFileFilters.filter,
  //     }),
  //   {
  //     staleTime: Infinity,
  //     onSuccess: (data) => {},
  //   }
  // );

  const calibrationDetail = useQuery(
    ["calibration_detail", props.match.params.id],
    () =>
      CalibrationFileServices.get(props.match.params.id, {
        exVerificationStatus: "verified",
      }),
    {
      staleTime: Infinity,
      // enabled: calibrationFilters.filter != null,
    }
  );

  useEffect(() => {
    if (calibrationDetail.data) {
      let exercise = calibrationDetail.data?.exercise;

      if (exStageMapping[exercise]) {
        setStages(() => ({
          ...exStageMapping[exercise],
        }));
      }
      updateState((prev) => ({
        ...prev,
        // fileStatus: calibrationDetail.data?.status,
        exVerificationStatus: calibrationDetail.data?.ex_verification_status,
        labellingStatus: calibrationDetail.data?.labelling_status,
      }));
      loadFeedback(calibrationDetail.data);
    }
  }, [calibrationDetail.data]);
  const loadFeedback = (data) => {
    // debugger
    // const totalDuration = Math.max(data.label_data.map((item) => item.end));
    const labels = [];
    data.label_data.map((item) => {
      labels.push({ x: item.start, y: item.label });
      labels.push({ x: item.end, y: item.label });
    });

    const isLabelled = data.labelling_status == "verified";
    updateState((prevState) => ({
      ...prevState,
      annotations: {
        currentState: 0,
        currentSegment: { start: 0, end: null, label: 0 },
        segments: data.label_data,
      },
      labels: labels,
      isLabelled: isLabelled,
      isAutoLabelled: false,
      labelStatus: data.labelling_status,
      comments: data.comments,
    }));

    // debugger
    if (
      isLabelled &&
      AUTO_LABEL_EXERCISES.indexOf(calibrationDetail.data?.exercise) > -1
    ) {
      // autoRepPredict.mutate();
      // TO BE ENABLED LATER
    }
    return data;
  };

  const history = useHistory();
  useHotkeys(
    "ctrl+s",
    () => {
      console.log("Save PRESSED");
    },
    { filterPreventDefault: true }
  );

  useHotkeys(
    "p, space",
    (e) => {
      e.preventDefault();
      updateState((prevState) => ({
        ...prevState,
        playing: !prevState.playing,
      }));
    },
    { filterPreventDefault: true }
  );

  useHotkeys(
    "ctrl+n, shift+n",
    () => {
      var nextId = nextFile.data?.id;
      if (nextId) {
        history.push(`/v2/label/${nextId}${window.location.search}`);
      }else {
        message.error("No next file found");
      }
      // var url =
      //   !calibrationDetail.isLoading &&
      //   calibrationDetail.data &&
      //   calibrationDetail.data.next_calibration_file
      //     ? `/v2/label/${calibrationDetail.data.next_calibration_file}${window.location.search}`
      //     : null;
      // if (url) {
      //   history.push(url);
      // }
    },
    { filterPreventDefault: true },
    [nextFile.data]
  );

  const player = useRef(null);
  const [playbackRate, setPlaybackRate] = playbackRateState(1);
  const [state, updateState] = useState({
    playbackRate: null,
    playing: false,
    played: 0,
    annotations: {
      currentState: 0,
      segments: [],
      currentSegment: { start: 0, end: null, label: 0 },
    },
    labels: [],
    currentStage: 0,
    currentTime: 0,
    isLabelled: null,
    status: null,
  });

  const populateAutoLabels = (label_data) => {
    const labels = [];
    label_data.map((item) => {
      labels.push({ x: item.start, y: item.label });
      labels.push({ x: item.end, y: item.label });
    });
    updateState((prevState) => ({
      ...prevState,
      labels: labels,
      annotations: {
        ...prevState.annotations,
        segments: label_data,
        auto_segments: label_data,
      },
      label_data: [...label_data],
      repEditMode: true,
      isAutoLabelled: true,
    }));
  };

  // const toggleShowHistory = () => {
  //   updateState((prev) => ({
  //     ...prev,
  //     showHistory: prev.showHistory ? false : true,
  //   }));
  // };

  // const workoutLabel = useQuery(
  //   ["workout_label", workoutFileDetail.data?.label_id],
  //   () => WorkoutLabelService.get(workoutFileDetail.data?.label_id),
  //   {
  //     staleTime: Infinity,
  //     enabled: workoutFileDetail.isLoading
  //       ? false
  //       : workoutFileDetail.data?.label_id != null,
  //     // onSuccess: loadFeedback,
  //   }
  // );

  // useEffect(() => {
  //   if (workoutLabel.data) {
  //     console.log("DATA: ", workoutLabel.data);
  //     loadFeedback(workoutLabel.data);
  //   }
  // }, [workoutLabel.data]);

  const saveAnnotation = useMutation(
    async () => {
      var data = {
        label_data: state.annotations.segments,
        labelling_status:
          state.annotations.segments.length > 0 ? "labelled" : "unsure",
        comments: "",
      };
      return CalibrationFileServices.labelFile(calibrationDetail.data.id, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("workout_file");
        queryClient.invalidateQueries("workout_label");

        notification["success"]({
          message: "Annotations Updated",
          duration: 0.5,
        });
        // var url =
        //   !calibrationDetail.isLoading &&
        //   calibrationDetail.data &&
        //   calibrationDetail.data.next_calibration_file
        //     ? `/v2/label/${calibrationDetail.data.next_calibration_file}${window.location.search}`
        //     : null;
        
        let url  = nextFile.data?.id ? `/v2/label/${nextFile.data?.id}${window.location.search}` : null;
        updateState((prev) => ({ ...prev, repEditMode: false }));
        if (url) {
          history.push(url);
        }
      },
      onError: (error) => {
        notification["error"]({
          message: "Request Failed!!!",
          description: JSON.stringify(error.response.data),
          duration: 0.5,
        });
      },
    }
  );

  const markUnsure = useMutation(
    async () => {
      var data = { labelling_status: "unsure", comments: state.comments };
      return CalibrationFileServices.labelFile(calibrationDetail.data.id, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("workout_file");
        queryClient.invalidateQueries("workout_label");
        notification["success"]({
          message: "File Marked Unsure",
          duration: 0.5,
        });
      },
      onError: (error) => {
        notification["error"]({
          message: "Request Failed!!!",
          description: JSON.stringify(error.response.data),
          duration: 0.5,
        });
      },
    }
  );

  const notificationWithInvalidate = (message, type, invalidate) => {
    if (invalidate) {
      queryClient.invalidateQueries("workout_file");
      queryClient.invalidateQueries("workout_label");
    }
    if (message) {
      notification[type]({
        message: message,
        duration: 0.5,
      });
    }
  };
  // const formFeedback = useMutation(
  //   async (formType) => {
  //     var data = {
  //       form_type: formType,
  //       comments: state.comments,
  //     };
  //     return WorkoutLabelService.update(workoutFileDetail.data.label_id, data);
  //   },
  //   {
  //     onSuccess: () => {
  //       notificationWithInvalidate("Feedback Added", "success", true);
  //     },
  //     onError: (error) => {
  //       notificationWithInvalidate("Request Failed", "error", false);
  //     },
  //   }
  // );

  const resetFlags = useMutation(
    async () => {
      var fileData = { status: "ACTIVE" };
      var data = {
        is_verified: false,
        is_rep_count_verified: false,
        unsure: false,
        comments: state.comments,
        form_type: "",
        status: state.annotations.segments.length > 0 ? "LABELLED" : "PENDING",
      };
      return Promise.all([
        WorkoutFileService.update(calibrationDetail.data.id, fileData),
        WorkoutLabelService.update(calibrationDetail.data.label_id, data),
      ]);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("workout_file");
        queryClient.invalidateQueries("workout_label");
        notification["success"]({
          message: "Success",
          duration: 0.5,
        });
      },
      onError: (error) => {
        notification["error"]({
          message: "Request Failed!!!",
          description: JSON.stringify(error.response.data),
          duration: 0.5,
        });
      },
    }
  );

  const markRelabel = useMutation(
    async () => {
      if (state.comments) {
        var data = {
          is_rep_count_verified: false,
          unsure: false,
          comments: state.comments,
          status: "NEEDS_RELABEL",
          // status: state.annotations.segments.length > 0 ? "LABELLED" : "PENDING",
        };
        return WorkoutLabelService.update(
          calibrationDetail.data.label_id,
          data
        );
      } else {
        message.error("Please add comments");
        return;
      }
    },
    {
      onSuccess: () => {
        if (state.comments) {
          queryClient.invalidateQueries("workout_file");
          queryClient.invalidateQueries("workout_label");
          notification["success"]({
            message: "Success",
            duration: 0.5,
          });
        }
      },
      onError: (error) => {
        notification["error"]({
          message: "Request Failed!!!",
          description: JSON.stringify(error.response.data),
          duration: 0.5,
        });
      },
    }
  );

  const autoLabelMutation = useMutation(
    async () => {
      return WorkoutFileService.autoLabel(calibrationDetail.data.id);
    },
    {
      onMutate: () => {
        message.loading("Computing Autolabel...", 0);
      },
      onSuccess: (data) => {
        message.destroy();
        let labels = [];
        data.rep_progression.map((item) => {
          labels.push({ x: item.start, y: item.label });
          labels.push({ x: item.end, y: item.label });
        });
        notification["success"]({
          message: "Success",
          duration: 2,
        });
        updateState((prevState) => ({
          ...prevState,
          annotations: {
            currentState: 0,
            currentSegment: { start: 0, end: null, label: 0 },
            segments: data.rep_progression,
          },
          isLabelled: false,
          isAutoLabelled: true,
          autoLabelResolution: "",
          labelStatus: "AUTOMATIC",
          labels: labels,
          comments: data.comments,
        }));
        // debugger;
      },
      onError: (error) => {
        message.destroy();
        notification["error"]({
          message: "Request Failed!!!",
          description: JSON.stringify(error.response.data),
          duration: 2,
        });
      },
    }
  );

  const onReady = () => {
    updateState((prevState) => ({
      ...prevState,
      playbackRate: playbackRate || 0.5,
      totalDuration: Math.floor(player.current.getDuration() * 1000),
    }));
  };

  const onStart = () => {
    updateState((prevState) => ({
      ...prevState,
      playbackRate: playbackRate,
      playing: true,
    }));
  };

  const onPlay = () => {
    updateState((prevState) => ({
      ...prevState,
      playbackRate: playbackRate,
      playing: true,
    }));
  };

  const onPause = () => {
    updateState((prevState) => ({
      ...prevState,
      playbackRate: playbackRate,
      playing: false,
    }));
  };

  const onProgress = (data) => {
    var labels = state.labels;
    console.log("Progress", data);
    if (!state.isLabelled && !state.isAutoLabelled) {
      labels.push({
        x: data.played * state.totalDuration,
        y: state.currentStage,
      });
      updateState((prevState) => ({
        ...prevState,
        played: data.played,
        labels: labels,
      }));
    } else {
      var currentStage = (
        calibrationDetail.data?.label_data.length > 0
          ? calibrationDetail.data?.label_data
          : state.label_data
      ).filter(
        (item) =>
          item.start / state.totalDuration < data.played &&
          data.played < item.end / state.totalDuration
      )[0];

      updateState((prevState) => ({
        ...prevState,
        played: data.played,
        currentStage:
          currentStage && currentStage.label != undefined
            ? currentStage.label
            : state.currentStage,
      }));
    }
  };

  const switchAnnotationState = () => {
    var currentAnnotation = JSON.parse(JSON.stringify(state.annotations));
    var currentSegment = currentAnnotation.currentSegment;
    currentSegment["end"] = Math.floor(state.totalDuration * state.played);
    currentSegment["label"] = state.currentStage;
    currentAnnotation.segments.push(currentSegment);
    var newCurrentSegment = {
      start: currentSegment["end"] + 1,
      end: null,
      label: state.currentStage,
    };
    currentAnnotation["currentSegment"] = newCurrentSegment;
    updateState((prevState) => ({
      ...prevState,
      currentStage: nextStageMapping[state.currentStage],
      annotations: currentAnnotation,
      playing: false,
    }));

    // debugger;
  };

  useHotkeys(
    "ctrl+r, shift+r, l",
    () => {
      switchAnnotationState();
    },
    { filterPreventDefault: false },
    [state]
  );

  const resetAnnotations = (nextLabel) => {
    updateState((prevState) => ({
      ...prevState,
      currentStage: nextLabel,
      annotations: {
        currentState: 0,
        segments: [],
        currentSegment: { start: 0, end: null, label: 0 },
      },
      labels: [],
      currentStage: 0,
      currentTime: 0,
      playing: false,
      isLabelled: false,
      repEditMode: false,
      isAutoLabelled: false,
      labelStatus: "unlabelled",
      autoLabelResolution: state.isAutoLabelled ? "REJECTED" : "",
    }));
    player.current.seekTo(0);
  };

  const onPrevious = (by = 35) => {
    const newPos = state.played - by / state.totalDuration;
    player.current.seekTo(newPos);
    const labels = state.labels;
    if (!state.isLabelled && !state.isAutoLabelled) {
      const newLabels = labels.filter((item) => item.x < newPos);
      updateState((prevState) => ({ ...prevState, labels: newLabels }));
    }
  };

  const onNext = (by = 35) => {
    const newPos = state.played + by / state.totalDuration;
    if (newPos <= 1) {
      player.current.seekTo(newPos);
    }
  };

  const undoLastStage = () => {
    const segments = state.annotations.segments;
    const lastSegment = segments[segments.length - 1];
    const newSegments = segments.slice(0, -1);
    // debugger
    const newPos = lastSegment.start / state.totalDuration;
    player.current.seekTo(newPos);
    const labels = state.labels.filter((item) => item.x <= lastSegment.start);
    let currentStage = 0;
    if (newSegments.length > 0) {
      currentStage =
        nextStageMapping[newSegments[newSegments.length - 1].label];
    }

    const currentSegment = lastSegment;
    currentSegment["end"] = null;
    currentSegment["label"] = currentStage;
    updateState((prevState) => ({
      ...prevState,
      labels: labels,
      currentStage: currentStage,
      annotations: {
        ...state.annotations,
        currentSegment: currentSegment,
        segments: newSegments,
      },
      autoLabelResolution: state.isAutoLabelled ? "MODIFIED" : "",
    }));
  };

  const saveStage = (stage) => {
    const segments = state.annotations.segments;
    const newStart = stage["start"];
    const newEnd = stage["end"];
    const label = parseInt(stage["label"]);

    const newSegments = [];
    for (let segment of segments) {
      // Condition to check no  overlap
      if (segment.end < newStart || segment.start > newEnd) {
        // If full overlap
        newSegments.push(segment);
      } else if (segment.start > newStart && segment.end < newEnd) {
        continue;
      } else if (segment.start < newStart && segment.end > newEnd) {
        const prevSegment = { ...segment };
        prevSegment.end = newStart;
        const nextSegment = { ...segment };
        nextSegment.start = newEnd;
        newSegments.push(prevSegment);
        newSegments.push({ start: newStart, end: newEnd, label: label });
        newSegments.push(nextSegment);
      } else if (segment.start < newStart) {
        segment.end = newStart;
        newSegments.push(segment);
        newSegments.push({ start: newStart, end: newEnd, label: label });
      } else if (segment.end > newEnd) {
        segment.start = newEnd;
        // newSegments.push({ start: newStart, end: newEnd, label: label });
        newSegments.push(segment);
      }
    }

    const labels = [];
    newSegments.map((item) => {
      labels.push({ x: item.start, y: item.label });
      labels.push({ x: item.end, y: item.label });
    });
    updateState((prevState) => ({
      ...prevState,
      annotations: {
        ...state.annotations,
        segments: [...newSegments],
      },
      currentStage: nextStageMapping[prevState.currentStage],
      labels: [...labels],
      autoLabelResolution: state.isAutoLabelled ? "MODIFIED" : "",
    }));
  };

  const changeStageLabel = (idx, newLabel) => {
    const segments = state.annotations.segments;
    const updatedSegment = { ...segments[idx] };
    updatedSegment.label = newLabel;
    segments[idx] = updatedSegment;
    const labels = [];
    segments.map((item) => {
      labels.push({ x: item.start, y: item.label });
      labels.push({ x: item.end, y: item.label });
    });
    updateState((prevState) => ({
      ...prevState,
      annotations: {
        ...state.annotations,
        segments: [...segments],
      },
      labels: [...labels],
      repEditMode: true,
    }));
  };

  useHotkeys(
    "left",
    () => {
      onPrevious();
    },
    { filterPreventDefault: true },
    [onPrevious]
  );
  useHotkeys(
    "right",
    () => {
      onNext();
    },
    { filterPreventDefault: true },
    [onNext]
  );
  useHotkeys(
    "shift+right",
    () => {
      onNext(250);
    },
    { filterPreventDefault: true },
    [onNext]
  );

  useHotkeys(
    "shift+p",
    () => {
      onNext(2000);
    },
    { filterPreventDefault: true },
    [onNext]
  );

  useHotkeys(
    "shift+o",
    () => {
      onNext(-2000);
    },
    { filterPreventDefault: true },
    [onNext]
  );

  useHotkeys(
    "shift+left",
    () => {
      onPrevious(250);
    },
    { filterPreventDefault: true },
    [onNext]
  );


  return (
    <React.Fragment>
      <div className="page-content">
        {!calibrationDetail.isLoading ? (
          <PageHeader
            ghost={false}
            // onBack={() => {
            //   // Move to calibrations page 
            //   history.push("/v2/calibrations");
            // }}
            title={
              <div style={{ whiteSpace: "break-spaces" }}>
                <span
                  style={{
                    color:
                      calibrationDetail.data?.labelling_status == "unsure"
                        ? "yellow"
                        : null,
                  }}
                >
                  {`#ID: ${calibrationDetail.data.id}`}
                  {calibrationDetail.data?.ex_verification_status ==
                  "verified" ? (
                    <SafetyOutlined style={{ marginLeft: "1rem" }} />
                  ) : null}
                </span>

                <Row>
                  <Tag
                    color={
                      calibrationDetail.data?.ex_verification_status ==
                      "verified"
                        ? "green"
                        : null
                    }
                    key="exercise"
                  >
                    {!calibrationDetail.isLoading && calibrationDetail.data
                      ? calibrationDetail.data.exercise
                      : ""}{" "}
                    {calibrationDetail.data?.ex_verification_status ==
                    "verified" ? (
                      <SafetyOutlined style={{ marginLeft: "0.25rem" }} />
                    ) : null}
                  </Tag>

                  {calibrationDetail.data?.labelling_status == "labelled" ? (
                    <Tag key="Labelled" color="green">
                      Labelled
                    </Tag>
                  ) : calibrationDetail.data?.labelling_status == "unsure" ? (
                    <Tag key="Unsure" color="yellow">
                      Unsure
                    </Tag>
                  ) : (
                    <Tag key="Pending">Unlabelled</Tag>
                  )}
                  {calibrationDetail.data?.labelled_by ? (
                    <Tag key="username">
                      Labeller: {calibrationDetail.data?.labelled_by}
                    </Tag>
                  ) : null}
                  <Tag key="created_at">
                    Recorded At:{" "}
                    {calibrationDetail.data.record_time
                      ? calibrationDetail.data.record_time
                      : "N/A"}
                  </Tag>
                </Row>
              </div>
            }
            extra={getMoreButtons({
              markUnsure,
              markRelabel,
              saveAnnotation,
              autoMoveToggle,
              user: user,
              resetFlags: resetFlags,
              poconfirmVisible: state.popConfirm,
              labelStatus: state.labelStatus,
              controlConfig: controlConfig,
              saveDisabled:
                state.annotations.segments.length == 0 && !state.comments,
              relabel: () => resetAnnotations(),
              calibrationDetail: calibrationDetail,
              nextFile: nextFile,
              repEditMode: state.repEditMode,
              autoLabel: autoLabelMutation.mutate,
              isLocked: calibrationDetail.data?.ex_locked,
            })}
            onBack={() => history.push("/v2/label")
          }
          ></PageHeader>
        ) : null}

        {calibrationDetail.isLoading ? (
          <Spin />
        ) : (
          <>
            <Row>
              <Col span={14}>
                <div
                  style={{
                    position: "absolute",
                    width: "640px",
                    height: "360px",
                  }}
                >
                  <p
                    style={{
                      textAlign: "right",
                      fontSize: "48px",
                      margin: "1rem 1rem 0rem 1rem",
                    }}
                  >
                    {state.currentStage}
                  </p>
                  <div
                    style={{
                      textAlign: "right",
                      margin: "0rem 1rem 1rem 1rem",
                    }}
                  >
                    <div>
                      <Typography.Text type="secondary">
                        Current Time
                      </Typography.Text>
                    </div>
                    <div>
                      {Math.ceil(state.played * state.totalDuration)}/
                      {state.totalDuration}
                    </div>
                  </div>
                </div>
                <ReactPlayer
                  ref={player}
                  url={calibrationDetail.data.video_url}
                  controls={true}
                  playbackRate={playbackRate}
                  playing={state.playing}
                  onStart={onStart}
                  onPlay={onPlay}
                  onPause={onPause}
                  onReady={onReady}
                  onProgress={onProgress}
                  width="640px"
                  height="360px"
                  progressInterval={20}
                />

                <PlaybackNStageControls
                  // fileState={state.fileStatus}
                  isVerified={calibrationDetail.data?.ex_verification_status == "verified"}
                  isArchived={calibrationDetail.data?.is_rejected}
                  isLabelled={state.labellingStatus == "labelled"}
                  isAutoLabelled={state.isAutoLabelled}
                  played={state.played}
                  totalDuration={state.totalDuration}
                  playbackRate={playbackRate}
                  setPlaybackRate={setPlaybackRate}
                  updatePlayBackRate={(newValue) =>
                    updateState((prevState) => ({
                      ...prevState,
                      playbackRate: newValue,
                    }))
                  }
                  onPrevious={onPrevious}
                  currentPlayBackState={state.playing}
                  updatePlaybackState={(playing) =>
                    updateState((prevState) => ({
                      ...prevState,
                      playing: playing,
                    }))
                  }
                  onNext={onNext}
                  changeState={(newState) =>
                    updateState((prevState) => ({
                      ...prevState,
                      currentStage: newState,
                    }))
                  }
                  editMode={state.repEditMode}
                  enableEditMode={() =>
                    updateState((prev) => ({ ...prev, repEditMode: true }))
                  }
                  saveStage={saveStage}
                  currentStage={state.currentStage}
                  switchAnnotationState={switchAnnotationState}
                  resetAnnotations={resetAnnotations}
                  clearAll={resetAnnotations}
                />

                <>
                  {(state.exVerificationStatus == "verified" ||
                    state.isAutoLabelled) &&
                  (!calibrationDetail.data?.ex_locked || state.isLabelled) ? (
                    <RepLabelChart
                      state={state}
                      data={state.labels}
                      time={state.played * state.totalDuration}
                      // time={state.played}
                      stage={state.currentStage}
                    />
                  ) : calibrationDetail.data?.ex_locked ? (
                    <div style={{ width: "640px" }}>
                      <Empty description="Exercise Locked"></Empty>
                    </div>
                  ) : null}
                </>
              </Col>
              <Col span={10}>
                <Tabs
                  onChange={(e) => setActiveTab(e)}
                  activeKey={activeTabKey}
                >
                  <Tabs.TabPane tab="Rep Segment" key="rep_segmentation">
                    <RepDetailsView
                      segments={state.annotations.segments || []}
                      isLabelled={state.isLabelled}
                      undoLastStage={undoLastStage}
                      changeStageLabel={changeStageLabel}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Model Prediction" key="model_prediction">
                    <ModelPrediction
                      workoutId={props.match.params.id}
                      pointer={state.played}
                      totalDuration={state.totalDuration}
                    />
                  </Tabs.TabPane>
                </Tabs>

                <div>
                  <Typography.Text type="secondary">Comments</Typography.Text>
                </div>
                <Input.TextArea
                  value={state.comments}
                  onChange={(e) =>
                    updateState((prevState) => ({
                      ...prevState,
                      comments: e.target.value,
                    }))
                  }
                  style={{ height: "100px" }}
                />
              </Col>
            </Row>
          </>
        )}
        {/* {!workoutFileDetail.isLoading && workoutFileDetail.data ? (
          <EditExercise
            onClose={toggleEditExercise}
            visible={state.editMode}
            exercise={workoutFileDetail.data.exercise}
            rightHand={
              workoutFileDetail.data.tags.filter(
                (item) => item.key == "RIGHT_HAND"
              )[0]?.value == "True"
            }
            workoutFileId={props.match.params.id}
          />
        ) : null}
        {!workoutFileDetail.isLoading && workoutFileDetail.data ? (
          <ChangeHistory
            onClose={toggleShowHistory}
            visible={state.showHistory}
            changeHistory={workoutFileDetail.data?.history}
            workoutFileId={props.match.params.id}
          />
        ) : null} */}
      </div>
    </React.Fragment>
  );
};

export default LabelDetail;
