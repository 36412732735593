import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Card,
  Row,
  Col,
  List,
  Tag,
  PageHeader,
  Popover,
  Space,
  Select,
  message,
  Divider,
} from "antd";
import { AllUserDashboard } from "../../services/dataService";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import {
  FilterOutlined,
  MoreOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";

import createPersistedState from "use-persisted-state";
const useWorkoutListViewState = createPersistedState(
  "callibrationListViewState"
);

const UserCard = ({ item, batch, query }) => {
  const queryClient = useQueryClient();

  const updateUser = useMutation(
    async (newDetails) => {
      var batchData = item.metadata[[batch]] || {};

      var data = {
        user_name: item.name,
        ...item?.metadata,
        [batch]: {...batchData, ...newDetails},
      };
      return AllUserDashboard.updateCalUserData(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("cal_user_files");
        message.success("Updated Data!!");
      },
      onError: (error) => {
        message.success("Update Data Failed!!");
      },
    }
  );

  return (
    <Link to={`/callibration/${item.name}?batch=${batch || ""}&query=${query || ""}`}>
      <Card hoverable>
        <div>
          <Row align="middle" justify="space-between" style={{ width: "100%" }}>
            <span>
              <b>{item.name} </b>
            </span>

            <Space>
              {item.metadata?.[[batch]]?.removed ? (
                <Tag color="red">Removed</Tag>
              ) : item.metadata?.[[batch]]?.paid ? (
                <Tag color="green">Paid</Tag>
              ) : item.metadata?.[[batch]]?.all_completed ? (
                <Tag color="green">Completed</Tag>
              ) : null}

              {/* <span style={{color: "gray"}}><em>{item.firebase_extracted_data.date} </em> </span> - 🕒 {item.firebase_extracted_data.duration} sec | 🏋️ {item.firebase_extracted_data.exercises} | 🔥 {Math.round(item.firebase_extracted_data.calories)}  */}
              <Tag color="blue">{item.completed_exercises} Exercises</Tag>
              <Tag color="blue">{item.completed_sets} Sets</Tag>
              <Popover
                placement="topRight"
                content={
                  <div>
                    <Space
                      direction="vertical"
                      seperator={<Divider style={{ margin: "5px 0px" }} />}
                    >
                      {!item.metadata?.paid ? (
                        <Button
                          type="link"
                          onClick={(e) => {
                            e.preventDefault();
                            updateUser.mutate({ paid: true });
                          }}
                        >
                          Mark Paid
                        </Button>
                      ) : null}

                      {!item.metadata?.all_completed ? (
                        <Button
                          type="link"
                          onClick={(e) => {
                            e.preventDefault();
                            updateUser.mutate({ all_completed: true });
                          }}
                        >
                          Mark Completed
                        </Button>
                      ) : null}

                      {!item.metadata?.removed &&
                      !(item.metadata?.paid || item.metadata?.all_completed) ? (
                        <Button
                          danger
                          type="link"
                          onClick={(e) => {
                            e.preventDefault();
                            updateUser.mutate({ removed: true });
                          }}
                        >
                          Remove
                        </Button>
                      ) : null}
                    </Space>
                  </div>
                }
              >
                <Button type="text" icon={<MoreOutlined />}></Button>
              </Popover>
            </Space>
          </Row>
        </div>
      </Card>
    </Link>
  );
};

const AllUserList = (props) => {
  // 
  const [state, updateState] = useWorkoutListViewState({
    filter: { page: 1, },
  });
  const queryClient = useQueryClient();

  const userData = useQuery(
    ["cal_user_files", state.query, state.filter],
    () =>
      AllUserDashboard.allUserData({ search: state.query, ...state.filter }),
    {}
  );

  const downloadUser = useMutation(
    async (state) => {
      var data = { search: state.query, ...state.filter };
      return AllUserDashboard.downloadCalUserData(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("cal_user_files");
        // message.success("Updated Data!!");
      },
      onError: (error) => {
        // message.success("Update Data Failed!!");
      },
    }
  );

  const bulkStatusChange = useMutation(
    async (state) => {
      var data = { search: state.query, ...state.filter };
      return AllUserDashboard.bulkChangePaid(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("cal_user_files");
        message.success("Updated Data!!");
      },
      onError: (error) => {
        message.success("Update Data Failed!!");
      },
    }
  );

  const routes = [
    {
      path: "dashboard",
      breadcrumbName: "Dashboard",
    },
    {
      path: "all_user_data",
      breadcrumbName: "Data Collection",
    },
  ];

  const paidFilters = [
    { label: "Paid", key: "paid" },
    { label: "Unpaid", key: "unpaid" },

    // { label: "Completed 80%", key: "complete_80" },
  ];
  const completedFilters = [
    { label: "Completed 90%", key: "complete_90" },
    { label: "Completed", key: "completed_all" },
  ];

  const userFilter = [
    { label: "Removed", key: "removed" },
    { label: "Inactive", key: "inactive" },
  ];

  function itemRender(route, params, routes, paths) {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={`/${paths.join("/")}`}>{route.breadcrumbName}</Link>
    );
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <PageHeader
          ghost={false}
          // onBack={() => window.history.back()}
          title="Data Collection"
          // breadcrumb={{routes, itemRender}}
          extra={
            [
              // <div>
              //   <Row>
              //     <Button
              //       onClick={() =>
              //         updateState((prevState) => ({
              //           ...prevState,
              //           importDrawerFlag: true,
              //         }))
              //       }
              //     >
              //       Import File
              //     </Button>
              //   </Row>
              // </div>,
            ]
          }
        ></PageHeader>
      </div>
      {/* <div>Filters</div> */}
      <Row align="middle" style={{ marginBottom: "1rem" }}>
        <Col span={6}>
          <Input.Search
            // value={state.query}
            defaultValue={state.query}
            onSearch={(e) =>
              updateState((prevState) => ({ ...prevState, query: e }))
            }
          />
        </Col>
        <Col span={1}></Col>
        <Col span={12}>
          <Space>
            {paidFilters.map((item) => (
              <Tag.CheckableTag
                checked={state.filter["paid_filter"] == item.key}
                key={item.key}
                onClick={(checked) =>
                  updateState((prev) => ({
                    ...prev,
                    filter: {
                      ...state.filter,
                      paid_filter:
                        prev.filter.paid_filter != item.key ? item.key : null,
                    },
                  }))
                }
              >
                {item.label}
              </Tag.CheckableTag>
            ))}
            <Divider
              type="vertical"
              style={{ borderLeft: "1px solid rgb(0 0 0 / 50%)" }}
            />
            {completedFilters.map((item) => (
              <Tag.CheckableTag
                checked={state.filter["completed_filter"] == item.key}
                key={item.key}
                onClick={(e) =>
                  updateState((prev) => ({
                    ...prev,
                    filter: {
                      ...state.filter,
                      completed_filter:
                        prev.filter.completed_filter != item.key
                          ? item.key
                          : null,
                    },
                  }))
                }
              >
                {item.label}
              </Tag.CheckableTag>
            ))}
            <Divider
              type="vertical"
              style={{ borderLeft: "1px solid rgb(0 0 0 / 50%)" }}
            />

            {userFilter.map((item) => (
              <Tag.CheckableTag
                checked={state.filter["user_filter"] == item.key}
                key={item.key}
                onClick={(e) =>
                  updateState((prev) => ({
                    ...prev,
                    filter: {
                      ...state.filter,
                      user_filter:
                        prev.filter.user_filter != item.key ? item.key : null,
                    },
                  }))
                }
              >
                {item.label}
              </Tag.CheckableTag>
            ))}
            {/* <Divider
              type="vertical"
              style={{ borderLeft: "1px solid rgb(0 0 0 / 50%)" }}
            /> */}
            {/* <Select
              defaultValue="R5"
              value={state.filter.batch}
              onSelect={(e) =>
                updateState((prev) => ({
                  ...prev,
                  filter: {
                    ...prev.filter,
                    batch: e,
                  },
                }))
              }
              // style={{ width: 120 }}
              bordered={false}
            >
              <Select.Option value="R5">R5</Select.Option>
              <Select.Option value="R6">R6</Select.Option>
              <Select.Option value="R7">R7</Select.Option>
              <Select.Option value="R8">R8</Select.Option>
              <Select.Option value="R9">R9</Select.Option>
              <Select.Option value="R10">R10</Select.Option>
              <Select.Option value="R11">R11</Select.Option>
              <Select.Option value="R12">R12</Select.Option>
            </Select> */}
          </Space>
        </Col>
        <Col span={5}>
          <Row
            align="middle"
            justify="end"
            style={{ width: "100%", paddingRight: "20px" }}
          >
            {userData.data?.results ? (
              <span>
                <b>{`Showing ${userData.data?.results?.length}/${userData.data?.total}`}</b>
              </span>
            ) : null}
            <Space align="end" justify="middle">
              <Popover
                placement="bottomRight"
                content={
                  <div>
                    <Space
                      direction="vertical"
                      seperator={<Divider style={{ margin: "5px 0px" }} />}
                    >
                      <Button
                        type="link"
                        onClick={(e) => {
                          // e.preventDefault();
                          downloadUser.mutate(state);
                        }}
                      >
                        Download Users
                      </Button>
                      <Button
                        type="link"
                        onClick={(e) => {
                          // e.preventDefault();
                          bulkStatusChange.mutate(state);
                        }}
                        icon={<ArrowRightOutlined />}
                      >
                        Move to paid
                      </Button>
                    </Space>
                  </div>
                }
              >
                <Button type="text" icon={<MoreOutlined />}></Button>
              </Popover>
            </Space>
          </Row>
        </Col>
      </Row>
      {/* {state.filter.selectedExercises?.length ||
      state.filter.selectedBatches?.length ||
      state.filter.status ||
      state.filter.isUnsure ? (
        <Row style={{ marginBottom: "1rem" }}>
          {state.filter.selectedExercises?.length ? (
            <Tag
              closable
              onClose={() => {
                updateState((prevState) => ({
                  ...prevState,
                  filter: { ...state.filter, selectedExercises: [], page: 1 },
                }));
              }}
            >{`Selected Exercises: ${state.filter.selectedExercises}`}</Tag>
          ) : null}
          {state.filter.selectedBatches?.length ? (
            <Tag
              closable
              onClose={() => {
                updateState((prevState) => ({
                  ...prevState,
                  filter: { ...state.filter, selectedBatches: [], page: 1 },
                }));
              }}
            >{`Selected Batch: ${state.filter.selectedBatches}`}</Tag>
          ) : null}
          {state.filter.status ? (
            <Tag
              closable
              onClose={() => {
                updateState((prevState) => ({
                  ...prevState,
                  filter: { ...state.filter, status: "", page: 1 },
                }));
              }}
            >{`Status: ${state.filter.status}`}</Tag>
          ) : null}
          {state.filter.isUnsure ? (
            <Tag
              closable
              onClose={() => {
                updateState((prevState) => ({
                  ...prevState,
                  filter: { ...state.filter, isUnsure: "", page: 1 },
                }));
              }}
            >{`Is Unsure: ${state.filter.isUnsure}`}</Tag>
          ) : null}
        </Row>
      ) : null} */}

      <List
        // pagination={{
        //   onChange: (page) => {
        //     updateState((prevState) => ({
        //       ...prevState,
        //       filter: { ...state.filter, page: page },
        //     }));
        //   },
        //   total: userData.data ? userData.data.count : 0,
        //   current: state.filter.page,
        // }}
        grid={{
          gutter: 12,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 2,
          xxl: 2,
        }}
        dataSource={
          userData.data
            ? userData.data.results.filter(
                (item) => item.batch == state.filter.batch
              )
            : []
        }
        loading={userData.isLoading}
        renderItem={(item, idx) => (
          <List.Item key={idx}>
            <UserCard item={item} idx={idx} batch={state.filter.batch} query={state.query} />{" "}
          </List.Item>
        )}
      />
    </React.Fragment>
  );
};

export default AllUserList;
