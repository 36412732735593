import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Card,
  Row,
  Col,
  List,
  Tag,
  PageHeader,
  Select,
  Radio,
  Space,
  Typography,
} from "antd";
import {
  ConstantItemService,
  ConstantListService,
  VariableFilterValues,
  WorkoutFileService,
} from "../../services/dataService";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import moment from "moment";

// import { fieldToName } from "../../../../constant";

const statusOption = [
  { label: "All", value: "" },
  { label: "Pending", value: "PENDING" },
  { label: "Labelled", value: "LABELLED" },
  { label: "Archived", value: "ARCHIVED" },
  { label: "Automatic", value: "AUTOMATIC"},
  { label: "Unsure", value: "UNSURE" },
  { label: "Import Failed", value: "IMPORT_FAILED" },
  { label: "Export Failed", value: "EXPORT_ERROR" },
  // { label: "Uploaded", value: "uploaded" },
  // { label: "Completed", value: "completed" },
];

const fileStatusOption = [
  { label: "All", value: "" },
  { label: "Active", value: "ACTIVE" },
  { label: "New Data", value: "NEW_DATA" },
  { label: "Archived", value: "ARCHIVED" },
  { label: "Backlog", value: "BACKLOG" },
  { label: "Unsure", value: "UNSURE" },
  { label: "Ignore Unsure", value: "NOT_UNSURE" },

  { label: "Import Failed", value: "IMPORT_FAILED" },
  { label: "Export Failed", value: "EXPORT_ERROR" },
];

const labellingStatusOption = [
  { label: "All", value: "" },
  { label: "Pending", value: "PENDING" },
  { label: "Labelled", value: "LABELLED" },
  { label: "Automatic", value: "AUTOMATIC" },
  { label: "Needs Relabelling", value: "NEEDS_RELABEL" },
];

const unsureOptions = [
  { label: "All", value: "" },
  { label: "Unsure", value: true },
  { label: "Sure", value: false },
];

const isVerifiedOptions = [
  { label: "All", value: "" },
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];

const isProdOptions = [
  { label: "All", value: "" },
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];

const rightHandOptions = [
  { label: "All", value: "" },
  { label: "Yes", value: "True" },
  { label: "No", value: "False" },
];

// const archivedOptions = [
//   { label: "All", value: "" },
//   { label: "Un-Archived", value: true },
//   { label: "Archived", value: false },
// ];

const FilterGroup = ({ item, onEdit, filters = {}, onChange }) => {
  const [filterValues, updateFilterValues] = useState({
    unsureOptions,
    isVerifiedOptions,
    rightHandOptions,
    statusOption,
    isProdOptions,
    exerciseList: [],
    batchList: [],
    kfoldTagsValues: [],
  });

  const [state, updateState] = useState({
    selectedExercises: [],
    selectedBatches: [],
    status: "",
    isUnsure: "",
    ...filters,
  });

  // useEffect(() => {
  //   updateState((prevState) => ({ ...prevState, ...filters }));
  // }, [filters]);

  // useEffect(() => {
  //   onChange(state);
  // }, [state]);

  const constants = useQuery(["constant"], () => ConstantListService.getAll(), {
    onSuccess: (data) => {
      var exerciseList =
        data.filter((item) => item.key == "exercise_list")[0] || [];
      if (exerciseList) {
        exerciseList = exerciseList.value.items.map((item) => ({
          label: item.label || item.name,
          value: item.name,
        }));
      }
      var batchList = data.filter((item) => item.key == "batch_list")[0] || [];
      if (batchList) {
        batchList = batchList.value.items.map((item) => ({
          label: item.label || item.name,
          value: item.name,
        }));
      }
      updateFilterValues({ ...filterValues, batchList, exerciseList });
    },
  });

  const kfoldTags = useQuery(
    ["constant", "kfold"],
    () => WorkoutFileService.getKFoldTags(),
    {
      onSuccess: (data) =>
        updateFilterValues((prev) => ({
          ...prev,
          kfoldTagsValues: data.values.map((val) => ({
            label: val,
            value: val,
          })),
        })),
    }
  );

  const variableFilters = useQuery(["constants", "variableFilters"], () =>
    VariableFilterValues.getAll()
  );

  return (
    <div style={{ width: "700px", padding: "1rem" }}>
      <Row  gutter={16}>
        <Col span={12}>
          <Space style={{ width: "100%" }} direction="vertical">
            <div style={{ padding: "5px 0px" }}>
              <Typography.Text type="secondary">Exercises</Typography.Text>
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="All Exercises"
                value={filters.selectedExercises?.split(",")}
                onChange={(e) =>
                  onChange({
                    ...filters,
                    selectedExercises: e.length > 0 ? e.join(",") : null,
                  })
                }
              >
                {filterValues.exerciseList.map((item) => (
                  <Select.Option key={item.value}>{item.label}</Select.Option>
                ))}
              </Select>
            </div>
            <div style={{ padding: "5px 0px" }}>
              <Typography.Text type="secondary">File Status</Typography.Text>

              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="File Status"
                value={filters.fileStatus?.split(",")}
                onChange={(e) =>
                  onChange({
                    ...filters,
                    fileStatus: e.length > 0 ? e.join(",") : null,
                  })
                }
              >
                {fileStatusOption.map((item) => (
                  <Select.Option key={item.value}>{item.label}</Select.Option>
                ))}
              </Select>
            </div>
            <div style={{ padding: "5px 0px" }}>
              <Typography.Text type="secondary">
                Labelling Status
              </Typography.Text>

              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Labelling Status"
                value={filters.labelStatus?.split(",")}
                onChange={(e) =>
                  onChange({
                    ...filters,
                    labelStatus: e.length > 0 ? e.join(",") : null,
                  })
                }
              >
                {labellingStatusOption.map((item) => (
                  <Select.Option key={item.value}>{item.label}</Select.Option>
                ))}
              </Select>
            </div>
            {/* <div style={{ padding: "5px 0px" }}>
          <Typography.Text type="secondary">File Status</Typography.Text>
          <br />
          <Radio.Group
            options={filterValues.statusOption}
            onChange={(e) => onChange({ ...filters, status: e.target.value, })}

            value={filters.status}
            optionType="button"
          />
        </div> */}
            {/* <div style={{ padding: "5px 0px" }}>
              <Typography.Text type="secondary">Is Unsure</Typography.Text>
              <br />
              <Radio.Group
                style={{}}
                options={filterValues.unsureOptions}
                // onChange={(e) =>
                //   updateState((prevState) => ({
                //     ...prevState,
                //     isUnsure: e.target.value,
                //   }))
                // }
                onChange={(e) =>
                  onChange({ ...filters, isUnsure: e.target.value })
                }
                value={filters.isUnsure}
                optionType="button"
              />
            </div> */}
            <div style={{ padding: "5px 0px" }}>
              <Typography.Text type="secondary">Is Prod</Typography.Text>
              <br />
              <Radio.Group
                style={{}}
                options={filterValues.isProdOptions}
                // onChange={(e) =>
                //   updateState((prevState) => ({
                //     ...prevState,
                //     isUnsure: e.target.value,
                //   }))
                // }
                onChange={(e) =>
                  onChange({ ...filters, isProd: e.target.value })
                }
                value={filters.isProd}
                optionType="button"
              />
            </div>
            <div style={{ padding: "5px 0px" }}>
              <Typography.Text type="secondary">Is Automatic</Typography.Text>
              <br />
              <Radio.Group
                style={{}}
                options={filterValues.isProdOptions}
                // onChange={(e) =>
                //   updateState((prevState) => ({
                //     ...prevState,
                //     isUnsure: e.target.value,
                //   }))
                // }
                onChange={(e) =>
                  onChange({ ...filters, isAutomatic: e.target.value })
                }
                value={filters.isAutomatic}
                optionType="button"
              />
            </div>
          </Space>
        </Col>
        <Col span={12}>
          <Space style={{ width: "100%" }} direction="vertical">
            <div style={{ padding: "5px 0px" }}>
              <Typography.Text type="secondary">Is Verified</Typography.Text>
              <br />
              <Radio.Group
                style={{}}
                options={filterValues.isVerifiedOptions}
                // onChange={(e) =>
                //   updateState((prevState) => ({
                //     ...prevState,
                //     isUnsure: e.target.value,
                //   }))
                // }
                onChange={(e) =>
                  onChange({ ...filters, isVerified: e.target.value })
                }
                value={filters.isVerified}
                optionType="button"
              />
            </div>
            <div style={{ padding: "5px 0px" }}>
              <Typography.Text type="secondary">
                Is Right Hand Data
              </Typography.Text>
              <br />
              <Radio.Group
                style={{}}
                options={filterValues.rightHandOptions}
                // onChange={(e) =>
                //   updateState((prevState) => ({
                //     ...prevState,
                //     isUnsure: e.target.value,
                //   }))
                // }
                onChange={(e) =>
                  onChange({ ...filters, rightHand: e.target.value })
                }
                value={filters.rightHand}
                optionType="button"
              />
            </div>

            <div style={{ padding: "5px 0px" }}>
              <Typography.Text type="secondary">Updated by</Typography.Text>

              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Updated By"
                value={filters.updatedBy?.split(",")}
                onChange={(e) =>
                  onChange({
                    ...filters,
                    updatedBy: e.length > 0 ? e.join(",") : null,
                  })
                }
              >
                {variableFilters.data?.updated_by.map((item) => (
                  <Select.Option key={item}>{item}</Select.Option>
                ))}
              </Select>
            </div>

            <div style={{ padding: "5px 0px" }}>
              <Typography.Text type="secondary">Import Batch</Typography.Text>

              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Import Batch"
                value={filters.importBatch?.split(",")}
                onChange={(e) =>
                  onChange({
                    ...filters,
                    importBatch: e.length > 0 ? e.join(",") : null,
                  })
                }
              >
                {variableFilters.data?.import_flags.map((item) => (
                  <Select.Option key={item}>{item}</Select.Option>
                ))}
              </Select>
            </div>
            {/* <div style={{ padding: "5px 0px" }}>
          <Typography.Text type="secondary">KFold Tags</Typography.Text>

          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="KFold Tags"
            value={filters.kfoldTag?.split(",")}
            onChange={(e) =>
              onChange({
                ...filters,
                kfoldTags: e.length > 0 ? e.join(",") : null,
              })
            }
          >
            {filterValues.kfoldTagsValues.map((item) => (
              <Select.Option key={item.value}>{item.label}</Select.Option>
            ))}
          </Select>
        </div> */}

            <div style={{ padding: "5px 0px" }}>
              <Typography.Text type="secondary">KFold Tags</Typography.Text>

              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="KFold Tags"
                value={filters.kfoldTag?.split(",")}
                onChange={(e) =>
                  onChange({
                    ...filters,
                    kfoldTags: e.length > 0 ? e.join(",") : null,
                  })
                }
              >
                {filterValues.kfoldTagsValues.map((item) => (
                  <Select.Option key={item.value}>{item.label}</Select.Option>
                ))}
              </Select>
            </div>
          </Space>
        </Col>
      </Row>
      {/* {state.selectedExercises.length == 0 ? "All Exercises" : ""} */}

      {/* {state.selectedBatches.length == 0 ? "All Batches" : ""} */}
    </div>
  );
};

export default FilterGroup;
