import React, { useCallback, useEffect, useState } from "react";
import {
  Input,
  Button,
  Card,
  Row,
  Col,
  List,
  Tag,
  PageHeader,
  Popover,
  Space,
  Drawer,
  message,
  notification,
  Select,
  Tabs,
} from "antd";
import {
  ProdWorkoutService,
  LabelledCSVService,
  CustomService,
} from "../../services/dataService";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import moment from "moment";
import { FilterOutlined, MoreOutlined, InboxOutlined } from "@ant-design/icons";
// import { fieldToName } from "../../../../constant";
import ImportFileComponent from "./components";
import createPersistedState from "use-persisted-state";
import _ from "lodash";
import DebugSet from "./debugSet";
import SimWorkoutView from "../prodWorkoutFileDetails/simWorkoutView";
import CompareModel from "./compareModel";
const useWorkoutListViewState = createPersistedState("workoutListViewState");

const WorkoutCard = ({ item }) => {
  const getColor = (status) => {
    if (status == "failed") {
      return "red";
    } else if (status == "processing") {
      return "yellow";
    } else if (status == "processed") {
      return "green";
    }
    return "blue";
  };

  return (
    <Link to={`/prod_files/${item.workoutID}/`}>
      <Card hoverable>
        <div style={{ width: "100%" }}>
          <div>
            <span style={{ color: "gray" }}>
              <em>{moment(item.timeStarted * 1000).format("YYYY-MM-DD")} </em>{" "}
            </span>{" "}
            - 🕒 {Math.round(item.timeActive)} sec | 🏋️ {item.sets.length} | 🔥{" "}
            {Math.round(item.workoutCalories)}
          </div>
          <div></div>
        </div>
      </Card>
    </Link>
  );
};

const ProdWorkoutFileList = (props) => {
  const [state, updateState] = useWorkoutListViewState({ filter: { page: 1 } });

  // const workoutFiles = useQuery(
  //   ["prod_workout_file", state.query],
  //   () => ProdWorkoutService.getAll({ search: state.query, ...state.filter }),
  //   {}
  // );

  const searchUser = useQuery(
    ["search_user", state.query],
    () => CustomService.searchUser({ query: state.query }),
    {
      enabled: state.query ? true : false,
      staleTime: Infinity,
      onSuccess: (data) => {
        updateState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      },
    }
  );

  const userWorkouts = useQuery(
    ["user_workouts", state.selectedUser],
    () =>
      CustomService.getWorkouts({
        user_id: state.selectedUser,
      }),
    {
      enabled: state.selectedUser ? true : false,
      staleTime: Infinity,
    }
  );

  const debounceUpdate = useCallback(
    _.debounce((value) => {
      updateState((prevState) => ({
        ...prevState,
        query: value,
      }));
    }, 1000),
    []
  );
  const onSearch = (value) => {
    // Add a debounce here

    updateState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    debounceUpdate(value);
  };
  console.log(searchUser.data);

  const options = [
    { label: "Failed", value: "failed" },
    { label: "Processed", value: "processed" },
    { label: "Processing", value: "processing" },
  ];
  return (
    <React.Fragment>
      <Drawer
        destroyOnClose={true}
        title={"Import File"}
        width={600}
        visible={state.importDrawerFlag}
        onClose={() =>
          updateState((prevState) => ({
            ...prevState,
            importDrawerFlag: false,
          }))
        }
      >
        <ImportFileComponent />
      </Drawer>

      {/* <div className="page-content">
        <PageHeader
          ghost={false}
          //   onBack={() => window.history.back()}
          title="User Workout Debugger"
          
        ></PageHeader>
      </div> */}
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="User Workouts" key="1">
          {/* <div>Filters</div> */}
          <div style={{ margin: "4px" }}>
            <Row style={{ marginBottom: "1rem" }}>
              <Col span={8}>
                <Select
                  size="large"
                  showSearch
                  style={{
                    width: "100%",
                    margin: "4px",
                  }}
                  value={state.selectedUser}
                  placeholder="Search User"
                  loading={searchUser.isLoading}
                  onSearch={onSearch}
                  filterOption={false}
                  onChange={(value) => {
                    updateState((prevState) => ({
                      ...prevState,
                      selectedUser: value,
                    }));
                  }}

                  // options={
                  //   searchUser.data?.map((item) => ({
                  //     value: item._id,
                  //     label: `${item._source.name} - ${item._source.email}`,
                  //   })) || []
                  // }
                  // options={
                  //   options
                  // }
                >
                  {searchUser.data?.map((item) => (
                    <Select.Option value={item._id} key={item._id}>
                      {item._source.name} - {item._source.email}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>

            <List
              // pagination={{
              //   onChange: (page) => {
              //     updateState((prevState) => ({
              //       ...prevState,
              //       filter: { ...state.filter, page: page },
              //     }));
              //   },
              //   total: workoutFiles.data ? workoutFiles.data.count : 0,
              //   current: state.filter.page,
              // }}
              grid={{
                gutter: 12,
                xs: 1,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2,
                xxl: 2,
              }}
              dataSource={userWorkouts.data ? userWorkouts.data : []}
              loading={userWorkouts.isLoading}
              renderItem={(item, idx) => (
                <List.Item key={idx}>
                  <WorkoutCard item={item} idx={idx} />{" "}
                </List.Item>
              )}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Debug Set" key="2">
          <DebugSet />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Simulations" key="3">
          <SimWorkoutView />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Compare Model" key="4">
          <CompareModel />
        </Tabs.TabPane>
      </Tabs>
    </React.Fragment>
  );
};

export default ProdWorkoutFileList;
