import React from "react";
import { MiscServices } from "../../../services/dataService";
import { useMutation, useQuery } from "react-query";
import { Button, Col, Input, Row, Switch, Typography } from "antd";

const CalibrationConfig = () => {
  const [search, setSearch] = React.useState("");

  const calibrationConfig = useQuery(
    ["calibrationConfig"],
    () => MiscServices.getCalibrationConfig(),
    {
      staleTime: 1000 * 60 * 60 * 24,
      onSuccess: (data) => {
        setConfig(data);
      },
      
    }
  );

  const [config, setConfig] = React.useState(calibrationConfig.data);


  let filteredExercises =
    config?.exercises?.filter((exercise: any) => {
      return exercise.name.toLowerCase().includes(search.toLowerCase());
    }) ?? [];

  const onSwitchChange = (key: string, checked: boolean) => {
    // Update in config
    const newConfig = { ...config };
    const exercise = newConfig.exercises.find(
      (exercise: any) => exercise.key === key
    );
    exercise.enabled = checked;
    setConfig(newConfig);
  };

  const updateConfig = useMutation(
    (config: any) => MiscServices.updateCalibrationConfig(config),
    {
      onSuccess: () => {
        calibrationConfig.refetch();
      },
    }
  );

  return (
    <div>
      <Input.Search
        placeholder="Search"
        defaultValue={""}
        onSearch={(value) => {
          setSearch(value);
        }}
        style={{ width: "500px", marginBottom: "10px" }}
      />
      <Button  onClick={() => updateConfig.mutate(config)} loading={updateConfig.isLoading}>
        Save
        </Button>
      <div style={{ width: "500px", height: "80vh", overflowY: "scroll" }}>
        {filteredExercises.map((exercise: any) => (
          <Row
            key={exercise.key}
            style={{
              borderBottom: "1px solid #f0f0f0",
              padding: "10px 10px",
            }}
          >
            <Col span={16}>
              <div>
                <Typography.Text>{exercise.name}</Typography.Text>
              </div>
            </Col>
            <Col>
              <Switch
                checkedChildren="On"
                unCheckedChildren="Off"
                defaultChecked={exercise.enabled}
                onChange={(checked) => {
                  onSwitchChange(exercise.key, checked);
                }}
              />
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
};

export default CalibrationConfig;
