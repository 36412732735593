import React, { useEffect, useState, useCallback } from "react";
import {
  Input,
  Button,
  Card,
  Row,
  Col,
  List,
  Tag,
  PageHeader,
  Popover,
  Space,
  Drawer,
  Upload,
  AutoComplete,
  notification,
  Badge,
  Spin,
  Form,
} from "antd";
import {
  WorkoutFileService,
  LabelledCSVService,
  FilterValues,
} from "../../services/dataService";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import moment from "moment";
import FilterGroup from "./filters";
import {
  FilterOutlined,
  MoreOutlined,
  InboxOutlined,
  SafetyOutlined,
} from "@ant-design/icons";
// import { fieldToName } from "../../../../constant";
import createPersistedState from "use-persisted-state";
import { KEY_TO_LABEL } from "../../constants";
import { debounce } from "lodash";
import validator from "validator";
import _ from "lodash";
import DumbellIcon from "../../components/dumbell.svg";

const useWorkoutListViewState = createPersistedState("workoutListViewState");

const WorkoutCard = ({ item, onEdit }) => { 
  const queryClient = useQueryClient();
  const [state, setState] = useState({});

  const normFile = (e) => {
    if (Array.isArray(e)) {
      setState(() => ({ ...state, file: e[0].fileList[0].originFileObj }));
      return e;
    }
    setState(() => ({ ...state, file: e.fileList[0].originFileObj }));
    return e && e.fileList;
  };

  const uploadLabelledCSV = async () => {
    var formData = new FormData();
    formData.append("file", state.file);
    return LabelledCSVService.upload(item.id, formData);
  };

  const uploadCSV = useMutation(uploadLabelledCSV, {
    onSuccess: () => {
      queryClient.invalidateQueries("workout_file");
      notification["success"]({
        message: "Labelled CSV upload",
      });
      setState({ ...state, csvFileUploadFlag: false });
    },
    onError: (error) => {
      notification["error"]({
        message: "Add Failed!!!",
        description: JSON.stringify(error.response.data),
      });
    },
  });

  return (
    <>
      <Drawer
        closable
        width={500}
        onClose={(e) => {
          setState({ ...state, csvFileUploadFlag: false });
          e.preventDefault();
        }}
        visible={state.csvFileUploadFlag}
        destroyOnClose
        title={"Upload CSV"}
      >
        <div style={{ padding: "1rem 1rem", height: "400px" }}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Upload.Dragger name="file" multiple={false} onChange={normFile}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">Upload Labeled CSV file</p>
            </Upload.Dragger>

            <Button
              onClick={(e) => {
                e.preventDefault();
                uploadCSV.mutate();
              }}
              type="primary"
              loading={uploadCSV.isLoading}
            >
              Submit
            </Button>
          </Space>
        </div>
      </Drawer>

      <Link to={`/label/${item.id}/`}>
        <Card className="file-card">
          <Row>
            <Col
              span={16}
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                fontSize: "20px",
                marginBottom: "20px",
              }}
            >
              {/* {item.file_name}
               */}
              <span
                style={{
                  color:
                    item.status == "ARCHIVED"
                      ? "red"
                      : item.is_verified && item.is_verified
                      ? "#389e0d"
                      : item.status == "AUTOMATIC"
                      ? "orange"
                      : null,
                }}
              >
                {`#ID: ${item.id}`}
                {item.is_verified && item.is_verified ? (
                  <SafetyOutlined style={{ marginLeft: "1rem" }} />
                ) : null}
                {item.is_rep_count_verified ? (
                  // <DumbellIcon style={{ marginLeft: "1rem", fill: "green" }} />
                  <img
                    style={{
                      height: "24px",
                      transform: "rotate(320deg)",
                      marginTop: "-8px",
                      marginLeft: "8px",
                    }}
                    src={DumbellIcon}
                    alt="React Logo"
                  />
                ) : null}
              </span>
            </Col>

            <Col span={8}>
              <div style={{ float: "right" }}>
                {item.is_verified && !item.is_rep_count_verified ? (
                  <Tag color="green" key="ex-verified">
                    Ex. Verified
                  </Tag>
                ) : null}
                {item.is_rep_count_verified && !item.is_verified ? (
                  <Tag color="green" key="rep-verified">
                    Rep. Verified
                  </Tag>
                ) : null}
                {item.is_rep_count_verified && item.is_verified ? (
                  <Tag color="green" key="all-verified">
                    Verified
                  </Tag>
                ) : null}
                {item.status == "ACTIVE" ? (
                  <Tag color="green" key="ACTIVE">
                    Active
                  </Tag>
                ) : item.status == "NEW_DATA" ? (
                  <Tag color="warning" key="NEW_DATA">
                    New Data
                  </Tag>
                ) : item.status == "ARCHIVED" ? (
                  <Tag color="warning" key="ARCHIVED">
                    Archived
                  </Tag>
                ) : item.status == "IMPORT_FAILED" ? (
                  <Tag color="warning" key="IMPORT_FAILED">
                    Import Failed
                  </Tag>
                ) : item.status == "EXPORT_ERROR" ? (
                  <Tag color="warning" key="EXPORT_ERROR">
                    Export Error
                  </Tag>
                ) : null}
                {item.feedback_status == "LABELLED" ? (
                  <Tag color="green" key="LABELLED">
                    Label: Labelled
                  </Tag>
                ) : item.feedback_status == "PENDING" ? (
                  <Tag color="warning" key="PENDING">
                    Label: Pending
                  </Tag>
                ) : item.feedback_status == "NEEDS_RELABEL" ? (
                  <Tag color="warning" key="NEEDS_RELABEL">
                    Label: Needs Relabel
                  </Tag>
                ) : item.feedback_status == "AUTOMATIC" ? (
                    <Tag color="warning" key="AUTOMATIC">
                      Label: Automatic
                    </Tag>
                ) : null}
                {item.unsure ? (
                  <Tag color="warning" key="Unsure">
                    Unsure
                  </Tag>
                ) : null}

                {/* <Popover
                  content={
                    <div style={{ width: "1" }}>
                      <Space direction="vertical">
                        <Button
                          type="link"
                          onClick={(e) => {
                            window.open(item.video_url, "_blank");
                            e.preventDefault();
                          }}

                          // onClick={() => window.open(item.video_url, "_blank").focus()}
                        >
                          Download Video
                        </Button>
                        <Button
                          type="link"
                          onClick={(e) => {
                            window.open(item.csv_url, "_blank");
                            e.preventDefault();
                          }}

                          // onClick={() => window.open(item.video_url, "_blank").focus()}
                        >
                          Download Excel
                        </Button>
                        <Button
                          type="link"
                          onClick={(e) => {
                            setState({ ...state, csvFileUploadFlag: true });
                            e.preventDefault();
                          }}

                          // onClick={() => window.open(item.video_url, "_blank").focus()}
                        >
                          Upload Excel
                        </Button>
                      </Space>
                    </div>
                  }
                  trigger="click"
                >
                  <Button type="link">
                    <MoreOutlined />
                  </Button>
                </Popover> */}
              </div>
            </Col>
          </Row>

          <Row style={{ marginBottom: "10px" }}>
            <Col span={4}>Exercise:</Col>
            <Col span={20}>
              <div>
                {item.exercise ? <Tag color="blue">{item.exercise}</Tag> : "-"}
              </div>
            </Col>
          </Row>

          {/* {lock_files['1'][Object.values(lock_files['0']).indexOf(item.exercise)] == true ? (
            <Row style={{ marginBottom: "10px" }}>
              <Col span={4}>Locked:</Col>
              <Col span={20}>
                <div>
                  {'This exercise is locked' ? <Tag color="red">{'True'}</Tag> : "-"}
                </div>
              </Col>
            </Row>
          ) : null} */}

          {item.username ? (
            <Row style={{ marginBottom: "10px" }}>
              <Col span={4}>Labeller:</Col>
              <Col span={20}>
                <div>
                  {item.exercise ? (
                    <Tag color="blue">{item.username}</Tag>
                  ) : (
                    "-"
                  )}
                </div>
              </Col>
            </Row>
          ) : null}

          <Row>
            <Col span={4}>Recorded Time:</Col>
            <Col span={20}>
              {moment(item.record_time).format("MMMM Do YYYY, h:mm:ss a")}
            </Col>
          </Row>
        </Card>
      </Link>
    </>
  );
};

const WorkoutFileList = (props) => {
  
  const queryClient = useQueryClient();
  const [state, updateState] = useWorkoutListViewState({ filter: { page: 1 } });
  const [filterEnabled, setFilterEnabled] = useState(true);
  // const [filter, updateFilter] = useState({ page: 1 });

  //   useEffect(() => {
  //     props.loadData(filter);
  //   }, [filter]);
  const [form] = Form.useForm();

  const workoutFiles = useQuery(
    ["workout_file", state.filter, state.query],
    () => WorkoutFileService.getAll({ search: state.query, ...state.filter }),
    { enabled: filterEnabled }
  );

  const workoutDetailFiles = useQuery(
    ["workout_file", "detail", state.filter, state.query],
    () => WorkoutFileService.get(state.query),
    { enabled: state.query != undefined && state.query != "" && filterEnabled }
  );

  // console.log(
  //   "VAL",
  //   validator.isUUID(state.query),
  //   filterEnabled && validator.isUUID(state.query)
  // );

  const filterValues = useQuery(
    ["filter_suggestions", state.query],
    () => FilterValues.getAll({ query: state.query }),
    { enabled: validator.isUUID(state.query || ""), staleTime: Infinity }
  );

  const onAutoSuggestSelect = (selected) => {
    let key, value;
    let selectedSplit = selected.split("-");
    key = selectedSplit[0];
    value = selectedSplit[1];
    updateState((prev) => ({
      ...prev,
      query: "",
      filter: { ...prev.filter, [key]: value },
    }));
    setFilterEnabled(true);
  };

  const onFilterTagRemove = (key) => {
    updateState((prevState) => ({
      ...prevState,
      filter: { ...state.filter, [key]: undefined, page: 1 },
    }));
  };

  const onAutoCompleteSearch = useCallback(
    debounce(() => {
      setFilterEnabled(true);
      queryClient.invalidateQueries(["filter_suggestions"]);
    }, 500),
    []
  );

  const getApplierFilterTags = () => {
    var filters = state.filter;
    var filterKeys = Object.keys(filters).filter(
      (key) =>
        key != "page" &&
        key != "size" &&
        filters[key] != "" &&
        filters[key] != undefined
    );

    console.log(filters, filterKeys);

    return (
      <Row style={{ marginBottom: "1rem" }}>
        {filterKeys.map((key) => (
          <Tag key={key} closable onClose={() => onFilterTagRemove(key)}>{`${
            KEY_TO_LABEL[key] ?? key
          }: ${String(state.filter[key])}`}</Tag>
        ))}
      </Row>
    );
  };

  const updateKfoldTag = useMutation(
    (values) => {
      var tag = values.tag;
      var workoutIds = values.workoutIds;
      WorkoutFileService.updateKfoldTags({
        tag: tag,
        workout_ids: workoutIds.split("\n"),
      });
    },
    {
      onSuccess: () => {
        notification["success"]({
          message: "KFold Tags Updated",
        });
      },
      onError: (error) => {
        notification["error"]({
          message: "Some Error happened!!!",
          description: JSON.stringify(error.response.data),
        });
      },
    }
  );

  return (
    <React.Fragment>
      <Drawer
        closable
        width={500}
        onClose={(e) => {
          updateState({ ...state, kfoldTagFlag: false });
          e.preventDefault();
        }}
        visible={state.kfoldTagFlag}
        destroyOnClose
        title={"Add KFold Tag"}
      >
        <div style={{ padding: "1rem 1rem", height: "400px" }}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Form
              form={form}
              layout="vertical"
              name="basic"
              initialValues={{}}
              onFinish={updateKfoldTag.mutate}
            >
              <Form.Item
                label="Kfold Tag"
                name="tag"
                rules={[{ required: true, message: "Please input your name!" }]}
              >
                <Input placeholder="Tag"></Input>
              </Form.Item>
              <Form.Item
                label="Workout Ids"
                name="workoutIds"
                rules={[{ required: true, message: "Please input your name!" }]}
              >
                <Input.TextArea placeholder="Workout ids"></Input.TextArea>
              </Form.Item>
              <Form.Item>
                <Button
                  loading={updateKfoldTag.isLoading}
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: "20px" }}
                >
                  Submit
                </Button>
                {/* <Button htmlType="button" onClick={onReset}>
                  Reset
                </Button> */}
              </Form.Item>
            </Form>
          </Space>
        </div>
      </Drawer>
      <div className="page-content">
        <PageHeader
          ghost={false}
          // onBack={() => window.history.back()}
          title="Annotation View"
          extra={[
            <Button
              onClick={() =>
                updateState((prev) => ({ ...prev, kfoldTagFlag: true }))
              }
            >
              KFold Tags
            </Button>,
          ]}
        ></PageHeader>
        {/* <SDSFileList /> */}
        {/* <SDSTable /> */}
      </div>
      {/* <div>Filters</div> */}
      <Row style={{ marginBottom: "1rem" }}>
        <Col span={8}>
          {/* <Input.Search
            allowClear
            // value={state.query}
            defaultValue={state.query}
            onSearch={(e) => {
              updateState((prevState) => ({ ...prevState, query: e }));
              queryClient.invalidateQueries(["workout_file"]);
            }}
          /> */}

          <AutoComplete
            value={state.query}
            style={{ width: "100%", paddingRight: "1rem" }}
            placeholder="search here"
            onSelect={onAutoSuggestSelect}
            defaultValue={state.query}
            onSearch={(e) => {
              updateState((prevState) => ({ ...prevState, query: e }));
              setFilterEnabled(false);
              onAutoCompleteSearch();
            }}
          >
            {filterValues.isLoading ? (
              <AutoComplete.Option key={"Loading..."} value={"Loading..."}>
                <Spin />
              </AutoComplete.Option>
            ) : (
              (filterValues.data ?? []).map((item) => (
                <AutoComplete.Option
                  key={item.key + "-" + item.value}
                  value={item.key + "-" + item.value}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>
                      {item.label} - {item.value}
                    </span>
                    <span>
                      <Badge
                        style={{ backgroundColor: "#096dd9" }}
                        count={item.count}
                      />
                    </span>
                  </div>
                </AutoComplete.Option>
              ))
            )}
          </AutoComplete>
        </Col>
        <Col span={4}>
          <Popover
            trigger="click"
            content={
              <FilterGroup
                filters={state.filter}
                onChange={(filters) => {
                  updateState((prevState) => ({
                    ...prevState,
                    filter: { ...state.filter, ...filters, page: 1 },
                  }));
                  setFilterEnabled(true);
                  queryClient.invalidateQueries(["workout_file"]);
                }}
              />
            }
          >
            <Button icon={<FilterOutlined />}>Filter</Button>
          </Popover>
        </Col>
      </Row>
      {getApplierFilterTags()}
      {workoutDetailFiles.data?.id ? (
        <Link to={`/label/${workoutDetailFiles.data?.id}/`}>
          Workout Found{" "}
        </Link>
      ) : null}
      <List
        pagination={{
          onChange: (page, pageSize) => {
            updateState((prevState) => ({
              ...prevState,
              filter: { ...state.filter, page: page, size: pageSize },
            }));
          },
          total: workoutFiles.data ? workoutFiles.data.count : 0,
          current: state.filter.page,
          pageSize: state.filter.size || 10,
        }}
        grid={{
          gutter: 12,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 2,
          xxl: 2,
        }}
        dataSource={workoutFiles.data ? workoutFiles.data.results : []}
        loading={workoutFiles.isLoading}
        renderItem={(item, idx) => (
          <List.Item key={idx}>
            <WorkoutCard onEdit={props.onEdit} item={item} idx={idx} />{" "}
          </List.Item>
        )}
      />
    </React.Fragment>
  );
};

export default WorkoutFileList;
