import React, { useEffect } from "react";
import * as d3 from "d3";

const SimPlot = ({ simData }: { simData: number[][] }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  useEffect(() => {

    plotSimMatrix();
  }, [simData]);

  const plotSimMatrix = () => {
    let margin = { top: 50, right: 50, bottom: 50, left: 50 },
      width = 600 - margin.left - margin.right,
      height = 600 - margin.top - margin.bottom;

    let svg = d3
      .select(ref.current)
      .select("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);

    let g = svg
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    let x = d3.scaleBand().range([0, width]).padding(0.05);
    let y = d3.scaleBand().range([ 0, height]).padding(0.05);

    let data = simData;
    let max = d3.max(data, (d) => d3.max(d, (d) => d)) || 0;
    let min = d3.min(data, (d) => d3.min(d, (d) => d)) || 0;

    x.domain(d3.range(data.length).map((d) => `${d}`));
    y.domain(d3.range(data.length).map((d) => `${d}`));

    let color = d3
      .scaleLinear()
      .domain([min, max])
      .range(["white", "steelblue"] as any);

    let row = g
      .selectAll(".row")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "row")
      .attr("transform", (d, i) => "translate(0," + y(`${i}`) + ")");

    let cell = row
      .selectAll(".cell")
      .data((d) => d)
      .enter()
      .append("g")
      .attr("class", "cell")

      .attr("transform", (d, i) => "translate(" + x(`${i}`) + ", 0)");

    cell
      .append("rect")
      .attr("width", x.bandwidth())
      .attr("height", y.bandwidth())
      .style("fill", (d) => d3.rgb(color(d) as any) as any)

      .style("stroke-width", 0);

    cell
      .append("text")
      .attr("dy", ".32em")
      .attr("x", x.bandwidth() / 2)
      .attr("y", y.bandwidth() / 2)
      .attr("text-anchor", "middle")
      .text((d) => d.toFixed(2));
  };

  return (
    <div ref={ref} style={{ height: "100%", width: "100%" }}>
      <div style={{ height: "100%", width: "100%" }}>
        <svg style={{ height: "100%", width: "100%" }} id="simPlot"></svg>
      </div>
    </div>
  );
};


export default SimPlot;