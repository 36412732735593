import {
  Button,
  Col,
  Empty,
  Input,
  Row,
  Space,
  Tabs,
  Tag,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { CustomService } from "../../services/dataService";
import { useMutation } from "react-query";
import RawDataPlot from "../workoutFileAnalysis/d3ChartV2";

let columns = [
  "rot-rate_x",
  "rot-rate_y",
  "rot-rate_z",
  "pitch",
  "roll",
  "yaw",
  "usrAcc_x",
  "usrAcc_y",
  "usrAcc_z",
  "gravity_x",
  "gravity_y",
  "gravity_z",
  "time",
];

const RangeSelector = ({ onChange, value }) => {
  return (
    <div>
      <Input.Group compact>
        <Input
          style={{ width: "50%" }}
          placeholder="Start"
          value={value[0]}
          onChange={(e) => {
            onChange([e.target.value, value[1]]);
          }}
        />
        <Input
          style={{ width: "50%" }}
          placeholder="End"
          value={value[1]}
          onChange={(e) => {
            onChange([value[0], e.target.value]);
          }}
        />
      </Input.Group>
    </div>
  );
};
const LogView = ({ log }) => {
  return (
    <Row
      style={{
        border: "1px solid #eee",
        padding: "1rem",
      }}
    >
      <div style={{ width: "100%" }}>
        <Typography.Text type="secondary">
          {log?.start_timestamp}
        </Typography.Text>
      </div>
      <Typography.Text>
        {log?.message.split("\n").map((i, key) => {
          return <div key={key}>{i}</div>;
        })}
      </Typography.Text>
    </Row>
  );
};

const SetView = ({ set }) => {
  return (
    <Row  style={{
      border: "1px solid #eee",
      padding: "1rem",
      margin: "0rem 0.5rem"
    }}>
      <div style={{ width: "100%" }}>
      <Typography.Text strong>{set.exercise}</Typography.Text>
      </div>
      <Typography.Text>
        Reps: {set.measurementValue}
      </Typography.Text>
    </Row>
  );
};
const FrontendSim = ({ data }) => {
  return (
    <Row>
      <Col span={12}>
      <Typography.Text strong>Logs:</Typography.Text>
      {data?.logs?.map((log, index) => {
        return <LogView key={index} log={log} />;
      })}
      </Col>
      <Col span={12}>
      <Typography.Text strong>Sets:</Typography.Text>

      {data?.sets?.map((set, index) => {
        return <SetView key={index} set={set} />;
      })}
      </Col>
    </Row>
  );
};
const SetDetailView = ({ set }) => {
  const [state, updateState] = useState({
    loadedSetId: null,
    filter: {
      page: 1,
    },
  });
  useEffect(() => {
    let key =  set?.setBoatEndTime || set?.start_time || set?.creation_date;
    if (set && key !== state.loadedSetId) {
      updateState((prevState) => ({
        ...prevState,
        loadedSetId: set.setBoatEndTime || set.start_time || set.creation_date,
        filter: {
          ...prevState.filter,
          start: set.start_time ? set.start_time : set.creation_date - 60,
          end: set.end_time ? set.end_time : set.creation_date,
        },
      }));
      debugMutation.mutate({
        ...set,
        watch_hand: set.event_properties?.properties?.watchHand,
        watch_orientation: set.event_properties?.properties?.watchOrientation,
      });
      debugFrontendMutation.mutate({
        ...set,
        watch_hand: set.event_properties?.properties?.watchHand || "left",
        watch_orientation: set.event_properties?.properties?.watchOrientation || "right",
      });

    }
  }, [set]);
  const debugMutation = useMutation((inputSet) =>
    CustomService.debugSet(
      inputSet
        ? inputSet
        : {
            ...set,
            start_time: state.filter.start,
            end_time: state.filter.end,
          }
    )
  );

  const debugFrontendMutation = useMutation((inputSet) =>
    CustomService.debugFrontendSet(
      inputSet
        ? inputSet
        : {
            ...set,
            start_time: state.filter.start,
            end_time: state.filter.end,
          }
    )
  );

  return (
    <div>
      {!set ? "Select a set" : null}
      {set ? (
        <div
          style={{
            padding: ".5rem 1.5rem",
          }}
        >
          <Row align="middle" justify="space-between">
            <Col>
              <div>
                <Typography.Text
                  strong
                  style={{
                    marginRight: "1rem",
                  }}
                >
                  {set.exercise}
                </Typography.Text>
                <Typography.Text>
                  {moment(set.creation_date * 1000).toLocaleString()}
                </Typography.Text>
              </div>
            </Col>
            <Col>
              <Tag>{set.start_time ? "Auto-Detected" : "Manual"}</Tag>
            </Col>
          </Row>
          <Tabs
            defaultActiveKey="1"
            tabBarExtraContent={{
              right: (
                <Space>
                  <RangeSelector
                    onChange={(value) => {
                      updateState((prevState) => ({
                        ...prevState,
                        filter: { page: 1, start: value[0], end: value[1] },
                      }));
                    }}
                    value={[state.filter.start, state.filter.end]}
                  ></RangeSelector>
                  {/* <Dropdown.Button menu={menuProps} onClick={handleButtonClick}>
                    Dropdown
                  </Dropdown.Button> */}
                  <Button
                    loading={debugMutation.isLoading}
                    onClick={() => {
                      debugMutation.mutate();
                    }}
                  >
                    Apply
                  </Button>
                </Space>
              ),
            }}
          >
            <Tabs.TabPane tab="Debugger" key="1">
              {debugMutation.data?.raw_data ? (
                <RawDataPlot
                  rawData={debugMutation?.data?.raw_data}
                  windowExPred={
                    debugMutation.data?.graph_elements?.window_scores
                  }
                  repDetection={
                    debugMutation.data?.graph_elements?.detection_labels
                  }
                  repSequence={debugMutation.data?.graph_elements?.seq_labels}
                  detectedSets={debugMutation.data?.detected_sets}
                  logData={debugFrontendMutation.data?.logs}
                />
              ) : null}
              {debugMutation.error ? (
                // <div>{debugMutation.error.message}</div>
                <Empty
                  description={debugMutation.error?.response?.data?.detail}
                />
              ) : null}
            </Tabs.TabPane>
            <Tabs.TabPane tab="MixPanel Events" key="2">
              <div
                style={{
                  maxHeight: "60vh",
                  overflowY: "scroll",
                }}
              >
                <ReactJson src={set.event_properties} />
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Frontend Sim" key="3">
              <div
                style={{
                  maxHeight: "60vh",
                  overflowY: "scroll",
                }}
              >
                {debugFrontendMutation.data ? (
                  <FrontendSim data={debugFrontendMutation.data} />
                ) : null}
                {debugFrontendMutation.isLoading ? <div>Loading...</div> : null}
              </div>
            </Tabs.TabPane>
          </Tabs>
        </div>
      ) : null}
    </div>
  );
};

export default SetDetailView;
