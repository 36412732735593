import {
  Button,
  Card,
  Col,
  Input,
  List,
  PageHeader,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import ExerciseSelect from "../components/exerciseSelect";
import { useQuery } from "react-query";
import { CalibrationFileServices } from "../../../services/dataService";
import { Link } from "react-router-dom";
import { exNameMap } from "../../../constants";
import createPersistedState from "use-persisted-state";
import moment from "moment";
interface LabelFilters {
  search: string;
  exercise: string;
  ex_verification_status: string;
  labelling_status: string;
  import_status: string;
  kfold_tag: string;
  include_custom: boolean | null;
  is_archived: boolean | null;
  labelled_by: string;
  page: number;
}
const useLabelFilters = createPersistedState<LabelFilters>("labelFilters");

const CalibrationCard = ({ calibration, searchParams }: any) => {
  const verificationStatus = calibration.ex_verification_status;
  const labelStatus = calibration.labelling_status;
  const importStatus = calibration.import_status;
  const archived = calibration.ex_verification_status == "archived";

  const getStatusTags = () => {
    let statusTags = [] as any;

    if (labelStatus === "labelled") {
      statusTags.push(<Tag color="green">LABELLED</Tag>);
    } else if (labelStatus === "unlabelled") {
      statusTags.push(<Tag color="yellow">UNLABELLED</Tag>);
    } else if (labelStatus === "unsure") {
      statusTags.push(<Tag color="orange">UNSURE</Tag>);
    }
    return statusTags;
  };

  let baseUrl = window.location.pathname;
  // Check if trailing slash is present in the URL
  if (baseUrl[baseUrl.length - 1] !== "/") {
    baseUrl += "/";
  }
  return (
    <Link to={`${baseUrl}${calibration.id}?${searchParams}`}>
      <Card>
        <Row justify="space-between">
          <Typography.Title level={5}>
            {exNameMap[calibration.exercise as keyof typeof exNameMap] ||
              calibration.exercise}
          </Typography.Title>
          <div>{getStatusTags()}</div>
        </Row>

        <Typography.Text type="secondary">
          ID#: {calibration.id}
        </Typography.Text>
        <br />
        <Typography.Text type="secondary">
          Record Time:{" "}
          {calibration.record_time
            ? moment(calibration.record_time).format("YYYY-MM-DD HH:mm:ss")
            : "N/A"}
        </Typography.Text>
      </Card>
    </Link>
  );
};
const FilterView = ({
  filters,
  onChange,
}: {
  filters: {
    search: string;
    exercise: string;
    ex_verification_status: string;
    labelling_status: string;
    import_status: string;
    kfold_tag: string;
    include_custom: boolean | null;
    is_archived: boolean | null;
    labelled_by: string;
    page: number;
  };
  onChange: (value: any) => void;
}) => {
  const handleChangeFilters = (key: string, value: any) => {
    onChange({
      ...filters,
      [key]: value,
    });
  };

  const uniqueUsers = useQuery("uniqueUsers", () =>
    CalibrationFileServices.getUniqueUsers()
  );
  return (
    <div>
      <Row gutter={24} align="bottom">
        <Col span={12}>
          <Input.Search
            placeholder="Search"
            defaultValue={filters.search}
            onChange={(e) => {
              handleChangeFilters("search", e.target.value);
            }}
          />
        </Col>

        <Col span={12}>
          <div style={{ marginLeft: "10px", display: "inline-block" }}>
            <div className="form-label">
              <div style={{ display: "inline-block" }}>Exercise</div>
            </div>
            <ExerciseSelect
              allowClear={true}
              value={filters.exercise}
              onChange={(value) => {
                handleChangeFilters("exercise", value);
              }}
              mode="tags"
            />
          </div>
          <div style={{ marginLeft: "10px", display: "inline-block" }}>
            <div className="form-label">
              <div style={{ display: "inline-block" }}>Label Status</div>
            </div>
            <Select
              style={{ width: "150px" }}
              placeholder="Label Status"
              value={filters.labelling_status}
              onChange={(value) => {
                handleChangeFilters("labelling_status", value);
              }}
            >
              <Select.Option value="">All</Select.Option>
              <Select.Option value="unlabelled">Unlabelled</Select.Option>
              <Select.Option value="labelled">Labelled</Select.Option>
              <Select.Option value="unsure">Unsure</Select.Option>
            </Select>
          </div>
          <div style={{ marginLeft: "10px", display: "inline-block" }}>
            <div className="form-label">
              <div style={{ display: "inline-block" }}>Labelled By</div>
            </div>
            <Select
              style={{ width: "150px" }}
              placeholder="Labelled By"
              value={filters.labelled_by}
              onChange={(value) => {
                handleChangeFilters("labelled_by", value);
              }}
              allowClear
              onClear={() => {
                handleChangeFilters("labelled_by", "");
              }}
            >
              {/* <Select.Option value="">All</Select.Option>
              <Select.Option value="unlabelled">Unlabelled</Select.Option>
              <Select.Option value="labelled">Labelled</Select.Option> */}

              {uniqueUsers.data?.labelled_by?.map((user: any) => (
                <Select.Option key={user.user} value={user.user}>
                  {user.user || "Unknown"} <Tag>{user.value}</Tag>
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
      </Row>

      <div></div>
    </div>
  );
};

const FilterTags = ({ filters, onChange }: any) => {
  const formatValue = (value: any) => {
    if (typeof value === "boolean") {
      return value ? "True" : "False";
    }
    if (Array.isArray(value)) {
      return value.join(",");
    }
    return value;
  };
  return (
    <div
      style={{
        margin: "0.5rem 0rem",
      }}
    >
      <Row gutter={12}>
        <Col>
          {Object.keys(filters)
            .filter((key) => filters[key])
            .map((key) => {
              if (filters[key]) {
                return (
                  <Tag
                    closable
                    onClose={() => {
                      onChange(key);
                    }}
                  >
                    {key}: {formatValue(filters[key])}
                  </Tag>
                );
              }
            })}
        </Col>
      </Row>
    </div>
  );
};
const CalibrationsForLabel = () => {
  // const [page, setPage] = useState(1);
  const [filters, setFilters] = useLabelFilters({
    search: "",
    exercise: "",
    ex_verification_status: "verified",
    labelling_status: "",
    import_status: "",
    kfold_tag: "",
    include_custom: null,
    is_archived: null,
    labelled_by: "",
    page: 1,
  });

  useEffect(() => {
    // Load query params
    const urlParams = new URLSearchParams(window.location.search);
    const exercise = urlParams.get("exercise");
    const ex_verification_status = urlParams.get("ex_verification_status");
    const search = urlParams.get("search");
    const labelling_status = urlParams.get("labelling_status");
    const import_status = urlParams.get("import_status");
    const kfold_tag = urlParams.get("kfold_tag");
    const include_custom = urlParams.get("include_custom");
    const is_archived = urlParams.get("is_archived");
    const labelled_by = urlParams.get("labelled_by");
    if (
      exercise ||
      ex_verification_status ||
      search ||
      labelling_status ||
      import_status ||
      kfold_tag ||
      include_custom ||
      is_archived ||
      labelled_by
    ) {
      setFilters({
        ...filters,
        search: search || "",
        exercise: exercise || "",
        ex_verification_status: ex_verification_status || "",
        labelling_status: labelling_status || "",
        import_status: import_status || "",
        kfold_tag: kfold_tag || "",
        include_custom: include_custom ? include_custom == "true" : null,
        is_archived: is_archived ? is_archived == "true" : null,
        labelled_by: labelled_by || "",
      });
    }
  }, []);

  const calibrations = useQuery(["allCalibrations",  filters], () =>
    CalibrationFileServices.getAll({
      ...filters,
      ex_verification_status: "verified",
    })
  );

  const onFilterRemove = (key: string) => {
    setFilters({
      ...filters,
      [key]: "",
    });
  };

  const queryParams: any = {
    ...filters,
  };

  Object.keys(queryParams).forEach((key) => {
    if (!queryParams[key]) {
      delete queryParams[key];
    } else {
      queryParams[key] = queryParams[key].toString();
    }
  });

  const urlSearchParams = new URLSearchParams(queryParams);

  return (
    <div>
      <PageHeader title="Calibrations" />
      <FilterView filters={filters} onChange={setFilters} />
      <Row style={{
        margin: "0.5rem 0rem"
      }}>
        <Tag color="grey">{calibrations.data?.total} Results</Tag>
      </Row>

      {/* <FilterTags filters={filters} onChange={onFilterRemove} /> */}

      <div
        style={{
          margin: "1rem 0rem",
        }}
      >
        <List
          dataSource={calibrations.data?.results || []}
          pagination={{
            onChange: (page) => {
              setFilters(prev => ({
                ...prev,
                page,
              }));
            },
            current: filters.page,
            pageSize: 10,
            total: calibrations.data?.total,
            showSizeChanger: false,
          }}
          grid={{
            gutter: 12,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 2,
            xxl: 2,
          }}
          loading={calibrations.isLoading}
          renderItem={(calibration: any) => (
            <List.Item key={calibration.id}>
              <CalibrationCard
                calibration={calibration}
                key={calibration.id}
                searchParams={urlSearchParams.toString()}
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

export default CalibrationsForLabel;
