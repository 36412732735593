import React, { useState } from "react";

import { Button, Drawer, Row, PageHeader , Table, Typography} from "antd";


const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: "Labelled",
    dataIndex: "LABELLED",
    sorter: {
      compare: (a, b) => a.LABELLED - b.LABELLED,
      multiple: 3,
    },
  },
  {
    title: "Pending",
    dataIndex: "PENDING",
    sorter: {
      compare: (a, b) => a.PENDING - b.PENDING,
      multiple: 2,
    },
  },
  {
    title: "Rejected",
    dataIndex: "ARCHIVED",
    sorter: {
      compare: (a, b) => a.ARCHIVED - b.ARCHIVED,
      multiple: 2,
    },
  },
  {
    title: "Failed",
    dataIndex: "IMPORT_FAILED",
    sorter: {
      compare: (a, b) => a.IMPORT_FAILED - b.IMPORT_FAILED,
      multiple: 2,
    },
  },
  {
    title: "Verified",
    dataIndex: "verified",
    sorter: {
      compare: (a, b) => a.IMPORT_FAILED - b.IMPORT_FAILED,
      multiple: 2,
    },
  },
  {
    title: "Rep. Verified",
    dataIndex: "rep_verified",
    sorter: {
      compare: (a, b) => a.IMPORT_FAILED - b.IMPORT_FAILED,
      multiple: 2,
    },
  },
  {
    title: 'Total',
    dataIndex: 'total',
    sorter: {
      compare: (a, b) => a.total - b.total,
      multiple: 1,
    },
  }
];


function onChange(pagination, filters, sorter, extra) {
  console.log('params', pagination, filters, sorter, extra);
}

// ReactDOM.render(<Table columns={columns} dataSource={data} onChange={onChange} />, mountNode);
const BatchStatus = (props) => {
  console.log(props.data)
  return (
    <React.Fragment>
      <div className="page-content">
        <Typography.Title level={5}>Batch Stats:</Typography.Title>
        <Table columns={columns} dataSource={props.data} pagination={false}/>
      </div>
    </React.Fragment>
  );
};

export default BatchStatus;
