import { axios } from "./api";

const baseUrl = process.env.REACT_APP_API_URL;

export class BaseCRUDService {
  constructor(base) {
    this.base = `${baseUrl}/${base}`;
  }

  getAll = async (params = {}) => {
    Object.keys(params).map(
      (key) =>
        (params[key] = Array.isArray(params[key])
          ? params[key].join(",")
          : params[key])
    );
    console.log(params)
    return axios
      .get(`${this.base}/`, { params: params })
      .then((res) => res.data);
  };

  get = async (id, params={}) => {
    Object.keys(params).map(
      (key) =>
        (params[key] = Array.isArray(params[key])
          ? params[key].join(",")
          : params[key])
    );
    console.log("PARAMS", params)
    
    return axios
      .get(`${this.base}/${id}/`, { params: params })
      .then((res) => res.data);
  };

  create(data) {
    return axios.post(`${this.base}/`, data).then((res) => res.data);
  }

  update(id, data) {
    return axios.patch(`${this.base}/${id}/`, data).then((res) => res.data);
  }

  delete(id) {
    return axios.delete(`${this.base}/${id}/`).then((res) => res.data);
  }

  deleteAll() {
    return axios.delete(`${this.base}/`).then((res) => res.data);
  }
}
