import {
  Button,
  Col,
  Empty,
  Input,
  Modal,
  Popover,
  Radio,
  Row,
  Space,
  Tabs,
  Tag,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { CustomService } from "../../services/dataService";
import { useMutation } from "react-query";
import RawDataPlot from "../workoutFileAnalysis/d3ChartV2";
import { SetCard } from "./SetCard";
import SetDetailView from "./setDetailView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamation,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import WorkoutGraph from "./workoutGraph";

let columns = [
  "rot-rate_x",
  "rot-rate_y",
  "rot-rate_z",
  "pitch",
  "roll",
  "yaw",
  "usrAcc_x",
  "usrAcc_y",
  "usrAcc_z",
  "gravity_x",
  "gravity_y",
  "gravity_z",
  "time",
];

const LogView = ({ log }) => {
  return (
    <Row
      style={{
        border: "1px solid #eee",
        padding: "1rem",
      }}
    >
      <div style={{ width: "100%" }}>
        <Typography.Text type="secondary">
          {log?.start_timestamp}
        </Typography.Text>
      </div>
      <Typography.Text>
        {log?.message.split("\n").map((i, key) => {
          return <div key={key}>{i}</div>;
        })}
      </Typography.Text>
    </Row>
  );
};

const SetView = ({ set, onClick }) => {
  return (
    <Row
      onClick={() => {
        onClick();
      }}
      style={{
        cursor: "pointer",
        border: "1px solid #eee",
        padding: "1rem",
        margin: "0rem 0.5rem",
      }}
    >
      <div style={{ width: "100%", marginBottom: "5px" }}>
        <Tag color="blue"> {set.exercise}</Tag>{" "}
        {!set.frontendSetId ? <Typography.Text>🚨</Typography.Text> : null}
        {set.missing ? <Typography.Text>❓</Typography.Text> : null}
        {set.metadata?.ai_set?.exercise &&
        set.metadata?.ai_set?.exercise != set.exercise ? (
          <Typography.Text>
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              style={{ color: "#edce02" }}
            />
          </Typography.Text>
        ) : null}
      </div>
      <Space
        split={
          <div
            style={{
              width: "1px",
              height: "1.25rem",
              borderRight: "solid 1px #c1bebe",
            }}
          ></div>
        }
        wrap
      >
        <Typography.Text>
          Reps: {set.measurementValue || set.num_reps}
        </Typography.Text>
        {set.metadata?.ai_set?.num_reps ? (
          <Typography.Text>
            Logged Reps: {set.metadata?.ai_set?.num_reps}
          </Typography.Text>
        ) : null}
        {set.metadata?.ai_set?.exercise &&
        set.metadata?.ai_set?.exercise != set.exercise ? (
          <Typography.Text>
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              style={{ color: "#edce02", marginRight: "5px" }}
            />
            {set.metadata?.ai_set?.exercise}
          </Typography.Text>
        ) : null}
      </Space>
    </Row>
  );
};

const SetDebugView = ({ sets, userId }) => {
  const [state, updateState] = useState({
    selectedSet: null,
  });
  return (
    <Row>
      <Col span={6}>
        <Typography.Text strong>Sets:</Typography.Text>
        <div
          style={{
            maxHeight: "70vh",
            overflow: "auto",
          }}
        >
          {sets?.map((set, index) => {
            return (
              <SetView
                key={index}
                set={set}
                onClick={() => {
                  updateState((prevState) => ({
                    ...prevState,
                    selectedSet: set,
                  }));
                }}
              />
            );
          })}
        </div>
      </Col>
      <Col span={18}>
        <div
          style={{
            maxHeight: "70vh",
            overflow: "auto",
          }}
        >
          {state.selectedSet ? (
            <SetDetailView
              set={{
                ...state.selectedSet,
                start_time: state.selectedSet.setBoatStartTime,
                end_time: state.selectedSet.setBoatEndTime,
                user_id: userId,
                creation_date: state.selectedSet.setBoatEndTime,
              }}
            />
          ) : null}
        </div>
      </Col>
    </Row>
  );
};

const WorkoutReplayView = ({ workout, visible, onClose }) => {
  const [state, updateState] = useState({
    filter: {
      page: 1,
      watch_hand: "left",
      watch_orientation: "right",
    },
  });
  useEffect(() => {
    if ((workout, visible)) {
      debugWorkoutMutation.mutate({
        user_id: workout.user_id,
        workout_id: workout.id,
        start_time: workout.start_time,
        end_time: workout.end_time,
        watch_hand: state.filter.watch_hand,
        watch_orientation: state.filter.watch_orientation,
      });
    }
  }, [workout, visible]);

  const debugWorkoutMutation = useMutation((data) =>
    CustomService.debugFrontendWorkout(data)
  );

  const onRerun = () => {
    debugWorkoutMutation.mutate({
      user_id: workout.user_id,
      workout_id: workout.id,
      start_time: workout.start_time,
      end_time: workout.end_time,
      watch_hand: state.filter.watch_hand,
      watch_orientation: state.filter.watch_orientation,
    });
  }

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      destroyOnClose={true}
      width={1400}
      footer={null}
    >
      {debugWorkoutMutation.isLoading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Empty description="Loading..." />
        </div>
      ) : null}
      {!debugWorkoutMutation.isLoading && workout ? (
        <div
          style={{
            padding: ".5rem 1.5rem",
          }}
        >
          <Row
            style={{
              width: "100%",
            }}
          >
            {/* <Col span={8}>
            {workout.sets?.map((set) => (
              <SetCard
                set={set}
                key={set.id}
                selected={state.selectedSet?.id == set.id}
                onClick={() => {
                  updateState((prevState) => ({
                    ...prevState,
                    selectedSet: set,
                  }));
                }}
              />
            ))}
          </Col>
          <Col span={16}>
            {debugWorkoutMutation.data ? (
              <FrontendSim data={debugWorkoutMutation.data} />
            ) : null}
          </Col> */}

            <Tabs
              defaultActiveKey="1"
              style={{
                width: "100%",
              }}
              tabBarExtraContent={
                <Popover
                  trigger={"click"}
                  placement="bottomRight"
                  content={
                    <Row gutter={[12, 12]}>
                      <Col span={24}>
                        <div>Watch Hand:</div>
                        <Radio.Group
                          defaultValue="left"
                          buttonStyle="solid"
                          value={state.filter.watch_hand}
                          onChange={(e) => {
                            updateState((prevState) => ({
                                ...prevState,
                                filter: {
                                  ...prevState.filter,
                                  watch_hand: e.target.value,
                                },
                                }));
                          }}
                        >
                          <Radio.Button value="left">Left</Radio.Button>
                          <Radio.Button value="right">Right</Radio.Button>
                        </Radio.Group>
                      </Col>
                      <Col span={24}>
                        <div>Watch Orientation:</div>
                        <Radio.Group
                          defaultValue="right"
                          buttonStyle="solid"
                            value={state.filter.watch_orientation}
                          onChange={(e) => {
                            updateState((prevState) => ({
                                ...prevState,
                                filter: {
                                  ...prevState.filter,
                                  watch_orientation: e.target.value,
                                },
                                }));
                          }}
                        >
                          <Radio.Button value="left">Left</Radio.Button>
                          <Radio.Button value="right">Right</Radio.Button>
                        </Radio.Group>
                      </Col>

                      <Button onClick={onRerun}>Rerun</Button>
                    </Row>
                  }
                >
                  <Button>Configuration</Button>
                </Popover>
              }
            >
              <Tabs.TabPane tab="Sim Sets" key="1">
                <SetDebugView
                  sets={debugWorkoutMutation.data?.sets}
                  userId={workout.user_id}
                />
              </Tabs.TabPane>

              <Tabs.TabPane tab="Logs" key="3">
                <div
                  style={{
                    maxHeight: "70vh",
                    overflow: "auto",
                    width: "100%",
                  }}
                >
                  {debugWorkoutMutation.data?.logs?.map((log, index) => {
                    return <LogView key={index} log={log} />;
                  })}
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Timeline" key="4">
                <div
                  style={{
                    maxHeight: "70vh",
                    overflow: "auto",
                    width: "100%",
                  }}
                >
                  <WorkoutGraph
                    allSets={debugWorkoutMutation.data?.sets}
                    logs={debugWorkoutMutation.data?.logs}
                    detectingSets={
                      debugWorkoutMutation.data?.all_detecting_states
                    }
                    workoutEndTime={workout.end_time}
                    workoutStartTime={workout.start_time}
                  />
                </div>
              </Tabs.TabPane>
            </Tabs>
          </Row>
        </div>
      ) : null}
    </Modal>
  );
};

export {
  WorkoutReplayView,
  SetView,
  LogView,
  SetDebugView,
  SetDetailView,
}

export default WorkoutReplayView;
