export const AUTO_LABEL_EXERCISES = [
  "barbellBackSquat",
  "barbellBenchPressTraditional",
  "barbellBentoverRowTraditional",
  "barbellBicepCurlTraditional",
  "barbellBulgarianSplit",
  "barbellBenchPressCloseGrip",
  "barbellDeadliftTraditional",
  "barbellFrontSquatTraditional",
  "barbellHipThrusters",
  "barbellBenchPressIncline",
  "barbellLungeTraditional",
  "barbellMilitaryPressTraditional",
  "barbellRackPull",
  "barbellBentoverRowReverseGrip",
  "barbellShrug",
  "barbellUprightRow",
  "benchDips",
  "bodyweightCrunchBicycle",
  "bodyweightLungeTraditional",
  "boxJump",
  "cableRearDeltFlyBentover",
  "cableBicepCurlBar",
  "cableCrossoverHigh",
  "cableCrossoverLow",
  "cableCrunchTraditional",
  "cableFacePullRope",
  "cableBicepCurlRope",
  "cableOverheadTriExtensionRopeHigh",
  "cableOverheadTriExtensionRopeLow",
  "cableTricepExtensionRope",
  "cableSeatedRowBarTraditional",
  "cableTricepExtensionBar",
  "cableRearDeltFlyUpright",
  "cableUprightRow",
  "bodyweightChinUps",
  "bodyweightCrunch",
  "bodyweightDeadBug",
  "bodyweightPushUpDiamond",
  "bodyweightDips",
  "dumbbellBenchPressTraditional",
  "dumbbellBicepCurlTraditional",
  "dumbbellBulgarianSplitSquat",
  "dumbbellDeadliftTraditional",
  "dumbbellFly",
  "dumbbellFrontRaiseStanding",
  "dumbbellBicepCurlHammerTraditional",
  "dumbbellBenchPressIncline",
  "dumbbellFlyInclineBench",
  "dumbbellLateralRaiseTraditional",
  "dumbbellLungeTraditional",
  "dumbbellOverheadPress",
  "dumbbellPullover",
  "dumbbellRearDeltFlyTraditional",
  "dumbbellRowTraditional",
  "dumbbellShrugStanding",
  "dumbbellSkullCrusher",
  "dumbbellStepUp",
  "dumbbellTricepKickback",
  "dumbbellTricepExtensionOverheadSingleDumbbell",
  "dumbbellUprightRow",
  "ezBarBicepCurlTraditional",
  "ezBarPreacherCurl",
  "ezBarSkullCrusher",
  "dumbbellGobletSquat",
  "bodyweightPullUpHammer",
  "bodyweightJumpingJack",
  "kettlebellSwingTraditional",
  "cableLatPulldownTraditional",
  "bodyweightMountainClimberTraditional",
  "noise",
  "bodyweightPlankTaps",
  "plateFrontRaise",
  "bodyweightPullUpTraditional",
  "bodyweightPushUpTraditional",
  "cableLatPulldownReverse",
  "dumbbellRussianTwist",
  "bodyweightSitUpTraditional",
  "bodyweightToeTouches",
  "trapBarDeadlift",
  "trapBarShrug",
  "cableLatPulldownDBar",
  "cableSeatedRowDBar",
  "dumbbellArnoldPress",
  "bodyweightSquat",
  "cableStraightArmPulldownRope",
  "cableStraightArmPulldownBar",
  "machineRearDeltFly",
  "machineRowTraditional",
  "machineAbCrunch",
  "machineChestPressSeatedTraditional",
  "machineTricepPressTraditional",
  "machineRowHammer",
  "backExtensionTraditional",
  "barbellLandminePressSingleArm",
  "barbellLandminePressDoubleArm",
  "plateSvendPress",
  "machineFlyPecDeck",
  "bodyweightAbRollout",
  "machinePreacherCurlTraditional",
  "cableFrontRaiseSingleArm",
  "cableLatRaiseSingleArm",
  "dumbbellConcentrationCurl",
  "machineTricepExtensionTraditional",
  "machineShoulderPressHammer",
  "machineChestPressSeatedHammer",
  "dumbbellRenegadeRow",
  "dumbbellZottmanCurl",
  "bodyweightJumpSquat",
  "resistanceBandSplitter",
  "resistanceBandBicepCurlTraditional",
  "bodyweightJackknifeSitUp",
  "dumbbellLungeWalking",
  "jumpRope",
  "battleRopes",
  "machineBicepCurlAlternating",
  "cableBicepCurlSuperman",
  "bodyweightPilatesSwimmers",
  "tBarRowTraditional",
  "cableRussianTwistRight",
  "cableRussianTwistLeft",
  "wallBalls",
  "ballSlam",
  "dumbbellSideRaiseToFrontRaise",
  "barbellLandmineSplitSquat",
  "landmindeSquatPressDoubleArm",
  "machineShoulderPressTraditional",
  "cableTricepExtensionVbar",
  "plateSideBend",
  "bodyweightRunningTreadmill",
  "stairClimber",
  "bikingSpinning",
  "machineElliptical",
];
