import React, { useState } from "react";

import Chart from "./d3Chart";
import ExRepChart from "./exRepsChart";
import { Tabs, Row, Col, Drawer } from "antd";
const { TabPane } = Tabs;

const ChaartPage = (props) => {
  const [flags, updateFlags] = useState({ activeTab: "1" });

  return (
    <React.Fragment>
      <Chart
        updateSelectedEx={(selectedEx) =>
          updateFlags((prevState) => ({ ...prevState, selectedEx }))
        }
      />
      <Drawer
        destroyOnClose={true}
        title={"Selected Exercise"}
        width={600}
        visible={flags.selectedEx}
        onClose={() =>
          updateFlags((prevState) => ({
            ...prevState,
            selectedEx: null,
          }))
        }
      >
        {flags.selectedEx ? (
          <Row>
            <ExRepChart data={flags.selectedEx}></ExRepChart>
          </Row>
        ) : null}
      </Drawer>
    </React.Fragment>
  );
};

export default ChaartPage;
