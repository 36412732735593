import React, { useEffect, useState } from "react";
import { Button, Col, Input, InputNumber, Row, Spin, Typography } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { CustomService, ProdWorkoutService } from "../../services/dataService";
import {
  CompareModelResponse,
  DebugCompareModelResponse,
  PredSets,
  SetComparision,
} from "../../interfaces/modelComparison";
import WorkoutTimeline from "./timeline";
import SetPlot from "./setPlot";
import RawData from "./rawDataPlot";
import SimPlot from "./simPlot";

const SetView = ({ set }: { set: PredSets }) => {
  return (
    <div
      style={{
        margin: "10px",
        padding: "10px",
        border: "1px solid black",
        borderRadius: "5px",
        backgroundColor: "#e4e4e4",
      }}
    >
      <div> {set.exercise} </div>
      <div>
        {" "}
        Score - {Math.round(set.score * 100) / 100}, Reps - {set.rep_count}{" "}
      </div>
      <div> </div>
    </div>
  );
};

const FalsePositiveData = ({
  data
}: {
  data: SetComparision;
}) => {

  return (
    <Row>
      <Col >
        {data.fp_sets} False Positives
      </Col>
      <Col >
        {data.missing_sets} False Negatives
      </Col>
    </Row>
  )

}

const CompareModel = ({}) => {
  type State = {
    workoutId?: string;
    startTimestamp?: number;
    endTimestamp?: number;
    debugSet?: {
      userId: string;
      startTimestamp: number;
      endTimestamp: number;
      v1ExScores: any[][];
      v2ExScores: any[][];
    };
  };
  const [state, setState] = useState<State>({
    workoutId: undefined,
  });

  useEffect(() => {
    if (state.workoutId) {
      runComparison.mutate();
    }
  }, [state.workoutId]);

  useEffect(() => {
    if (state.debugSet) {
      loadDebugSet();
    }
  }, [state.debugSet]);

  const runComparison = useMutation(async () =>
    CustomService.compareModel({
      workout_id: state.workoutId,
    })
  );

  const debugComparison = useMutation(
    async ({
      userId,
      startTimestamp,
      endTimestamp,
    }: {
      userId: string;
      startTimestamp: number;
      endTimestamp: number;
    }) =>
      CustomService.compareDebugModel({
        user_id: userId,
        start_timestamp: startTimestamp,
        end_timestamp: endTimestamp,
      })
  );

  let comparisonData = runComparison.data as CompareModelResponse;
  // let comparisonData = runComparison.data?.data as CompareModelResponse
  console.log(comparisonData);
  let debugComparisonData = debugComparison.data as DebugCompareModelResponse;

  const selectDebugSet = (startTimestamp: number, endTimestamp: number) => {
    let userId = comparisonData?.userId;
    let startIdx = Math.floor(
      (startTimestamp - comparisonData?.startTimestamp) / 2
    );
    let endIdx = Math.ceil((endTimestamp - comparisonData?.startTimestamp) / 2);
    let v1ExScores = comparisonData?.all_top_exercises_v1.filter((d, index) => {
      let _startTimestamp = d[3] as number;
      let _endTimestamp = d[4] as number;
      return _startTimestamp >= startTimestamp && _endTimestamp <= endTimestamp;
    });
    let v2ExScores = comparisonData?.all_top_exercises_v2.filter((d, index) => {
      let _startTimestamp = d[3] as number;
      let _endTimestamp = d[4] as number;
      return _startTimestamp >= startTimestamp && _endTimestamp <= endTimestamp;
    });
    console.log(
      comparisonData.raw_data_len,
      (comparisonData.endTimestamp - comparisonData.startTimestamp) / 2,
      comparisonData?.all_top_exercises_v1.length,
      comparisonData?.all_top_exercises_v2.length
    );
    setState((prev) => ({
      ...prev,
      startTimestamp: startTimestamp,
      endTimestamp: endTimestamp,
      debugSet: {
        userId: userId,
        startTimestamp: startTimestamp,
        endTimestamp: endTimestamp,
        v1ExScores: v1ExScores,
        v2ExScores: v2ExScores,
      },
    }));
  };

  const loadDebugSet = () => {
    if (state.debugSet) {
      debugComparison.mutate({
        userId: state.debugSet.userId,
        startTimestamp: state.debugSet.startTimestamp,
        endTimestamp: state.debugSet.endTimestamp,
      });
    }
  };

  return (
    <>
      <Input
        placeholder="Workout ID"
        style={{ width: "400px" }}
        suffix={<ArrowRightOutlined />}
        onClick={(e: any) => {
          setState((prev) => ({ ...prev, workoutId: e.target.value }));
        }}
        onPressEnter={(e: any) => {
          setState((prev) => ({ ...prev, workoutId: e.target.value }));
        }}
      />

      {runComparison.isLoading ? <Spin /> : null}
      {!runComparison.isLoading && comparisonData && !runComparison.error ? (
        <WorkoutTimeline
          actualSets={comparisonData.actual_sets}
          predictedSetsV1={comparisonData.v1_sets}
          predictedSetsV2={comparisonData.v2_sets}
          onSelectSet={selectDebugSet}
          v1SetMap = {comparisonData?.v1?.pred_sets_map}
          v2SetMap = {comparisonData?.v2?.pred_sets_map}

        />
      ) : null}

      {}

      {
            comparisonData?.v1?.pred_sets_map && comparisonData?.v2?.pred_sets_map ?
            <div>
             <span>V1</span> <FalsePositiveData data={comparisonData?.v1} />
             <span>V2</span> <FalsePositiveData data={comparisonData?.v2} />
            </div>
            : null
          }
      <div
        style={{
          marginTop: "1rem",
        }}
      >
        <InputNumber
          value={state.startTimestamp}
          onChange={(value) => {
            setState((prev) => ({
              ...prev,
              startTimestamp: value as number,
            }));
          }}
          style={{ marginRight: "10px", width: "200px" }}
        />

        <InputNumber
          value={state.endTimestamp}
          onChange={(value) => {
            setState((prev) => ({
              ...prev,
              endTimestamp: value as number,
            }));
          }}
          style={{ marginRight: "10px", width: "200px" }}
        />

        <Button
          onClick={() => {
            // loadDebugSet();
            selectDebugSet(
              state.startTimestamp as number,
              state.endTimestamp as number
            );
          }}
        >Rerun 
        </Button>
      </div>
      <Row>
        <Col span={12} style={{
          overflowX: "scroll"
        }}>
          <div>
            <Typography.Title level={5}>
              {" "}
              Ex Predictions and raw data
            </Typography.Title>
          </div>
          {state.debugSet ? (
            <div>
              <SetPlot {...state.debugSet} />
            </div>
          ) : null}
          {debugComparisonData ? (
            <div>
              <RawData data={debugComparisonData.raw_data}
              v1SetMap = {comparisonData?.v1?.pred_sets_map}
              v2SetMap = {comparisonData?.v2?.pred_sets_map}

               />
            </div>
          ) : null}
          
          {/* {JSON.stringify(comparisonData?.v1)}
          {JSON.stringify(comparisonData?.v2)} */}

        </Col>
        <Col span={12}>
          <div>
            <Typography.Title level={5}> V1 vs V2 Sets</Typography.Title>
          </div>
          {debugComparisonData ? (
            <div>
              <div>
                <div>
                  <div>
                    <Typography.Title level={5}> V1 Sets</Typography.Title>
                  </div>
                  {debugComparisonData.v1_sets.map((set, index) => {
                    return <SetView set={set} />;
                  })}
                  {debugComparisonData.v1_sets.length === 0 ? (
                    <div> No sets detected </div>
                  ) : null}
                </div>
                <div>
                  <div>
                    <Typography.Title level={5}> V2 Sets</Typography.Title>
                  </div>

                  {debugComparisonData.v2_sets.map((set, index) => {
                    return <SetView set={set} />;
                  })}
                  {debugComparisonData.v2_sets.length === 0 ? (
                    <div> No sets detected </div>
                  ) : null}
                </div>
              </div>
              <SimPlot simData={debugComparisonData.similarity_mat} />
            </div>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default CompareModel;
