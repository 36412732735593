import React, { useState, useEffect } from "react";
import { AdminAllServices } from "../../services/dataService";
import moment from "moment";
import {
  Button,
  Drawer,
  Tag,
  Spin,
  PageHeader,
  Tabs,
  notification,
  message,
  List,
  Card,
  Space,
  Slider,
  Table,
  Col,
  Row,
  Input,
  Empty,
  Typography,
  Divider,
} from "antd";
import {
  RightOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  VideoCameraOutlined,
  DeleteOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import ImportView from "./importView";
import ExportRequestView from "./exportLogsView"
import ActiveExercise from "./activeExercises"
import ModelRegistryView from "./modelRegistry";


const ImportRequestCard = ({ item, onSelect, isSelected }) => {
  return (
    <Card hoverable onClick={() => onSelect(item)}>
      <div style={{ width: "100%" }}>
        <Row>
          <Col span={18}>
            <div style={{ color: "gray" }}>
              <i>
                {moment(item.createdAt).fromNow()} |{" "}
                {item.res?.total_breakdown?.total ?? 0} Processed
              </i>
            </div>
            <div>Logs: {item.log}</div>
            <div>Comments: {item.comments} </div>
            {/* <div>
              <b>{item.log} </b>
            </div> */}
          </Col>
          <Col span={6}>
            <div style={{ float: "right" }}>
              {item.status == "successful" || item.status == "success" ? (
                <Tag color="green" style={{ float: "right" }}>
                  Completed
                </Tag>
              ) : item.status == "running" ? (
                <Tag color="warning" style={{ float: "right" }}>
                  Running
                </Tag>
              ) : item.status == "failed" ? (
                <Tag color="error" style={{ float: "right" }}>
                  Failed
                </Tag>
              ) : null}
              {/* <Tag color="gray">{item.batch} </Tag> */}
            </div>
          </Col>
        </Row>
        <div>
          {/* <Tag color="blue" style={{float: "right"}}>{item.unique_exercise?.length} Exercises</Tag>
          <Tag color="blue" style={{float: "right"}}>{item.total} Sets</Tag> */}
        </div>
      </div>
    </Card>
  );
};

const ImportDetails = ({ item }) => {
  const [state, setState] = useState(null);

  // useEffect(() => {
  //     // Need to prepare table here in antd format
  //     console.log("HERE")
  // }, [item])

  let data = [];
  let columns = [
    {
      title: "Exercise",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Total Processed",
      dataIndex: "total",
      key: "total",
    },
    {
      tital: "Auto Labelled",
      dataIndex: "AUTOMATIC",
      key: "AUTOMATIC"
    },
    {
      title: "Less Data",
      dataIndex: "Less Data",
      key: "Less Data",
    },
    {
      title: "No Left Hand",
      dataIndex: "No Left Hand",
      key: "No Left Hand",
    },
    {
      title: "No Data",
      dataIndex: "No Data",
      key: "No Data",
    },
    {
      title: "NaN Found",
      dataIndex: "NaN Found",
      key: "NaN Found",
    },
    {
      title: "Invalid Labels Found",
      dataIndex: "Invalid Labels Found",
      key: "Invalid Labels Found",
    },
    {
      title: "No CSV/Movie file found",
      dataIndex: "No CSV/Movie file found",
      key: "No CSV/Movie file found",
    },
    {
      title: "Incomplete Headers",
      dataIndex: "Incomplete Headers",
      key: "Incomplete Headers",
    },
  ];

  let errorFiles = item?.res?.error_files || [];
  let exBreakdown = item?.res?.ex_wise_breakdown || {};
  let totalBreakdown = item?.res?.total_breakdown || {};

  data.push({ key: "Total", ...totalBreakdown });
  Object.keys(exBreakdown).map((key) => {
    data.push({ key: key, ...exBreakdown[key] });
  });

  return (

    <div style={{ width: "100%", padding: "1rem", height: "70vh", overflowY: "scroll" }}>
      <Row>
        <Col span={18}>
          <Typography.Title level={3}>#ID: {item.id}</Typography.Title>
          <div style={{ color: "gray" }}>
            <i>
              {moment(item.createdAt).fromNow()} |{" "}
              {item.res?.total_breakdown?.total ?? 0} Processed
            </i>
          </div>
          <div>Logs: {item.log}</div>
          <div>Comments: {item.comments} </div>
          {/* <div>
        <b>{item.log} </b>
      </div> */}
        </Col>
        <Col span={6}>
          <div style={{ float: "right" }}>
            {item.status == "successful" || item.status == "success" ? (
              <Tag color="green" style={{ float: "right" }}>
                Completed
              </Tag>
            ) : item.status == "running" ? (
              <Tag color="warning" style={{ float: "right" }}>
                Running
              </Tag>
            ) : item.status == "failed" ? (
              <Tag color="error" style={{ float: "right" }}>
                Failed
              </Tag>
            ) : null}
            {/* <Tag color="gray">{item.batch} </Tag> */}
          </div>
        </Col>
      </Row>

      <div style={{ padding: "15px 0px" }}>
        <Typography.Title level={4}>Breakdown</Typography.Title>
        <Table dataSource={data} columns={columns} />
      </div>

      <div style={{ padding: "15px 0px" }}>
        <Typography.Title level={4}>Error Files</Typography.Title>
        {/* <div style={{ height: "400px", overflowY: "scroll" }}> */}
        <div>
          {errorFiles.map((item) => (
            <div>
              <b>{item.error}</b>
              <br />
              {item.video_path}
              <br />
              <Divider type="horizontal" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const ImportRequestList = ({ data, isLoading, onSearch, onImport }) => {
  const [selected, updateSelected] = useState(null);
  return (
    <>
      <div style={{ margin: "10px 0px" }}>
        <Row>
          <Col span={12}>
            {/* <Input.Search onSearch={onSearch}></Input.Search> */}
          </Col>
          <Col span={12}>
            <Button
              onClick={onImport}
              type="primary"
              style={{ float: "right" }}
            >
              Import Data
            </Button>
          </Col>
        </Row>
      </div>
      <Row>
        <Col span={8}>
          <div style={{ height: "70vh", overflowY: "scroll" }}>
            <List
              // pagination={{
              //   onChange: (page) => {
              //     updateState((prevState) => ({
              //       ...prevState,
              //       filter: { ...state.filter, page: page },
              //     }));
              //   },
              //   total: userData.data ? userData.data.count : 0,
              //   current: state.filter.page,
              // }}
              // grid={{
              //   gutter: 12,
              //   xs: 1,
              //   sm: 2,
              //   md: 2,
              //   lg: 2,
              //   xl: 2,
              //   xxl: 2,
              // }}
              dataSource={data ? data : []}
              loading={isLoading}
              renderItem={(item, idx) => (
                <List.Item key={idx}>
                  <ImportRequestCard
                    item={item}
                    idx={idx}
                    onSelect={(item) => updateSelected(item)}
                  />{" "}
                </List.Item>
              )}
            />
          </div>
        </Col>
        <Col span={16}>
          {selected ? (
            <ImportDetails item={selected} />
          ) : (
            <Empty description="No Log Selected" />
          )}
          {/* <Button onClick={onImport} type="primary" style={{float: "right"}}>Import Data</Button> */}
        </Col>
      </Row>

      {/* <List
        // pagination={{
        //   onChange: (page) => {
        //     updateState((prevState) => ({
        //       ...prevState,
        //       filter: { ...state.filter, page: page },
        //     }));
        //   },
        //   total: userData.data ? userData.data.count : 0,
        //   current: state.filter.page,
        // }}
        grid={{
          gutter: 12,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 2,
          xxl: 2,
        }}
        dataSource={data ? data : []}
        loading={isLoading}
        renderItem={(item, idx) => (
          <List.Item key={idx}>
            <ImportRequestCard item={item} idx={idx} />{" "}
          </List.Item>
        )}
      /> */}
    </>
  );
};

const Dashboard = (props) => {
  const queryClient = useQueryClient();
  const [state, updateState] = useState({
    filters: {},
  });

  const importRequests = useQuery(
    ["import_requests", state.filters],
    () => AdminAllServices.listImportRequests(state.filters),
    { staleTime: Infinity }
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <PageHeader
          ghost={false}
          // onBack={() => window.history.back()}
          title="Admin View"
          extra={
            [
              // <div>
              //   <Link to="/dashboard/all_user_data/">
              //   <Button>
              //     Data Collection
              //   </Button>
              //   </Link>
              // </div>
            ]
          }
        ></PageHeader>
        <Drawer
          title="Import Batch"
          width="600"
          visible={state.importViewVisible}
          onClose={() =>
            updateState((prev) => ({ ...prev, importViewVisible: false }))
          }
        >
          <div style={{ margin: "10px 20px" }}>
            <ImportView />
          </div>
        </Drawer>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Data Import" key="1">
            <ImportRequestList
              onSearch={(query) =>
                updateState((prev) => ({
                  ...prev,
                  filters: { ...prev.filters, search: query },
                }))
              }
              data={importRequests.data}
              isLoading={importRequests.isLoading}
              onImport={() =>
                updateState((prev) => ({ ...prev, importViewVisible: true }))
              }
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Data Export" key="2">
            {/* Content of Tab Pane 2 */}
            <ExportRequestView />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Active Exercises" key="3">
            {/* Content of Tab Pane 2 */}
            <ActiveExercise />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Models" key="4">
            <ModelRegistryView />
          </Tabs.TabPane>
          
          <Tabs.TabPane tab="Users" key="5">
            {/* Content of Tab Pane 2 */}
          </Tabs.TabPane>
          {/* <TabPane tab="Tab 3" key="3">
      Content of Tab Pane 3
    </TabPane> */}
        </Tabs>
        {/* {dashboardStats.isLoading ? <Spin/> : 
        <div>
          <BatchStatus data={dashboardStats.data.batch_stats}/>
          <UserStatus data={dashboardStats.data.user_stats}/>
          <ExerciseStatus data={dashboardStats.data.ex_stats}/>

          </div>} */}
        {/* <SDSFileList /> */}
        {/* <SDSTable /> */}
      </div>
    </React.Fragment>
  );
};

export {ImportRequestList};

export default Dashboard;
