import {
  Badge,
  Button,
  Card,
  Col,
  Input,
  List,
  PageHeader,
  Popover,
  Radio,
  Row,
  Select,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import ExerciseSelect from "../components/exerciseSelect";
import { useQuery } from "react-query";
import { CalibrationFileServices } from "../../../services/dataService";
import { Link } from "react-router-dom";
import { exNameMap } from "../../../constants";
import createPersistedState from "use-persisted-state";
import moment from "moment";
interface AllCalibrationFilterState {
  search: string;
  exercise: string[];
  ex_verification_status: string;
  label_status: string;
  import_status: string;
  kfold_tag: string;
  include_custom: boolean | null;
  is_archived: boolean | null;
  ex_verified_by: string;
  labelled_by: string;
  archived_by: string;
  kfold_verified_by: string;
  user_id: string;
  page: number;
}
const useAllCalibrationFilterState =
  createPersistedState<AllCalibrationFilterState>("allCalibrationFilterState");
const CalibrationCard = ({ calibration, searchParams }: any) => {
  const verificationStatus = calibration.ex_verification_status;
  const labelStatus = calibration.labelling_status;
  const importStatus = calibration.import_status;
  const archived = calibration.ex_verification_status == "archived";

  const getStatusTags = () => {
    let statusTags = [] as any;

    if (archived) {
      statusTags.push(<Tag color="red">ARCHIVED</Tag>);
      return statusTags;
    }

    if (verificationStatus === "verified") {
      statusTags.push(<Tag color="green">EX VERIFIED</Tag>);
    } else if (verificationStatus === "unverified") {
      statusTags.push(<Tag color="orange">EX UNVERIFIED</Tag>);
    }

    if (labelStatus === "labelled") {
      statusTags.push(<Tag color="green">LABELLED</Tag>);
    } else if (labelStatus === "unlabelled") {
      statusTags.push(<Tag color="yellow">UNLABELLED</Tag>);
    } else if (labelStatus === "unsure") {
      statusTags.push(<Tag color="orange">UNSURE</Tag>);
    }
    return statusTags;
  };

  let baseUrl = window.location.pathname;
  // Check if trailing slash is present in the URL
  if (baseUrl[baseUrl.length - 1] !== "/") {
    baseUrl += "/";
  }
  return (
    <Link to={`${baseUrl}${calibration.id}?${searchParams}`}>
      <Card>
        <Row justify="space-between">
          <Typography.Title level={5}>
            {exNameMap[calibration.exercise as keyof typeof exNameMap] ||
              calibration.exercise}
          </Typography.Title>
          <div>{getStatusTags()}</div>
        </Row>

        <Typography.Text type="secondary">
          ID#: {calibration.id}
        </Typography.Text>
        <br />
        <Typography.Text type="secondary">
          Record Time:{" "}
          {calibration.record_time
            ? moment(calibration.record_time).format("YYYY-MM-DD HH:mm:ss")
            : "N/A"}
        </Typography.Text>
      </Card>
    </Link>
  );
};
const FilterView = ({
  filters,
  onChange,
}: {
  filters: AllCalibrationFilterState;
  onChange: (value: any) => void;
}) => {
  const handleChangeFilters = (key: string, value: any) => {
    onChange({
      ...filters,
      [key]: value,
    });
  };

  const uniqueUsers = useQuery("uniqueUsers", () =>
    CalibrationFileServices.getUniqueUsers()
  );

  return (
    <div>
      <Row gutter={24}>
        <Col span={16}>
          <Input.Search
            placeholder="Search"
            defaultValue={filters.search}
            onChange={(e) => {
              handleChangeFilters("search", e.target.value);
            }}
          />
        </Col>
        <Col span={8}>
          <Popover
            trigger={"click"}
            content={
              <div
                style={{
                  width: "600px",
                }}
              >
                <Row gutter={[12, 12]}>
                  <Col span={24}>
                    <div className="form-label">
                      <div style={{ display: "inline-block" }}>Exercise</div>
                    </div>
                    <ExerciseSelect
                      allowClear={true}
                      value={filters.exercise}
                      onChange={(value) => {
                        // Check if array

                        // if (Array.isArray(value)) {
                        //   value = value.join(",");
                        // }

                        handleChangeFilters("exercise", value);
                        console.log("value", value);
                      }}
                      //multiple
                      mode="tags"
                    />
                  </Col>
                  <Col span={12}>
                    {/* // Label Status  */}
                    <div className="form-label">
                      <div style={{ display: "inline-block" }}>
                        Label Status
                      </div>
                    </div>

                    <Radio.Group
                      value={filters.label_status}
                      onChange={(e) => {
                        handleChangeFilters("label_status", e.target.value);
                      }}
                      //   buttonStyle="solid"
                      optionType="button"
                    >
                      <Radio value="">All</Radio>
                      <Radio value="labelled">Labelled</Radio>
                      <Radio value="unlabelled">Unlabelled</Radio>
                    </Radio.Group>
                  </Col>

                  <Col span={12}>
                    {/* // Import Status */}
                    <div className="form-label">
                      <div style={{ display: "inline-block" }}>
                        Import Status
                      </div>
                    </div>
                    {/* <Select
                      style={{ width: "100%" }}
                      value={filters.import_status}
                      onChange={(value) => {
                        handleChangeFilters("import_status", value);
                      }}
                    >
                      <Select.Option value="">All</Select.Option>
                      <Select.Option value="successful">
                        Successful
                      </Select.Option>
                      <Select.Option value="failed">Not Imported</Select.Option>
                    </Select> */}
                    <Radio.Group
                      value={filters.import_status}
                      onChange={(e) => {
                        handleChangeFilters("import_status", e.target.value);
                      }}
                      //   buttonStyle="solid"
                      optionType="button"
                    >
                      <Radio value="">All</Radio>
                      <Radio value="successful">Successful</Radio>
                      <Radio value="failed">Failed</Radio>
                    </Radio.Group>
                  </Col>

                  {/* //Ex verification  */}
                  <Col span={12}>
                    <div className="form-label">
                      <div style={{ display: "inline-block" }}>
                        Verification Status
                      </div>
                    </div>
                    {/* <Select
                      style={{ width: "100%" }}
                      placeholder="Verification Status"
                      value={filters.ex_verification_status}
                      onChange={(value) => {
                        handleChangeFilters("ex_verification_status", value);
                      }}
                    >
                      <Select.Option value="">All</Select.Option>
                      <Select.Option value="verified">Verified</Select.Option>
                      <Select.Option value="unverified">
                        Unverified
                      </Select.Option>
                    </Select> */}
                    <Radio.Group
                      value={filters.ex_verification_status}
                      onChange={(e) => {
                        handleChangeFilters(
                          "ex_verification_status",
                          e.target.value
                        );
                      }}
                      //   buttonStyle="solid"
                      optionType="button"
                    >
                      <Radio value="">All</Radio>
                      <Radio value="verified">Verified</Radio>
                      <Radio value="unverified">Unverified</Radio>
                    </Radio.Group>
                  </Col>

                  {/* // Kfold Tag */}
                  <Col span={12}>
                    <div className="form-label">
                      <div style={{ display: "inline-block" }}>Kfold Tag</div>
                    </div>
                    <Select
                      style={{ width: "100%" }}
                      value={filters.kfold_tag}
                      onChange={(value) => {
                        handleChangeFilters("kfold_tag", value);
                      }}
                    >
                      <Select.Option value="">All</Select.Option>
                      <Select.Option value="kfold">Kfold</Select.Option>
                      <Select.Option value="non_kfold">Non Kfold</Select.Option>
                    </Select>
                  </Col>

                  {/* // Include Custom */}
                  <Col span={12}>
                    <div className="form-label">
                      <div style={{ display: "inline-block" }}>
                        Include Custom
                      </div>
                    </div>
                    {/* <Select
                      style={{ width: "100%" }}
                      value={filters.include_custom}
                      onChange={(value) => {
                        handleChangeFilters("include_custom", value);
                      }}
                    >
                      <Select.Option value="">All</Select.Option>
                      <Select.Option value={true}>True</Select.Option>
                      <Select.Option value={false}>False</Select.Option>
                    </Select> */}

                    <Radio.Group
                      value={filters.include_custom}
                      onChange={(e) => {
                        handleChangeFilters("include_custom", e.target.value);
                      }}
                      optionType="button"
                      //   buttonStyle="solid"
                    >
                      <Radio value="">All</Radio>
                      <Radio value={true}>True</Radio>
                      <Radio value={false}>False</Radio>
                    </Radio.Group>
                  </Col>

                  {/* // Is Rejected */}

                  <Col span={12}>
                    <div className="form-label">
                      <div style={{ display: "inline-block" }}>
                        Archived Status
                      </div>
                    </div>

                    <Radio.Group
                      value={filters.is_archived}
                      onChange={(e) => {
                        handleChangeFilters("is_archived", e.target.value);
                      }}
                      //   buttonStyle="solid"
                      optionType="button"
                    >
                      <Radio value="">All</Radio>
                      <Radio value={true}>Archived</Radio>
                      <Radio value={false}>Not Archived</Radio>
                    </Radio.Group>
                  </Col>

                  {/* // Verified by  */}
                  <Col span={12}>
                    <div className="form-label">
                      <div style={{ display: "inline-block" }}>Verified By</div>
                    </div>
                    <Select
                      style={{ width: "100%" }}
                      value={filters.ex_verified_by}
                      onChange={(value) => {
                        handleChangeFilters("ex_verified_by", value);
                      }}
                      allowClear
                      onClear={() => {
                        handleChangeFilters("ex_verified_by", "");
                      }}
                    >
                      {/* <Select.Option value="">All</Select.Option> */}
                      {uniqueUsers.data?.ex_verified_by?.map((user: any) => (
                        <Select.Option key={user.user} value={user.user}>
                          {user.user || "Unknown"} <Tag>{user.value}</Tag>
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>

                  <Col span={12}>
                    <div className="form-label">
                      <div style={{ display: "inline-block" }}>Labelled By</div>
                    </div>
                    <Select
                      style={{ width: "100%" }}
                      value={filters.labelled_by}
                      onChange={(value) => {
                        handleChangeFilters("labelled_by", value);
                      }}
                      allowClear
                      onClear={() => {
                        handleChangeFilters("ex_verified_by", "");
                      }}
                    >
                      {/* <Select.Option value="">All</Select.Option> */}
                      {uniqueUsers.data?.labelled_by?.map((user: any) => (
                        <Select.Option key={user.user} value={user.user}>
                          {user.user || "Unknown"} <Tag>{user.value}</Tag>
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>

                  <Col span={12}>
                    <div className="form-label">
                      <div style={{ display: "inline-block" }}>Archived By</div>
                    </div>
                    <Select
                      style={{ width: "100%" }}
                      value={filters.archived_by}
                      onChange={(value) => {
                        handleChangeFilters("archived_by", value);
                      }}
                      allowClear
                      onClear={() => {
                        handleChangeFilters("archived_by", "");
                      }}
                    >
                      {/* <Select.Option value="">All</Select.Option> */}
                      {uniqueUsers.data?.archived_by?.map((user: any) => (
                        <Select.Option key={user.user} value={user.user}>
                          {user.user || "Unknown"} <Tag>{user.value}</Tag>
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>

                  <Col span={12}>
                    <div className="form-label">
                      <div style={{ display: "inline-block" }}>
                        Kfold Verified By
                      </div>
                    </div>
                    <Select
                      style={{ width: "100%" }}
                      value={filters.kfold_verified_by}
                      onChange={(value) => {
                        handleChangeFilters("kfold_verified_by", value);
                      }}
                      allowClear
                      onClear={() => {
                        handleChangeFilters("kfold_verified_by", "");
                      }}
                    >
                      {/* <Select.Option value="">All</Select.Option> */}
                      {uniqueUsers.data?.kfold_verified_by?.map((user: any) => (
                        <Select.Option key={user.user} value={user.user}>
                          {user.user || "Unknown"} <Badge count={user.value} />
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>

                  <Col span={12}>
                    <div className="form-label">
                      <div style={{ display: "inline-block" }}>User Id</div>
                    </div>
                    <Select
                      style={{ width: "100%" }}
                      value={filters.user_id}
                      onChange={(value) => {
                        handleChangeFilters("user_id", value);
                      }}
                      allowClear
                      onClear={() => {
                        handleChangeFilters("user_id", "");
                      }}
                      showSearch
                      filterOption={(input, option) =>
                        // Search in key and value
                        {
                          return (
                            (option?.key || ("" as any))
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0 ||
                            (option?.value || ("" as any))
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }
                      }
                    >
                      {/* <Select.Option value="">All</Select.Option> */}
                      {uniqueUsers.data?.calibration_users?.map(
                        (user: any, id: any) => (
                          <Select.Option
                            key={user.value + user.user}
                            value={user.value}
                          >
                            {user.user || "Unknown"} <br />
                            <Tag>{user.value}</Tag>
                          </Select.Option>
                        )
                      )}
                    </Select>
                  </Col>
                </Row>
              </div>
            }
          >
            <Button type="primary">Filters</Button>
          </Popover>
        </Col>
      </Row>

      <div></div>
    </div>
  );
};

const FilterTags = ({ filters, onChange, total }: any) => {
  const formatValue = (value: any) => {
    if (typeof value === "boolean") {
      return value ? "True" : "False";
    }
    if (Array.isArray(value)) {
      return value.join(",");
    }
    return value;
  };
  return (
    <div
      style={{
        margin: "0.5rem 0rem",
      }}
    >
      <Row gutter={12}>
        <Col>
          <Tag color="grey">{total} Results</Tag>
          {Object.keys(filters)
            .filter((key) => {
              if (key == "exercise") {
                return filters[key]?.length > 0;
              }else if (key == "page") {
                return false;
              
              } else {
                // console.log("key", key, filters[key], filters[key] !== "")
                return (
                  filters[key] !== "" &&
                  filters[key] !== null &&
                  filters[key] !== undefined
                );
              }
            })
            .map((key) => {
              //   if (filters[key] != "") {
              return (
                <Tag
                  closable
                  onClose={() => {
                    onChange(key);
                  }}
                >
                  {key}: {formatValue(filters[key])}
                </Tag>
              );
            })}
        </Col>
      </Row>
    </div>
  );
};
const AllCalibrations = () => {
  // const [page, setPage] = useState(1);
  const [filters, setFilters] = useAllCalibrationFilterState({
    search: "",
    exercise: [],
    ex_verification_status: "",
    label_status: "",
    import_status: "",
    kfold_tag: "",
    include_custom: null,
    is_archived: null,
    ex_verified_by: "",
    labelled_by: "",
    archived_by: "",
    kfold_verified_by: "",
    user_id: "",
    page: 1,
  });

  useEffect(() => {
    // Load query params
    const urlParams = new URLSearchParams(window.location.search);
    const exercise = urlParams.get("exercise");
    const ex_verification_status = urlParams.get("ex_verification_status");
    const search = urlParams.get("search");
    const labelling_status = urlParams.get("labelling_status");
    const import_status = urlParams.get("import_status");
    const kfold_tag = urlParams.get("kfold_tag");
    const include_custom = urlParams.get("include_custom");
    const is_archived = urlParams.get("is_archived");
    const artchived_by = urlParams.get("archived_by");
    const labelling_by = urlParams.get("labelling_by");
    const user_id = urlParams.get("user_id");
    const ex_verified_by = urlParams.get("ex_verified_by");
    if (
      exercise ||
      ex_verification_status ||
      search ||
      labelling_status ||
      import_status ||
      kfold_tag ||
      include_custom ||
      is_archived ||
      labelling_by ||
      user_id ||
      artchived_by
    ) {
      setFilters({
        ...filters,
        search: search || "",
        exercise: exercise?.split(",") || [],
        ex_verification_status: ex_verification_status || "",
        label_status: labelling_status || "",
        import_status: import_status || "",
        kfold_tag: kfold_tag || "",
        include_custom: include_custom ? include_custom == "true" : null,
        is_archived: is_archived ? is_archived == "true" : null,
        archived_by: artchived_by || "",
        labelled_by: labelling_by || "",
        user_id: user_id || "",
        ex_verified_by: ex_verified_by || "",
      });
    }
  }, []);

  const calibrations = useQuery(["allCalibrations",  filters], () =>
    CalibrationFileServices.getAll({
      ...filters,
    })
  );

  const onFilterRemove = (key: string) => {
    setFilters({
      ...filters,
      [key]: null,
    });
  };

  const queryParams: any = {
    ...filters,
  };

  Object.keys(filters).forEach((key) => {
    if (!queryParams[key]) {
      delete queryParams[key];
    } else {
      queryParams[key] = queryParams[key].toString();
    }
  });

  const urlSearchParams = new URLSearchParams(queryParams);

  return (
    <div>
      <PageHeader title="Calibrations" />
      <FilterView filters={filters} onChange={setFilters} />

      <FilterTags
        filters={filters}
        onChange={onFilterRemove}
        total={calibrations.data?.total}
      />

      <div
        style={{
          margin: "1rem 0rem",
        }}
      >
        <List
          dataSource={calibrations.data?.results || []}
          pagination={{
            onChange: (page) => {
              setFilters((prev) => ({
                ...prev,
                page,
              }));
            },
            current: filters.page,
            pageSize: 10,
            total: calibrations.data?.total,
            showSizeChanger: false,
          }}
          grid={{
            gutter: 12,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 2,
            xxl: 2,
          }}
          loading={calibrations.isLoading}
          renderItem={(calibration: any) => (
            <List.Item key={calibration.id}>
              <CalibrationCard
                calibration={calibration}
                key={calibration.id}
                searchParams={urlSearchParams.toString()}
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

export default AllCalibrations;
