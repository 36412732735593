import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Card,
  Row,
  Col,
  List,
  Tag,
  PageHeader,
  Popover,
  Space,
  Drawer,
  notification,
  message,
  Select,
  Divider,
} from "antd";
import { AllUserDashboard } from "../../services/dataService";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import { labelKeys, batchExercises } from "../../constants";
import { FilterOutlined, MoreOutlined, InboxOutlined } from "@ant-design/icons";
// import { fieldToName } from "../../../../constant";
import createPersistedState from "use-persisted-state";
const useWorkoutListViewState = createPersistedState("workoutListViewState");
const { CheckableTag } = Tag;

const ExerciseCard = ({ item, onClick }) => {
  return (
    <Card hoverable onClick={() => onClick(item.workouts)}>
      <div style={{ width: "100%" }}>
        <div>
          <span>{item.exercise} </span>{" "}
          {!item.count ? (
            <Tag color="error" style={{ float: "right" }}>
              No Sets
            </Tag>
          ) : item.count < 3 ? (
            <Tag color="warning" style={{ float: "right" }}>
              {item.count} Sets
            </Tag>
          ) : (
            <Tag color="green" style={{ float: "right" }}>
              {item.count} Sets
            </Tag>
          )}
        </div>
      </div>
    </Card>
  );
};

const ProdWorkoutFileList = (props) => {
  const { search } = useLocation();

  const queryClient = useQueryClient();

  const [state, updateState] = useState({
    setFilter: "all",
    filter: { page: 1, batch: "R5" },
  });

  useEffect(() => {
    const params = new URLSearchParams(search)
    console.log("BATCH",params.get("batch"))
    updateState((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        batch: params.get("batch") || "R5",
      },
    }));
  }, []);

  const userData = useQuery(
    ["cal_user_files", state.filter.batch],
    () =>
      AllUserDashboard.userDetailData(props.match.params.id, {
        batch: state.filter.batch,
      }),
    {}
  );

  const updateUser = useMutation(
    async (newDetails) => {
      var batchData = userData.data?.metadata ? userData.data?.metadata[state.filter.batch] : {};
      var data = {
        user_name: props.match.params.id,
        ...userData.data?.metadata,
        [state.filter.batch]: {...batchData, ...newDetails},
      };
      return AllUserDashboard.updateCalUserData(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("cal_user_files");
        message.success("Updated Data!!");
      },
      onError: (error) => {
        message.success("Update Data Failed!!");
      },
    }
  );

  const routes = [
    {
      path: "callibration",
      breadcrumbName: "Data Collection",
    },
    {
      path: "",
      breadcrumbName: props.match.params.id,
    },
  ];

  function itemRender(route, params, routes, paths) {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={`/${paths.join("/")}`}>{route.breadcrumbName}</Link>
    );
  }

  function isValid(filterKey, value) {
    if (filterKey == "all") {
      return true;
    } else if (filterKey == "noSets" && !value) {
      return true;
    } else if (filterKey == "moreThan3" && value >= 3) {
      return true;
    } else if (filterKey == "lessThan3" && value < 3 && value >= 1) {
      return true;
    }
    return false;
  }

  function onCheck(key) {
    updateState((prevState) => ({ ...prevState, setFilter: key }));
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title={props.match.params.id}
          subTitle={
            userData.data?.metadata?.[[state.filter.batch]]?.all_completed ? (
              <Tag color="green">Completed</Tag>
            ) : null
          }
          breadcrumb={{ routes, itemRender }}
          extra={[
            <div>
              <Row>
                <Space>
                  {!userData.data?.metadata?.[[state.filter.batch]]?.paid ? (
                    <Button
                      onClick={() => updateUser.mutate({ paid: true })}
                      loading={updateUser.isLoading}
                    >
                      Paid
                    </Button>
                  ) : null}
                  {!userData.data?.metadata?.[[state.filter.batch]]?.all_completed ? (
                    <Button
                      onClick={() => updateUser.mutate({ all_completed: true })}
                      loading={updateUser.isLoading}
                    >
                      Mark Completed
                    </Button>
                  ) : null}
                  {!userData.data?.metadata?.removed ? (
                    <Button
                      onClick={() => updateUser.mutate({ removed: true })}
                      loading={updateUser.isLoading}
                    >
                      Removed
                    </Button>
                  ) : null}
                </Space>
              </Row>
            </div>,
          ]}
        ></PageHeader>
        <Row style={{ marginBottom: "1rem" }} align="middle">
          <Col span={8}>
            <Input.Search
              // value={state.query}
              defaultValue={state.query}
              onSearch={(e) =>
                updateState((prevState) => ({ ...prevState, query: e }))
              }
            />
          </Col>
          <Col span={10}>
            {/* <Select
            value={state.setFilter}
            style={{ width: "150px" }}
            onSelect={(key) =>
              updateState((prevState) => ({ ...prevState, setFilter: key }))
            }
          >
            <Select.Option key="">All</Select.Option>
            <Select.Option key="noSets">No Sets</Select.Option>
            <Select.Option key="lessThan3">1-3 sets Sets</Select.Option>
            <Select.Option key="moreThan3">More than 3 Sets</Select.Option>
          </Select> */}
            <div style={{ marginLeft: "30px" }}>
              <CheckableTag
                checked={state.setFilter == "all"}
                key="all"
                onChange={() => onCheck("all")}
              >
                All
              </CheckableTag>
              <CheckableTag
                checked={state.setFilter == "noSets"}
                key="noSets"
                onChange={() => onCheck("noSets")}
              >
                No Sets
              </CheckableTag>
              <CheckableTag
                checked={state.setFilter == "lessThan3"}
                key="lessThan3"
                onChange={() => onCheck("lessThan3")}
              >
                1-3 sets Sets
              </CheckableTag>
              <CheckableTag
                checked={state.setFilter == "moreThan3"}
                key="moreThan3"
                onChange={() => onCheck("moreThan3")}
              >
                More than 3 Sets
              </CheckableTag>
              <Select
                defaultValue="R5"
                value={state.filter.batch}
                onSelect={(e) =>
                  updateState((prev) => ({
                    ...prev,
                    filter: {
                      ...prev.filter,
                      batch: e,
                    },
                  }))
                }
                // style={{ width: 120 }}
                bordered={false}
              >
                <Select.Option value="R5">R5</Select.Option>
                <Select.Option value="R6">R6</Select.Option>
                <Select.Option value="R7">R7</Select.Option>
                <Select.Option value="R8">R8</Select.Option>
                <Select.Option value="R9">R9</Select.Option>
              </Select>
            </div>
          </Col>
        </Row>

        <Drawer
          width={600}
          title={"Callibration Workouts"}
          visible={state.workouts?.length > 0}
          onClose={() => updateState((prev) => ({ ...prev, workouts: null }))}
        >
          {state.workouts ? (
            <div style={{ margin: "20px 20px" }}>
              <Space
                direction="vertical"
                split={<Divider />}
                style={{ width: "100%" }}
              >
                {state.workouts.map((item) => (
                  <Row key={item.id}>
                    <Link to={`/label/${item.id}/`} target="_blank">
                      {item.name}
                    </Link>
                  </Row>
                ))}
              </Space>
            </div>
          ) : null}
        </Drawer>

        <List
          grid={{
            gutter: 12,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 2,
            xxl: 2,
          }}
          dataSource={
            userData.data
              ? batchExercises[state.filter.batch]
                  .filter((item) =>
                    state.query
                      ? item.toLowerCase().includes(state.query.toLowerCase())
                      : true
                  )
                  .map((key) => ({
                    exercise: key,
                    count: userData.data.results[key],
                    workouts: userData.data.exercise_mapping[key] || [],
                  }))
                  .filter((item) => isValid(state.setFilter, item.count))
              : []
          }
          loading={userData.isLoading}
          renderItem={(item, idx) => (
            <List.Item key={idx}>
              <ExerciseCard
                item={item}
                idx={idx}
                onClick={(workouts) =>
                  updateState((prev) => ({ ...prev, workouts: workouts }))
                }
              />
            </List.Item>
          )}
        />
      </div>
      {/* <div>Filters</div> */}
    </React.Fragment>
  );
};

export default ProdWorkoutFileList;
