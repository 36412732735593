import React, { useState, useRef, useEffect } from "react";
import {
  ConstantItemService,
  WorkoutFileService,
  ModelServices,
} from "../../../services/dataService";
import moment from "moment";
import { useHotkeys } from "react-hotkeys-hook";
import { Select, Button } from "antd";
import { FullscreenOutlined } from "@ant-design/icons";
import { useQuery, useMutation, useQueryClient } from "react-query";
import ReactPlayer from "react-player";
import RepLabelChart from "./labelGraph";
import { Link, useHistory } from "react-router-dom";
import { UserContext } from "../../../contexts";
import createPersistedState from "use-persisted-state";
import { useContext } from "react";
import { exStageMapping, defaultStages } from "../../../constants";
import EditExercise from "./editExerciseName";
import ChangeHistory from "./history";
import { KEY_TO_LABEL } from "../../../constants";
import MultilineChart from "./modelPredGraph";
const modelPredictionState = createPersistedState("modelPredictionState");

const ModelPrediction = ({ workoutId, pointer, totalDuration }) => {
  const [state, setState] = modelPredictionState({ modalVisible: false });

  useEffect(() => {
    setState((prev) => ({ ...prev, predData: null }));
    if (state.selectedModel) {
      repPredict.mutate();
    }
  }, [workoutId]);

  const models = useQuery(
    ["model", state.filters],
    () => ModelServices.getAll(state.filters),
    {
      staleTime: Infinity,
      onSuccess: (data) => {
        const first = data.results ? data.results[0] : null;
        setState((prev) => ({
          ...prev,
          selectedModel: state.selectedModel
            ? state.selectedModel
            : first
            ? first.id
            : null,
        }));
      },
    }
  );

  const repPredict = useMutation(
    () =>
      ModelServices.repPredict({
        id: state.selectedModel,
        workout_id: workoutId,
        selectedModel: state.selectedModel,
      }),
    {
      onSuccess: (data) => {
        setState((prev) => ({ ...prev, predData: data }));
      },
    }
  );

  return (
    <div>
      {!models.isLoading ? (
        <Select
          showSearch
          value={state.selectedModel}
          style={{ width: 250, marginRight: "20px" }}
          onChange={(e) => setState((prev) => ({ ...prev, selectedModel: e }))}
          loading={models.isLoading}
          // options={
          //   models.data
          //     ? models.data.results.map((item) => ({
          //         value: item.id,
          //         label: item.name,
          //       }))
          //     : []
          // }
        >
          {(models.data ? models.data.results : []).map((item) => (
            <Select.Option key={item.id}>{item.name}</Select.Option>
          ))}
        </Select>
      ) : null}

      <Button
        style={{ marginRight: "20px" }}
        onClick={() => repPredict.mutate()}
        loading={repPredict.isLoading}
      >
        Get Prediction
      </Button>
      <Button
        onClick={() => setState((prev) => ({ ...prev, modalVisible: true }))}
      >
        <FullscreenOutlined />
      </Button>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "0.5rem",
        }}
      >
        <span style={{fontWeight: "bold"}}>
          {"Detection Reps:"} {repPredict.data?.rep_detection_count}
        </span >
        <span style={{fontWeight: "bold"}}>
          {"Seq Label Reps:"} {repPredict.data?.total_seq}
        </span>
        <span style={{fontWeight: "bold"}}>
          {"Regression Reps:"} {repPredict.data?.total_rep}
        </span>
        <span style={{fontWeight: "bold"}}>
          {"Actual Reps:"} {repPredict.data?.actual_reps}
        </span>
      </div>

      {state.predData ? (
        <MultilineChart
          data={state.predData}
          pointer={pointer}
          modalVisible={state.modalVisible}
          totalDuration={totalDuration}
          closeModal={(prev) =>
            setState((prev) => ({ ...prev, modalVisible: false }))
          }
        />
      ) : null}
    </div>
  );
};

export default ModelPrediction;
