import React from "react";
import {
  CalibrationFileServices,
  MiscServices,
} from "../../../services/dataService";
import { useMutation, useQuery } from "react-query";
import { Button, Col, Input, List, Row, Switch, Tag, Typography } from "antd";
import { Link } from "react-router-dom";

const CalibrationUsers = () => {
  const [page, setPage] = React.useState(1);
  const [calibrationPage, setCalibrationPage] = React.useState(1);
  const [selectedUser, setSelectedUser] = React.useState("");
  const [search, setSearch] = React.useState("");
  const calibrationUsers = useQuery(["calibrationUsers", page, search], () =>
    CalibrationFileServices.getUsers({
      page: page,
      user_search: search,
    })
  );

  const userCalibrations = useQuery(["userCalibrations", selectedUser, calibrationPage], () =>
    CalibrationFileServices.getAll({
      user_id: selectedUser,
      page: calibrationPage,
    })
  );


  const openCalibration = (file: any) => {
    let url = "/v2/calibration/" + file.id;
    window.open(url, "_blank");

  }

  const getStatusTags = (calibration: any) => {
    let statusTags = [] as any;
    const verificationStatus = calibration.ex_verification_status;
    const labelStatus = calibration.labelling_status;
    const importStatus = calibration.import_status;
    const archived = calibration.ex_verification_status == "archived";

    if (archived) {
      statusTags.push(<Tag color="red">ARCHIVED</Tag>);
      return statusTags;
    }

    if (verificationStatus === "verified") {
      statusTags.push(<Tag color="green">EX VERIFIED</Tag>);
    } else if (verificationStatus === "unverified") {
      statusTags.push(<Tag color="orange">EX UNVERIFIED</Tag>);
    }

    if (labelStatus === "labelled") {
      statusTags.push(<Tag color="green">LABELLED</Tag>);
    } else if (labelStatus === "unlabelled") {
      statusTags.push(<Tag color="yellow">UNLABELLED</Tag>);
    } else if (labelStatus === "unsure") {
      statusTags.push(<Tag color="orange">UNSURE</Tag>);
    }
    return statusTags;
  };

  return (
    <div>
      <Row gutter={12}>
        <Col span={8}>
          <Input.Search
            placeholder="Search"
            defaultValue={search}
            onSearch={(value) => {
              setSearch(value);
            }}
            style={{ marginBottom: "10px" }}
          />

          <div style={{ height: "80vh", overflowY: "scroll" }}>
            {/* {calibrationUsers.data?.map((user: any) => (
          <Row
            key={user.id}
            style={{
              borderBottom: "1px solid #f0f0f0",
              padding: "10px 0",
            }}
          >
            <Col span={12}>
              <Typography.Text>{user.user_name}</Typography.Text>
            </Col>
           
          </Row>
        ))} */}

            <List
              pagination={{
                current: page,
                onChange: (page) => {
                  setPage(page);
                },
                total: calibrationUsers.data?.total ?? 0,
              }}
              dataSource={calibrationUsers.data?.results ?? []}
              loading={calibrationUsers.isLoading}
              renderItem={(user: any) => (
                <List.Item key={user.user_id}>
                  <Row
                    style={{
                      width: "100%",
                    }}
                  >
                    <Col span={18}>
                      <Button
                        type="link"
                        onClick={() => {
                          setSelectedUser(user.user_id);
                        }}
                      >
                        {user.user_name}
                      </Button>
                    </Col>
                    <Col span={6}>
                      <Typography.Text>
                        {user.calibration_count}
                      </Typography.Text>
                    </Col>
                  </Row>
                </List.Item>
              )}
            />
          </div>
        </Col>
        <Col span={16}>
          <Typography.Title level={5}>Calibrations</Typography.Title>
          <List
            pagination={{
              current: calibrationPage,
              onChange: (page) => {
                setCalibrationPage(page);
              },
              total: userCalibrations.data?.total ?? 0,
            }}
            dataSource={userCalibrations.data?.results ?? []}
            loading={userCalibrations.isLoading}
            renderItem={(calibration: any) => (
              <List.Item key={calibration.id}>
                <Row
                  style={{
                    width: "100%",
                  }}
                >
                  <Col span={18}>
                    <Link to={`/v2/calibrations/${calibration.id}`}>
                    <Button type="link">{calibration.exercise_name}</Button>
                    </Link>
                    {/* <Typography.Text>  {calibration.exercise_name}

                    </Typography.Text> */}
                    <Typography.Text type="secondary">
                      {calibration.labelled_on}
                    </Typography.Text>
                  </Col>
                  <Col span={6}>
                    <div>{getStatusTags(calibration)}</div>
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CalibrationUsers;
