import React, { useState, useRef, useEffect } from "react";
// import { LineChart, Line, XAxis, YAxis } from "recharts";
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  MarkSeries,
  LineSeries,
} from "react-vis";
import "../../../node_modules/react-vis/dist/style.css";


const RepLabelChart = (props) => {
  // const [data, updateData] = React.useState([]);
  // useEffect(() => {
  //   updateData(props.data);
  // }, [props.data]);
  // console.log(props.data)
  console.log("Time: ", props.time)
  console.log("Label: ", props.stage)
  return (
    // <LineChart width={640} height={100} data={props.data}>
    //   <XAxis dataKey="x" domain={[0, 1]}/>
    //   <YAxis/>
    //   <Line type="monotone" dataKey="y" stroke="#8884d8" strokeWidth={2} />
    // </LineChart>
    
    <XYPlot width={600} height={100}>
      {/* <HorizontalGridLines /> */}
      <LineSeries
        color = {props.label == "AUTOMATIC" ? "orange" : "blue"}
        curve={null}
        // getNull={(d) => d.y !== null}
        // fill={"white"}
        data={props.data}
        // data={data.filter((item, idx) => idx == 0)}
        style={{ strokeLinejoin: "round" }}
      />
      <MarkSeries
        data={[{x: props.time, y: props.stage, size: 5}]}/>
      <XAxis
        top={0}
        hideLine
        tickValues={[0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]}
        title="time"
      />

      <YAxis hideLine tickValues={[0, 1.0, 2.0, 3.0]} />
    </XYPlot>
  );
};

export default RepLabelChart;