import React from "react";
import { Layout } from "antd";
import "./index.css";
import MenuItems from "./menu";

const SideBar = ({ active, validPath }) => {
  return (
    <Layout.Sider
      width="100"
      className="sidebar"
      breakpoint={"lg"}
      theme="light"
      collapsedWidth={0}
      trigger={null}
    >
      <MenuItems active={active} validPath={validPath}/>
    </Layout.Sider>
  );
};

export default SideBar;
