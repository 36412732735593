import { BaseCRUDService } from "./baseService";
import { axios } from "./api";
import exp from "constants";

const baseUrl = process.env.REACT_APP_API_URL;

export const DashboardInfoService = new BaseCRUDService("dashboard_info");
export const WorkoutLabelService = new BaseCRUDService("label");
export const ConstantListService = new BaseCRUDService("constants");
export const ConstantItemService = new BaseCRUDService("constants");
export const DashboardService = new BaseCRUDService("dashboard");

class LabelledCSVUploadCRUDService extends BaseCRUDService {
  upload(id, data) {
    return axios
      .post(`${this.base}/${id}/upload_csv/`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data);
  }
}

class _WorkoutFileService extends BaseCRUDService {
  getKFoldTags() {
    return axios.get(`${this.base}/get_kfold_tags/`).then((res) => res.data);
  }

  updateKfoldTags(data) {
    return axios
      .post(`${this.base}/update_kfold_tag/`, data)
      .then((res) => res.data);
  }

  autoLabel(id, data) {
    return axios
      .post(`${this.base}/${id}/auto_label/`, data)
      .then((res) => res.data);
  }
}

export const LabelledCSVService = new LabelledCSVUploadCRUDService("workout");

class ProdWorkoutFileCRUDService extends BaseCRUDService {
  searchUsers(params) {
    return axios
      .get(
        `${this.base}/search_users/`,
        { params: params },
        {
          headers: {},
        }
      )
      .then((res) => res.data);
  }

  importWorkout(data) {
    return axios
      .post(`${this.base}/import_item/`, data, {
        headers: {},
      })
      .then((res) => res.data);
  }

  getWorkouts(params) {
    return axios
      .get(
        `${this.base}/user_workouts/`,
        { params },
        {
          headers: {},
        }
      )
      .then((res) => res.data);
  }

  getModels(id, data) {
    return axios
      .get(`${this.base}/available_model/`, data)
      .then((res) => res.data);
  }

  rerunPred(id, data) {
    return axios
      .post(`${this.base}/${id}/rerun_pred/`, data, {
        headers: {},
      })
      .then((res) => res.data);
  }
}

class CustomService_ extends BaseCRUDService {
  getWorkouts(data) {
    return axios
      .post(`${this.base}/get_user_workouts/`, data, {
        headers: {},
      })
      .then((res) => res.data);
  }

  searchUser(data) {
    return axios
      .post(`${this.base}/user_search/`, data, {
        headers: {},
      })
      .then((res) => res.data);
  }

  detailWorkout(data) {
    return axios
      .post(`${this.base}/get_workout_detail/`, data, {
        headers: {},
      })
      .then((res) => res.data);
  }

  debugSet(data) {
    return axios
      .post(`${this.base}/debug_set/`, data, {
        headers: {},
      })
      .then((res) => res.data);
  }

  debugFrontendSet(data) {
    return axios
      .post(`${this.base}/debug_set_frontend/`, data, {
        headers: {},
      })
      .then((res) => res.data);
  }

  debugFrontendWorkout(data) {
    return axios
      .post(`${this.base}/debug_workout_frontend/`, data, {
        headers: {},
      })
      .then((res) => res.data);
  }

  simulations(data) {
    return axios
      .post(`${this.base}/simulations/`, data, {
        headers: {},
      })
      .then((res) => {
        return res.data?.simulations;
      });
  }

  simulationWorkout(data) {
    return axios
      .post(`${this.base}/simulations_data/`, data, {
        headers: {},
      })
      .then((res) => res.data);
  }

  compareModel(data) {
    return axios
      .post(`${this.base}/compare_model/`, data, {
        headers: {},
      })
      .then((res) => res.data);
  }

  compareDebugModel(data) {
    return axios
      .post(`${this.base}/compare_debug_model/`, data, {
        headers: {},
      })
      .then((res) => res.data);
  }
}

export const CustomService = new CustomService_("custom");

export const ProdWorkoutService = new ProdWorkoutFileCRUDService(
  "prod_workout"
);

class AllUserDashboardService extends BaseCRUDService {
  allUserData(params) {
    return axios
      .get(
        `${this.base}/all_user_details/`,
        { params: params },
        {
          headers: {},
        }
      )
      .then((res) => res.data);
  }

  userDetailData(id) {
    return axios
      .post(
        `${this.base}/users_file_details/`,
        { id: id },
        {
          headers: {},
        }
      )
      .then((res) => res.data);
  }

  updateCalUserData(data) {
    return axios
      .post(`${this.base}/update_cal_user_data/`, data, {
        headers: {},
      })
      .then((res) => res.data);
  }

  downloadCalUserData(params) {
    return axios
      .get(
        `${this.base}/download_csv/`,
        { params: params },
        {
          headers: {},
          responseType: "blob",
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  bulkChangePaid(params) {
    return axios
      .post(
        `${this.base}/bulk_update_paid/`,
        { params: params },
        {
          params: params,
          headers: {},
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  }
}

export const AllUserDashboard = new AllUserDashboardService("dashboard");

class AdminServices extends BaseCRUDService {
  importBatch(data) {
    return axios
      .post(`${this.base}/import_batch/`, data, {
        headers: {},
      })
      .then((res) => res.data);
  }

  exportData(data) {
    return axios
      .post(`${this.base}/export_data/`, data, {
        headers: {},
      })
      .then((res) => res.data);
  }

  listImportRequests(params) {
    return axios
      .get(
        `${this.base}/import_requests/`,
        { params: params },
        {
          headers: {},
        }
      )
      .then((res) => res.data);
  }
  listExportRequests(params) {
    return axios
      .get(
        `${this.base}/export_requests/`,
        { params: params },
        {
          headers: {},
        }
      )
      .then((res) => res.data);
  }
}

class _ModelServices extends BaseCRUDService {
  repPredict(data) {
    return axios
      .post(`${this.base}/${data.id}/rep_count_prediction/`, data, {
        headers: {},
      })
      .then((res) => res.data);
  }

  autoRepPredict(data) {
    return axios
      .post(`${this.base}/auto_rep_count/`, data, {
        headers: {},
      })
      .then((res) => res.data);
  }

  getModels(data) {
    return axios
      .post(`${this.base}/get_models/`, data, {
        headers: {},
      })
      .then((res) => res.data);
  }

  getModelResults(data) {
    return axios
      .post(`${this.base}/get_model_results/`, data, {
        headers: {},
      })
      .then((res) => res.data);
  }
}

class _CalibrationFileServices extends BaseCRUDService {
  constructor() {
    super("v2/calibrations");
  }
  getDashboardData(params) {
    return axios
      .get(`${this.base}/dashboard/`, { params: params })
      .then((res) => res);
  }

  getNextFile(id, params) {
    return axios
      .get(`${this.base}/${id}/next_file/`, { params: params })
      .then((res) => res.data);
  }

  verifyFile(id, data) {
    return axios
      .post(`${this.base}/${id}/verify_file/`, data, {
        headers: {},
      })
      .then((res) => res.data);
  }

  labelFile(id, data) {
    return axios
      .post(`${this.base}/${id}/label_file/`, data, {
        headers: {},
      })
      .then((res) => res.data);
  }

  rejectFile(id, data) {
    return axios
      .post(`${this.base}/${id}/reject_file/`, data, {
        headers: {},
      })
      .then((res) => res.data);
  }

  getUsers(params) {
    return axios
      .get(`${this.base}/users/`, { params: params })
      .then((res) => res.data);
  }

  getUniqueUsers(params) {
    return axios
      .get(`${this.base}/unique_users/`, { params: params })
      .then((res) => res.data);
  }

  resetLabels(id) {
    return axios
      .post(`${this.base}/${id}/reset_labels/`)
      .then((res) => res.data);
  
  }

  resetVerification(id) {
    return axios
      .post(`${this.base}/${id}/reset_verification/`)
      .then((res) => res.data);
  }
}

class _KfoldService extends BaseCRUDService {
  constructor() {
    super("v2/kfold");
  }

  createBatch(data) {
    return axios.post(`${this.base}/create_batch/`, data).then((res) => res.data);
  }

  getFiles(
    id,
    params,
  ) {
    return axios.get(`${this.base}/${id}/files/`, { params: params }).then((res) => res.data);
  }

  getKfoldFiles(
    id,
  ) {
    return axios.get(`${this.base}/batch_file/${id}/`).then((res) => res.data);
  }
  
  
  getNextFile(
    id,
  ) {
    return axios.get(`${this.base}/batch_file/${id}/next_file/`).then((res) => res.data);
  }

  validateFile(
    id,
    data
  ) {
    return axios.post(`${this.base}/batch_file/${id}/validate/`, data).then((res) => res.data);
  }
}
class _MiscServices extends BaseCRUDService{
  constructor(){
    super("v2/misc")
  }

  getMasterExercises(params) {
    return axios
      .get(`${this.base}/master_exercises/`, { params: params })
      .then((res) => res.data);
  }

  getCalibrationConfig(params) {
    return axios
      .get(`${this.base}/get_calibration_config/`, { params: params })
      .then((res) => res.data);
  }

  updateCalibrationConfig(data) {
    return axios
      .post(`${this.base}/update_calibration_config/`, data)
      .then((res) => res.data);
  }


}
export const AdminAllServices = new AdminServices("admin");
export const ModelServices = new _ModelServices("model");
export const MiscServices = new _MiscServices("misc");
export const WorkoutFileService = new _WorkoutFileService("workout");

export const FilterValues = new BaseCRUDService("filter_values");
export const VariableFilterValues = new BaseCRUDService(
  "variable_filter_values"
);

export const CalibrationFileServices = new _CalibrationFileServices();
export const KfoldService = new _KfoldService();