import React, { useState, useRef, useEffect } from "react";
import {
  ConstantItemService,
  WorkoutFileService,
  WorkoutLabelService,
} from "../../services/dataService";
import moment from "moment";
import { useHotkeys } from "react-hotkeys-hook";
import { Button, Row, Col, Typography, InputNumber, Popover } from "antd";
import {
  VideoCameraOutlined,
  DeleteOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { useQuery, useMutation, useQueryClient } from "react-query";

const RepDetailsView = (props) => {
  const [segments, setSegments] = useState(props.segments);
  const edit = false;
  const deleteRepSegment = () => {
    // const segments = state.annotations.segments;
    // const lastSegment = segments[segments.length - 1];
    // const newSegments = segments.slice(0, -1);
    // // debugger
    // const newPos = lastSegment.start / state.totalDuration;
    // player.current.seekTo(newPos);
    // const labels = state.labels.filter((item) => item.x < newPos);
    // let currentStage = 0;
    // if (newSegments.length > 0) {
    //   currentStage =
    //     nextStageMapping[newSegments[newSegments.length - 1].label];
    // }
    // const currentSegment = lastSegment;
    // currentSegment["end"] = null;
    // currentSegment["label"] = currentStage;
    // updateState((prevState) => ({
    //   ...prevState,
    //   labels: labels,
    //   currentStage: currentStage,
    //   annotations: {
    //     ...state.annotations,
    //     currentSegment: currentSegment,
    //     segments: newSegments,
    //   },
    // }));
  };

  const addRepSegment = () => {};

  const adjustRepSegment = () => {};

  const popoverContent = (idx) => {
    return (<div>
      <Button type="link" danger onClick={() => props.changeStageLabel(idx, 0)}>
        Change to 0
      </Button>
    </div>);
  };

  return (
    <>
      {/* <Typography.Title level={5}>Rep Segments</Typography.Title> */}
      <div style={{ overflowY: "auto" }}>
        <div
          style={{
            borderBottom: "solid gray 1px",
          }}
        >
          <Row>
            <Col span={4}></Col>

            <Col span={4}>
              <>
                <div>
                  <Typography.Text type="secondary">Label</Typography.Text>
                </div>{" "}
              </>
            </Col>
            <Col span={4}>
              <>
                <div>
                  <Typography.Text type="secondary">Start</Typography.Text>
                </div>{" "}
              </>
            </Col>
            <Col span={4}>
              <>
                <div>
                  <Typography.Text type="secondary">End</Typography.Text>
                </div>{" "}
              </>
            </Col>
          </Row>
        </div>
      </div>
      <div style={{ height: "360px", overflowY: "auto" }}>
        {props.segments.map((item, idx) => (
          <div
            style={{
              borderBottom:
                idx != props.segments.length - 1 ? "solid gray 1px" : null,
            }}
            key={idx}
          >
            <Row>
              <Col span={4}>
                <Typography.Title level={5}>#{idx}</Typography.Title>
              </Col>

              <Col span={4}>
                {edit ? (
                  <InputNumber
                    defaultValue={item.label}
                    step={10}
                  ></InputNumber>
                ) : (
                  <>
                    <div>{item.label}</div>{" "}
                  </>
                )}
              </Col>
              <Col span={4}>
                {edit ? (
                  <InputNumber
                    defaultValue={item.start}
                    step={10}
                  ></InputNumber>
                ) : (
                  <>
                    <div>{item.start}</div>{" "}
                  </>
                )}
              </Col>
              <Col span={4}>
                {edit ? (
                  <InputNumber
                    defaultValue={item.end}
                    step={10}
                  ></InputNumber>
                ) : (
                  <>
                    <div>{item.end ? item.end : ""}</div>{" "}
                  </>
                )}
              </Col>
              <Col span={8}>
                <Button type="link">
                  <VideoCameraOutlined />
                </Button>
                {idx == props.segments.length - 1 ?
                <Button type="link">
                  <DeleteOutlined onClick={props.undoLastStage} />
                </Button> : null}
                <Popover content={popoverContent(idx)} title="" trigger="click">
                  <Button type="link">
                    <MoreOutlined />
                  </Button>
                </Popover>
              </Col>
            </Row>
          </div>
        ))}
      </div>
    </>
  );
};

export default RepDetailsView;
