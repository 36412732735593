export const labellingReps = [
  "Hanging Leg Raise",
  "Single Arm Landmine Press",
  "Svend Press (Plate Push)",
  "Battle Ropes",
  "Cable Russian twist (Left)",
  "Cable Russian twist (Right)",
  "Cable Straight Arm Lat Pull Down Rope",
  "Cable Single Arm Lateral Raise",
  "Bent Over Single-arm Row",
  "Dumbbell Walking Lunge",
  "EZ bar preacher curl",
  "Machine Ab Crunch ",
  "Machine arm curl",
  "Machine Back Extension",
  "Machine Seated Chest Press",
  "Machine Leg Curl",
  "Leg Extension Machine",
  "Smith Machine Upright Row",
  "Smith Machine Bentover Row",
  "Smith Machine Bench Press",
  "Burpee",
  "Resistance Band Lateral Walk (above knee)",
  "Resistance Band Bicep Curl",
  "Leg raise",
  "Resistance Band Splitter",
  "Scissor Kick",
  "Barbell Preacher Curl",
  "Double Arm Landmine Press",
  "Cable Superman Bicep Curl",
  "Cable Front Raise Single Arm",
  "Cable Straight Arm Lat Pull Down Bar",
  "Concentration Curl",
  "Dumbbell Seated Shrug",
  "Leg Press Machine",
  "Machine Lying Leg Curl",
  "Machine row regular grip",
  "Machine row hammer grip",
  "Machine Rear Delt Fly",
  "Machine Tricep Press",
  "Wall Balls ",
  "Smith Machine Incline Bench Press",
  "Smith Machine Squat",
  "Smith Machine Seated Shoulder Press",
  "Jump Squats",
  "Dumbbell Overhead Squat",
  "Ab Rollout",
  "Jump Rope ",
  "Reverse Crunch",
  "Bodyweight Squats",
  "Jackknife Sit up",
  "Resistance Band Squat (above knee)",
  "Plank",
  "Wall Sit",
];

export const labelKeys = [
  "hangingLegRaise",
  "barbellSingleArmLandminePress",
  "svendPress",
  "battleRopes",
  "cableRussianTwistRight",
  "cableRussianTwistLeft",
  "cableStraightArmLatPullDown",
  "cableSingleArmLatRaise",
  "dumbbellBentOverSingleArmRow",
  "dumbbellWalkingLunge",
  "ezBarPreacherCurl",
  "machineAbCrunch",
  "machineAlternateArmCurls",
  "machineBackExtension",
  "machineSeatedChestPress",
  "machineLegCurl",
  "machineLegExtension",
  "smithMachineUprightRow",
  "smithMachineBentoverRow",
  "smithMachineBenchPress",
  "burpee",
  "resistanceBandLateralWalk",
  "resistanceBandBicepCurl",
  "legRaise",
  "resistanceBandSplitter",
  "sissorKick",
  "barbellPreacherCurl",
  "barbellDoubleArmLandminePress",
  "cableSupermanBicepCurl",
  "cableFrontRaiseSingleArm",
  "cableStraightArmLatPullDownBar",
  "dumbbellConcentrationCurl",
  "dumbbellSeatedShrug",
  "machineLegPress",
  "machineLyingLegCurl",
  "machineRowRegularGrip",
  "machineRowHammerGrip",
  "machineRearDeltFly",
  "machineTricepPress",
  "wallBalls",
  "smithMachineInclineBenchPress",
  "smithMachineBackSquat",
  "smithMachineSeatedShoulderPress",
  "jumpSquat",
  "dumbbellOverheadSquat",
  "abRollout",
  "jumpRope",
  "reverseCrunch",
  "bodyweightSquat",
  "jackknifeSitUp",
  "resistanceBandSquat",
  "plank",
  "wallSit",
];

export const batchExercises = {
  R1: ["dumbbellBicepCurl", "bodyweightLunge", "pushUp", "sitUp"],
  R2: [
    "dumbbellHammerCurl",
    "dumbbellDeadlift",
    "dumbbellFly",
    "dumbbellReverseFly",
    "deadBug",
    "toeTouches",
    "dumbbellBenchPress",
    "dumbbellFrontRaise",
    "crunch",
    "dumbbellBulgarianSplitSquat",
  ],
  R3: [
    "barbellLunge",
    "barbellShrug",
    "dumbbellDeclineBenchPress",
    "barbellBenchPress",
    "barbellInclineBenchPress",
    "dumbbellInclineBenchPress",
    "barbellDeadlift",
    "trapBarDeadlift",
    "dips",
    "cableCrossoverHigh",
    "cableCrossoverLow",
    "dumbbellInclineFly",
    "cableBentoverRearDeltFly",
    "cableUprightRearDeltFly",
    "dumbbellLunge",
    "mountainClimber",
    "plankTap",
    "latPulldown",
    "reverseGripLatPulldown",
    "vBarPulldown",
    "hammerPullUp",
    "pullUp",
    "dumbbellLatRaise",
    "plateFrontRaise",
    "barbellUprightRow",
    "barbellReverseGripBentoverRow",
    "vBarSeatedCableRow",
    "dumbbellOverheadPress",
    "barbellMilitaryPress",
    "dumbbellShrug",
    "trapBarShrug",
    "bicycleCrunch",
    "russianTwist",
    "dumbbellPullover",
    "dumbbellSkullCrusher",
    "barbellBulgarianSplit",
    "barbellFrontSquat",
    "dumbbellStepUp",
    "kettlebellSwing",
    "barbellHipThrusters",
  ],
  R4: [
    "barbellBicepCurl",
    "cableBicepCurl",
    "cableRopeBicepCurl",
    "ezBarBicepCurl",
    "ezBarPreacherCurl",
    "barbellCloseGripBenchPress",
    "benchDips",
    "cableFacePull",
    "barbellRackPull",
    "chinUp",
    "diamondPushUp",
    "barbellBentoverRow",
    "cableUprightRow",
    "dumbbellRow",
    "dumbbellUprightRow",
    "cableSeatedRow",
    "dumbbellArnoldPress",
    "cableCrunch",
    "ezBarSkullCrusher",
    "barbellBackSquat",
    "gobletSquat",
    "boxJump",
    "jumpingJack",
    "cableRopeTricepExtension",
    "cableTricepExtension",
    "cableRopeOverheadTriExtensionHigh",
    "cableRopeOverheadTriExtensionLow",
    "dumbbellTricepKickback",
    "dumbbellTricepPress",
    "birdDog",
  ],
  R5: [
    "abRollout",
    "barbellPreacherCurl",
    "battleRopes",
    "dumbbellBentOverSingleArmRow",
    "bodyweightSquat",
    "burpee",
    "cableFrontRaiseSingleArm",
    "cableRussianTwistLeft",
    "cableRussianTwistRight",
    "cableSingleArmLatRaise",
    "cableStraightArmLatPullDownBar",
    "cableStraightArmLatPullDown",
    "cableSupermanBicepCurl",
    "dumbbellConcentrationCurl",
    "barbellDoubleArmLandminePress",
    "dumbbellOverheadSquat",
    "dumbbellSeatedShrug",
    "dumbbellWalkingLunge",
    "hangingLegRaise",
    "jackknifeSitUp",
    "jumpRope",
    "machineLegExtension",
    "machineLegPress",
    "legRaise",
    "machineAbCrunch",
    "machineAlternateArmCurls",
    "machineBackExtension",
    "machineLegCurl",
    "machineLyingLegCurl",
    "machineRearDeltFly",
    "machineRowHammerGrip",
    "machineRowRegularGrip",
    "machineSeatedChestPress",
    "machineTricepPress",
    "plank",
    "resistanceBandBicepCurl",
    "resistanceBandLateralWalk",
    "resistanceBandSplitter",
    "resistanceBandSquat",
    "reverseCrunch",
    "sissorKick",
    "barbellSingleArmLandminePress",
    "smithMachineBackSquat",
    "smithMachineBenchPress",
    "smithMachineBentoverRow",
    "smithMachineInclineBenchPress",
    "smithMachineSeatedShoulderPress",
    "smithMachineUprightRow",
    "svendPress",
    "wallSit",
  ],
  R7: [
    "jumpSquat",
    "TRXPushUp",
    "plankUpDown",
    "elbowToKnees",
    "TRXSquat",
    "TRXHammerPull",
    "TRXSquatRow",
    "sidePlankLeft",
    "sidePlankRight",
    "TRXHamstringCurl",
    "TRXCalkRaises",
    "TRXTDeltFly",
    "TRXSingleArmRow",
    "TRXStepBackLunge",
    "TRXJumpSquat",
    "TRXYDeltFly",
    "TRXLunge",
    "TRXReversePlank",
    "TRXSuspendedPlank",
    "TRXBicepCurl",
    "TRXHipThrust",
    "TRXWDeltFly",
    "TRXBalanceLunge",
    "TRXHipDrop",
    "TRXLateralLunge",
    "TRXLegRaise",
    "TRXRow",
    "TRXTricepExtension",
    "TRXChestPress",
    "TRXBicycleCurl",
    "TRXGluteBridge",
    "TRXPistolSquat",
    "TRXSidePlank",
    "TRXBodysaw",
    "TRXSplitSquat",
    "TRXMountainClimbers",
    "TRXPulseLunges",
    "TRXPullUps",
    "TRXSingleLegSquat",
    "TRXHamstringRunner",
    "TRXIceSkater",
    "TRXKneelingObliqueRollout",
    "TRXChestFly",
    "TRXAbRollout",
    "TRXPike",
  ],
  R6: [
    "wallBalls",
    "battleRopesHandsTogether",
    "kippingPullUp",
    "barbellStiffLegGoodMorning",
    "barbellAltGripDeadlift",
    "barbellPowerCleanPress",
    "barbellPowerCleanBlocks",
    "barbellPowerSnatchBlocks",
    "barbellClean",
    "barbellHangClean",
    "barbellCleanJerk",
    "barbellCleanPress",
    "barbellMuscleClean",
    "kettlebellSingleLegDeadlift",
    "barbellSnatchBalance",
    "barbellThruster",
    "barbellPowerCleanThruster",
    "dumbbellPowerCleanJerkSingle",
    "kettlebellBottomsUpPress",
    "dumbellPowerCleanSingle",
    "barbellPushJerk",
    "barbellPushPress",
    "barbellSnatch",
    "barbellSnatchHighPull",
    "barbellSnatchPull",
    "kettlebellSideLungeClean",
    "kettlebellClean",
    "barbellMuscleSnatch",
    "kettlebellCleanPress",
    "kettlebellSnatch",
    "kettlebellThrusterDouble",
    "kettlebellThrusterSingle",
    "kettlebellCleanJerk",
    "kettlebellBottomsUpClean",
    "kettlebellCleanSquatPress",
    "barbellSumoDeadlift",
    "dumbbellSnatch",
    "ringsPullUp",
    "handstandPushUp",
    "barbellOverheadSquat",
    "rowing",
    "skipping",
    "airSquat",
    "assaultBike",
    "ringDips",
    "skippingDoubleUnders",
    "muscleUp",
    "barbellSumoAltGripDeadlift",
  ],
  R8: [
    "machineFly",
    "barbellSeatedShoulderPress",
    "smithMachineBehindBackShrug",
    "dumbbellVSit",
    "bodyweightVSit",
    "kettlebellGoodMorning",
    "kettlebellRow",
    "dumbbellZottmanCurl",
    "landmineSplitSquat",
    "dumbbellSideRaiseToFrontRaise",
    "pilatesSwimmers",
    "dumbbellRenegadeRow",
    "smithMachineCalfRaise",
    "smithMachineShrug",
    "ballSlam",
    "smithMachineDeadlift",
    "smithMachineDeadliftAltGrip",
    "smithMachineOverheadPressSeated",
    "tBarRow",
    "spinning",
    "stairClimber",
    "skiErg",
    "elliptical",
    "runningOutdoors",
    "runningTreadmill",
  ],
  R9: [
    "seatedBackExtension",
    "legCurlLying",
    "legCurlSitting",
    "seatedChestPressMachine",
    "machineHackSquat",
    "backExtension",
    "vSquatMachine",
    "spidermanPushUp",
    "smithMachineFrontSquat",
    "seatedOverheadPressMachineRegularGrip",
    "seatedOverheadPressMachineHammerGrip",
    "machineStandingCalfRaise",
    "calfRaiseMachineSeated",
    "toesToBar",
    "dumbbellForearmCurl",
    "barbellForearmCurl",
    "machinePreacherCurlHammerGrip",
    "machinePreacherCurlRegularGrip",
    "barbellStepUp",
    "cablePullThrough",
  ],
};

export const exStageMapping = {
  jumpRope: {
    0: 1,
    1: 2,
    2: 0,
    [-1]: 0,
    [-2]: 0,
  },
};

export const defaultStages = {
  0: 1,
  1: 2,
  2: 3,
  3: 0,
  [-1]: 0,
  [-2]: 0,
};

export const KEY_TO_LABEL = {
  selectedExercises: "Exercise",
  exercise: "Exercise",
  batch: "Exercise",
  BATCH: "Batch",
  selectedBatch: "Batch",
  isUnsure: "Unsure",
  status: "Status",
  IMPORT_ERROR: "Import Error",
  IMPORT_DATE: "Import Date",
  KFOLD_FLAG: "KFold Flag",
  'feedback__status': "Status",
  'feedback__username': "Feedback User",
  'user_name': "Callibration User",
  'callibration_doc_id': "Callibration ID",
  'fileStatus': "File Status",
  'labelStatus': "Label Status",
  'updatedBy': "Updated By",
  'importBatch': "Import Batch",

};

export const exNameMap = {'cableTricepExtensionSingleArmRope': 'Single Arm Tricep Pushdown / Extension',
'singleArmRopeTricepExtension': 'Single Arm Tricep Pushdown / Extension',
'cableTricepKickback': 'Single Arm Cable Tricep Kickback',
'singleArmCableTricepKickback': 'Single Arm Cable Tricep Kickback',
'cableTricepExtensionOverheadSingleArmHigh': 'Single Arm Overhead Tricep Extension High',
'singleArmOverheadTricepExtHigh': 'Single Arm Overhead Tricep Extension High',
'cableTricepExtensionOverheadSingleArmLow': 'Single Arm Overhead Tricep Extension Low',
'singleArmOverheadTricepExtLow': 'Single Arm Overhead Tricep Extension Low',
'barbellLandminePressDoubleArm': 'Double Arm Landmine Press',
'barbellDoubleArmLandminePress': 'Double Arm Landmine Press',
'machineAbCoaster': 'Ab Coaster',
'abCoaster': 'Ab Coaster',
'machineAbCrunch': 'Ab Crunch Machine',
'abCrunchMachine': 'Ab Crunch Machine',
'bodyweightAbRollout': 'Ab Rollout',
'abRollOut': 'Ab Rollout',
'dumbbellArnoldPress': 'Arnold Press',
'assaultBike': 'Assault Bike',
'machineAssistedChinUps': 'Assisted Chin Up',
'assistedChinUps': 'Assisted Chin Up',
'machineAssistedDips': 'Assisted Dip',
'assistedDips': 'Assisted Dip',
'machineAssistedPullUps': 'Assisted Pull Up',
'assistedPullUps': 'Assisted Pull Up',
'backExtensionTraditional': 'Back Extension (HyperExtension)',
'backExtension': 'Back Extension (HyperExtension)',
'barbellBackSquat': 'Back Squat',
'ballSlam': 'Ball Slams',
'resistanceBandBicepCurlTraditional': 'Resistance Band Bicep Curl',
'resistanceBandBicepCurl': 'Resistance Band Bicep Curl',
'resistanceBandLateralWalk': 'Resistance Band Lateral Walk',
'resistanceBandPullApart': 'Band Pull Apart',
'resistanceBandSquatTraditional': 'Resistance Band Squat',
'resistanceBandSquat': 'Resistance Band Squat',
'resistanceBandBenchPressTraditional': 'Resistance Band Bench Press',
'resistanceBandBenchPress': 'Resistance Band Bench Press',
'resistanceBandBentOverRow': 'Resistance Band Bent Over Row',
'resistanceBandCalfRaise': 'Resistance Band Calf Raise ',
'resistanceBandDeadliftTraditional': 'Resistance Band Deadlift',
'resistanceBandDeadlift': 'Resistance Band Deadlift',
'resistanceBandDeadliftSingleLeg': 'Resistance Band Deadlift (Singe Leg) ',
'resistanceBandSingleLegDeadlift': 'Resistance Band Deadlift (Singe Leg) ',
'resistanceBandFrontSquat': 'Resistance Band Front Squat ',
'resistanceBandLatRaise': 'Resistance Band Lat Raise',
'resistanceBandRomanianDeadliftSingleLeg': 'Resistance Band Romanian Deadlift (Single Leg) ',
'resistanceBandSingleLegRomanianDeadlift': 'Resistance Band Romanian Deadlift (Single Leg) ',
'resistanceBandShoulderPress': 'Resistance Band Shoulder Press',
'resistanceBandTricepExtension': 'Resistance Band Tricep Pushdown ',
'barbellBenchPressTraditional': 'Barbell Bench Press',
'barbellBenchPress': 'Barbell Bench Press',
'barbellBicepCurlTraditional': 'Barbell Bicep Curl',
'barbellBicepCurl': 'Barbell Bicep Curl',
'barbellDeadliftTraditional': 'Barbell Deadlift',
'barbellDeadlift': 'Barbell Deadlift',
'barbellForearmCurl': 'Barbell Forearm Curl',
'barbellLungeTraditional': 'Barbell Lunge',
'barbellLunge': 'Barbell Lunge',
'barbellBentoverRowTraditional': 'Bent Over Barbell Row',
'barbellBentoverRow': 'Bent Over Barbell Row',
'barbellShrug': 'Barbell Shrug',
'barbellStepUp': 'Barbell Step Up',
'tBarRowTraditional': 'Barbell T-Bar Row',
'tBarRow': 'Barbell T-Bar Row',
'barbellSumoSquat': 'Sumo Squat',
'battleRopes': 'Battle Ropes',
'barbellCalfRaiseStanding': 'Barbell Standing Calf Raise',
'barbellStandingCalfRaise': 'Barbell Standing Calf Raise',
'barbellPreacherCurl': 'Barbell Preacher Curl',
'barbellSkullcrusher': 'Barbell SkullCrusher',
'barbellUprightRow': 'Barbell Upright Row',
'barbellSquatPress': 'Barbell Squat Press',
'benchDips': 'Bench Dips',
'dumbbellRowInclineBench': 'Dumbbell Incline Bench Row',
'benchSupportedDumbbellRow': 'Bench Supported Dumbbell Row',
'cableRearDeltFlyBentover': 'Bent Over Cable Rear Delt Fly',
'cableBentoverRearDeltFly': 'Bent Over Cable Rear Delt Fly',
'dumbbellBicepCurlTraditional': 'Bicep Curl',
'dumbbellBicepCurl': 'Bicep Curl',
'bodyweightCrunchBicycle': 'Bicycle Crunch',
'bicycleCrunch': 'Bicycle Crunch',
'bodyweightBirdDog': 'Bird Dog',
'birdDog': 'Bird Dog',
'bodyweightCalfRaise': 'Bodyweight Calf Raise',
'bodyweightGluteBridge': 'Bodyweight Glute Bridge',
'bodyweightSquat': 'Bodyweight Squat',
'boxJump': 'Box Jump',
'barbellBulgarianSplit': 'Barbell Bulgarian Split Squat',
'bodyweightBurpee': 'Burpee',
'burpee': 'Burpee',
'bodyweightLateralLunge': 'Bodyweight Lateral Lunge',
'cableBicepCurlBar': 'Cable Bicep Curl (Bar)',
'cableBicepCurl': 'Cable Bicep Curl (Bar)',
'cableChestPress': 'Cable Chest Press',
'cableChopLowToHigh': 'Cable Chop (Low to High)',
'cableChop': 'Cable Chop',
'cableChopHighToLow': 'Cable Chop (High to Low)',
'cableCrunchTraditional': 'Cable Crunch',
'cableCrunch': 'Cable Crunch',
'cableFacePullRope': 'Cable Face Pull',
'cableFacePull': 'Cable Face Pull',
'cableCrossoverHigh': 'Cable Fly (Crossover) High',
'cableCrossoverLow': 'Cable Fly (Crossover) Low',
'cableCrossoverMid': 'Cable Fly (Crossover) Mid',
'cableFrontRaiseSingleArm': 'Cable Front Raise Single Arm',
'cablePullThrough': 'Cable Pull Through',
'cableRopeUprightRow': 'Cable Rope Upright Row',
'cableUprightRow': 'Cable Upright Row',
'cableFrontRaiseDoubleArm': 'Cable Front Raise Double Arm',
'cableLatRaiseSingleArm': 'Cable Single Arm Lateral Raise',
'cableSingleArmLateralRaise': 'Cable Single Arm Lateral Raise',
'cableSeatedRowWideHammerGrip': 'Cable Seated Row (Wide Hammer Grip)',
'cableSeatedRussianTwist': 'Seated Cable Russian Twist',
'cableBicepCurlSuperman': 'Cable Superman Bicep Curl',
'cableSupermanBicepCurl': 'Cable Superman Bicep Curl',
'cableOverheadTriExtensionBarHigh': 'Overhead Tricep Extension High (Bar)',
'cableOverheadTriExtensionOutBar': 'Overhead Tricep Extension High (Bar)',
'cableOverheadTriExtensionRopeHigh': 'Overhead Tricep Extension High (Rope)',
'cableRopeOverheadTriExtensionHigh': 'Overhead Tricep Extension High (Rope)',
'cableOverheadTriExtensionVBarHigh': 'Overhead Tricep Extension High (V Bar)',
'cableOverheadTriExtensionOutVBar': 'Overhead Tricep Extension High (V Bar)',
'cableOverheadTriExtensionBarLow': 'Overhead Tricep Extension Low (Bar)',
'cableOverheadTriExtensionUpBar': 'Overhead Tricep Extension Low (Bar)',
'cableOverheadTriExtensionRopeLow': 'Overhead Tricep Extension Low (Rope)',
'cableRopeOverheadTriExtensionLow': 'Overhead Tricep Extension Low (Rope)',
'cableOverheadTriExtensionVBarLow': 'Overhead Tricep Extension Low (V Bar)',
'cableOverheadTriExtensionUpVBar': 'Overhead Tricep Extension Low (V Bar)',
'cableExternalShoulderRotation': 'Cable External Shoulder Rotation',
'cableInternalShoulderRotation': 'Cable Internal Shoulder Rotation',
'cableRussianTwistLeft': 'Standing Cable Russian Twist (Left)',
'cableRussianTwistRight': 'Standing Cable Russian Twist (Right)',
'machineChestPressSeatedHammer': 'Seated Chest Press Machine (Hammer Grip)',
'machineChestPressSeatedTraditional': 'Seated Chest Press Machine',
'seatedChestPressMachine': 'Seated Chest Press Machine',
'bodyweightChinUps': 'Chin Up',
'chinUp': 'Chin Up',
'barbellCleanTraditional': 'Clean (Barbell)',
'barbellClean': 'Clean (Barbell)',
'barbellCleanAndJerk': 'Clean and Jerk',
'barbellCleanJerk': 'Clean and Jerk',
'barbellCleanAndPress': 'Clean and Press',
'barbellCleanPress': 'Clean and Press',
'barbellBenchPressCloseGrip': 'Close-Grip Bench Press',
'barbellCloseGripBenchPress': 'Close-Grip Bench Press',
'dumbbellConcentrationCurl': 'Concentration Curl',
'cableCrossoverLatRaise': 'Cross Cable Lat Raise',
'bodyweightCrunch': 'Crunch',
'crunch': 'Crunch',
'dumbbellBenchPressTraditional': 'Dumbbell Bench Press',
'dumbbellBenchPress': 'Dumbbell Bench Press',
'dumbbellBulgarianSplitSquat': 'Dumbbell Bulgarian Split Squat',
'dumbbellFloorPress': 'Dumbbell Floor Press',
'dumbbellHipThruster': 'Dumbbell Hip Thruster',
'dumbbellFrontRaiseInclineBench': 'Dumbbell Incline Bench Front Raise',
'dumbbellInclineBenchFrontRaise': 'Dumbbell Incline Bench Front Raise',
'dumbbellReverseFlyInclineBench': 'Dumbbell Incline Bench Reverse Fly',
'dumbbellInclineBenchReverseFly': 'Dumbbell Incline Bench Reverse Fly',
'dumbbellYsInclineBench': 'Dumbbell Incline Bench Y Raise',
'dumbbellInclineBenchY': 'Dumbbell Incline Bench Y Raise',
'dumbbellBenchPressIncline': 'Dumbbell Incline Bench Press',
'dumbbellInclineBenchPress': 'Dumbbell Incline Bench Press',
'dumbbellCloseGripInclineHammerBenchPress': 'Dumbbell Incline Bench Press Close Grip',
'dumbbellCloseGripHammerInclineBenchPress': 'Dumbbell Incline Bench Press Close Grip',
'dumbbellInclineBenchRow': 'Dumbbell Incline Bench Row',
'dumbbellFlyInclineBench': 'Dumbbell Incline Fly',
'dumbbellInclineFly': 'Dumbbell Incline Fly',
'dumbbellLateralLunge': 'Dumbbell Lateral Lunge',
'dumbbellOverheadSquat': 'Dumbbell Overhead Squat',
'dumbbellPreacherCurl': 'Dumbbell Preacher Curl',
'dumbbellRearDeltFlyTraditional': 'Dumbbell Rear Delt (Reverse) Fly',
'dumbbellReverseFly': 'Dumbbell Rear Delt (Reverse) Fly',
'dumbbellRearDeltRow': 'Dumbbell Rear Delt Row',
'dumbbellOverheadPress': 'Dumbbell Shoulder Press',
'dumbbellSingleArmShoulderPress': 'Dumbbell Single Arm Shoulder Press',
'dumbbellSkullCrusher': 'Dumbbell Skull Crusher',
'dumbbellSplitSquat': 'Dumbbell Split Squat',
'dumbbellOverheadTricepExtensionSingleArm': 'Dumbbell Single Arm Overhead Tricep Extension',
'dumbbellTricepPress': 'Dumbbell Overhead Tricep Extension',
'dumbbellUprightRow': 'Dumbbell Upright Row',
'dumbbellVSit': 'Dumbbell V Sit',
'bodyweightDeadBug': 'Dead Bug',
'deadBug': 'Dead Bug',
'barbellBenchPressDecline': 'Decline Barbell Bench Press',
'barbellDeclineBenchPress': 'Decline Barbell Bench Press',
'dumbbellBenchPressDecline': 'Decline Dumbbell Bench Press',
'dumbbellDeclineBenchPress': 'Decline Dumbbell Bench Press',
'bodyweightPushUpDecline': 'Decline Push Up',
'declinePushUp': 'Decline Push Up',
'bodyweightPushUpDiamond': 'Diamond Push Up',
'diamondPushUp': 'Diamond Push Up',
'bodyweightDips': 'Dips',
'dips': 'Dips',
'bodyweightDonkeyKicks': 'Donkey Kicks',
'donkeyKicks': 'Donkey Kicks',
'cableLatPulldownDBar': 'Lat Pull Down Double Handles',
'doubleCableLatPulldown': 'Lat Pull Down Double Handles',
'dumbbellCalfRaise': 'Dumbbell Calf Raise',
'dumbbellCleanTraditional': 'Dumbbell Clean',
'dumbbellClean': 'Dumbbell Clean',
'dumbbellDeadliftTraditional': 'Dumbbell Deadlift',
'dumbbellDeadlift': 'Dumbbell Deadlift',
'dumbbellFly': 'Dumbbell Fly',
'dumbbellForearmCurl': 'Dumbbell Forearm Curl',
'dumbbellLungeTraditional': 'Dumbbell Lunge',
'dumbbellLunge': 'Dumbbell Lunge',
'dumbbellPullover': 'Dumbbell Pullover',
'dumbbellRowTraditional': 'Dumbbell Row',
'dumbbellRow': 'Dumbbell Row',
'dumbbellShrugStanding': 'Dumbbell Shrug',
'dumbbellShrug': 'Dumbbell Shrug',
'dumbbellSnatch': 'Dumbbell Snatch',
'dumbbellStepUp': 'Dumbbell Step Up',
'machineElliptical': 'Elliptical',
'ezBarBenchPress': 'EZ Bar Bench Press',
'eZBarBenchPress': 'EZ Bar Bench Press',
'ezBarCloseGripBenchPress': 'EZ Bar Close Grip Bench Press',
'eZBarCloseGripBenchPress': 'EZ Bar Close Grip Bench Press',
'ezBarUprightRow': 'EZ Bar Upright Row',
'eZBarUprightRow': 'EZ Bar Upright Row',
'ezBarBicepCurlTraditional': 'EZ-Bar Bicep Curl',
'ezBarBicepCurl': 'EZ-Bar Bicep Curl',
'ezBarPreacherCurl': 'EZ-Bar Preacher Curl',
'ezBarBicepCurlReverse': 'Reverse Grip EZ-Bar Bicep Curls',
'eZBarReverseCurl': 'Reverse Grip EZ-Bar Bicep Curls',
'ezBarSkullCrusher': 'EZ-Bar Skullcrusher',
'dumbbellFarmersCarry': 'Farmers Walk (Weighted)',
'dumbbellFarmersWalk': 'Farmers Walk (Weighted)',
'bodyweightFlutterKick': 'Flutter Kick',
'flutterKick': 'Flutter Kick',
'dumbbellFrontRaiseStanding': 'Front Raise',
'dumbbellFrontRaise': 'Front Raise',
'barbellFrontSquatTraditional': 'Front Squat',
'barbellFrontSquat': 'Front Squat',
'bodyweightGluteBridgeSingleLeg': 'Single Leg Glute Bridge ',
'bodyweightGluteKickback': 'Glute Kickback (Bodyweight) ',
'cableGluteKickBack': 'Glute Kickback (Cable)',
'glutePushDown': 'Glute Push Down (Assisted Pull-up Machine)',
'dumbbellGobletSquat': 'Goblet Squat',
'gobletSquat': 'Goblet Squat',
'barbellGoodMorning': 'Good Morning',
'gorillaRows': 'Gorilla Row',
'machineHackSquatFacingOut': 'Hack Squat (Facing Out)',
'machineHackSquat': 'Hack Squat (Facing Out)',
'dumbbellBicepCurlHammerTraditional': 'Hammer Curl',
'dumbbellHammerCurl': 'Hammer Curl',
'bodyweightPullUpHammer': 'Hammer Grip Pull Up',
'hammerPullUp': 'Hammer Grip Pull Up',
'cableBicepCurlHandles': 'Cable Bicep Curl (Handles)',
'cableHandlesBicepCurl': 'Cable Bicep Curl (Handles)',
'bodyweightHandstand': 'Handstand',
'handStand': 'Handstand',
'bodyweightHandstandPushUp': 'Handstand Push Up',
'handstandPushUp': 'Handstand Push Up',
'barbellHangClean': 'Hang Clean',
'bodyweightHangingKneeRaise': 'Hanging Knee Raise',
'bodyweightHangingLegRaise': 'Hanging Leg Raise',
'hangingLegRaise': 'Hanging Leg Raise',
'bodyweightHighKnees': 'High Knees',
'highKnees': 'High Knees',
'machineHipAbduction': 'Machine Hip Abduction',
'machineHipAdduction': 'Machine Hip Adduction',
'barbellHipThrusters': 'Hip Thruster (Barbell Glute Bridge)',
'bodyweightInAndOut': 'In And Out',
'inOut': 'In And Out',
'barbellBenchPressIncline': 'Barbell Incline Bench Press',
'barbellInclineBenchPress': 'Barbell Incline Bench Press',
'dumbbellInclineBicepCurl': 'Incline Dumbbell Bicep Curl',
'dumbbellInclineHammerCurl': 'Incline Dumbbell Hammer Curl',
'bodyweightPushUpIncline': 'Incline Push Up',
'inclinePushUp': 'Incline Push Up',
'bodyweightJackknifeSitUp': 'Jackknife Sit Up',
'jackknifeSitUp': 'Jackknife Sit Up',
'jumpRope': 'Jump Rope ',
'bodyweightJumpSquat': 'Bodyweight Jump Squat',
'bodyweightSquatJumps': 'Bodyweight Jump Squat',
'bodyweightJumpingJack': 'Jumping Jacks',
'jumpingJack': 'Jumping Jacks',
'kettlebellRomanianDeadliftDouble': 'Kettlebell Romanian Deadlift',
'kettlebellRomanianDeadlift': 'Kettlebell Romanian Deadlift',
'kettlebellShrugTraditional': 'Kettlebell Shrug',
'kettlebellShrug': 'Kettlebell Shrug',
'kettlebellRomanianDeadliftSingleLeg': 'Kettlebell Single Leg Romanian Deadlift',
'kettlebellSingleLegRomanianDeadlift': 'Kettlebell Single Leg Romanian Deadlift',
'kettlebellSquatTraditional': 'Kettlebell Squat',
'kettlebellSquat': 'Kettlebell Squat',
'kettlebellSwingTraditional': 'Kettlebell Swing',
'kettlebellSwing': 'Kettlebell Swing',
'bodyweightPullUpKipping': 'Kipping Pull Up',
'kippingPullUp': 'Kipping Pull Up',
'captainsChairKneeRaise': "Captain's Chair Knee Raise",
'kettlebellCleanTraditional': 'Kettlebell Clean',
'kettlebellClean': 'Kettlebell Clean',
'kettlebellCleanAndPress': 'Kettlebell Clean Press',
'kettlebellCleanPress': 'Kettlebell Clean Press',
'kettlebellDeadliftDoubleKettlebell': 'Kettlebell Deadlift',
'kettlebellDeadlift': 'Kettlebell Deadlift',
'kettlebellGluteBridge': 'Kettlebell Glute Bridge',
'kettlebellGoodMorning': 'Kettlebell Good Morning',
'kettlebellHalo': 'Kettlebell Halo',
'kettlebellLungeDouble': 'Kettlebell Lunge',
'kettlebellLunge': 'Kettlebell Lunge',
'kettlebellRowTraditional': 'Kettlebell Row',
'kettlebellRow': 'Kettlebell Row',
'kettlebellShoulderPressDoubleBells': 'Kettlebell Shoulder Press',
'kettlebellShoulderPress': 'Kettlebell Shoulder Press',
'kettlebellDeadliftSingleLeg': 'Kettlebell Single Leg Deadlift',
'kettlebellSingleLegDeadlift': 'Kettlebell Single Leg Deadlift',
'kettlebellSnatch': 'Kettlebell Snatch',
'kettlebellSumoSquat': 'Kettlebell Sumo Squat',
'kettlebellThrusterSingle': 'Kettlebell Thruster',
'kettlebellThruster': 'Kettlebell Thruster',
'kettlebellTurkishGetUpTraditional': 'Kettlebell Turkish Get Up',
'kettlebellTurkishGetup': 'Kettlebell Turkish Get Up',
'barbellLandmineDeadlift': 'Landmine Deadlift',
'barbellLandminePressSingleArm': 'Landmine Press',
'barbellLandminePress': 'Landmine Press',
'barbellLandmineRow': 'Landmine Row',
'barbellLandmineSplitSquat': 'Landmine Split Squat',
'barbellLandmineSquat': 'Landmine Squat',
'landmineSquat': 'Landmine Squat',
'landmineSquatPressDoubleArm': 'Landmine Double Arm Squat Press',
'cableLatPulldownTraditional': 'Lat Pull Down Narrow Hammer (V-Grip)',
'latPulldown': 'Lat Pull Down (Wide Bar)',
'vBarPulldown': 'Lat Pull Down Narrow Hammer (V-Grip)',
'dumbbellLateralRaiseTraditional': 'Lat Raise',
'dumbbellLatRaise': 'Lat Raise',
'cableLatPulldownWideHammer': 'Lat Pull Down Wide Hammer',
'latPulldownHammer': 'Lat Pull Down Wide Hammer',
'machineLegCurlSeated': 'Leg / Hamstring Curl Seated',
'singlelegMachineLegCurl': 'Machine Leg Curl (Single Leg) ',
'machineLegCurlLying': 'Leg Curl Machine Prone',
'legCurl': 'Leg / Hamstring Curl Seated',
'machineLegExtensionSeated': 'Leg Extension (Single Leg) ',
'legExtension': 'Leg Extension',
'singleLegMachineLegExtension': 'Leg Extension (Single Leg) ',
'machineLegPressAngled': 'Leg Press (Single Leg)',
'legPress': 'Leg Press',
'machineLegPressMovingChair': 'Leg Press Machine (Moving Chair)',
'singleLegLegPress': 'Leg Press (Single Leg)',
'legPressCalfRaise': 'Leg Press Calf Raise',
'captainsChairLegRaise': "Captain's Chair Leg Raise",
'bodyweightLungeTraditional': 'Bodyweight Lunge',
'bodyweightLunge': 'Bodyweight Lunge',
'bodyweightLegRaiseTraditional': 'Lying Leg Raise',
'legRaise': 'Lying Leg Raise',
'machineGluteKickback': 'Machine Glute Kickback',
'machineHipThrust': 'Machine Hip Thrust (Glute Bridge)',
'machinePreacherCurlHammer': 'Machine Preacher Curl (Hammer Grip)',
'machinePreacherCurlHammerGrip': 'Machine Preacher Curl (Hammer Grip)',
'machineTBarRowTraditional': 'Machine T-Bar Row (Regular)',
'machineTBarRowRegular': 'Machine T-Bar Row (Regular)',
'machineTBarRow45DegreeGrip': 'Machine T-Bar Row (45 Deg)',
'machineTBarRowHammer': 'Machine T-Bar Row (Hammer)',
'machineTBarRowHammerGrip': 'Machine T-Bar Row (Hammer)',
'machineBicepCurlAlternating': 'Machine Alternate Arm Curl',
'machineAlternateArmCurl': 'Machine Alternate Arm Curl',
'machineSeatedBackExtension': 'Machine Seated Back Extension',
'machineChestPressSeatedDeclineTraditional': 'Machine Decline Bench Press',
'machineDeclineBenchPress': 'Machine Decline Bench Press',
'machineFlyPecDeck': 'Machine Fly (Pec Dec)',
'pecDeck': 'Machine Fly (Pec Dec)',
'machineHighRow': 'Machine High Row (MTS Row)',
'machineChestPressInclineTraditional': 'Machine Incline Bench Press',
'machineInclineBenchPress': 'Machine Incline Bench Press',
'machineLateralRaiseTraditional': 'Machine Lateral Raise',
'machineLateralRaise': 'Machine Lateral Raise',
'machinePreacherCurlTraditional': 'Machine Preacher Curl',
'machinePreacherCurl': 'Machine Preacher Curl',
'machineRearDeltFly': 'Machine Rear Delt (Reverse) Fly',
'machineRowTraditional': 'Machine Row',
'machineRow': 'Machine Row',
'machineRowHammer': 'Machine Row (Hammer Grip)',
'machineRowHammerGrip': 'Machine Row (Hammer Grip)',
'machineCalfRaiseSeated': 'Machine Seated Calf Raise',
'seatedCalfRaiseMachine': 'Machine Seated Calf Raise',
'machineShoulderShrug': 'Machine Shrug',
'machineCalfRaiseStanding': 'Machine Standing Calf Raise',
'machineStandingCalfRaise': 'Machine Standing Calf Raise',
'machineTricepExtensionTraditional': 'Machine Tricep Extension',
'machineTricepExtension': 'Machine Tricep Extension',
'machineTricepPressTraditional': 'Machine Tricep Press',
'machineTricepPress': 'Machine Tricep Press',
'barbellMilitaryPressTraditional': 'Overhead Press / Military Press',
'barbellMilitaryPress': 'Overhead Press / Military Press',
'barbellMilitaryPressBehindHead': 'Overhead Press / Military Press (Behind Head)',
'behindHeadMilitaryPress': 'Overhead Press / Military Press (Behind Head)',
'bodyweightMountainClimberTraditional': 'Mountain Climber',
'mountainClimber': 'Mountain Climber',
'barbellMuscleClean': 'Muscle Clean',
'barbellMuscleSnatch': 'Muscle Snatch',
'bodyweightMuscleUp': 'Muscle Up',
'muscleUp': 'Muscle Up',
'nordicHamstringCurl': 'Nordic Hamstring Curl',
'bodyweightObliqueVUps': 'Oblique V-Ups',
'obliqueVUps': 'Oblique V-Ups',
'bodyweightPushUpPike': 'Pike Push Up',
'pikePushUp': 'Pike Push Up',
'bodyweightPistolSquat': 'Pistol Squats',
'bodyweightPistolSquats': 'Pistol Squats',
'bodyweightPlankTraditional': 'Plank',
'plank': 'Plank',
'bodyweightPlankJackHands': 'Plank Jack',
'plankJack': 'Plank Jack',
'bodyweightPlankTaps': 'Plank Tap',
'plankTap': 'Plank Tap',
'bodyweightPlankUpDown': 'Plank Up Down',
'plankUpDown': 'Plank Up Down',
'plateFrontRaise': 'Plate Front Raise',
'barbellPowerClean': 'Power Clean',
'barbellPowerCleanAndPress': 'Power Clean and Press',
'barbellPowerCleanPress': 'Power Clean and Press',
'barbellPowerCleanTruster': 'Power Clean Thruster',
'barbellPowerCleanThruster': 'Power Clean Thruster',
'barbellPowerSnatch': 'Power Snatch',
'powerSnatch': 'Power Snatch',
'bodyweightPullUpTraditional': 'Pull Up',
'pullUp': 'Pull Up',
'bodyweightPushUpOnKnees': 'Push Up on Knees',
'pushUpOnKnees': 'Push Up on Knees',
'bodyweightPushUpTraditional': 'Push Up',
'pushUp': 'Push Up',
'barbellRackPull': 'Rack Pull',
'dumbbellRenegadeRow': 'Dumbbell Renegade Row',
'dumbbellRenegadeRowPushUp': 'Dumbbell Renegade Row W/ Push Up',
'resistanceBandRowTraditional': 'Resistance Band Row',
'resistanceBandRow': 'Resistance Band Row',
'bodyweightReverseCrunch': 'Reverse Crunch',
'reverseCrunch': 'Reverse Crunch',
'bodyweightPlankReverseHands': 'Reverse Plank',
'reversePlank': 'Reverse Plank',
'bodyweightPlankElbows': 'Reverse Plank Elbows',
'reverseElbowPlank': 'Reverse Plank Elbows',
'ringDips': 'Ring Dip',
'ringPullUps': 'Ring Pull Up',
'barbellRomanianDeadlift': 'Romanian Deadlift',
'cableBicepCurlRope': 'Cable Bicep Curl (Rope)',
'cableRopeBicepCurl': 'Cable Bicep Curl (Rope)',
'machineRopePull': 'Rope Pull Machine',
'ergRowingMachine': 'Rowing Machine',
'dumbbellRussianTwist': 'Russian Twist',
'russianTwist': 'Russian Twist',
'cableBicepCurlBarReverseGrip': 'Reverse Grip Cable Curl Bar',
'cableReverseGripBarCurl': 'Reverse Grip Cable Curl Bar',
'barbellBicepCurlReverseGrip': 'Reverse Grip Barbell Curl',
'barbellReverseGripCurl': 'Reverse Grip Barbell Curl',
'barbellBentoverRowReverseGrip': 'Reverse Grip Bent Over Row',
'barbellReverseGripBentoverRow': 'Reverse Grip Bent Over Row',
'dumbbellBicepCurlReverse': 'Reverse Grip Dumbbell Bicep Curl',
'reverseGripDumbbellCurl': 'Reverse Grip Dumbbell Bicep Curl',
'cableLatPulldownReverse': 'Reverse Grip Pull Down',
'reverseGripLatPulldown': 'Reverse Grip Pull Down',
'bodyweightScissorKick': 'Scissor Kick',
'scissorKick': 'Scissor Kick',
'barbellShoulderPressSeated': 'Seated Barbell Shoulder Press',
'seatedBarbellShoulderPress': 'Seated Barbell Shoulder Press',
'cableSeatedRowBarTraditional': 'Seated Cable Row (Bar)',
'cableSeatedRow': 'Seated Cable Row (Bar)',
'cableSeatedRowDBar': 'Seated Cable Row (V Handle)',
'vBarSeatedCableRow': 'Seated Cable Row (V Handle)',
'cableSeatedRowBarWide': 'Seated Cable Row (Wide Grip)',
'cableSeatedRowWide': 'Seated Cable Row (Wide Grip)',
'dumbbellShrugSeated': 'Dumbbell Seated Shrug',
'dumbbellSeatedShrug': 'Dumbbell Seated Shrug',
'dumbbellRowShotgun': 'Shotgun Row (Cable)',
'shotgunRow': 'Shotgun Row (Cable)',
'machineShoulderPressTraditional': 'Shoulder Press Machine (Regular)',
'seatedOverheadPressMachineRegularGrip': 'Shoulder Press Machine (Regular)',
'machineShoulderPressHammer': 'Shoulder Press Machine (Hammer)',
'seatedOverheadPressMachineHammerGrip': 'Shoulder Press Machine (Hammer)',
'plateSideBend': 'Side Bend Weighted',
'sideBend': 'Side Bend Weighted',
'bodyweightPlankSideLeft': 'Side Plank',
'sidePlank': 'Side Plank',
'bodyweightSidePlankLeft': 'Side Plank (Left)',
'bodyweightSidePlankRight': 'Side Plank (Right)',
'dumbbellSideRaiseToFrontRaise': 'Side Raise to Front Raise',
'singleArmLatPulldown': 'Single Arm Lat Pulldown',
'dumbbellRowBentOverSingleArm': 'Bent Over Single Arm Row',
'dumbbellBentOverSingleArmRow': 'Bent Over Single Arm Row',
'bodyweightSingleLegHipThruster': 'Single Leg Hip Thruster',
'bodyweightSitUpTraditional': 'Sit Up',
'sitUp': 'Sit Up',
'sledPush': 'Sled Push',
'smithMachineShrugBehindBack': 'Smith Machine Behind Back Shrug',
'smithMachineBehindBackShrug': 'Smith Machine Behind Back Shrug',
'smithMachineBenchPressTraditional': 'Smith Machine Bench Press',
'smithMachineBenchPress': 'Smith Machine Bench Press',
'smithMachineBentoverRow': 'Smith Machine Bent Over Row',
'smithMachineCalfRaise': 'Smith Machine Calf Raise',
'smithMachineDeadliftTraditional': 'Smith Machine Deadlift',
'smithMachineDeadlift': 'Smith Machine Deadlift',
'smithMachineBenchPressInclineTraditional': 'Smith Machine Incline Bench Press',
'smithMachineInclineBenchPress': 'Smith Machine Incline Bench Press',
'smithMachineShoulderPressStanding': 'Smith Machine Shoulder Press',
'smithMachineShoulderPress': 'Smith Machine Shoulder Press',
'smithMachineShrugTraditional': 'Smith Machine Shrug',
'smithMachineShrug': 'Smith Machine Shrug',
'smithMachineSplitSquat': 'Smith Machine Split Squat',
'smithMachineBackSquat': 'Smith Machine Squat',
'smithMachineSquat': 'Smith Machine Squat',
'smithMachineUprightRow': 'Smith Machine Upright Row',
'smithMachineRomanianDeadlift': 'Smith Machine Romanian Deadlift',
'barbellSnatchTraditional': 'Snatch (Barbell)',
'barbellSnatch': 'Snatch (Barbell)',
'dumbbellBicepCurlSpiderman': 'Spider Curls',
'spiderCurls': 'Spider Curls',
'bodyweightPushUpSpiderman': 'Spiderman Push Up',
'spidermanPushUp': 'Spiderman Push Up',
'stationarySpinning': 'Spinning / Stationary Biking',
'stairClimber': 'Stair Climber',
'cableStraightArmPulldownBar': 'Straight Arm Cable Pull Down (Bar)',
'cableStraightArmPulldown': 'Straight Arm Cable Pull Down (Bar)',
'cableStraightArmPulldownRope': 'Straight Arm Cable Pull Down (Rope)',
'cableRopeStraightArmPulldown': 'Straight Arm Cable Pull Down (Rope)',
'barbellDeadliftSumoTraditional': 'Sumo Deadlift',
'barbellSumoDeadlift': 'Sumo Deadlift',
'plateSvendPress': 'Svend Press (Plate Push)',
'svendPress': 'Svend Press (Plate Push)',
'bodyweightPilatesSwimmers': 'Pilates Swimmer',
'pilatesSwimmers': 'Pilates Swimmer',
'tireFlip': 'Tire Flip',
'bodyweightToeTouches': 'Toe Touches',
'toeTouches': 'Toe Touches',
'bodyweightToesToBar': 'Toes to Bar',
'toesToBar': 'Toes to Bar',
'trapBarDeadlift': 'Trap Bar Deadlift',
'trapBarShrug': 'Trap Bar Shrug',
'cableTricepExtensionBar': 'Tricep Pushdown / Extension (Bar)',
'cableTricepExtension': 'Tricep Pushdown / Extension (Bar)',
'cableTricepExtensionRope': 'Tricep Pushdown / Extension (Rope)',
'cableRopeTricepExtension': 'Tricep Pushdown / Extension (Rope)',
'cableTricepExtensionVbar': 'Tricep Pushdown / Extension (V Bar)',
'cableTricepExtensionVBar': 'Tricep Pushdown / Extension (V Bar)',
'dumbbellTricepKickback': 'Tricep Kickback',
'cableRearDeltFlyUpright': 'Upright Cable Rear Delt Fly',
'cableUprightRearDeltFly': 'Upright Cable Rear Delt Fly',
'bodyweightVSit': 'V-Sit',
'vSit': 'V-Sit',
'dumbbellLungeWalking': 'Dumbbell Walking Lunge',
'dumbbellWalkingLunge': 'Dumbbell Walking Lunge',
'wallBalls': 'Wall Balls',
'bodyweightWallSit': 'Wall Sit',
'wallSit': 'Wall Sit',
'machineRowWideTraditional': 'Machine Row Wide Grip ',
'machineRowWideGrip': 'Machine Row Wide Grip ',
'dumbbellBicepCurlHammerCrossBody': 'Cross Body Hammer Curl',
'dumbbellCrossBodyHammercurl': 'Cross Body Hammer Curl',
'crossCableTricepExtension': 'Cross Cable Tricep Extension',
'bodyweightYSquats': 'Y Squat',
'squatsY': 'Y Squat',
'dumbbellZottmanCurl': 'Zottman Curls',
'zottmanCurl': 'Zottman Curls',
'dumbbellHammerBenchPress': 'Dumbbell Hammer Bench Press',
'dumbbellCloseGripHammerBenchPress': 'Dumbbell Close Grip Hammer Bench Press (Crush Press)',
'dumbbellRomanianDeadlift': 'Dumbbell Romanian Deadlift',
'singleArmCableRow': 'Single Arm Cable Row',
'vSquatMachineFacingOut': 'V Squat Machine',
'vSquatMachineFacingIn': 'V Squat Machine (Facing In / Reverse)',
'pendulumSquatMachine': 'Pendulum Squat Machine',
'bodyweightDeclineSitUp': 'Decline Sit Up',
'smithMachineHipThruster': 'Smith Machine Hip Thruster',
'sledDrag': 'Sled Drag',
'barbellFrontRaise': 'Barbell Front Raise',
'stabilityBallPushUp': 'Stability Ball Push Up',
'barbellAbRollout': 'Barbell Ab Rollout',
'barbellBehindBackShrug': 'Barbell Behind Back Shrug',
'barbellBicepDragCurl': 'Barbell Bicep Drag Curl',
'barbellCurtsyLunge': 'Barbell Curtsy Lunge',
'barbellFloorPress': 'Barbell Floor Press',
'zercherSquat': 'Zercher Squat',
'aroundTheWorlds': 'Around The Worlds',
'elevatedPlank': 'Elevated Plank',
'tatePress': 'Tate Press',
'ezBarOverheadTricepExtension': 'EZ Bar Overhead Tricep Extension',
'deadHang': 'Dead Hang',
'kettlebellStandingCalfRaise': 'Standing Kettlebell Calf Raise',
'resistanceBandPullUp': 'Resistance Band Pull Up',
'resistanceBandChinUp': 'Resistance Band Chin Up',
'resistanceBandHammerPullUp': 'Resistance Band Hammer Grip Pull Up',
'resistanceBandOverheadSquat': 'Resistance Band Overhead Squat',
'resistanceBandBulgarianSplitSquat': 'Resistance Band Bulgarian Split Squat',
'resistanceBandHamstringCurl': 'Resistance Band Hamstring Curl',
'smithMachineSingleLegDeadlift': 'Smith Machine Single Leg Deadlift',
'smithMachineDragCurl': 'Smith Machine Drag Curl',
'barbellPullOver': 'Barbell Pull Over',
'machineHackSquatFacingIn': 'Hack Squat (Facing In)',
'barbellSplitSquat': 'Barbell Split Squat',
'machineLatPulldownWide': 'Machine Lat Pull Down (Wide)',
'kettlebellWindmill': 'Kettlebell Windmill',
'heelTaps': 'Heel Taps',
'cableBentoverRearDeltFlySingleArm': 'Bent Over Cable Rear Delt Fly (Single Arm)',
'cableUprightRearDeltFlySingleArm': 'Upright Cable Rear Delt Fly (Single Arm) ',
'invertedRow': 'Inverted Row',
'hollowRock': 'Hollow Rock',
'dumbbellThruster': 'Dumbbell Thruster',
'lSitPullUp': 'L-Sit Pull Up',
'machineTorsoRotation': 'Torso Rotation Machine'}