import React, { useState } from "react";
import { Drawer, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import "./index.css";
import MenuItems from "./menu";

const NavBar = ({ active, validPath }) => {
  const [visible, setVisible] = useState(false);
  return (
    <nav className="navbar">
      <Button
        className="menu"
        type="primary"
        icon={<MenuOutlined />}
        onClick={() => setVisible(true)}
      />
      <Drawer
        placement="left"
        onClick={() => setVisible(false)}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <div style={{ backgroundColor: "#6d8c98", height: "100%" }}>
          <MenuItems active={active} validPath={validPath}/>
        </div>
      </Drawer>
      {/* <a href="/">
        <img src={logo} className="logo" alt="logo" />
      </a> */}
    </nav>
  );
};

export default NavBar;
