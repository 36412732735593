import React, { useEffect, useState, useCallback } from "react";
import {
  Input,
  Button,
  Card,
  Row,
  Col,
  List,
  Tag,
  message,
  Divider,
  Space,
  Select,
  Spin,
  notification,
} from "antd";
import { ProdWorkoutService } from "../../../services/dataService";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import moment from "moment";
import { FilterOutlined, MoreOutlined, InboxOutlined } from "@ant-design/icons";
// import { fieldToName } from "../../../../constant";
import createPersistedState from "use-persisted-state";
import { debounce } from "lodash";

const WorkoutCard = ({ item, onSelect, isLoading }) => {
  return (
    <div style={{ width: "100%" }}>
      <div>
        <span style={{ color: "gray" }}>
          <em>{item.date} </em>{" "}
        </span>{" "}
        - 🕒 {item.duration} sec | 🏋️ {item.exercises} | 🔥{" "}
        {Math.round(item.calories)}
        <Button loading={isLoading} onClick={onSelect} style={{ float: "right" }} size="small">
          Import
        </Button>
      </div>
      <div>{item.name}</div>
    </div>
  );
};
const SearchImportFile = (props) => {
  const queryClient = useQueryClient();

  const [state, updateState] = useState({ filter: { page: 1 } });
  const [filter, updateFilter] = useState({ page: 1 });

  const debouncedUpdate = useCallback(
    debounce(
      (newValue) =>
        updateState((prevState) => ({
          ...prevState,
          userSearchText: newValue,
        })),
      1000
    ),
    []
  );

  //   useEffect(() => {
  //     props.loadData(filter);
  //   }, [filter]);

  const userList = useQuery(
    ["search_user", state.userSearchText],
    () =>
      ProdWorkoutService.searchUsers({
        search: state.userSearchText,
      }),
    {
      enabled:
        state.userSearchText != undefined &&
        state.userSearchText != null &&
        state.userSearchText != "",
    }
  );

  const workoutList = useQuery(
    ["prod_workout_list_2", state.userId],
    () =>
      ProdWorkoutService.getWorkouts({
        user_id: state.userId,
      }),
    {
      enabled:
        state.userId != undefined && state.userId != null && state.userId != "",
    }
  );

  const importWorkoutFn = async (workoutId) => {
    updateState(prev => ({...prev, uploadWorkoutId: workoutId}))
    return ProdWorkoutService.importWorkout({
      workout_id: workoutId,
    });
  };

  const importWorkout = useMutation(importWorkoutFn, {
    onSuccess: () => {
      queryClient.invalidateQueries("prod_workout_file");
      message.success("Imported Successfully!!");
      updateState({ ...state, csvFileUploadFlag: false });
    },
    onError: (error) => {
      message.error("Import Failed!!");
    },
  });

  return (
    <React.Fragment>
      <div style={{ margin: "1rem 1.5rem" }}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <div style={{ margin: "5px 0px", fontSize: "16px" }}>
            <b>Search User</b>{" "}
          </div>
          <Select
            style={{ width: "100%" }}
            filterOption={false}
            onSearch={debouncedUpdate}
            showSearch
            notFoundContent={userList.isLoading ? <Spin size="small" /> : null}
            // {...props}
            onSelect={(e) =>
              updateState((prevState) => ({ ...prevState, userId: e }))
            }
          >
            {userList.data?.results?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name} - {item.email}{" "}
              </Select.Option>
            ))}
          </Select>
          <Input.Search
            onSearch={(e) =>
              updateState((prevState) => ({ ...prevState, userId: e }))
            }
          ></Input.Search>
          <div style={{ margin: "5px 0px", fontSize: "16px" }}>
            <b>Workouts</b>{" "}
          </div>
          <div>
            {workoutList.isLoading ? (
              <div style={{ margin: "10px auto" }}>
                <Spin></Spin>
              </div>
            ) : (
              <Space
                direction="vertical"
                split={
                  <Divider type="horizontal" style={{ margin: "5px 0px" }} />
                }
                style={{ width: "100%" }}
              >
                {workoutList.data?.results?.map((item) => (
                  <WorkoutCard
                    item={item}
                    onSelect={() => importWorkout.mutate(item.id)}
                    isLoading={state.uploadWorkoutId == item.id && importWorkout.isLoading}
                  ></WorkoutCard>
                ))}
              </Space>
            )}
          </div>
        </Space>
      </div>
    </React.Fragment>
  );
};

export default SearchImportFile;
