import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { MiscServices } from "../../../services/dataService";
import { Select } from "antd";

interface ExerciseSelectProps {
  value: string | string[] | undefined;
  onChange: (value: string | string[] | null) => void | undefined;
  selectRef?: any;
  allowClear?: boolean;
  mode?: undefined | "tags";
}
const ExerciseSelect = ({ value, onChange, selectRef,
  allowClear = false,
  mode = undefined,

 }: ExerciseSelectProps) => {
  const [exercise, setExercise] = React.useState(value ?? "");

  useEffect(() => {

    if (exercise && onChange && exercise !== value) {
      onChange(exercise);
    }
    if (exercise === "" && exercise!=value && onChange) {
      onChange(null);
    }
  }, [exercise]);

  const exerciseMeta = useQuery(
    ["exerciseMeta"],
    () => MiscServices.getMasterExercises(),
    {
      staleTime: 1000 * 60 * 60 * 24,
    }
  );
  return (
    <Select
      placeholder="Select Exercise"
      style={{
        width: "300px",
      }}
      allowClear={allowClear}
      showSearch={true}
      value={value}
      ref={selectRef}
      // onSelect={(value) => {
      //   setExercise(value);
      // }}
      onChange={(value) => {
        setExercise(value);
      }
      }
      onClear={() => {
        setExercise("");
        if (onChange) {
          onChange(null);
        }
      }
      }
      filterOption={(input, option) => (option?.children  as any).toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      mode={mode}
    >
      {exerciseMeta.data?.map((exercise: any) => (
        <Select.Option key={exercise.key} value={exercise.key} >
          {exercise.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ExerciseSelect;
