import React, { useEffect } from "react";
import * as d3 from "d3";
import { exercises, grouppings } from "../workoutFileAnalysis/constant";

type SetPlotProps = {
  userId: string;
  startTimestamp: number;
  endTimestamp: number;
  v1ExScores: [string, number][][];
  v2ExScores: [string, number][][];
};
const SetPlot = (props: SetPlotProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    // TODO: Get data from backend
    plotStackedExScoreBarChart();
  }, [
    props.userId,
    props.startTimestamp,
    props.endTimestamp,
    props.v1ExScores,
    props.v2ExScores,
  ]);


  const onMouseOver = (d: any) => {
    // console.log("mouse over");
        let tip = d3.select("body").selectAll(".tooltip") as d3.Selection<
        HTMLDivElement,
        unknown,
        null,
        undefined
        >;
        // if (tip.empty()) {
        //   tip = d3
        //     .select("body")
        //     .append("div")
        //     .attr("class", "tooltip")
        //     .style("opacity", 0) ;
        // }
        // Show tooltip
        // d3.select(tooltipRef.current)
          tip
            .style("opacity", 1)
            .style("left", d3.event.pageX + 10 + "px")
            .style("top", d3.event.pageY + 10 + "px")
            .style("background-color", "white")
            .style("border", "solid")
            .style("border-width", "1px")
            .style("border-radius", "5px")
            .style("padding", "10px")
            .style("pointer-events", "none")
            .html(
              `
            <div> Top Exercises </div>
            <div> ${Object.keys(d.data).map(
                (key) => `<div> ${key}: ${Math.round(d.data[key] * 100)/100} </div>`
                )} 
            </div>
            </div>
            `
            );
      };
  

  const onMouseMove = (d: any) => {
    // console.log("mouse move");
    // d3.select(tooltipRef.current)
    let tip = d3.select("body").selectAll(".tooltip") as d3.Selection<
    HTMLDivElement,
    unknown,
    null,
    undefined
    >;
    tip
      .style("left", d3.event.pageX + 10 + "px")
      .style("top", d3.event.pageY + 10 + "px");
    
  }

  const onMouseLeave = (d: any) => {
    // console.log("mouse leave");
    // d3.select(tooltipRef.current)
    let tip = d3.select("body").selectAll(".tooltip") as d3.Selection<
    HTMLDivElement,
    unknown,
    null,
    undefined
    >;
    tip.style("opacity", 0);
  }



  const plotStackedExScoreBarChart = () => {
    // set the dimensions and margins of the graph
    const margin = { top: 10, right: 30, bottom: 20, left: 50 },
      width = props.v1ExScores.length * 86 - margin.left - margin.right,
      height = 200 - margin.top - margin.bottom;

    let v1ExScores = props.v1ExScores;
    let v2ExScores = props.v2ExScores;
    let scores = props.v1ExScores.map((d) => {
      let exMap: any = {};
      d.forEach((ex) => {
        exMap[ex[0]] = ex[1];
      });
      return exMap;
    });
    const stackedExScoresV1 = d3
      .stack()
      .keys(exercises)
      .value((d, key) => {
        return d[key];
      })(scores);

    scores = props.v2ExScores.map((d) => {
      let exMap: any = {};
      d.forEach((ex) => {
        exMap[ex[0]] = ex[1];
      });
      return exMap;
    });
    const stackedExScoresV2 = d3
      .stack()
      .keys(exercises)
      .value((d, key) => {
        return d[key];
      })(scores);

    d3.select(ref.current).selectAll("*").remove();
    // windowExPred is a list of dict with scores for exercise, plot stacked bar chart

    let svg = d3.select(ref.current).append("svg").attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom + 50)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // let svgElem = svg.select("g");

    // if (v1ExScores.length === 0 || v2ExScores.length === 0) {
    //   return;
    // }

    // if (v1ExScores[0].length === 0 || v2ExScores[0].length === 0) {
    //   return;
    // }
    // Add X axis
    let x = d3
      .scaleLinear()
      .domain(
        d3.extent(
          v1ExScores.map((d, index) => {
            return index;
          })
        ) as any
      )
      .range([0, width - margin.left - margin.right - 100]);

    // Plot x Axis with marker every 2 seconds 
    svg
      .append("g")
      .attr("transform", `translate(${16},${height})`)
      .call(
        d3
          .axisBottom(x)
          .tickValues(
            x.ticks().filter((d) => {
              return !(d % 2);
            })
          )
          .tickFormat((d: any) => {
            return `${d * 2}s`;
          })
      );
    let y = d3
      .scaleLinear()
      .domain([0, 1])
      .range([height  - margin.top - margin.bottom, 0]);

    let exerciseColors = d3
      .scaleOrdinal()
      .domain(exercises)
      .range(
        exercises.map((exercise, idx) => {
          return d3.interpolateRainbow(idx / exercises.length);
        })
      );

    //   svgElem.attr("width", width).attr("height", height / 2);

    // Plot stacked bar chart with rounded corners

    svg
      .selectAll(".bar1")
      .data(stackedExScoresV1)
      .enter()
      .append("g")
      .attr("fill", (d) => d.key == "noise" ? "#e5e4e4": exerciseColors(d.key as any) as string)
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("x", (d, index) => x(index))
      .attr("y", (d) => y(d[1]) + 16)
      .attr("height", (d) => y(d[0]) - y(d[1]))
      .attr("width", 16)
      .attr("rx", 4)
      .attr("ry", 4)
      .on("mouseover", onMouseOver)
      .on("mousemove", onMouseMove)
      .on("mouseleave", onMouseLeave);;

    console.log(stackedExScoresV2[0], stackedExScoresV2[1]);
    // Create a side by side bar chart for v2
    svg
      .selectAll(".bar2")
      .data(stackedExScoresV2)
      .enter()
      .append("g")
      .attr("class", "v2")
      .attr("fill", (d) => d.key == "noise" ? "#e5e4e4": exerciseColors(d.key as any) as string)
      // .attr("fill", "red")
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("x", (d, index) => x(index) + 20)
      .attr("y", (d) => y(d[1]) + 16)
      .attr("height", (d) => y(d[0]) - y(d[1]))
      .attr("width", 16)
      .attr("rx", 4)
      .attr("ry", 4)
      .attr("stroke", "black")
      .attr("stroke-width", 1)
      .attr("stroke-opacity", 0.5)
        .on("mouseover", onMouseOver)
        .on("mousemove", onMouseMove)
        .on("mouseleave", onMouseLeave);
      ;
  };

  return (
    <div>
      <div ref={ref}></div>
    </div>
  );
};

export default SetPlot;
