import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { exercises } from "../workoutFileAnalysis/constant";
import { Col, Row, Typography } from "antd";

const LogView = ({ log }) => {
  return (
    <Row style={{ width: "100%" }}>
      <div>
        <Typography.Text type="secondary">
          {log?.start_timestamp} - {log?.end_timestamp}
        </Typography.Text>
      </div>
      <>
        {log?.children?.map((log, index) => {
          return (
            <Row
              key={index}
              style={{
                border: "1px solid #eee",
                padding: "1rem",
                width: "100%",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  width: "100%",
                }}
              >
                {log.start_timestamp}
              </div>

              <Typography.Text key={index}>
                {log.message.split("\n").map((i, key) => {
                  return <div key={key}>{i}</div>;
                })}
              </Typography.Text>
            </Row>
          );
        })}
      </>
    </Row>
  );
};

const WorkoutGraph = ({
  allSets,
  logs,
  detectingSets,
  workoutStartTime,
  workoutEndTime,
}) => {
  const ref = useRef(null);
  let simSets = allSets
    .filter((set) => !set.missing)
    .map((set) => ({
      id: set.id,
      exercise: set.exercise,
      startTime: set.setBoatStartTime,
      endTime: set.setBoatEndTime,
      measurementValue: set.measurementValue,
    }));
  let loggedSets = allSets
    .filter((set) => set.frontendSetId)
    .map((set) => ({
      id: set.frontendSetId,
      exercise: set.metadata?.ai_set?.exercise,
      measurementValue: set.measurementValue,
      isManual: !set.metadata?.ai_set?.start_time,
      startTime:
        set.metadata?.ai_set?.start_time ||
        set.metadata?.ai_set?.creation_date - 30,
      endTime:
        set.metadata?.ai_set?.end_time || set.metadata?.ai_set?.creation_date,
    }));

  // Group logs by 10 second interval
  let logsByInterval = {};
  logs.forEach((log) => {
    let interval = Math.floor(log.start_timestamp / 30);
    if (logsByInterval[interval]) {
      logsByInterval[interval].push(log);
    } else {
      logsByInterval[interval] = [log];
    }
  });
  logs = Object.values(logsByInterval).map((logs) => {
    let start_timestamp = logs[0].start_timestamp;
    let end_timestamp = logs[logs.length - 1].start_timestamp;
    return {
      start_timestamp,
      end_timestamp,
      children: logs,
    };
  });

  const [state, updateState] = useState({});

  const onClick = (d) => {
    let tip = d3.select("body").selectAll(".tooltip");
    if (tip.empty()) {
      tip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);
    }
    // Change opacity of the selected log
    d3.selectAll(".log-rect").attr("opacity", (d) =>
      d.start_timestamp == state.selectedLog?.start_timestamp ? 1 : 0.4
    );

    d3.select("#d" + Math.round(d.start_timestamp).toString()).attr(
      "opacity",
      1
    );
    updateState((prevState) => ({
      ...prevState,
      selectedLog: d,
    }));
  };

  const mouseover = (d) => {
    let tip = d3.select("body").selectAll(".tooltip");
    if (tip.empty()) {
      tip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);
    }
    console.log(d3.event.pageX, d3.event.pageY);
    // Show tooltip
    // d3.select(tooltipRef.current)
    if (d.currentExercise) {
      tip
        .style("opacity", 1)
        .style("left", d3.event.pageX + 10 + "px")
        .style("top", d3.event.pageY + 10 + "px")
        .style("background-color", "white")
        .style("border", "solid")
        .style("border-width", "1px")
        .style("border-radius", "5px")
        .style("padding", "10px")
        .style("pointer-events", "none")
        .html(
          `
        <div> Detection State </div>
        <div>Start Time: ${d.startTime}</div>
        <div>End Time: ${d.endTime}</div>
        <div>Exercise: ${d.currentExercise}</div>
        </div>
        `
        );
    } else {
      tip
        .style("opacity", 1)
        .style("left", d3.event.pageX + 10 + "px")
        .style("top", d3.event.pageY + 10 + "px")
        .style("background-color", "white")
        .style("border", "solid")
        .style("border-width", "1px")
        .style("border-radius", "5px")
        .style("padding", "10px")
        .style("pointer-events", "none")
        .html(
          `
        <div>
        <div>Start Time: ${d.startTime}</div>
        <div>End Time: ${d.endTime}</div>
        <div>Exercise: ${d.exercise}</div>
        <div>Reps: ${d.measurementValue}</div>
        </div>
        `
        );
    }
  };

  const mousemove = (d) => {
    let tip = d3.select("body").selectAll(".tooltip");
    if (tip.empty()) {
      tip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);
    }
    // d3.select(tooltipRef.current)
    tip
      .style("left", d3.event.pageX + 10 + "px")
      .style("top", d3.event.pageY + 10 + "px");
  };

  const mouseleave = (event, d) => {
    let tip = d3.select("body").selectAll(".tooltip");
    if (tip.empty()) {
      tip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);
    }

    // Hide tooltip (set opacity to 0)
    tip.style("opacity", 0);
  };

  useEffect(() => {
    // Ploting a horizontal bar chart to represent timeline of all sets,
    // there are two types of sets, simulated sets and logged sets and for each
    // there will be a separate position on the y-axis

    let exerciseColors = d3
      .scaleOrdinal()
      .domain(exercises)
      .range(d3.schemeCategory10);

    d3.select(ref.current).selectAll("*").remove();
    // Total duration of the workout
    // let startTime = Math.min(
    //   d3.min(simSets, (d) => d.startTime),
    //   d3.min(loggedSets, (d) => d.startTime)
    // );
    // let endTime = Math.max(
    //   d3.max(simSets, (d) => d.endTime),
    //   d3.max(loggedSets, (d) => d.endTime)
    // );
    const startTime = workoutStartTime;
    const endTime = workoutEndTime;
    const totalDuration =
      Math.max(
        d3.max(simSets, (d) => d.endTime),
        d3.max(loggedSets, (d) => d.endTime)
      ) -
      Math.min(
        d3.min(simSets, (d) => d.startTime),
        d3.min(loggedSets, (d) => d.startTime)
      );

    const margin = { top: 10, right: 30, bottom: 30, left: 100 },
      width = totalDuration * 2 - margin.left - margin.right,
      height = 400 - margin.top - margin.bottom;

    const svg = d3
      .select(ref.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // X-axis
    const x = d3.scaleLinear().domain([startTime, endTime]).range([0, width]);
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x));

    // Create a scale for Y-axis with logs at a top with only 10% of the height
    const y = d3
      .scaleOrdinal()
      .domain(["Logs", "Simulated Sets", "Logged Sets"])
      .range([0, height * 0.1, height * 0.55]);

    // Adding y axis, add ticks in the middle of the bars, the middle whould be compute according to y axis
    // let ticks = d3.axisLeft(y)
    const y_ = d3
      .scaleOrdinal()
      .domain(["", "Logs", "Simulated Sets", "Logged Sets", ""])
      .range([0, 0.05 * height, 0.3 * height, 0.85 * height, height]);

    const yAxis = d3.axisLeft(y_).tickSize(0).tickPadding(6);
    svg.append("g").attr("class", "y axis").call(yAxis);

    // Add detecting states undeneath the simulated sets
    svg
      .selectAll("myRect")
      .data(detectingSets)
      .enter()
      .append("rect")
      .attr("x", (d) => x(d.startTime))
      .attr("y", y("Simulated Sets") + 5)
      .attr("width", (d) => x(d.endTime) - x(d.startTime))
      //   .attr("height", y.bandwidth())
      .attr("height", y("Logged Sets") - y("Simulated Sets") - 0)
      .style("fill", (d) => exerciseColors(d.currentExercise))
      .style("stroke", "black")
      .style("stroke-dasharray", "3, 3")
      .style("opacity", 0.4)
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseleave", mouseleave);

    // Adding simulated sets
    svg
      .selectAll("myRect")
      .data(simSets)
      .enter()
      .append("rect")
      .attr("x", (d) => x(d.startTime))
      .attr("y", y("Simulated Sets") + 10)
      .attr("width", (d) => x(d.endTime) - x(d.startTime))
      //   .attr("height", y.bandwidth())
      .attr("height", y("Logged Sets") - y("Simulated Sets") - 10)
      .style("fill", (d) => exerciseColors(d.exercise))
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseleave", mouseleave);

    // Adding logged sets if manual set opacity to 0.5 and stoke to dotted black
    svg
      .selectAll("myRect")
      .data(loggedSets)
      .enter()
      .append("rect")
      .attr("x", (d) => x(d.startTime))
      .attr("y", y("Logged Sets") + 10)
      .attr("width", (d) => x(d.endTime) - x(d.startTime))
      .attr("height", y("Logged Sets") - y("Simulated Sets") - 20)
      .style("fill", (d) => exerciseColors(d.exercise))
      .style("stroke", (d) => (d.isManual ? "black" : "none"))
      .style("stroke-dasharray", (d) => (d.isManual ? "3, 3" : "none"))
      .style("opacity", (d) => (!d.isManual ? 1 : 0.4))
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseleave", mouseleave);

    // Place a square dot every 2 seconds for logs
    svg
      .selectAll("myRect")
      .data(logs)
      .enter()
      .append("rect")
      .attr("class", "log-rect")
      .attr("id", (d) => "d" + Math.round(d.start_timestamp).toString())
      .attr("x", (d) => x(d.start_timestamp + 15))
      .attr("y", y("Logs") + 10)
      .attr(
        "width",
        (d) => x(d.start_timestamp + 30) - x(d.start_timestamp + 15)
      )
      .attr("opacity", (d) =>
        d.start_timestamp == state.selectedLog?.start_timestamp ? 1 : 0.4
      )
      //   .attr("height", y.bandwidth())
      .attr("height", y("Simulated Sets") - y("Logs") - 20)
      .attr("rx", 2)
      .style("fill", "#0016f996")
      .on("click", onClick);
  }, [allSets]);

  return (
    <>
      <Row>
        <Col
          span={24}
          style={{
            overflowY: "auto",
          }}
        >
          <div
            style={{
              height: "400px",
            }}
          >
            <div ref={ref} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Typography.Text strong>Logs:</Typography.Text>
          <div
            style={{
              maxHeight: "70vh",
              overflow: "auto",
              width: "100%",
            }}
          >
            {state.selectedLog ? <LogView log={state.selectedLog} /> : null}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default WorkoutGraph;
