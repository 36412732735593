import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Card,
  Row,
  Col,
  List,
  PageHeader,
  Popover,
  Space,
  Drawer,
  Upload,
  notification,
  Empty,
  Select,
  Tabs,
} from "antd";
import {
  ProdWorkoutService,
  LabelledCSVService,
  CustomService,
} from "../../services/dataService";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import moment from "moment";
import ReactJson from "react-json-view";
import {
  FilterOutlined,
  MoreOutlined,
  InboxOutlined,
  SettingOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
// import { fieldToName } from "../../../../constant";
// import ImportFileComponent from "./components"
import createPersistedState from "use-persisted-state";
import WorkoutChart from "../workoutFileAnalysis/d3Chart";
import ExRepChart from "../workoutFileAnalysis/exRepsChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useCallback } from "react";
import { TrainLoadingIcon } from "../../components/icons";
import SetDetailView from "./setDetailView";
import WorkoutReplayView from "./replayWorkout";
import { SetCard } from "./SetCard";
import SimWorkoutView from "./simWorkoutView";

// const useWorkoutListViewState = createPersistedState("workoutListViewState");

const usemodelState = createPersistedState("modelConfigPred");

const MixpanelEventCard = ({ event }) => {
  const [open, setOpen] = useState(false);
  return (
    <Card
      style={{
        width: "100%",
        margin: "0.5rem 1rem",
      }}
    >
      <div>
        <Row justify="space-between">
          <Col>
            <>{event.event}</>
            <div>
              {moment(event.properties?.time * 1000)
                .local()
                .toLocaleString()}
            </div>
          </Col>

          <Col>
            <Button
              type="link"
              onClick={() => setOpen((prevState) => !prevState)}
            >
              {open ? <UpOutlined /> : <DownOutlined />}
            </Button>
          </Col>
        </Row>
      </div>
      <div
        style={
          open ? { height: "300px", overflowY: "auto" } : { display: "none" }
        }
      >
        {open ? <ReactJson src={event.properties} /> : null}
      </div>
    </Card>
  );
};

const MixpanelEventView = ({ events }) => {
  const [state, updateState] = useState({ filter: null });

  const filteredEvents = events.filter((event) => {
    if (state.filter == null) {
      return true;
    }
    return event.event == state.filter;
  });

  let uniqueOptions = [...new Set(events.map((event) => event.event))];
  console.log("Unique Options", uniqueOptions);

  return (
    <div>
      <div>
        <Select
          style={{ width: "100%" }}
          placeholder="Filter Events"
          value={state.filter}
          onChange={(value) =>
            updateState((prevState) => ({ ...prevState, filter: value }))
          }
        >
          {uniqueOptions.map((option) => (
            <Select.Option value={option}>{option}</Select.Option>
          ))}
        </Select>
      </div>
      <div>
        <Row
          gutter={[12, 12]}
          style={{
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          {filteredEvents.map((event) => (
            <MixpanelEventCard event={event} />
          ))}
        </Row>
      </div>
    </div>
  );
};

const ProdWorkoutFileList = (props) => {
  const [state, updateState] = useState({ filter: { page: 1 } });
  const [modelState, updateModelState] = usemodelState({
    repCountingModel: null,
    exDetectionModel: null,
  });
  const workoutFile = useQuery(
    ["prod_workout_file", props.match.params.id],
    () => CustomService.detailWorkout({ workout_id: props.match.params.id }),
    { staleTime: Infinity }
  );

  const models = useQuery(
    ["prod_workout_file_models"],
    () => ProdWorkoutService.getModels(),
    {
      staleTime: Infinity,
      onSuccess: (data) => {
        updateModelState((prevState) => ({
          ...prevState,
          repCountingModel:
            modelState.repCountingModel || data.rep_counting[0]?.id,
          exDetectionModel:
            modelState.exDetectionModel || data.ex_detection[0]?.id,
        }));
      },
    }
  );

  const routes = [
    {
      path: "/prod_files",
      breadcrumbName: "Workout Files",
    },

    {
      path: props.match.params.id,
      breadcrumbName: `${moment(workoutFile.data?.start_time * 1000)
        .local()
        .toLocaleString()} `,
    },
  ];

  function itemRender(route, params, routes, paths) {
    // debugger
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={`/${paths.join("/")}`}>{route.breadcrumbName}</Link>
    );
  }

  const updateSelectedEx = useCallback((selectedEx) => {
    updateState((prevState) => ({ ...prevState, selectedEx }));
  }, []);

  const toggleReplayWorkout = () => {
    updateState((prevState) => ({
      ...prevState,
      replayWorkout: !prevState.replayWorkout,
    }));

  }
  let manualSets = workoutFile.data?.sets.filter((set) =>
    set.start_time ? false : true
  );
  return (
    <React.Fragment>
      {workoutFile.isLoading ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TrainLoadingIcon />
        </div>
      ) : null}
      {!workoutFile.isLoading ? (
        <div className="page-content">
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title={`${moment(workoutFile.data.start_time * 1000)
              .local()
              .toLocaleString()} `}
            extra={[
              <Button 
              onClick={toggleReplayWorkout}>Replay Workout</Button>,
              <Popover
                placement="bottomRight"
                trigger={"click"}
                content={
                  <div style={{ width: "300px" }}>
                    <div style={{ margin: "0.75rem" }}>
                      <div>Ex-Detection Models</div>
                      <Select
                        value={modelState.exDetectionModel}
                        style={{ width: "100%" }}
                        onSelect={(value) =>
                          updateModelState((prev) => ({
                            ...prev,
                            exDetectionModel: value,
                          }))
                        }
                      >
                        {models.data?.ex_detection.map((model) => {
                          return (
                            <Select.Option value={model.id}>
                              {model.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </div>
                    <div style={{ margin: "0.75rem" }}>
                      <div>Rep-Counting Models</div>
                      <Select
                        value={modelState.repCountingModel}
                        style={{ width: "100%" }}
                        onSelect={(value) =>
                          updateModelState((prev) => ({
                            ...prev,
                            repCountingModel: value,
                          }))
                        }
                      >
                        {models.data?.rep_counting.map((model) => {
                          return (
                            <Select.Option value={model.id}>
                              {model.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                }
              >
                <Button
                  type="primary"
                  icon={<SettingOutlined />}
                  onClick={() => workoutFile.refetch()}
                >
                  Config
                </Button>
              </Popover>,
            ]}
            breadcrumb={{ routes, itemRender }}
          ></PageHeader>

          <WorkoutReplayView 
          visible={state.replayWorkout}
          onClose={toggleReplayWorkout}
          workout={workoutFile.data} />

          <Tabs
            defaultActiveKey="1"
            tabBarExtraContent={{
              right: (
                <div>
                  Manual Sets: {manualSets.length}/
                  {workoutFile.data.sets?.length}
                </div>
              ),
            }}
          >
            <Tabs.TabPane tab="Sets" key="1">
              <Row>
                <Col
                  span={6}
                  style={{
                    maxHeight: "80vh",
                    overflowY: "auto",
                  }}
                >
                  <Row gutter={[12, 12]}>
                    {workoutFile.data.sets?.map((set) => (
                      <SetCard
                        set={set}
                        key={set.id}
                        selected={state.selectedSet?.id == set.id}
                        onClick={() => {
                          updateState((prevState) => ({
                            ...prevState,
                            selectedSet: set,
                          }));
                        }}
                      />
                    ))}
                  </Row>
                </Col>
                <Col span={18}>
                  <SetDetailView set={state.selectedSet} />
                </Col>
              </Row>
            </Tabs.TabPane>

            <Tabs.TabPane tab="Mixpanel Events" key="2">
              <MixpanelEventView events={workoutFile.data.mixpanel_events} />
            </Tabs.TabPane>
            
          </Tabs>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default ProdWorkoutFileList;
