/** MultilineChart.js */
import React from "react";
import * as d3 from "d3";
// import allData from "./data";
import "./index.css";
import { Drawer, Row } from "antd";
import ExRepChart from "./exRepsChart";
import { exercises, grouppings } from "./constant";
const defaultDimentions = {
  width: 2500,
  height: 200,
  margin: { left: 10, top: 10, right: 10, bottom: 40 },
};

function makeCurlyBrace(x1, y1, x2, y2, w, q) {
  //Calculate unit vector
  var dx = x1 - x2;
  var dy = y1 - y2;
  var len = Math.sqrt(dx * dx + dy * dy);
  dx = dx / len;
  dy = dy / len;

  //Calculate Control Points of path,
  var qx1 = x1 + q * w * dy;
  var qy1 = y1 - q * w * dx;
  var qx2 = x1 - 0.25 * len * dx + (1 - q) * w * dy;
  var qy2 = y1 - 0.25 * len * dy - (1 - q) * w * dx;
  var tx1 = x1 - 0.5 * len * dx + w * dy;
  var ty1 = y1 - 0.5 * len * dy - w * dx;
  var qx3 = x2 + q * w * dy;
  var qy3 = y2 - q * w * dx;
  var qx4 = x1 - 0.75 * len * dx + (1 - q) * w * dy;
  var qy4 = y1 - 0.75 * len * dy - (1 - q) * w * dx;

  return (
    "M " +
    x1 +
    " " +
    y1 +
    " Q " +
    qx1 +
    " " +
    qy1 +
    " " +
    qx2 +
    " " +
    qy2 +
    " T " +
    tx1 +
    " " +
    ty1 +
    " M " +
    x2 +
    " " +
    y2 +
    " Q " +
    qx3 +
    " " +
    qy3 +
    " " +
    qx4 +
    " " +
    qy4 +
    " T " +
    tx1 +
    " " +
    ty1
  );
}

const MultilineChart = (props) => {
  const allData = props.data;
  const svgRef = React.useRef(null);
  const divRef = React.useRef(null);
  const exerciseTooltipRef = React.useRef(null);

  // to detect what line to animate we should store previous data state
  const { width, height, margin = {} } = defaultDimentions;
  const svgWidth = width + margin.left + margin.right;
  const svgHeight = height + margin.top + margin.bottom;

  const setExerciseTooltip = (exerciseData) => {
    console.log("HERE");
    var height = 200;
    var width = 500;
    var exerciseTooltip = d3
      .select(exerciseTooltipRef.current)
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "10px");
    exerciseTooltip
      .select("div")
      .html(
        "<b>Top 5 Pred: </b>" +
          "<br>" +
          Object.keys(exerciseData.top5)
            .map(
              (key) =>
                `<div>${key}:  ${
                  Math.round(exerciseData.top5[key] * 1000) / 1000
                }</div>`
            )
            .join(" ")
      )
      .style("opacity", 1);
    // var svg = exerciseTooltip
    //   .select("svg")
    //   .attr("width", "500px")
    //   .attr("height", "500px")
    //   .append("g")
    //   .attr("transform", "translate(" + 10 + "," + 10 + ")");

    // console.log(exerciseData);
    // const yMax = d3.max(exerciseData.other_info, (d) => d3.max(d.values)) + 0.1;
    // const xMax =
    //   exerciseData.other_info.length > 0
    //     ? exerciseData.other_info[0].values.length
    //     : 0 + 10;
    // // var ex2color = d3.scaleOrdinal().domain(exercises).range(d3.range(0, exercises.length).map(i => d3.interpolateRainbow(i/exercises.length)));
    // var ex2color = d3.scaleOrdinal().range(d3.schemeTableau10);

    // var x = d3.scaleLinear().domain([0, xMax]).range([0, width]);
    // var y = d3.scaleLinear().domain([0, yMax]).range([height, 0]);
    // const xAxis = d3
    //   .axisBottom(x)
    //   .ticks(width / 80)
    //   .tickSizeOuter(0);
    // const yAxis = d3.axisLeft(y).ticks(height / 60);

    // svg.append("g").call(yAxis);
    // svg
    //   .append("g")
    //   .attr("transform", "translate(0," + height + ")")
    //   .call(xAxis);
    // // Draw the line
    // svg
    //   .selectAll(".line")
    //   .data(exerciseData.other_info)
    //   .enter()
    //   .append("path")
    //   .attr("fill", "none")
    //   .attr("stroke", (d) => ex2color(d.exercise))
    //   .attr("stroke-width", 1.5)
    //   .attr("d", function (d) {
    //     return d3
    //       .line()
    //       .x(function (d, idx) {
    //         if (!x(idx)) {
    //           console.log(x(idx));
    //         }
    //         return x(idx);
    //       })
    //       .y(function (d) {
    //         if (!y(d)) {
    //           console.log(y(d));
    //         }
    //         return y(d);
    //       })(d.values);
    //   });
    // exerciseTooltip.style("opacity", 1);
  };

  React.useEffect(() => {
    console.log("RENDER");
    // Parse the Data
    var detectedExData = allData.detected_exercises;
    var data = [];
    var groups = [];
    var subgroups = [];
    var count = 0;
    for (var item of detectedExData) {
      //   if (count > 100) {
      //     break;
      //   }
      Object.keys(item.top_3_ex).map((key) => {
        if (!subgroups.includes(key)) {
          subgroups.push(key);
        }
      });
      data.push({
        ...item.top_3_ex,
        top_3_ex: item.top_3_ex,
        final_ex: item.final_ex,
        time: count,
      });
      groups.push(count);
      count += 1;
    }
    // set the dimensions and margins of the graph
    var margin = { top: 10, right: 30, bottom: 30, left: 50 },
      width = detectedExData.length * 20 - margin.left - margin.right,
      height = 200 - 1.5* margin.top - 1.5* margin.bottom;

    d3.select(svgRef.current).selectAll("*").remove();

    // append the svg object to the body of the page
    var svg = d3
      .select(svgRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", 2 * (height + margin.top + margin.bottom )  + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    var exTooltip = d3.select(exerciseTooltipRef.current);
    var tooltip = d3
      .select(divRef.current)
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "10px");

    // Add X axis
    var x = d3.scaleBand().domain(groups).range([0, width]).padding([0.25]);
    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x).tickSizeOuter(0))
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-65)");

    // Add Y axis
    var y = d3.scaleLinear().domain([0, 1]).range([height, 0]);
    // svg.append("g").call(d3.axisLeft(y));

    // color palette = one color per subgroup
    // console.log( d3.schemeSpectral)
    // console.log("SCHEME",grouppings, grouppings.length)
    // console.log(d3.range(0, 100))
    // var color2Num = d3.scaleOrdinal().domain(grouppings).range(d3.range(0, grouppings.length));
    // var num2color = d3.scaleSequential((val) => d3.interpolateSpectral(val/ grouppings.length))
    // var ex2color = d3.scaleOrdinal().domain(grouppings).range(d3.range(0, grouppings.length+2).map(i => d3.interpolateWarm(i/(exercises.length +1))));
    var ex2color = d3.scaleOrdinal().range(d3.schemeTableau10);
    //stack the data? --> stack per subgroup
    var stackedData = d3.stack().keys(subgroups)(data);

    // ----------------
    // Highlight a specific subgroup when hovered
    // ----------------

    // What happens when user hover a bar
    var mouseover = function (d) {
      console.log(d);
      if (d.exercise) {
        // tooltip
        // .html(
        //   "<b>Top 5 Pred: </b>"  + "<br>" + Object.keys(d.top5).map(key => `<div>${key}:  ${Math.round(d.top5[key]*1000)/1000}</div>`).join(" ")
        // )
        // .style("opacity", 1);
        setExerciseTooltip(d);
      } else {
        // what subgroup are we hovering?
        var subgroupName = d3.select(this.parentNode).datum().key; // This was the tricky part
        var subgroupValue = d.data[subgroupName];
        // Reduce opacity of all rect to 0.2
        // d3.selectAll(".myRect").style("opacity", 0.2);
        //   // Highlight all rects of this subgroup with opacity 0.8. It is possible to select them since they have a specific class = their name.
        // d3.selectAll("." + subgroupName).style("opacity", 1);
        tooltip
          .html(
            "<div class='tooltip-header'>Top Ex: </div>" +
              Object.keys(d.data.top_3_ex)
                .map(
                  (key) =>
                    "<div><em><b>" +
                    key +
                    "</b></em>: " +
                    d.data[key] +
                    "</div>"
                )
                .join("\n") +
              "<br>" +
              "<div class='tooltip-header'>Final Ex: </div>" +
              subgroupName +
              "<br>"
          )
          .style("opacity", 1);
      }
    };

    // When user do not hover anymore
    var mouseleave = function (d) {
      var currentTooltip = null;
      // var subgroupName = d3.select(this.parentNode).datum().key; // This was the tricky part
      // var subgroupValue = d.data[subgroupName];
      // d3.selectAll("." + subgroupName).style("opacity", 0.5);

      if (d.exercise) {
        currentTooltip = exTooltip;
      } else {
        currentTooltip = tooltip;
      }
      currentTooltip.style("opacity", 0);
      currentTooltip
        .style("left", -1000 + "px") // It is important to put the +90: other wise the tooltip is exactly where the point is an it creates a weird effect
        .style("top", -1000 + "px");
    };

    var mousemove = function (d) {
      var currentTooltip = null;
      if (d.exercise) {
        currentTooltip = exTooltip;
      } else {
        currentTooltip = tooltip;
      }

      currentTooltip
        .style("left", d3.event.pageX + 10 + "px") // It is important to put the +90: other wise the tooltip is exactly where the point is an it creates a weird effect
        .style("top", d3.event.pageY + "px");
    };

    // console.log("stackedData", stackedData)
    // Show the bars
    svg
      .append("g")
      .selectAll("g")
      // Enter in the stack data = loop key per key = group per group
      .data(stackedData)
      .enter()
      .append("g")
      // .style("opacity", 0.5)
      .attr("fill", function (d) {
        return ex2color(d.key);
      })
      .attr("class", function (d) {
        return "myRect " + d.key;
      }) // Add a class to each subgroup: their name
      .selectAll("rect")
      // enter a second time = loop subgroup per subgroup to add all rectangles
      .data(function (d) {
        return d;
      })
      .enter()
      .append("rect")
      .attr("opacity", (d) => {
        // console.log(d);
        return d.data.final_ex == "noise" ? 0.25 : 0.8;
      })
      .attr("x", function (d) {
        return x(d.data.time);
      })
      .attr("y", function (d) {
        return y(d[1]);
      })
      .attr("height", function (d) {
        if (d[1]) {
          return y(d[0]) - y(d[1]);
        } else {
          return 0;
        }
      })
      .attr("rx", 2)
      .attr("ry", 2)
      .attr("width", x.bandwidth())
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseleave", mouseleave);

    // Add X axis --> it is a date format
    var seqLabelsData = allData.seq_labels;
    var startHeight = height + margin.top + margin.bottom;
    var endHeight = startHeight + height / 2;
    var x = d3
      .scaleLinear()
      .domain([0, detectedExData.length * 100])
      .range([0, width]);

    // Add Y axis
    var y = d3
      .scaleLinear()
      .domain([0, d3.max(seqLabelsData)])
      .range([endHeight, startHeight]);
    // svg.append("g")
    //     .call(d3.axisLeft(y));

    // Add the line
    svg
      .append("path")
      .datum(seqLabelsData)
      .attr("fill", "none")
      .attr("stroke", "#4056e5d8")
      .attr("stroke-width", 1.5)
      .attr(
        "d",
        d3
          .line()
          .x(function (d, idx) {
            return x(idx);
          })
          .y(function (d) {
            return y(d);
          })
      );

    // Label Reps Count and exercises
    // Add the path using this helper function
    var startHeight =
      height + (height + margin.top + margin.bottom) + margin.bottom;

    var repsData = allData.sets;
    var xMax = detectedExData.length * 100;
    var x = d3.scaleLinear().domain([0, xMax]).range([0, width]);
    svg
      .append("g")
      .selectAll("path")
      .data(repsData)
      .enter()
      .append("path")
      .attr("d", function (d) {
        return makeCurlyBrace(
          x(d.start_time),
          startHeight,
          x(d.end_time),
          startHeight,
          10,
          0.6
        );
      })
      .attr("fill", function (d) {
        return "none";
      })
      .attr("stroke", "#4056e5d8")
      .attr("stroke-width", "2px")
      .attr("class", function (d, idx) {
        return "label " + idx;
      });
    svg
      .append("text")
      .selectAll("tspan")
      .data(repsData)
      .enter()
      .append("tspan")
      .attr("x", function (d) {
        return x((d.start_time + d.end_time) / 2);
      })
      .attr("y", startHeight + 20)
      .attr("text-anchor", "middle")
      .attr("stroke", "#4056e5d8")
      .attr("font-size", 10)
      .text(function (d) {
        return d.exercise + ` (${Math.round(d.reps * 10) / 10})`;
      })
      .on("click", (d) => {
        props.updateSelectedEx({ a: 1 });
      })
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseleave", mouseleave);

    var heartRateData = allData.heartRateData || [];
    var startHeight = 2 * (height + margin.top + margin.bottom);
    var endHeight = startHeight + height / 2;

    var x = d3
      .scaleLinear()
      .domain([0, heartRateData.length])
      .range([0, width]);
    // svg.append("g")
    //     .attr("transform", "translate(0," + endHeight + ")")
    //     .call(d3.axisBottom(x));

    // Add Y axis
    // var y = d3
    //   .scaleLinear()
    //   .domain([60, d3.max(heartRateData)])
    //   .range([endHeight, startHeight]);

    // svg.append("g").call(d3.axisLeft(y));

    // Add the line
    // svg
    //   .append("path")
    //   .datum(heartRateData)
    //   .attr("fill", "none")
    //   .attr("stroke", "red")
    //   .attr("stroke-width", 2.5)
    //   .attr(
    //     "d",
    //     d3
    //       .line()
    //       .x(function (d, idx) {
    //         return x(idx);
    //       })
    //       .y(function (d) {
    //         return y(d);
    //       })
    //   );

    // Add X axis --> it is a date format
    var detectionLabelsData = allData.detection_labels;
    var startHeight = height + height / 2 + margin.top + margin.bottom;
    var endHeight = startHeight + height - 2 *margin.bottom ;
    var x = d3
      .scaleLinear()
      .domain([0,  detectedExData.length *5  ])
      .range([0, width]);

    // Add Y axis
    var y = d3
      .scaleLinear()
      .domain([0, 1])
      .range([endHeight, startHeight]);
    // svg.append("g")
    //     .call(d3.axisLeft(y));

    // Add the line
    svg
      .append("path")
      .datum(detectionLabelsData)
      .attr("fill", "none")
      .attr("stroke", "#ff0000d6")
      .attr("stroke-width", 1.5)
      .attr(
        "d",
        d3
          .line()
          .x(function (d, idx) {
            return x(idx );
          })
          .y(function (d) {
            return y(d);
          })
      );
    
    svg
      .append("path")
      .datum(detectionLabelsData)
      .attr("fill", "none")
      .attr("stroke", "#ff0000d6")
      .style("stroke-dasharray", ("3, 3"))
      .attr("stroke-width", 1)
      .attr(
        "d",
        d3
          .line()
          .x(function (d, idx) {
            return x(idx );
          })
          .y(function (d) {
            return y(0.5);
          })
      );
  }, [allData]);

  console.log("Rerender....1");
  return (
    <div style={{ margin: "1rem 0rem" }}>
      <div ref={divRef}></div>
      <div ref={exerciseTooltipRef} className="tooltip">
        <div></div>
        {/* <svg></svg> */}
      </div>

      <div
        ref={svgRef}
        width={svgWidth}
        height={svgHeight}
        style={{ overflowX: "scroll", width: "80vw" }}
      />
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
}
export default React.memo(MultilineChart);
