import React, { useEffect, useState, useRef } from "react";
import { Tag, Drawer, Select, Form, Steps, Space, Divider } from "antd";
import {
  WorkoutFileService,
  ConstantItemService,
} from "../../services/dataService";
import { useQuery, useMutation, useQueryClient } from "react-query";
import moment from "moment";
import {
    ArrowRightOutlined,
  } from "@ant-design/icons";

const KEY_TO_LABEL = {
  status: "Status",
  unsure: "Is Unsure",
  is_verified: "Ex Verified",
  exercise: "Exercise",
  RIGHT_HAND: "Right Hand",
  is_rep_count_verified: "Rep Count Verified",
};

const ChangeCard = ({ item }) => {
  const changes = item.changes;
  console.log("CHANGE", item);
  const changeKeys = Object.keys(changes);
  return (
    <div style={{ width: "100%" }}>
    <Space style={{ width: "100%" }}             direction="horizontal"
            split={<Divider type="vertical" style={{ height: "100%", margin: "5px 0px" }} />}
>
    <i style={{ color: "gray" }}>{moment(item.updated_at).fromNow()}</i>
    <i style={{ color: "#1890ff" }}>{item.user}</i>
    </Space>
      {changeKeys.map((key) => (
        <div style={{ margin: "5px 0px" }}>
          <b>{KEY_TO_LABEL[key]}</b> :{" "}
          <span style={{fontFamily: "monospace"}}>{changes[key]["old"].toString()}</span> {<ArrowRightOutlined style={{margin: "0px 10px"}}/>}
          <span style={{fontFamily: "monospace"}}>{changes[key]["new"].toString()}</span>
        </div>
      ))}
    </div>
  );
};
const ChangeHistory = (props) => {
  //   debugger;
  const ref = useRef(null);

  useEffect(() => {
    console.log("ref:", ref && ref.current);
    if (ref && ref.current) {
      ref.current.focus();
    }
  }, []);

  return (
    <React.Fragment>
      <Drawer
        width={600}
        title={"Changes History"}
        visible={props.visible}
        onClose={props.onClose ? props.onClose : () => {}}
        destroyOnClose={true}
      >
        <div style={{ margin: "10px 20px" }}>
          <Space
            direction="vertical"
            style={{ width: "100%" }}
            split={<Divider style={{ width: "100%", margin: "5px 0px" }} />}
          >
            {props.changeHistory
              ? props.changeHistory.map((item, id) => (
                  <ChangeCard item={item} key={id} />
                ))
              : null}
          </Space>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default ChangeHistory;
