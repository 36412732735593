import React, { Component } from "react";
import {
  Route,
  Router,
  Switch,
  Redirect,
  BrowserRouter,
} from "react-router-dom";
import Auth from "./services/auth";
import LoginSignupPage from "./pages/signInUp";
import SiderNav from "./components/sider";
// import Buckets from "./pages/buckets";
import Dashboard from "./pages/dashboard";
import AnnotationView from "./pages/annotate";
import AnnotationDetailView from "./pages/annotateDetail";
import ChaartPage from "./pages/workoutFileAnalysis";
import { QueryClient, QueryClientProvider } from "react-query";
import history from "./history";
import "./App.css";
import { Layout, notification } from "antd";
import Navbar from "./components/sider/navbar";
import Sidebar from "./components/sider/sidebar";
import ProdWorkoutFileList from "./pages/prodWorkoutFileList";
import ProdWorkoutDetail from "./pages/prodWorkoutFileDetails";
import AllUserData from "./pages/dataCollection";
import UserDataDetail from "./pages/dataCollectionDetail";
import { UserContext } from "./contexts";
import AdminPage from "./pages/admin";
import DashboardV2 from "./pages/v2/dashboard";
import { ExerciseVerificationList, VerifyDetail } from "./pages/v2/exVerify";
import AdminPanel from "./pages/v2/admin";
import AllCalibrations from "./pages/v2/allCalibrations";
import CalibrationsForLabel from "./pages/v2/label";
import LabelDetail from "./pages/v2/labelDetail";
import CalibrationDetail from "./pages/v2/calibrationDetail";
import { KfoldExerciseVerificationList, KfoldVerifyDetail } from "./pages/v2/kfoldVerify";

// import InclExcl from "./pages/inclExcl";
// import RegressionDetail from "./pages/regressionDetail";
// import { getToken, onMessageListener } from "./services/firebase";

const { Header, Footer, Sider, Content } = Layout;
const queryClient = new QueryClient();

const ProtectedViews = (props) => {
  // const [isTokenFound, setTokenFound] = useState(false);
  // const [notification, setNotification] = useState({ title: "", body: "" });

  // getToken(setTokenFound);

  // onMessageListener()
  //   .then((payload) => {
  //     setShow(true);
  //     notification[success]({
  //       message: payload.notification.title,
  //       description: payload.notification.body,
  //     });
  //     console.log(payload);
  //   })
  //   .catch((err) => console.log("failed: ", err));

  var { location, validPath } = props;
  return (
    <Layout>
      <Navbar
        validPath={validPath}
        active={location.pathname.split("/")[1]}
      ></Navbar>
      <Sidebar
        validPath={validPath}
        active={location.pathname.split("/")[1]}
      ></Sidebar>
      <Layout>
        <Switch>
          {/* <Route exact path="/buckets" render={() => <Buckets />} /> */}
          {/* <Route
            exact
            path="/v2/dashboard/"
            render={({ match }) => <DashboardV2 match={match} />}
          /> */}
          <Route
            exact
            path="/dashboard/"
            render={({ match }) => <Dashboard match={match} />}
          />

          <Route
            exact
            path="/v2/calibrations/"
            render={({ match }) => <AllCalibrations match={match} />}
          />
          <Route
            exact
            path="/v2/calibrations/:id/"
            render={({ match }) => <UserDataDetail match={match} />}
          />

          <Route
            exact
            path="/prod_files/"
            render={({ match }) => <ProdWorkoutFileList match={match} />}
          />
          <Route
            exact
            path="/prod_files/:id/"
            render={({ match }) => <ProdWorkoutDetail match={match} />}
          />

          <Route
            exact
            path="/label/"
            render={({ match }) => <AnnotationView match={match} />}
          />
          <Route
            exact
            path="/label/:id/"
            render={({ match }) => (
              <AnnotationDetailView history={history} match={match} />
            )}
          />
          <Route
            exact
            path="/admin/"
            render={({ match }) => <AdminPage match={match} />}
          />
          <Route
            exact
            path="/chartpage/"
            render={({ match }) => <ChaartPage match={match} />}
          />

          {/* <Route
            exact
            path="/developers/"
            render={({ match }) => <Developer match={match} />}
          />
          <Route
            exact
            path="/developers/:id/"
            render={({ match }) => <DeveloperDetail match={match} />}
          />
          <Route
            exact
            path="/regression/"
            render={({ match }) => <RegressionCard match={match} />}
          />
          <Route
            exact
            path="/regression/:id/"
            render={({ match }) => <RegresssionDetail match={match} />}
          />
          <Route
            exact
            path="/incl_excl/"
            render={({ match }) => <InclExcl match={match} />}
          /> */}
        </Switch>
      </Layout>
    </Layout>
  );
};

const ProtectedViewsV2 = (props) => {
  var { location, validPath } = props;
  return (
    <Layout>
      <Navbar
        validPath={validPath}
        active={location.pathname.split("/")[1]}
      ></Navbar>
      <Sidebar
        validPath={validPath}
        active={location.pathname.split("/")[2]}
      ></Sidebar>
      <Layout>
        <Switch>
          <Route exact path="/dashboard" render={() => <Dashboard />} />
          <Route
            exact
            path="/v2/dashboard/"
            render={({ match }) => <DashboardV2 match={match} />}
          />
          <Route
            exact
            path="/label/:id"
            render={({ match }) => <AnnotationDetailView match={match} />}
          />
          <Route
            exact
            path="/v2/verify/"
            render={({ match }) => <ExerciseVerificationList match={match} />}
          />
          <Route
            exact
            path="/v2/verify/:id/"
            render={({ match }) => <VerifyDetail match={match} />}
          />
          <Route
            exact
            path="/v2/calibrations/"
            render={({ match }) => <AllCalibrations match={match} />}
          />
          <Route
            exact
            path="/v2/calibrations/:id/"
            render={({ match }) => <CalibrationDetail match={match} />}
          />

          <Route
            exact
            path="/prod_files/"
            render={({ match }) => <ProdWorkoutFileList match={match} />}
          />
          <Route
            exact
            path="/prod_files/:id/"
            render={({ match }) => <ProdWorkoutDetail match={match} />}
          />

          <Route
            exact
            path="/v2/label/"
            render={({ match }) => <CalibrationsForLabel match={match} />}
          />
          <Route
            exact
            path="/v2/label/:id/"
            render={({ match }) => (
              // <AnnotationDetailView history={history} match={match} />
              <LabelDetail history={history} match={match} />
            )}
          />
          <Route
            exact
            path="/v2/admin/"
            render={({ match }) => <AdminPanel match={match} />}
          />
          <Route
            exact
            path="/v2/kfold/"
            render={({ match }) => (
              <KfoldExerciseVerificationList match={match} />
            )}
          />
          <Route
            exact
            path="/v2/kfold/:id/"
            render={({ match }) => (
              <KfoldVerifyDetail match={match} />
            )}
          />
          
          <Route
            exact
            path="/chartpage/"
            render={({ match }) => <ChaartPage match={match} />}
          />
        </Switch>
      </Layout>
    </Layout>
  );
};

const App = () => {
  const auth = new Auth();
  const getValidPath = () => {
    const user = auth.getUser();
    if (user?.role == "admin") {
      return [
        "/dashboard",
        "/label",
        "/v2/dashboard",
        "/v2/label",
        "/v2/chartpage",
        "/v2/calibrations",
        "/v2/prod_files",
        "/v2/admin",
        "/v2/verify",
        "/v2/kfold",
        "/v2/kfold_verify",
      ];
    } else if (user?.role == "tester") {
      return ["/v2/label","/v2/kfold"];
    } else if (user?.role == "trainer") {
      return ["/v2/dashboard", "/v2/verify", "/v2/kfold"];
    } else {
      return [
        "/v2/dashboard",
        "/v2/label",
        "/label",

        // "/chartpage",
        // "/callibration",
        // "/prod_files",
        "/v2/dashboard",
        "/v2/label",
        "/v2/verify",
        "/v2/chartpage",
        "/v2/calibrations",
        "/v2/prod_files",
        "/v2/kfold",
      ];
    }
  };

  console.log("valid path", getValidPath());
  // Check if route contains v2
  const isV2 = window.location.pathname.includes("v2");
  return (
    <QueryClientProvider client={queryClient}>
      <>
        <UserContext.Provider value={auth.getUser()}>
          <BrowserRouter history={history}>
            <div>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={({ history, location }) =>
                    !auth.isAuthenticated() ? (
                      <LoginSignupPage history={history} location={location} />
                    ) : (
                      <Redirect to={getValidPath()[0]} />
                    )
                  }
                />
                {/* <Route
                  path={getValidPath()}
                  render={({ history, location }) =>
                    auth.isAuthenticated() && isV2 ? (
                      <ProtectedViewsV2
                        validPath={getValidPath()}
                        history={history}
                        location={location}
                      />
                    ) : auth.isAuthenticated() && !isV2 ? (
                      <ProtectedViews
                        validPath={getValidPath()}
                        history={history}
                        location={location}
                      />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                /> */}
                <Route
                  path={getValidPath()}
                  render={({ history, location }) =>
                    auth.isAuthenticated() ? (
                      <ProtectedViewsV2
                        validPath={getValidPath()}
                        history={history}
                        location={location}
                      />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  path="*"
                  render={({ location }) => (
                    <Redirect to={{ pathname: "/v2/dashboard" }}></Redirect>
                  )}
                />
              </Switch>
            </div>
          </BrowserRouter>
        </UserContext.Provider>
      </>
    </QueryClientProvider>
  );
};

export default App;
