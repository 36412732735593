import React from "react";
import { Menu, Grid } from "antd";
import { Drawer, Button } from "antd";
import { LogoutOutlined, SettingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Avatar } from "antd";
import {
  UserOutlined,
  DashboardOutlined,
  FileOutlined,
  SnippetsOutlined,
  AuditOutlined,
  BugOutlined,
  FormOutlined,
  CheckCircleOutlined
} from "@ant-design/icons";
import Auth from "../../services/auth";

const RightMenu = ({ active, validPath }) => {
  // console.log("VALID_PATH", validPath, validPath.indexOf('/label'));
  // console.log("VALID_PATH", validPath.indexOf('/label'));

  // debugger;
  return (
    <Menu
      theme="dark"
      defaultSelectedKeys={[(active || "").replaceAll(/\//gi, "")]}
      mode="inline"
      style={{ paddingLeft: "0px" }}
    >
      {/* <Menu.Item key="dashboard" style={{ height: "90px" }}>
        <Link to="/dashboard/" style={{ paddingLeft: "0px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DashboardOutlined
              style={{ fontSize: "32px", marginRight: "0px" }}
            />
            <span>Dashboard</span>
          </div>
        </Link>
      </Menu.Item> */}

      {validPath?.indexOf("/v2/dashboard") >= 0 ? (
        <Menu.Item
          key="dashboard"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "72px",
            alignItems: "center",
            justifyContent: "center",
          }}
          icon={
            <DashboardOutlined
              style={{ fontSize: "24px", marginRight: "0px" }}
            />
          }
        >
          <Link style={{ padding: "0px" }} to="/v2/dashboard/">
            Dashboard
          </Link>
        </Menu.Item>
      ) : null}
      {validPath?.indexOf("/v2/label") >= 0 ? (
        <Menu.Item
          key="label"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "72px",
            alignItems: "center",
            justifyContent: "center",
          }}
          icon={
            <FormOutlined  style={{ fontSize: "24px", marginRight: "0px" }} />
          }
        >
          <Link to="/v2/label/">Label</Link>
        </Menu.Item>
      ) : null}

      {validPath?.indexOf("/v2/calibrations") >= 0 ? (
        <Menu.Item
          key="calibrations"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "72px",
            alignItems: "center",
            justifyContent: "center",
          }}
          icon={
            <AuditOutlined style={{ fontSize: "24px", marginRight: "0px" }} />
          }
        >
          <Link to="/v2/calibrations/">Callibration</Link>
        </Menu.Item>
      ) : null}

      {validPath?.indexOf("/v2/verify") >= 0 ? (
        <Menu.Item
          key="verify"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "72px",
            alignItems: "center",
            justifyContent: "center",
          }}
          icon={
            <CheckCircleOutlined  style={{ fontSize: "24px", marginRight: "0px" }} />
          }
        >
          <Link to="/v2/verify">Verify</Link>
        </Menu.Item>
      ) : null}

      {validPath?.indexOf("/v2/kfold") >= 0 ? (
        <Menu.Item
          key="kfold"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "72px",
            alignItems: "center",
            justifyContent: "center",
          }}
          icon={
            <span  style={{ fontSize: "16px", marginRight: "0px", fontFamily: "monospace", 
              textTransform: "uppercase"
             }} > KFold </span>
          }
        >
          <Link to="/v2/kfold">Verification</Link>
        </Menu.Item>
      ) : null}

      {/* {validPath?.indexOf("/prod_files") >= 0 ? (
        <Menu.Item
          key="prod_files"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "72px",
            alignItems: "center",
            justifyContent: "center",
          }}
          icon={
            <SnippetsOutlined
              style={{ fontSize: "24px", marginRight: "0px" }}
            />
          }
        >
          <Link to="/prod_files/">Others</Link>
        </Menu.Item>
      ) : null} */}
      {validPath?.indexOf("/v2/admin") >= 0 ? (
        <Menu.Item
          key="admin"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "72px",
            alignItems: "center",
            justifyContent: "center",
          }}
          icon={
            <SettingOutlined style={{ fontSize: "24px", marginRight: "0px" }} />
          }
        >
          <Link style={{ padding: "0px" }} to="/v2/admin">
            Admin
          </Link>
        </Menu.Item>
      ) : null}

      {/* <Menu.Item
        key="regression"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "72px",
          alignItems: "center",
          justifyContent: "center",
        }}
        icon={<BugOutlined style={{ fontSize: "24px", marginRight: "0px" }} />}
      >
        <Link to="/regression/">Regression</Link>
      </Menu.Item>
      <Menu.Item
        key="incl_excl"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "72px",
          alignItems: "center",
          justifyContent: "center",
        }}
        icon={
          <MonitorOutlined style={{ fontSize: "24px", marginRight: "0px" }} />
        }
      >
        <Link to="/incl_excl/">Incl/Excl</Link>
      </Menu.Item> */}

      {/* <Menu.Item key="bucket_maker">
        <Link to="/bucket_maker/">Bucket Maker</Link>
      </Menu.Item>
      <Menu.Item key="predicted">
        <Link to="/predicted/">Testing</Link>
      </Menu.Item>
      <Menu.Item key="try_it_out">
        <Link to="/try_it_out/">Try it out</Link>
      </Menu.Item> */}
    </Menu>
  );
};

const NavMenu = (props) => {
  const auth = new Auth();
  return (
    <nav className="menuBar">
      <div style={{ padding: "3rem 0rem" }}>
        <Avatar size={64} style={{ backgroundColor: "#fdfffc" }}>
          {/* <img width={40} src="/ai.png" alt="image" /> */}
          <img width={100} src="/trainfit-small.png" alt="image" />
        </Avatar>
      </div>
      <div className="menuCon">
        <div>
          <div className="rightMenu">
            <RightMenu active={props.active} validPath={props.validPath} />
          </div>
        </div>
      </div>
      <Button
        onClick={() => auth.logout()}
        ghost
        type="link"
        style={{ position: "absolute", bottom: "20px", color: "white" }}
        icon={<LogoutOutlined />}
      >
        Logout
      </Button>
    </nav>
  );
};

export default NavMenu;
