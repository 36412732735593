export const exercises  =['barbellBackSquat',
'barbellBenchPress',
'barbellBentoverRow',
'barbellBicepCurl',
'barbellBulgarianSplit',
'barbellCloseGripBenchPress',
'barbellDeadlift',
'barbellFrontSquat',
'barbellHipThrusters',
'barbellInclineBenchPress',
'barbellLunge',
'barbellMilitaryPress',
'barbellRackPull',
'barbellReverseGripBentoverRow',
'barbellShrug',
'barbellUprightRow',
'benchDips',
'bicycleCrunch',
'bodyweightLunge',
'boxJump',
'cableBentoverRearDeltFly',
'cableBicepCurl',
'cableCrossoverHigh',
'cableCrossoverLow',
'cableCrunch',
'cableFacePull',
'cableRopeBicepCurl',
'cableRopeOverheadTriExtensionHigh',
'cableRopeOverheadTriExtensionLow',
'cableRopeTricepExtension',
'cableSeatedRow',
'cableTricepExtension',
'cableUprightRearDeltFly',
'cableUprightRow',
'chinUp',
'crunch',
'deadBug',
'diamondPushUp',
'dips',
'dumbbellBenchPress',
'dumbbellBicepCurl',
'dumbbellBulgarianSplitSquat',
'dumbbellDeadlift',
'dumbbellFly',
'dumbbellFrontRaise',
'dumbbellHammerCurl',
'dumbbellInclineBenchPress',
'dumbbellInclineFly',
'dumbbellLatRaise',
'dumbbellLunge',
'dumbbellOverheadPress',
'dumbbellPullover',
'dumbbellReverseFly',
'dumbbellRow',
'dumbbellShrug',
'dumbbellSkullCrusher',
'dumbbellStepUp',
'dumbbellTricepKickback',
'dumbbellOverheadTricepExtensionSingleArm',
'dumbbellUprightRow',
'ezBarBicepCurl',
'ezBarPreacherCurl',
'ezBarSkullCrusher',
'gobletSquat',
'hammerPullUp',
'jumpingJack',
'kettlebellSwing',
'doubleCableLatPulldown',
'mountainClimber',
'noise',
'plankTap',
'plateFrontRaise',
'pullUp',
'pushUp',
'reverseGripLatPulldown',
'russianTwist',
'sitUp',
'toeTouches',
'trapBarDeadlift',
'trapBarShrug',
'vBarPulldown',
'vBarSeatedCableRow',
'dumbbellArnoldPress',
'bodyweightSquat',
'cableRopeStraightArmPulldown',
'cableStraightArmPulldown',
'machineRearDeltFly',
'machineRow',
'abCrunchMachine',
'seatedChestPressMachine',
'machineTricepPress',
'machineRowHammerGrip',
'backExtension',
'barbellLandminePress',
'barbellDoubleArmLandminePress',
'svendPress',
'pecDeck',
'abRollOut',
'machinePreacherCurl',
'cableFrontRaiseSingleArm',
'cableSingleArmLateralRaise',
'dumbbellConcentrationCurl',
'machineTricepExtension',
'seatedOverheadPressMachineHammerGrip',
'machineChestPressSeatedHammer',
'dumbbellRenegadeRow',
'zottmanCurl',
'bodyweightSquatJumps',
'resistanceBandSplitter',
'resistanceBandBicepCurl',
'jackknifeSitUp',
'dumbbellWalkingLunge',
'jumpRope',
'battleRopes',
'machineAlternateArmCurl',
'cableSupermanBicepCurl',
'pilatesSwimmers',
'tBarRow',
'cableRussianTwistRight',
'cableRussianTwistLeft',
'wallBalls',
'ballSlam',
'dumbbellSideRaiseToFrontRaise',
'barbellLandmineSplitSquat',
'landmindeSquatPressDoubleArm',
'seatedOverheadPressMachineRegularGrip',
'vBarTricepPushdown',
'sideBend',
'bodyweightRunningTreadmill',
'stairClimber',
'stationarySpinning',
'machineElliptical']

export const grouppings = [
    "pushUpGrouping",
    "raiseGrouping",
    "pressGrouping",
    "curlsGrouping",
    "pullUpGrouping",
    "deadliftGrouping",
    "flyGrouping",
    "sitUpGrouping",
    "squatGrouping",
    "rowGrouping",
    "lungeGrouping",
    "shrugGrouping",
    "cableCrossoverGrouping",
    "cableExtGrouping",
    "skullCrushGrouping",
    "otherGrouping",
    "noise", 

]