import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { CalibrationFileServices } from "../../../services/dataService";
import { Card, List, Row, Tag, Typography } from "antd";
import { exNameMap } from "../../../constants";
import { Link } from "react-router-dom";
import moment from "moment";

const CalibrationCard = ({ calibration, searchParams }: any) => {
  const verificationStatus = calibration.ex_verification_status;
  const labelStatus = calibration.labelling_status;
  const importStatus = calibration.import_status;
  const archived = calibration.ex_verification_status == "archived";

  const getStatusTags = () => {
    let statusTags = [] as any;

    if (archived) {
      statusTags.push(<Tag color="red">ARCHIVED</Tag>);
      return statusTags;
    }

    if (verificationStatus === "verified") {
      statusTags.push(<Tag color="green">EX VERIFIED</Tag>);
    } else if (verificationStatus === "unverified") {
      statusTags.push(<Tag color="orange">EX UNVERIFIED</Tag>);
    }

    if (labelStatus === "labelled") {
      statusTags.push(<Tag color="green">LABELLED</Tag>);
    } else if (labelStatus === "unlabelled") {
      statusTags.push(<Tag color="yellow">UNLABELLED</Tag>);
    } else if (labelStatus === "unsure") {
      statusTags.push(<Tag color="orange">UNSURE</Tag>);
    }
    return statusTags;
  };

  let baseUrl = window.location.pathname;
  // Check if trailing slash is present in the URL
  if (baseUrl[baseUrl.length - 1] !== "/") {
    baseUrl += "/";
  }
  return (
    <Link to={`${baseUrl}${calibration.id}?${searchParams}`}>
      <Card>
        <Row justify="space-between">
          <Typography.Title level={5}>
            {exNameMap[calibration.exercise as keyof typeof exNameMap] ||
              calibration.exercise}
          </Typography.Title>
          <div>{getStatusTags()}</div>
        </Row>

        <Typography.Text type="secondary">
          ID#: {calibration.id}
        </Typography.Text>
        <br />
        <Typography.Text type="secondary">
          Record Time:{" "}
          {calibration.record_time
            ? moment(calibration.record_time).format("YYYY-MM-DD HH:mm:ss")
            : "N/A"}
        </Typography.Text>
      </Card>
    </Link>
  );
};

const CalibrationList = ({ filters, search, 
  onPageChange
 }: any) => {
  // const [page, setPage] = React.useState(filters.page || 1);
  const queryClient = useQueryClient();
  const calibrationFiles = useQuery(["calibrations", filters], () =>
    CalibrationFileServices.getAll({
      ...filters,
      search,
    })
  );

  const queryParams: any = {
    ...filters,
    search,
  };

  Object.keys(queryParams).forEach((key) => {
    if (!queryParams[key]) {
      delete queryParams[key];
    } else {
      queryParams[key] = queryParams[key].toString();
    }
  });

  const urlSearchParams = new URLSearchParams(queryParams);

  return (
    <div>
      {/* {calibrationFiles.data?.results?.map((calibration: any) => (
        <CalibrationCard calibration={calibration} key={calibration.id} />
      ))} */}
      <Row
        style={{
          margin: "0.5rem 0rem",
        }}
      >
        <Tag color="grey">{calibrationFiles.data?.total} Results</Tag>
      </Row>
      <div style={{ margin: "1rem 0rem" }}>
        <List
          grid={{
            gutter: 12,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 2,
            xxl: 2,
          }}
          dataSource={calibrationFiles.data?.results || []}
          pagination={{
            onChange: (page) => {
              // setPage(page);
              if (onPageChange) {
                onPageChange(page);
              }
            },
            current: filters.page,
            pageSize: 10,
            total: calibrationFiles.data?.total,
            showSizeChanger: false,
          }}
          loading={calibrationFiles.isLoading}
          renderItem={(calibration: any) => (
            <List.Item key={calibration.id}>
              <CalibrationCard
                calibration={calibration}
                key={calibration.id}
                searchParams={urlSearchParams.toString()}
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

export default CalibrationList;
