import React, { useState } from "react";
import moment from "moment";
import {
  Button,
  DatePicker,

  Row,

  Typography,
  Input,
  Table,
  Popover,
  Switch,
} from "antd";
import { CalibrationFileServices } from "../../../services/dataService";
import {
  RightOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  VideoCameraOutlined,
  DeleteOutlined,
  SaveOutlined,
  CheckCircleOutlined,
  CheckSquareOutlined,
} from "@ant-design/icons";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { render } from "@testing-library/react";
import { text } from "d3";
const { RangePicker } = DatePicker;

const UserStats = ({ data }: any) => {
  const [state, setState] = useState({
    search: "",
    typeFilter: "",
  });

  const [showDebugColumns, setShowDebugColumns] = useState(false);
  const { Column, ColumnGroup } = Table;

  const redirect = (user: string, to: string, params: any) => {
    if (to === "calibration") {
      params.ex_verified_by = user;
      let searchParams = new URLSearchParams(params).toString();
      window.location.href = `/v2/calibrations/?${searchParams}`;
    } else if (to === "verification") {
      params.ex_verified_by = user;
      let searchParams = new URLSearchParams(params).toString();
      window.location.href = `/v2/verify/?${searchParams}`;
    } else if (to === "label") {
      params.labelled_by = user;
      let searchParams = new URLSearchParams(params).toString();
      window.location.href = `/v2/label/?${searchParams}`;
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <Typography.Title level={5}>User Stats:</Typography.Title>
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
           
            <Input.Search
              style={{ width: "200px" }}
              onChange={(e) =>
                setState((prev) => ({ ...prev, search: e.target.value }))
              }
            />
          </div>
        </div>
        <Table
          // columns={columns as any}
          loading={!data}
          dataSource={
            state.search || state.typeFilter
              ? data
                  .filter(
                    (data: any) =>
                      data.user.includes(state.search)
                  )
                  .filter((data: any) => {
                    if (state.typeFilter === "predefined") {
                      return data.img_url;
                    } else if (state.typeFilter === "custom") {
                      return !data.img_url;
                    }
                    return true;
                  })
              : data
          }
          pagination={false}
          scroll={{ y: 600 }}
        >
          <Column
            title="User Name"
            dataIndex="user"
            key="user"
            render={(text: any, record: any) => {
              return (
                <Row>
                  <a
                    href={`/calibration-files/${record.id}`}
                    style={{
                      wordBreak: "break-all",
                    }}
                  >
                    {record.user || "Unknown User"}
                  </a>
                </Row>
              );
            }}
            // sorter={(a: any, b: any) =>
            //   a.exercise_name.localeCompare(b.exercise_name)
            // }
          />

         
          {/* <ColumnGroup title="Labelled"> */}
            <Column
              align="center"
              title="Labelled"
              dataIndex="labelled"
              key="labelled"
              render={(text: any, record: any) => (
                // <span>{record.labelling_status.labelled}</span>
                <Button
                  type="link"
                  onClick={() =>
                    redirect(record.user, "label", {
                      labelling_status: "labelled",
                    })
                  }
                >
                  {record.labelled || 0}
                </Button>
              )}
              sorter={(a: any, b: any) => (a.labelled || 0) - (b.labelled || 0)}
            />
          {/* </ColumnGroup> */}

          {/* <ColumnGroup title="Exercise Verification Status"> */}
            <Column
              align="center"
              title="Verified"
              dataIndex="verified"
              key="verified"
              render={(text: any, record: any) => (
                // <span>{record.ex_verification_status.verified}</span>
                <Button
                  type="link"
                  onClick={() =>
                    redirect(record.user, "verification", {
                      ex_verification_status: "verified",
                    })
                  }
                >
                  {record.ex_verified || 0}
                </Button>
              )}
              sorter={(a: any, b: any) =>
                (a.ex_verified || 0) - (b.ex_verified || 0)
              }
            />
          {/* </ColumnGroup> */}
          <Column
            title="Archived Files"
            dataIndex="archived"
            key="archived"
            align="center"
            render={(text: any, record: any) => (
              <Button
                type="link"
                onClick={() =>
                  redirect(record.user, "calibration", {
                    is_archived: true,
                    import_status: "successful",
                  })
                }
              >
                {record.archived || 0}
              </Button>
            )}
            sorter={(a: any, b: any) => (a.archived || 0) - (b.archived || 0)}
          />
        </Table>
      </div>
    </React.Fragment>
  );
};

export default UserStats;