import React, { useEffect, useState } from "react";
import * as d3 from "d3";
import { Checkbox, Col, Row, Space, message } from "antd";
import { exercises, grouppings } from "./constant";
import "./index.css";

const defaultDimentions = {
  width: 1600,
  height: 250,
  margin: { left: 10, top: 5, right: 10, bottom: 5 },
};

let columns = [
  "rot-rate_x",
  "rot-rate_y",
  "rot-rate_z",
  "pitch",
  "roll",
  "yaw",
  "usrAcc_x",
  "usrAcc_y",
  "usrAcc_z",
  "gravity_x",
  "gravity_y",
  "gravity_z",
  //   "time",
];

// Generate a distinct color for each column
let columnColors = columns.map((column, idx) => {
  return d3.interpolateRainbow(idx / columns.length);
});

const WindowExPredGraph = ({ windowExPred, tooltipRef, logData }) => {
  const [state, setState] = useState({});

  const svgRef = React.useRef(null);
  logData = logData?.map((d) => {
    let isNoise = d.message.includes("Converted to noise");
    let isProcessingSetboat = d.message.includes("creating workout set");

    // Parse winningex from WinningEx: ('machineTricepPress', 0.9972903)

    let winningEx = d.message.match(/WinningEx: \((.*),/);
    console.log(winningEx);
    return {
      ...d,
      isNoise: isNoise,
      isProcessingSetboat: isProcessingSetboat,
      winningEx: winningEx ? winningEx[1] : null,
    };
  });

  const mouseover = (d) => {
    let tip = d3.select("body").selectAll(".tooltip");
    if (tip.empty()) {
      tip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);
    }
    console.log(d3.event.pageX, d3.event.pageY);
    // Show tooltip
    // d3.select(tooltipRef.current)

    let html = "";
    if (d.message) {
      html = `<div><strong>Log: <br/> </strong>${d.message
        .split("\n")
        .map((item) => `<div>${item}</div>`)
        .join("")}</div><br/>`;
    } else {
      html = `<div><strong>Scores: <br/> </strong>${Object.keys(d.data)
        .map((key) => {
          return `${key}: ${Math.round(d.data[key] * 100) / 100}`;
        })
        .join("<br/>")}`;
    }
    tip
      .style("opacity", 1)
      .style("left", d3.event.pageX + 10 + "px")
      .style("top", d3.event.pageY + 10 + "px")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "10px")
      .style("pointer-events", "none")
      .html(html);
  };

  const mousemove = (d) => {
    let tip = d3.select("body").selectAll(".tooltip");
    if (tip.empty()) {
      tip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);
    }
    // d3.select(tooltipRef.current)
    tip
      .style("left", d3.event.pageX + 10 + "px")
      .style("top", d3.event.pageY + 10 + "px");
  };

  const mouseleave = (event, d) => {
    let tip = d3.select("body").selectAll(".tooltip");
    if (tip.empty()) {
      tip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);
    }

    // Hide tooltip (set opacity to 0)
    tip.style("opacity", 0);
  };

  useEffect(() => {
    if (windowExPred) {
      // Plot raw data

      d3.select(svgRef.current).selectAll("*").remove();
      // windowExPred is a list of dict with scores for exercise, plot stacked bar chart

      let svg = d3.select(svgRef.current).append("svg");

      let x = d3
        .scaleLinear()
        .domain(d3.extent(windowExPred, (d, index) => index))
        .range([
          0,
          defaultDimentions.width -
            defaultDimentions.margin.left -
            defaultDimentions.margin.right -
            100,
        ]);

      let y = d3
        .scaleLinear()
        .domain([0, 1])
        .range([
          defaultDimentions.height / 2 -
            defaultDimentions.margin.top -
            defaultDimentions.margin.bottom,
          0,
        ]);

      let exerciseColors = d3
        .scaleOrdinal()
        .domain(exercises)
        .range(
          exercises.map((exercise, idx) => {
            return d3.interpolateRainbow(idx / exercises.length);
          })
        );

      let stackedData = d3
        .stack()
        .keys(exercises)
        .value((d, key) => d[key])(windowExPred);
      svg
        .attr("width", defaultDimentions.width)
        .attr("height", defaultDimentions.height / 2);

      // Plot stacked bar chart with rounded corners
      
      console.log(stackedData)
      svg
        .selectAll("g")
        .data(stackedData)
        .enter()
        .append("g")
        .attr("fill", (d) => exerciseColors(d.key))
        .selectAll("rect")
        .data((d) => d)
        .enter()
        .append("rect")
        .attr("x", (d, index) => x(index))
        .attr("y", (d) => y(d[1]) + 16)
        .attr("height", (d) => y(d[0]) - y(d[1]) )
        .attr("width", 32)
        .attr("rx", 4)
        .attr("ry", 4)
        .on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseleave", mouseleave);
      // Add circular marker for log data
      if (logData){
        svg
        .selectAll("g")
        .data(logData)
        .selectAll("circle")
        .data(logData)
        .enter()
        .append("circle")
        .attr("cx", (d, index) => x(index + 0) + 16)
        .attr("cy", (d) => y(1) + 8)
        .attr("r", 5)
        .attr("fill", (d) =>
          d.isNoise
            ? exerciseColors("noise")
            : d.isProcessingSetboat
            ? "green"
            : "blue"
        )
        .attr("opacity", (d) => 0.8)
        .attr("stroke", (d) => (d.isProcessingSetboat ? "grey" : "none"))
        .attr("stroke-width", 2)
        .on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseleave", mouseleave);
      }

    }
  }, [windowExPred]);

  return (
    <div style={{ margin: "1rem 0rem" }}>
      {/* <div ref={exerciseTooltipRef} className="tooltip"></div> */}

      <div ref={svgRef}></div>
    </div>
  );
};

const RepCountAndSeqGrap = ({ repDetection, repSequence }) => {
  const [state, setState] = useState({});

  const svgRef = React.useRef(null);

  useEffect(() => {
    if (repDetection && repSequence) {
      // Plot raw data

      d3.select(svgRef.current).selectAll("*").remove();
      // windowExPred is a list of dict with scores for exercise, plot stacked bar chart

      let svg = d3.select(svgRef.current).append("svg");

      // Plot line chart for rep detection

      let x = d3
        .scaleLinear()
        .domain(d3.extent(repDetection, (d, index) => index))
        .range([
          0,
          defaultDimentions.width -
            defaultDimentions.margin.left -
            defaultDimentions.margin.right,
        ]);

      let y = d3
        .scaleLinear()
        .domain([0, 1])
        .range([
          defaultDimentions.height / 2 -
            defaultDimentions.margin.top -
            defaultDimentions.margin.bottom,
          0,
        ]);

      let line = d3
        .line()
        .x((d, index) => x(index))
        .y((d) => y(d));

      svg
        .attr("width", defaultDimentions.width)
        .attr("height", defaultDimentions.height / 2)
        .append("path")
        .datum(repDetection)
        .attr("fill", "none")
        .attr("stroke", "#3089d7")
        .attr("stroke-width", 1.5)
        .attr("opacity", 0.8)
        .attr("d", line);

      // Plot rep sequence line chart
      x = d3
        .scaleLinear()
        .domain(d3.extent(repSequence, (d, index) => index))
        .range([
          0,
          defaultDimentions.width -
            defaultDimentions.margin.left -
            defaultDimentions.margin.right,
        ]);

      y = d3
        .scaleLinear()
        .domain([0, 4])
        .range([
          defaultDimentions.height / 2 -
            defaultDimentions.margin.top -
            defaultDimentions.margin.bottom,
          0,
        ]);

      line = d3
        .line()
        .x((d, index) => x(index))
        .y((d) => y(d));

      svg
        .append("path")
        .datum(repSequence)
        .attr("fill", "none")
        .attr("stroke", "#0c850c")
        .attr("opacity", 0.8)
        .attr("stroke-width", 1.5)
        .attr("d", line);

      // Add grid lines

      //   svg
      //     .append("g")
      //     .attr("class", "grid")
      //     .attr(
      //       "transform",
      //       `translate(0,${
      //         defaultDimentions.height -
      //         defaultDimentions.margin.top -
      //         defaultDimentions.margin.bottom
      //       })`
      //     )
      //     .call(
      //       d3
      //         .axisBottom(x)
      //         .ticks(10)
      //         .tickSize(
      //           -defaultDimentions.height +
      //             defaultDimentions.margin.top +
      //             defaultDimentions.margin.bottom
      //         )
      //         .tickFormat("")
      //     )
      //     .call((g) => g.select(".domain").remove())
      //     .call((g) => g.selectAll(".tick").remove())
      //     .call((g) =>
      //       g
      //         .append("text")
      //         .attr("x", defaultDimentions.width - 10)
      //         .attr("y", -4)
      //         .attr("fill", "currentColor")
      //         .attr("text-anchor", "end")
      //         .text("Time")
      //     );
    }
  }, [repDetection, repSequence]);

  return (
    <div style={{ margin: "1rem 0rem" }}>
      {/* <div ref={exerciseTooltipRef} className="tooltip"></div> */}

      <div ref={svgRef}></div>
    </div>
  );
};

const DetectedSets = ({ detectedSets }) => {
  return (
    <Row>
      <strong
        style={{
          marginRight: "1rem",
        }}
      >
        Detected Sets:{" "}
      </strong>
      {detectedSets.map((set, idx) => (
        <Col>
          <div>
            {set.top_exercises[0]? set.top_exercises[0]["exercise"] : ""} ( {set.reps.length} reps ),
          </div>
        </Col>
      ))}
    </Row>
  );
};
const RawDataPlot = ({
  rawData,
  windowExPred,
  repDetection,
  repSequence,
  detectedSets,
  logData,
}) => {
  const [state, setState] = useState({
    visibleColumns: columns,
  });

  const svgRef = React.useRef(null);
  const tooltipRef = React.useRef(null);
  let svgWidth = defaultDimentions.width;
  let svgHeight = defaultDimentions.height;
  let margin = defaultDimentions.margin;

  const plotSingleColumn = (svg, x, y, columnIdx) => {
    const data = rawData;

    const line = d3
      .line()
      .x((d, index) => x(index))
      .y((d) => y(d[columnIdx]));

    svg
      .append("path")
      .attr("id", "column-" + columnIdx)
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", columnColors[columnIdx])
      .attr("stroke-width", 1.5)
      .attr("d", line);
  };

  const onTickHover = (d) => {
    let tip = d3.select("body").selectAll(".tooltip");
    if (tip.empty()) {
      tip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);
    }
    // d3.select(tooltipRef.current)
    tip
      .style("opacity", 1)
      .style("left", d3.event.pageX + 10 + "px")
      .style("top", d3.event.pageY + 10 + "px")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "10px")
      .style("pointer-events", "none")
      .html(
        `<div><strong>Time: <br/> </strong>${rawData[d][12]}
        <br/> Click to copy timestamp</div><br/>`
      );
  };

  const onTickLeave = (d) => {
    let tip = d3.select("body").selectAll(".tooltip");
    if (tip.empty()) {
      tip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);
    }

    // Hide tooltip (set opacity to 0)
    tip.style("opacity", 0);
  };

  useEffect(() => {
    // Plot raw data

    d3.select(svgRef.current).selectAll("*").remove();

    // svg.attr("width", svgWidth).attr("height", svgHeight);
    var svg = d3
      .select(svgRef.current)
      .append("svg")
      .attr("width", svgWidth + margin.left + margin.right)
      .attr("height", svgHeight + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    let x = d3
      .scaleLinear()
      .domain(d3.extent(rawData, (d, index) => index))
      .range([0, svgWidth - margin.left - margin.right]);

    let allVals = [];
    rawData.forEach((d) => {
      columns.forEach((column, idx) => {
        allVals.push(d[idx]);
      });
    });
    let y = d3
      .scaleLinear()
      .domain(
        d3.extent(allVals, (d) => {
          return d;
        })
      )
      .range([svgHeight - margin.top - margin.bottom, 0]);

    // Plot each column
    columns.forEach((column, idx) => {
      plotSingleColumn(svg, x, y, idx);
    });

    // Add ticks with text on top
    svg.append("g").call(d3.axisTop(x).ticks(Math.round(rawData.length / 100)));

    // Add hoverable on ticks and increase size, Copy the timestamp
    svg
      .selectAll(".tick")
      .on("mouseover", onTickHover)
      .on("mouseleave", onTickLeave)
      .on("click", (d) => {
        navigator.clipboard.writeText(rawData[d][12]);
        message.success("Timestamp copied to clipboard");
      })
      .attr("opacity", 0.8)
      .attr("cursor", "pointer")
      .attr("font-size", "10px")
      .attr("font-weight", "bold")
      .attr("stroke-width", "3px");
  }, [rawData]);

  const updateChart = (columnIdx, mode) => {
    let svg = d3.select(svgRef.current).select("svg").select("g");

    // If mode is "show", the change the opacity of the column to 1

    if (mode == "show") {
      svg.select("#column-" + columnIdx).attr("opacity", 1);
    } else {
      svg.select("#column-" + columnIdx).attr("opacity", 0.1);
    }
  };

  const onColumnCheck = (e, targetCol) => {
    let newVisibleColumns = state.visibleColumns;
    if (e.target.checked) {
      newVisibleColumns.push(targetCol);
    } else {
      newVisibleColumns = newVisibleColumns.filter(
        (column) => column != targetCol
      );
    }
    setState((prevState) => ({
      ...prevState,
      visibleColumns: newVisibleColumns,
    }));

    // Update the chart
    columns.forEach((column, idx) => {
      if (newVisibleColumns.includes(column)) {
        updateChart(idx, "show");
      } else {
        updateChart(idx, "hide");
      }
    });
  };

  return (
    <>
      <DetectedSets detectedSets={detectedSets}></DetectedSets>

      <div style={{ margin: "0.5rem 0rem" }}>
        <div></div>
        {/* <div ref={tooltipRef} className="tooltip">
        <div></div>
      </div> */}

        <div className="legends">
          <Row>
            <Space>
              {columns.map((column, idx) => (
                <Col>
                  <div className="legend" key={idx}>
                    <Checkbox
                      style={{
                        color: columnColors[idx],
                      }}
                      checked={state.visibleColumns.includes(column)}
                      onChange={(e) => onColumnCheck(e, column)}
                    >
                      {column}
                    </Checkbox>
                  </div>
                </Col>
              ))}
            </Space>
          </Row>
        </div>

        <div style={{ overflow: "auto" }}>
          <div ref={svgRef} />
          <WindowExPredGraph
            windowExPred={windowExPred}
            tooltipRef={tooltipRef}
            logData={logData}
          />
          <RepCountAndSeqGrap
            repDetection={repDetection}
            repSequence={repSequence}
          ></RepCountAndSeqGrap>
        </div>
      </div>
    </>
  );
};

export default RawDataPlot;
