// {
//     "event": "newSetLogged",
//     "properties": {
//       "time": 1697159994.183,
//       "distinct_id": "053839D3-8564-48DF-8A63-4B1F357F0692",
//       "$app_build_number": "1",
//       "$app_version_string": "3.1.6",
//       "$city": "Toronto",
//       "$device_id": "1FCE4DD5-5AEC-4043-A799-ED816EA57E40",
//       "$distinct_id_before_identity": "N1Yzv61B0OMD5cf1FyaadKCcUK92",
//       "$had_persisted_distinct_id": true,
//       "$insert_id": "ba1acbef36a3e5bf",
//       "$lib_version": "4.1.0",
//       "$manufacturer": "Apple",
//       "$model": "Watch6,4",
//       "$mp_api_endpoint": "api.mixpanel.com",
//       "$mp_api_timestamp_ms": 1697159994618,
//       "$os": "watchOS",
//       "$os_version": "10.1",
//       "$region": "Ontario",
//       "$screen_height": 224,
//       "$screen_width": 184,
//       "$user_id": "N1Yzv61B0OMD5cf1FyaadKCcUK92",
//       "$watch_model": "Apple Watch 44mm",
//       "TestFlightUser": true,
//       "device": "Watch",
//       "mp_country_code": "CA",
//       "mp_lib": "swift",
//       "mp_processing_time_ms": 1697159994744,
//       "repCount": 8,
//       "setLogType": "manual",
//       "setUID": "BF42FC1E-A766-4DE5-85D5-EF4B77DA4FEE",
//       "timeStamp": 1697159994.183202,
//       "type": "pushUp",
//       "watchHand": "left",
//       "watchOrientation": "right",
//       "weight": 25
//     }
//   }

import { Button, Input, Row } from "antd";
import { useState } from "react";
import SetDetailView from "../prodWorkoutFileDetails/setDetailView";

const DebugSet = (props) => {
  const [state, setState] = useState({});

  const onChange = (e) => {
    // Check if val is a json

    let val = e.target.value;
    try {
      val = JSON.parse(val);
      setState((prev) => ({
        ...prev,
        set: {
          start_time: val.properties.setBoatStartTime,
          end_time: val.properties.setBoatEndTime,
          setBoatEndTime: val.properties.setBoatEndTime,
          setBoatStartTime: val.properties.setBoatStartTime,
          creation_date: val.properties.timeStamp,
          user_id: val.properties.$user_id,
        },
      }));
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Row
        style={{
          padding: "0rem 0.5rem",
        }}
      >
        <Input.TextArea
          placeholder="Mixpanel Event"
          style={{
            margin: "0.5rem 0rem",
            maxWidth: "100%",
          }}
          onChange={onChange}
        ></Input.TextArea>
        {/* <Button>Debug</Button> */}
      </Row>
      <div
        style={{
          overflowY: "scroll",
        }}
      >
        {state.set ? <SetDetailView set={state.set} /> : null}
      </div>
    </>
  );
};

export default DebugSet;
