import React, { useState } from "react";

import { DatePicker, Col, Row, PageHeader, Table, Typography } from "antd";
import moment from "moment";
const { RangePicker } = DatePicker;


const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: "New Data",
    dataIndex: "NEW_DATA",
    align: "center",
    width: "10%",
    sorter: {
      compare: (a, b) => a.NEW_DATA - b.NEW_DATA,
      multiple: 3,
    },
  },
  {
    title: "Labelled",
    dataIndex: "LABELLED",
    sorter: {
      compare: (a, b) => a.LABELLED - b.LABELLED,
      multiple: 3,
    },
  },
  {
    title: "Automatic",
    dataIndex: "AUTOMATIC",
    sorter: {
      compare: (a, b) => a.AUTOMATIC - b.AUTOMATIC,
      multiple: 3,
    },
  },
  {
    title: "Pending",
    dataIndex: "PENDING",
    sorter: {
      compare: (a, b) => a.PENDING - b.PENDING,
      multiple: 2,
    },
  },
  {
    title: "Rejected",
    dataIndex: "ARCHIVED",
    sorter: {
      compare: (a, b) => a.ARCHIVED - b.ARCHIVED,
      multiple: 2,
    },
  },
  {
    title: "Failed",
    dataIndex: "IMPORT_FAILED",
    sorter: {
      compare: (a, b) => a.IMPORT_FAILED - b.IMPORT_FAILED,
      multiple: 2,
    },
  },
  {
    title: "Verified",
    dataIndex: "verified",
    sorter: {
      compare: (a, b) => a.verified - b.verified,
      multiple: 2,
    },
  },
  {
    title: "Rep. Verified",
    dataIndex: "rep_verified",
    sorter: {
      compare: (a, b) => a.rep_verified - b.rep_verified,
      multiple: 2,
    },
  },
  {
    title: "Total",
    dataIndex: "total",
    align: "center",
    width: "10%",
    sorter: {
      compare: (a, b) => a.total - b.total,
      multiple: 1,
    },
  },
];


const ImportBatchStatus = (props) => {

  const onDateChange = (startDate, endDate) => {

  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Row style={{ marginBottom: "10px" }}>
          <Col span={16}>
            <Typography.Title level={5}>Import Batches Stats:</Typography.Title>
          </Col>
          <Col span={8} style={{ display: "flex", justifyContent: "end" }}>
            
          </Col>
        </Row>
        <Table columns={columns} dataSource={props.data} pagination={false} />
      </div>
    </React.Fragment>
  );
};

export default ImportBatchStatus;
