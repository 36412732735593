import React, { useState } from "react";
import { DashboardService } from "../../services/dataService";
import moment from "moment";
import {
  Button,
  DatePicker,
  Tag,
  Spin,
  PageHeader,
  Tabs,
  notification,
  message,
  InputNumber,
  Row,
  Space,
  Slider,
  Select,
  Col,
  Typography,
} from "antd";
import {
  RightOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  VideoCameraOutlined,
  DeleteOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";

import BatchStatus from "./batchStatus";
import UserStatus from "./userStatus";
import ExerciseStatus from "./exerciseStatus";
import ImportBatchStatus from "./importFlag";
const { RangePicker } = DatePicker;
const Dashboard = (props) => {
  const queryClient = useQueryClient();
  const [state, updateState] = useState({
    filters: { dateRange: [moment().subtract(2, "y"), moment()] },
  });

  const dashboardStats = useQuery(
    ["dashboard_stats", state.filters],
    () =>
      DashboardService.getAll({
        start_date: state.filters.dateRange[0].format("YYYY-MM-DD"),
        end_date: state.filters.dateRange[1].format("YYYY-MM-DD"),
      }),
    { staleTime: Infinity }
  );

  const onDateRangeChange = ([startDate, endDate]) => {
    updateState((prev) => ({ ...prev, filters: {...prev.filters, dateRange: [startDate, endDate]} }));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <PageHeader
          ghost={false}
          // onBack={() => window.history.back()}
          title="Dashboard"
          extra={[
            <RangePicker
              allowClear={true}
              value={state.filters.dateRange}
              onChange={onDateRangeChange}
              format="YYYY-MM-DD"
            />,
          ]}
        ></PageHeader>
        {dashboardStats.isLoading ? (
          <Spin />
        ) : (
          <div>
            {/* <BatchStatus data={dashboardStats.data.batch_stats} /> */}
            <UserStatus data={dashboardStats.data.user_stats} />
            <ImportBatchStatus  data={dashboardStats.data.import_flag} />
            <ExerciseStatus data={dashboardStats.data.ex_stats} />
          </div>
        )}
        {/* <SDSFileList /> */}
        {/* <SDSTable /> */}
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
