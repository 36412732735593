import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Row,
  Space,
  Modal,
  Tag,
  Typography,
  Col,
  Slider,
  InputNumber,
  Empty,
  Popconfirm,
} from "antd";
import {
  MinusSquareFilled,
  MinusSquareTwoTone,
  PlayCircleOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  PauseCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";

const StageControl = ({
  changeState,
  currentStage,
  switchAnnotationState,
  resetAnnotations,
}) => {
  return (
    <Row align="middle">
      <div>
        <Typography.Text type="secondary">Stage Controls</Typography.Text>
      </div>
      <div>
        <Space style={{ margin: "5px 0px" }}>
          <Tag.CheckableTag
            checked={currentStage == "0"}
            value="0"
            onClick={() => changeState("0")}
          >
            0
          </Tag.CheckableTag>
          <Tag.CheckableTag
            checked={currentStage == "-1"}
            onClick={() => changeState("-1")}
            value="-1"
          >
            -1
          </Tag.CheckableTag>
          <Tag.CheckableTag
            checked={currentStage == "1"}
            onClick={() => changeState("1")}
            value="1"
          >
            1
          </Tag.CheckableTag>
          <Tag.CheckableTag
            checked={currentStage == "2"}
            onClick={() => changeState("2")}
            value="2"
          >
            2
          </Tag.CheckableTag>
          <Tag.CheckableTag
            checked={currentStage == "3"}
            value="3"
            onClick={() => changeState("3")}
          >
            3
          </Tag.CheckableTag>
          <Tag.CheckableTag
            checked={currentStage == "-2"}
            value="-2"
            onClick={() => changeState("-2")}
          >
            -2
          </Tag.CheckableTag>
        </Space>
      </div>
      <div>
        <Space style={{ margin: "5px 0px" }}>
          <Button
            size="small"
            type="primary"
            onClick={() => switchAnnotationState()}
          >
            Add
          </Button>
          <Button size="small" onClick={resetAnnotations}>
            Reset
          </Button>
        </Space>
      </div>
    </Row>
  );
};

const StageControlEdit = ({ played, saveStage, clearAll }) => {
  const [state, setState] = useState({ label: 0 });

  const addStart = () => {
    setState((prev) => ({ ...prev, start: played }));
  };
  const addEnd = () => {
    setState((prev) => ({ ...prev, end: played }));
  };

  const onSave = () => {
    saveStage(state);
    reset();
  };

  const switchLabel = (newLabel) => {
    setState((prev) => ({ ...prev, label: newLabel }));
  };

  const reset = () => {
    setState((prev) => ({ ...prev, label: "0", start: null, end: null }));
  };

  const saveNewLabel = () => {};
  return (
    <Row align="middle">
      <div>
        <Typography.Text type="secondary">Stage Controls</Typography.Text>
      </div>
      <br />
      <Row style={{ width: "100%" }}>
        <Col span={12}>
          <Typography.Text type="secondary">Start</Typography.Text>
          <span style={{ marginLeft: "1rem" }}>{state.start || "   -"}</span>
        </Col>
        <Col span={12}>
          <Typography.Text type="secondary">End</Typography.Text>
          <span style={{ marginLeft: "1rem" }}>{state.end || "-"}</span>
        </Col>
      </Row>
      <div>
        <Space style={{ margin: "5px 0px" }}>
          <Tag.CheckableTag
            checked={state.label == "0"}
            value="0"
            onClick={() => switchLabel("0")}
          >
            0
          </Tag.CheckableTag>
          <Tag.CheckableTag
            checked={state.label == "-1"}
            onClick={() => switchLabel("-1")}
            value="-1"
          >
            -1
          </Tag.CheckableTag>
          <Tag.CheckableTag
            checked={state.label == "1"}
            onClick={() => switchLabel("1")}
            value="1"
          >
            1
          </Tag.CheckableTag>
          <Tag.CheckableTag
            checked={state.label == "2"}
            onClick={() => switchLabel("2")}
            value="2"
          >
            2
          </Tag.CheckableTag>
          <Tag.CheckableTag
            checked={state.label == "3"}
            value="3"
            onClick={() => switchLabel("3")}
          >
            3
          </Tag.CheckableTag>
          <Tag.CheckableTag
            checked={state.label == "-2"}
            value="-2"
            onClick={() => switchLabel("-2")}
          >
            -2
          </Tag.CheckableTag>
        </Space>
      </div>
      <div>
        <Space style={{ margin: "5px 0px" }}>
          {!state.start ? (
            <Button size="small" type="primary" onClick={() => addStart()}>
              Add Start
            </Button>
          ) : state.start && !state.end ? (
            <Button size="small" type="primary" onClick={() => addEnd()}>
              Add End
            </Button>
          ) : (
            <Button size="small" type="primary" onClick={() => onSave()}>
              Save Stage
            </Button>
          )}
          {/* <Button
            size="small"
            type="primary"
            onClick={() => switchAnnotationState()}
          >
            Save
          </Button> */}
          <Button size="small" onClick={reset}>
            Reset
          </Button>

          <Popconfirm title="Are you sure?" onConfirm={clearAll}>
            <Button danger size="small" >
              Clear all
            </Button>
          </Popconfirm>
        </Space>
      </div>
    </Row>
  );
};

const PlaybackNStageControls = ({
  changeState,
  editMode,
  isVerified,
  isArchived,
  isLabelled,
  isAutoLabelled,
  played,
  totalDuration,
  playbackRate,
  setPlaybackRate,
  updatePlaybackRate,
  currentPlayBackState,
  updatePlaybackState,
  currentStage,
  switchAnnotationState,
  resetAnnotations,
  onNext,
  saveStage,
  onPrevious,
  clearAll,
  enableEditMode,
}) => {
  console.log("CURRENT STAGE", currentStage, editMode);
  return (
    <>
      <div style={{ width: "640px", margin: "10px 0px" }}>
        <Row align="middle">
          <Col span={12}>
            <div>
              <Typography.Text type="secondary">Current Time</Typography.Text>
            </div>
            <div>
              {Math.ceil(played * totalDuration)}/{totalDuration}
            </div>
          </Col>
          <Col span={12}>
            <Typography.Text type="secondary">Playback Rate</Typography.Text>
            <Row>
              <Slider
                style={{ width: "150px" }}
                min={0.1}
                max={1}
                onChange={(newValue) => setPlaybackRate(newValue)}
                value={playbackRate}
                step={0.05}
              />
              <InputNumber
                min={0}
                max={1}
                style={{ margin: "0 10px" }}
                step={0.05}
                value={playbackRate}
                onChange={(newValue) => updatePlaybackRate(newValue)}
              />
            </Row>
          </Col>
        </Row>
      </div>

      <div style={{ width: "640px", margin: "10px 0px" }}>
        <Row align="middle">
          <Col span={12}>
            <div>
              <Typography.Text type="secondary">Controls</Typography.Text>
            </div>
            <Space style={{ margin: "10px 0px" }}>
              <Button onClick={onPrevious} icon={<DoubleLeftOutlined />}>
                Prev
              </Button>
              {currentPlayBackState ? (
                <Button
                  onClick={() => updatePlaybackState(false)}
                  icon={<PauseCircleOutlined />}
                >
                  Pause
                </Button>
              ) : (
                <Button
                  onClick={() => updatePlaybackState(true)}
                  icon={<PlayCircleOutlined />}
                >
                  Play
                </Button>
              )}

              <Button onClick={onNext}>
                Next <DoubleRightOutlined />
              </Button>
            </Space>
          </Col>
          <Col span={12}>
            <Row align="middle">
              {!isLabelled && !isAutoLabelled && isVerified && !isArchived ? (
                <StageControl
                  changeState={changeState}
                  currentStage={currentStage}
                  switchAnnotationState={switchAnnotationState}
                  resetAnnotations={resetAnnotations}
                />
              ) : !isVerified == "PENDING" || !isArchived == "ARCHIVED" ? (
                <div style={{ marginTop: "2rem" }}>
                  <Empty
                    description="This file can't be labelled (Invalid/Archived)"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  ></Empty>{" "}
                </div>
              ) : editMode ? (
                <StageControlEdit
                  played={Math.ceil(played * totalDuration)}
                  saveStage={saveStage}
                  clearAll={clearAll}
                />
              ) : (
                <div>
                  <div>
                    <Typography.Text type="secondary">
                      Current Stage
                    </Typography.Text>
                    <Button type="link" onClick={() => enableEditMode()}>
                      <EditOutlined />
                    </Button>
                  </div>
                  <div>{currentStage}</div>
                </div>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};
export { StageControl, PlaybackNStageControls };
