/** MultilineChart.js */
import React, { useEffect } from "react";
import * as d3 from "d3";
// import allData from "./data";
import "./index.css";
import { Drawer, Row, Col } from "antd";
import { exercises, grouppings } from "./constant";

const ExRepChart = (props) => {
  const exerciseData = props.data;
  const svgRef = React.useRef(null);
  const divRef = React.useRef(null);
  const exerciseTooltipRef = React.useRef(null);

  // to detect what line to animate we should store previous data state
  const [state, setState] = React.useState({});

  const getTop5Component = () => {
    var items = [];
    Object.keys(exerciseData.top5).map((key) =>
      items.push({ key: key, value: exerciseData.top5[key] })
    );
    items = items.sort((a, b) => -(a.value - b.value));
    return (
      <div>
        {items.map((item) => {
          return (
            <Row>
              <Col span={12}>{item.key}</Col>
              <Col span={12}>{Math.round(item.value*1000)/1000}</Col>
            </Row>
          );
        })}
      </div>
    );
  };

  const getTop5Other= () => {
    
    const items = exerciseData.other_info.sort((a, b) => -(a.score - b.score));
    return (
      <div>
        {items.map((item) => {
          return (
            <Row>
              <Col span={12}>{item.exercise}</Col>
              <Col span={12}>{Math.round(item.score*1000)/1000}</Col>
            </Row>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    var height = 300;
    var width = 580;
    var exerciseTooltip = d3
      .select(exerciseTooltipRef.current)
      .style("opacity", 0)
      .style("background-color", "white")
      .style("padding", "20px");

    // exerciseTooltip
    //   .select("div")
    //   .style("padding", "0px 0px 20px 0px")
    //   .html(
    //     "<b>Top 5 Pred: </b>" +
    //       "<br>" +
    //       Object.keys(exerciseData.top5)
    //         .map(
    //           (key) =>
    //             `<div>${key}:  ${
    //               Math.round(exerciseData.top5[key] * 1000) / 1000
    //             }</div>`
    //         )
    //         .join(" ")
    //   )
    //   .style("opacity", 1);
    var svg = exerciseTooltip
      .select("svg")
      .attr("width", width)
      .attr("height", height + 100)
      .append("g")
      .attr("transform", "translate(" + 10 + "," + 10 + ")");

    console.log(exerciseData);
    const yMax = d3.max(exerciseData.other_info, (d) => d3.max(d.values)) + 0.1;
    const yMin = d3.min(exerciseData.other_info, (d) => d3.min(d.values)) + 0.1;

    const xMax =
      exerciseData.other_info.length > 0
        ? exerciseData.other_info[0].values.length
        : 0 + 10;
    // var ex2color = d3.scaleOrdinal().domain(exercises).range(d3.range(0, exercises.length).map(i => d3.interpolateRainbow(i/exercises.length)));
    var ex2color = d3.scaleOrdinal().range(d3.schemeTableau10);

    var x = d3
      .scaleLinear()
      .domain([0, xMax])
      .range([20, width - 150]);
    var y = d3.scaleLinear().domain([yMin, yMax]).range([height, 50]);
    const xAxis = d3
      .axisBottom(x)
      .ticks(width / 80)
      .tickSizeOuter(0);
    // const yAxis = d3.axisLeft(y).ticks(height / 60);

    // svg
    //   .append("g")
    //   .attr("transform", "translate(20," + 0 + ")")
    //   .call(yAxis);
    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);
    // Draw the line
    const lines = svg
      .selectAll(".line")
      .data(exerciseData.other_info)
      .enter()
      .append("g");

    lines
      .append("path")
      .attr("fill", "none")
      .attr("stroke", (d) => ex2color(d.exercise))
      .attr("stroke-width", 1.5)
      .attr("opacity", "0.5")
      .attr("d", function (d) {
        return d3
          .line()
          .x(function (d, idx) {
            return x(idx);
          })
          .y(function (d) {
            return y(d);
          })(d.values);
      })
      .attr("class", function (d, idx) {
        return "normal-line";
      });

    const ghost_lines = lines
      .append("path")
      .attr("class", "ghost-line")
      .attr("d", function (d) {
        return d3
          .line()
          .x(function (d, idx) {
            return x(idx);
          })
          .y(function (d) {
            return y(d);
          })(d.values);
      });

    lines
      .append("text")
      .attr("class", "label-tspan")
      //   .attr("fill", "black")
    //   .attr("font-family", "Georgia")
      .attr("opacity", "0.5")

      //   .attr("font-size", "80%")
      .attr("stroke", (d) => ex2color(d.exercise))
      .attr("font-size", 12)
      .datum(function (d) {
        return {
          exercise: d.exercise,
          length: d.values.length,
          value: d.values[d.values.length - 1],
        };
      })
      .attr("transform", function (d, idx) {
        return (
          "translate(" +
          ((idx % 3) * 150 + 20) +
          "," +
          (Math.floor(idx / 3) * 20 + 5) +
          ")"
        );
      })
      .attr("x", 5)
      .text(function (d) {
        return d.exercise;
      });

    svg
      .selectAll(".ghost-line")
      .on("mouseover", function () {
        const selection = d3.select(this).raise();
        const normalLine = d3.select(this.parentNode).select(".normal-line");

        normalLine
          .transition()
          .delay("100")
          .duration("10")
          .style("opacity", "1")
          .style("stroke-width", "3");
        const legend = d3.select(this.parentNode).select(".label-tspan");

        legend.transition().delay("100").duration("10").style("opacity", "1");
      })
      .on("mouseout", function () {
        const selection = d3.select(this);
        const normalLine = d3.select(this.parentNode).select(".normal-line");

        normalLine
          .transition()
          .delay("100")
          .duration("10")
          .style("opacity", "0.5")
          .style("stroke-width", "1.5");
        const legend = d3.select(this.parentNode).select(".label-tspan");
        legend.transition().delay("100").duration("10").style("opacity", "0.5");
      });

    exerciseTooltip.style("opacity", 1);
  }, [exerciseData]);

  return (
    <div>
      <Row style={{margin: "20px 20px"}}>
        <Col span={12}><div><b>Top 5 (Frontend)</b></div><div>{getTop5Component()} </div></Col>
        <Col span={12}><div><b>Top 5 (Other)</b></div><div>{getTop5Other()} </div></Col>
      </Row>
      <div ref={exerciseTooltipRef}>
        <div></div>
        <svg></svg>
      </div>
    </div>
  );
};

export default ExRepChart;
