import React from "react";
import {
  Card,
  Row,
  Col, Tag
} from "antd";

export const SetCard = ({ set, onClick, selected }) => {
  let eventProperties = set.event_properties?.properties || {};
  let isAutoDetected = set.start_time ? true : false;
  return (
    <Col span={24}>
      <Card
        onClick={onClick}
        style={{
          // width: "100%",
          // margin: "0.5rem 1rem",
          // backgroundColor: selected ? "#f0f0f0" : "white"
          border: selected ? "1px solid #1890ff" : "1px solid #f0f0f0",
          outline: selected ? "2px solid #1890ff78" : "none",
          margin: "0rem 0.5rem",
        }}
      >
        <Row justify="space-between">
          <Col>
            <em>{set.exercise}</em>
          </Col>
          <Col>
            <Tag>{set.start_time ? "Auto-Detected" : "Manual"}</Tag>
          </Col>
        </Row>
        <div>
          <span style={{ color: "gray" }}>
            <em>Reps: {set.num_reps}</em>
          </span>
          <span style={{ color: "gray", marginLeft: "1rem" }}>
            <em>
              AI Reps:{" "}
              {eventProperties.repsCountPrevious
                ? eventProperties.repsCountPrevious
                : isAutoDetected
                  ? eventProperties.repCount
                  : "-"}
            </em>
          </span>
          <br />
          <span style={{ color: "gray" }}>
            <em>
              Predicted Ex:{" "}
              {eventProperties.topTypes ? eventProperties.topTypes[0] : "-"}
            </em>
          </span>
        </div>
      </Card>
    </Col>
  );
};
