/** MultilineChart.js */
import React, { useEffect, useState } from "react";
import * as d3 from "d3";
// import allData from "./data";
// import "./index.css";
import { Button, Drawer, Row, Modal } from "antd";
import { MinusSquareFilled, MinusSquareTwoTone } from "@ant-design/icons";
// import ExRepChart from "./exRepsChart";
// import { exercises, grouppings } from "./constant";
import createPersistedState from "use-persisted-state";

const modelGraphState = createPersistedState("modelGraphState");

const defaultDimentions = {
  width: 2500,
  height: 200,
  margin: { left: 10, top: 10, right: 10, bottom: 40 },
};

const ROT_RATE_X = 0;
const ROT_RATE_Y = 1;
const ROT_RATE_Z = 2;
const PITCH = 3;
const ROLL = 4;
const YAW = 5;
const ACC_X = 6;
const ACC_Y = 7;
const ACC_Z = 8;
const GRAVITY_X = 9;
const GRAVITY_Y = 10;
const GRAVITY_Z = 11;

const DATA_NAME = {
  [ROT_RATE_X]: "ROT_RATE_X",
  [ROT_RATE_Y]: "ROT_RATE_Y",
  [ROT_RATE_Z]: "ROT_RATE_Z",
  [PITCH]: "PITCH",
  [ROLL]: "ROLL",
  [YAW]: "YAW",
  [ACC_X]: "ACC_X",
  [ACC_Y]: "ACC_Y",
  [ACC_Z]: "ACC_Z",
  [GRAVITY_X]: "GRAVITY_X",
  [GRAVITY_Y]: "GRAVITY_Y",
  [GRAVITY_Z]: "GRAVITY_Z",
};
const STROKE_COLOR = {
  [ROT_RATE_X]: "#ea5545",
  [ROT_RATE_Y]: "#f46a9b",
  [ROT_RATE_Z]: "#ef9b20",
  [PITCH]: "#edbf33",
  [ROLL]: "#ede15b",
  [YAW]: "#bdcf32",
  [ACC_X]: "#87bc45",
  [ACC_Y]: "#27aeef",
  [ACC_Z]: "#b33dc6",
  [GRAVITY_X]: "#fd7f6f",
  [GRAVITY_Y]: "#7eb0d5",
  [GRAVITY_Z]: "#b2e061",
};

const MultilineChart = (props) => {
  const [state, setState] = modelGraphState({
    enabledAxes: null,
    repDetectionProb: true,
    enabledAxes: [
      ROT_RATE_X,
      ROT_RATE_Y,
      ROT_RATE_Z,
      PITCH,
      ROLL,
      YAW,
      ACC_X,
      ACC_Y,
      ACC_Z,
      GRAVITY_X,
      GRAVITY_Y,
      GRAVITY_Z,
    ],
  });

  const allData = props.data;
  const svgRef = React.useRef(null);
  const svgModalRef = React.useRef(null);

  const divRef = React.useRef(null);
  const exerciseTooltipRef = React.useRef(null);

  // to detect what line to animate we should store previous data state
  const { width, height, margin = {} } = defaultDimentions;
  // const svgWidth = width + margin.left + margin.right;
  // const svgHeight = height + margin.top + margin.bottom;

  const toggleModal = () => {
    setState((prev) => ({
      ...prev,
      modalVisible: prev.modalVisible ? false : true,
      enabledAxes: prev.enabledAxes
        ? null
        : [
            ROT_RATE_X,
            ROT_RATE_Y,
            ROT_RATE_Z,
            PITCH,
            ROLL,
            YAW,
            ACC_X,
            ACC_Y,
            ACC_Z,
            GRAVITY_X,
            GRAVITY_Y,
            GRAVITY_Z,
          ],
    }));
  };

  const toggleLine = (key) => {
    let enabledAxes = state.enabledAxes;
    if (enabledAxes && enabledAxes.indexOf(key) == -1) {
      enabledAxes = [...enabledAxes, key];
    } else if (enabledAxes) {
      enabledAxes = enabledAxes.filter((item) => item != key);
    }
    setState((prev) => ({ ...prev, enabledAxes: enabledAxes }));
  };

  React.useEffect(() => {
    console.log("RENDER");
    // Parse the Data
    if (!allData?.total_seq_labels) {
      return;
    }
    var predictedLabels = allData.total_seq_labels;
    var actualLabels = allData.seq_labels;

    // set the dimensions and margins of the graph

    let svgLocalRef = props.modalVisible ? svgModalRef : svgRef;

    console.log("svgLocalRef", svgLocalRef.current.offsetWidth);

    d3.select(svgLocalRef.current).selectAll("*").remove();

    if (props.modalVisible) {
      var margin = { top: 0, right: 30, bottom: 10, left: 10 },
        width = svgLocalRef.current.offsetWidth,
        height = 320 - margin.top - margin.bottom;
    } else {
      var margin = { top: 0, right: 30, bottom: 10, left: 10 },
        width = svgLocalRef.current.offsetWidth,
        height = 300 - margin.top - margin.bottom;
    }

    // append the svg object to the body of the page
    var svg = d3
      .select(svgLocalRef.current)
      .append("svg")
      .attr("width", width)
      .attr(
        "height",
        props.modalVisible
          ? 2 * height + margin.top + margin.bottom
          : height + margin.top + margin.bottom
      )
      .append("g")
      .attr("transform", "translate(" + margin.top + "," + margin.top + ")");

    // Add X axis --> it is a date format
    var startHeight = 0;
    var endHeight = height;
    var x = d3
      .scaleLinear()
      .domain([0, props.totalDuration / 20 || actualLabels.length])
      .range([margin.left, width]);

    // Add Y axis
    var y = d3
      .scaleLinear()
      .domain([0, 4])
      .range([props.modalVisible ? endHeight : endHeight * 0.66, startHeight]);

    // Add the line
    let line1 = svg
      .append("path")
      .attr("id", "1")
      .datum(actualLabels)
      .attr("fill", "none")
      .attr("stroke", "#4056e5d8")
      .attr("opacity", 0.8)
      .attr("stroke-width", 2)
      .attr(
        "d",
        d3
          .line()
          .x(function (d, idx) {
            return x(idx);
          })
          .y(function (d) {
            return y(d);
          })
      );

    svg
      .append("path")
      .attr("id", "2")
      .datum(predictedLabels)
      .attr("fill", "none")
      .attr("stroke", "#ff00008c")
      .attr("opacity", 0.7)
      .attr("stroke-width", 2)
      .attr(
        "d",
        d3
          .line()
          .x(function (d, idx) {
            return x(idx);
          })
          .y(function (d) {
            return y(d);
          })
      );

    if (!props.repDetectionProb) {
      let data = allData.rep_detection_probability;
      x = d3
        .scaleLinear()
        .domain([0, props.totalDuration / 400 || data.length])
        .range([margin.left, width]);

      // Add Y axis
      y = d3
        .scaleLinear()
        .domain([0, 1])
        .range([height, 0.66 * height]);
      svg
        .append("path")
        .attr("id", "repdetection-1")
        .datum(data)
        .attr("fill", "none")
        .attr("stroke", "green")
        .attr("opacity", 0.8)
        .attr("stroke-width", 2)
        .attr(
          "d",
          d3
            .line()
            .x(function (d, idx) {
              return x(idx);
            })
            .y(function (d) {
              return y(d);
            })
        );

      svg
        .append("path")
        .attr("id", "repdetection-cutoff")
        .datum(data)
        .attr("fill", "none")
        .attr("stroke", "red")
        .attr("opacity", 0.5)
        .attr("stroke-width", 1)
        .attr(
          "d",
          d3
            .line()
            .x(function (d, idx) {
              return x(idx);
            })
            .y(function (d) {
              return y(0.3);
            })
        );
    }
    // Plot all data
    if (props.modalVisible) {
      for (let enabledAxis of state.enabledAxes) {
        let data = allData.data.map((item) => item[enabledAxis]);
        x = d3
          .scaleLinear()
          .domain([0, data.length])
          .range([margin.left, width]);

        // Add Y axis
        y = d3
          .scaleLinear()
          .domain([Math.min(...data), Math.max(...data)])
          .range([endHeight + endHeight, endHeight + startHeight]);

        svg
          .append("path")
          .attr("id", "sensor" + enabledAxis)
          .datum(data)
          .attr("fill", "none")
          .attr("stroke", STROKE_COLOR[enabledAxis])
          .attr("opacity", 0.7)
          .attr("stroke-width", 2)
          .attr(
            "d",
            d3
              .line()
              .x(function (d, idx) {
                return x(idx);
              })
              .y(function (d) {
                return y(d);
              })
          );
      }

      if (state.repDetectionProb) {
        // Rep Detection Probability
        let data = allData.rep_detection_probability;
        x = d3
          .scaleLinear()
          .domain([0, data.length])
          .range([margin.left, width]);

        // Add Y axis
        y = d3
          .scaleLinear()
          .domain([0, 1])
          .range([endHeight + endHeight, endHeight + startHeight]);
        svg
          .append("path")
          .attr("id", "repdetection")
          .datum(data)
          .attr("fill", "none")
          .attr("stroke", "blue")
          .attr("opacity", 0.7)
          .attr("stroke-width", 4)
          .attr(
            "d",
            d3
              .line()
              .x(function (d, idx) {
                return x(idx);
              })
              .y(function (d) {
                return y(d);
              })
          );

        svg
          .append("path")
          .attr("id", "repdetection-cutoff")
          .datum(data)
          .attr("fill", "none")
          .attr("stroke", "grey")
          .attr("opacity", 0.5)
          .attr("stroke-width", 1)
          .attr(
            "d",
            d3
              .line()
              .x(function (d, idx) {
                return x(idx);
              })
              .y(function (d) {
                return 0.3;
              })
          );
      }
    }
  }, [
    allData,
    props.modalVisible,
    state.enabledAxes,
    state.repDetectionProb,
    props.totalDuration,
  ]);

  useEffect(() => {
    var predictedLabels = allData.total_seq_labels;
    var actualLabels = allData.seq_labels;

    // set the dimensions and margins of the graph
    var margin = { top: 0, right: 30, bottom: 10, left: 10 },
      width = svgRef.current.offsetWidth,
      height = 300 - margin.top - margin.bottom;

    d3.select(svgRef.current).selectAll("#dot").remove();
    d3.select(svgRef.current).selectAll("#dot2").remove();

    // append the svg object to the body of the page
    var svg = d3.select(svgRef.current).select("svg");

    // Add X axis --> it is a date format
    var startHeight = 0;
    var endHeight = height * 0.66;
    var x = d3
      .scaleLinear()
      .domain([0, props.totalDuration / 20 || actualLabels.length])
      .range([margin.left, width]);

    // Add Y axis
    var y = d3.scaleLinear().domain([0, 4]).range([endHeight, startHeight]);

    let cx = x(actualLabels.length * props.pointer);
    let cy = y(actualLabels[Math.round(actualLabels.length * props.pointer)]);
    console.log(cx, cy);
    svg
      // .append("defs")
      // .append("marker")
      // .attr("viewBox", [0, 0, 20, 20])
      // .attr("refX", 10)
      // .attr("refY", 10)
      // .attr("markerWidth", 10)
      // .attr("markerHeight", 10)
      .append("circle")
      .attr("id", "dot")
      .attr("cx", cx)
      .attr("cy", cy)
      .attr("r", 5)
      .style("fill", "#4056e5d8");

    let c2x = x(actualLabels.length * props.pointer);
    let c2y = y(
      predictedLabels[Math.round(actualLabels.length * props.pointer)]
    );
    svg
      // .append("defs")
      // .append("marker")
      // .attr("viewBox", [0, 0, 20, 20])
      // .attr("refX", 10)
      // .attr("refY", 10)
      // .attr("markerWidth", 10)
      // .attr("markerHeight", 10)
      .append("rect")
      .attr("id", "dot2")
      .attr("x", c2x - 5)
      .attr("y", c2y - 5)
      .attr("width", 10)
      .attr("height", 10)
      .style("fill", "#ff00008c");
  }, [props.pointer, props.totalDuration]);

  console.log("state.enabledAxes", state.enabledAxes);

  return (
    <div style={{ margin: "1rem 0rem" }}>
      <div ref={divRef}></div>
      {/* <div ref={exerciseTooltipRef} className="tooltip">
        <div></div>
        <svg></svg>
      </div> */}

      <div
        ref={svgRef}
        // width={svgWidth}
        // height={svgHeight}
        style={{ overflowX: "scroll", width: "100%" }}
      />
      <Modal
        width={1080}
        title="Graph"
        visible={props.modalVisible}
        onCancel={() => props.closeModal()}
        footer={null}
      >
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {Object.keys(DATA_NAME).map((key) => {
            // console.log("KEY", typeof key, state.enabledAxes.indexOf(key))
            return (
              <div>
                <Button
                  type="link"
                  onClick={() => {
                    toggleLine(parseInt(key));
                  }}
                >
                  <MinusSquareTwoTone
                    // style={{
                    //   color:
                    //     (state.enabledAxes || []).indexOf(parseInt(key)) == -1
                    //       ? "white"
                    //       : STROKE_COLOR[key],
                    // }}
                    twoToneColor={
                      (state.enabledAxes || []).indexOf(parseInt(key)) == -1
                        ? "grey"
                        : STROKE_COLOR[key]
                    }
                  />{" "}
                  <span style={{ color: STROKE_COLOR[key] }}>
                    {DATA_NAME[key]}
                  </span>
                </Button>
              </div>
            );
          })}
          <div>
            <Button
              type="link"
              onClick={() =>
                setState((prev) => ({
                  ...prev,
                  repDetectionProb: !prev.repDetectionProb,
                }))
              }
            >
              <MinusSquareTwoTone
                // style={{
                //   color: !state.repDetectionProb ? "white" : "blue",
                // }}
                twoToneColor={!state.repDetectionProb ? "grey" : "blue"}
              />{" "}
              <span style={{ color: "blue" }}>Rep Detection Probability</span>
            </Button>
          </div>
        </div>
        <div
          ref={svgModalRef}
          // width={svgWidth}
          // height={svgHeight}
          style={{ overflowX: "scroll", width: "1000px", height: "700px" }}
        />
      </Modal>
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
}
export default React.memo(MultilineChart);
