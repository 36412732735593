import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  List,
  message,
  Modal,
  PageHeader,
  Row,
  Select,
  Tag,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";

import { useQuery, useQueryClient } from "react-query";
import { KfoldService } from "../../../services/dataService";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import createPersistedState from "use-persisted-state";

const useKfoldState = createPersistedState<string>("kfoldState");
const usePageState = createPersistedState<any>("pageState");

const KfoldCreateForm = ({
  onSuccess,
}: {
  onSuccess: () => void;
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      values.calibration_files = values.calibration_files.split("\n");
      await KfoldService.createBatch(values);
      queryClient.invalidateQueries("kfoldBatch");
      form.resetFields();
      message.success("Batch created successfully");
      onSuccess();
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };
  return (
    <Form form={form} layout="vertical" onFinish={onFinish} initialValues={{
      ignore_validated: true
    
    } as any}>
      <Form.Item label="Name" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="Calibration Files" name="calibration_files">
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        // label="Exclude Validated?"
        name="ignore_validated"
        valuePropName="checked"
        // wrapperCol={{ offset: 8, span: 16 }}
      >
        <Checkbox> Exclude Validated?</Checkbox>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Create
        </Button>
      </Form.Item>
    </Form>
  );
};
const KfoldExerciseVerificationList = () => {
  const [kfold_batch_id, setKfoldBatchId] = useKfoldState("");
  const [page, setPage] = usePageState(1);
  const [state, updateState] = useState({
    search: "",
    createFlag: false,
    // kfold_batch_id: "",
    filters: {
      exercise: "",
      ex_verification_status: "",
      include_custom: false,
      // page: 1,
    },
  });

  const kfoldBatch = useQuery(
    ["kfoldBatch", state.filters],
    async () => KfoldService.getAll(state.filters),
    {
      keepPreviousData: true,
    }
  );

  const kfoldBatchFiles = useQuery(
    ["kfoldBatchFiles", kfold_batch_id, page],
    async () =>
      KfoldService.getFiles(kfold_batch_id, {
        page: page,
      }),
    {
      enabled: kfold_batch_id !== "",
    }
  );

  useEffect(() => {
    // Load query params
    const urlParams = new URLSearchParams(window.location.search);
    const exercise = urlParams.get("exercise");
    const ex_verification_status = urlParams.get("ex_verification_status");
    const search = urlParams.get("search");
    if (exercise || ex_verification_status || search) {
      updateState({
        ...state,
        search: search || "",
        filters: {
          exercise: exercise || "",
          ex_verification_status: ex_verification_status || "",
          include_custom: false,
        },
      });
    }
  }, []);

  const onPageChange = (page: number) => {
    setPage(page);
  };
  return (
    <>
      <Modal
        title="Create Kfold Verification"
        visible={state.createFlag}
        onCancel={() => updateState({ ...state, createFlag: false })}
        footer={null}
      >
        <KfoldCreateForm 
          onSuccess={() => {
            updateState({ ...state, createFlag: false });
          }}
         />
      </Modal>
      <PageHeader title="Kfold Verification" />
      <Row justify="space-between">
        <Col span={16}>
          <div>
            <div>
              <Typography.Text type="secondary">Select Batch</Typography.Text>
            </div>
            <KfoldSelect
              selectedId={kfold_batch_id}
              onChange={(value) => {
                setKfoldBatchId(value);
              }}
            />
          </div>
        </Col>
        <Button
          type="primary"
          style={{ marginBottom: "10px", marginRight: "10px" }}
          onClick={() => updateState({ ...state, createFlag: true })}
        >
          Create Kfold Verification
        </Button>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Tag>{kfoldBatchFiles.data?.total_files || 0} Files</Tag>
      </Row>
      <Row
        style={{
          width: "100%",
          marginTop: "10px",
        }}
      >
        <List
          style={{
            width: "100%",
          }}
          pagination={{
            current: page,
            pageSize: 10,
            total: kfoldBatchFiles.data?.total_files,
            onChange: onPageChange,
          }}
          grid={{ gutter: 16, column: 2 }}
          dataSource={kfoldBatchFiles.data?.results}
          renderItem={(item: any) => (
            <List.Item>
              <Link to={`/v2/kfold/${item.id}`}>
                <Card style={{ width: "100%" }}>
                  <Row justify="space-between">
                    <Typography.Text
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {item.exercise_name}
                    </Typography.Text>
                    <div>
                      <div>
                        <Typography.Text type="secondary">
                          {item.file_path}
                        </Typography.Text>
                      </div>
                      <div>
                        {item.is_validated ? (
                          <Tag color="blue">Validated</Tag>
                        ) : (
                          <Tag color="yellow">Pending</Tag>
                        )}
                        {item.validated_by && (
                          <Tag color="green">{item.validated_by}</Tag>
                        )}
                      </div>
                    </div>
                  </Row>
                </Card>
              </Link>
            </List.Item>
          )}
        />
      </Row>
    </>
  );
};

export default KfoldExerciseVerificationList;

const KfoldSelect = ({
  selectedId,
  onChange,
}: {
  selectedId: string;
  onChange: (value: string) => void;
}) => {
  const [search, setSearch] = useState("");

  const kfoldBatch = useQuery(
    ["kfoldBatch", search],
    async () =>
      KfoldService.getAll({
        search,
      }),
    {
      keepPreviousData: true,
    }
  );

  const debounceSave = useCallback(
    debounce((value: string) => {
      setSearch(value);
    }, 500),
    []
  );
  return (
    <Select
      style={{ width: "250px" }}
      placeholder="Select Batch"
      value={selectedId}
      onSearch={debounceSave}
      onChange={(value) => {
        onChange(value);
      }}
      allowClear
      showSearch
      filterOption={false}
    >
      {kfoldBatch.isLoading && (
        <Select.Option disabled>Loading...</Select.Option>
      )}
      {kfoldBatch.data &&
        kfoldBatch.data.map((batch: any) => (
          <Select.Option key={batch.id} value={batch.id}>
            <div>{batch.name}</div>
            <div>
              <Typography.Text type="secondary">
                {batch.total_files} Total files
              </Typography.Text>
            </div>

            <div>
              <Typography.Text type="secondary">
                {batch.validated_files} files validated
              </Typography.Text>
            </div>
          </Select.Option>
        ))}
    </Select>
  );
};
