import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  CalibrationFileServices,
  MiscServices,
} from "../../../services/dataService";
import ReactPlayer from "react-player";
import createPersistedState from "use-persisted-state";
import {
  AutoComplete,
  Button,
  Col,
  Divider,
  Input,
  message,
  Popover,
  Radio,
  Row,
  Select,
  Slider,
  Tabs,
  Tag,
  Typography,
} from "antd";
import { exNameMap } from "../../../constants";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./index.css";
import { useHotkeys } from "react-hotkeys-hook";
import ExerciseSelect from "../components/exerciseSelect";
import { Link, useHistory } from "react-router-dom";
import { set } from "d3";
import RepLabelChart from "../labelDetail/labelGraph";
import { ExportOutlined, ArrowRightOutlined } from "@ant-design/icons";
import ChangeHistory from "./history";
// import { useNavigate } from "react-router-dom";
const playbackRateState = createPersistedState<number>("playbackRateState");

interface ControlConfig {
  played: number;
  seeking: boolean;
  seekingTime: number;
  duration: number;
  currentStage: number;
}
const persistedControlConfig =
  createPersistedState<ControlConfig>("controlConfig");

interface AllCalibrationFilterState {
  search: string;
  exercise: string[];
  ex_verification_status: string;
  label_status: string;
  import_status: string;
  kfold_tag: string;
  include_custom: boolean | null;
  is_archived: boolean | null;
  ex_verified_by: string;
  labelled_by: string;
  archived_by: string;
  kfold_verified_by: string;
  user_id: string;
}
const useAllCalibrationFilterState =
  createPersistedState<AllCalibrationFilterState>("allCalibrationFilterState");

const handOptions = [
  { label: "Left", value: "left" },

  { label: "Right", value: "right" },
  { label: "Unclear", value: "unclear" },
];
const CalibrationDetail = ({ match }: any) => {
  let { id } = match.params;
  // const navigate = useNavigate();
  const [filterState, setFilterState] = useState<AllCalibrationFilterState>({
    search: "",
    exercise: [],
    ex_verification_status: "",
    label_status: "",
    import_status: "",
    kfold_tag: "",
    include_custom: null,
    is_archived: null,
    ex_verified_by: "",
    labelled_by: "",
    archived_by: "",
    kfold_verified_by: "",
    user_id: "",
  });

  useEffect(() => {
    // Load query params
    const urlParams = new URLSearchParams(window.location.search);
    const exercise = urlParams.get("exercise");
    const ex_verification_status = urlParams.get("ex_verification_status");
    const search = urlParams.get("search");
    const labelling_status = urlParams.get("labelling_status");
    const import_status = urlParams.get("import_status");
    const kfold_tag = urlParams.get("kfold_tag");
    const include_custom = urlParams.get("include_custom");
    const is_archived = urlParams.get("is_archived");
    const labelling_by = urlParams.get("labelling_by");
    const user_id = urlParams.get("user_id");
    const archived_by = urlParams.get("archived_by");
    if (
      exercise ||
      ex_verification_status ||
      search ||
      labelling_status ||
      import_status ||
      kfold_tag ||
      include_custom ||
      is_archived ||
      labelling_by ||
      user_id || 
      archived_by
    ) {
      setFilterState({
        ...filterState,
        search: search || "",
        exercise: exercise?.split(",") || [],
        ex_verification_status: ex_verification_status || "",
        label_status: labelling_status || "",
        import_status: import_status || "",
        kfold_tag: kfold_tag || "",
        include_custom: include_custom ? include_custom == "true" : null,
        is_archived: is_archived ? is_archived == "true" : null,
        ex_verified_by: archived_by || "",
        labelled_by: labelling_by || "",
        user_id: user_id || "",
      });
    }
  }, []);

  const player = useRef(null);
  const [playbackRate, setPlaybackRate] = playbackRateState(3);
  const [playing, setPlaying] = React.useState(false);
  const [state, setState] = persistedControlConfig({
    played: 0,
    seeking: false,
    seekingTime: 0,
    duration: 0,
    currentStage: 0,
  });

  const exerciseRef = useRef(null);
  const formRef = useRef(null);
  const [exercise, setExercise] = React.useState("");
  const [comment, setComment] = React.useState("");
  const [watchHand, setWatchHand] = React.useState("left");
  const [form, setForm] = React.useState("good");
  const [rejectionReason, setRejectionReason] = React.useState("Bad Form");
  const history = useHistory();

  const calibrationDetail = useQuery(
    ["calibrationDetailVerify", id],
    () =>
      CalibrationFileServices.get(id, {
        // ...filterState,
        // search: filterState.search,
      }),
    {
      onSuccess: (data) => {
        setExercise(data.exercise);
        setComment(data.comment);
      },
    }
  );

  const nextCalibrationFile = useQuery(
    ["nextCalibrationFile", id, filterState],
    () =>
      CalibrationFileServices.getNextFile(id, {
        ...filterState,
      })
  );

  const exerciseMeta = useQuery(
    ["exerciseMeta"],
    () => MiscServices.getMasterExercises(),
    {
      staleTime: 1000 * 60 * 60 * 24,
    }
  );

  // Pause Play hotkey
  useHotkeys(
    "space",
    (event) => {
      event.preventDefault();
      setPlaying(!playing);
    },
    {},
    [playing]
  );

  // Set focus on exercise select on "E" key press
  useHotkeys(
    "e",
    (event) => {
      event.preventDefault();
      if (exerciseRef.current) {
        (exerciseRef.current as any).focus();
      }
    },
    {},
    []
  );

  // Set focus on form select on "F" key press
  useHotkeys(
    "f",
    (event) => {
      event.preventDefault();
      if (formRef.current) {
        (formRef.current as any).focus();
      }
    },
    {},
    []
  );

  // Set Hotkey for skipping
  useHotkeys(
    "s",
    (event) => {
      event.preventDefault();
      onSkip();
    },
    {},
    [calibrationDetail.data]
  );
  useHotkeys(
    "n",
    (event) => {
      event.preventDefault();
      onSkip();
    },
    {},
    [calibrationDetail.data]
  );

  // Set Hotkey for verifying
  useHotkeys(
    "v",
    (event) => {
      event.preventDefault();
      onVerify();
    },
    {},
    [calibrationDetail.data]
  );

  const verifyMutation = useMutation(
    (data: any) => CalibrationFileServices.verifyFile(id, data),
    {
      onSuccess: () => {
        message.success("File Verified");
      },
    }
  );

  const rejectMutation = useMutation(
    (data: any) => CalibrationFileServices.rejectFile(id, data),
    {
      onSuccess: () => {
        message.success("File Archived");
        // let nextId = calibrationDetail.data.next_calibration_file;
        // navigate(`/v2/verify/${nextId}`);
        // history.push(`/v2/verify/${nextId}`);
      },
    }
  );
  const resetLabelMutation = useMutation(
    () => CalibrationFileServices.resetLabels(id),
    {
      onSuccess: () => {
        message.success("Labels Reset");
        calibrationDetail.refetch();
      },
    }
  );

  const resetVerificationMutation = useMutation(
    () => CalibrationFileServices.resetVerification(id),
    {
      onSuccess: () => {
        message.success("Verification Reset");
        calibrationDetail.refetch();
      },
    }
  );
  const onVerify = () => {
    let data = {
      comment: comment,
      form_quality: form,
      exercise: exercise || calibrationDetail.data.exercise,
      watch_hand: watchHand,
      ex_verification_status: "verified",
    };

    verifyMutation.mutate(data);
  };

  const onSkip = () => {
    let nextId = nextCalibrationFile.data.id;
    // navigate(`/v2/verify/${nextId}`);
    history.push(`/v2/calibrations/${nextId}${window.location.search}`);
  };

  const onReject = () => {
    // Comment must be present
    let data = {
      comment: comment,
      form_quality: form,
      exercise: exercise,
      watch_hand: watchHand,
      ex_verification_status: "archived",
      rejection_reason: rejectionReason,
    };
    rejectMutation.mutate(data);
  };

  if (calibrationDetail.isLoading) {
    return <div>Loading...</div>;
  }

  const labels: any[] = [];
  calibrationDetail.data?.label_data.map((item: any) => {
    labels.push({ x: item.start, y: item.label });
    labels.push({ x: item.end, y: item.label });
  });

  const onProgress = (data: any) => {
    let played = data.played * 1000;
    var currentStage = (calibrationDetail.data?.label_data).filter(
      (item: any) =>
        item.start / state.duration < played &&
        played < item.end / state.duration
    )[0];

    setState((prevState) => ({
      ...prevState,
      played: data.played,
      currentStage:
        currentStage && currentStage.label != undefined
          ? currentStage.label
          : state.currentStage,
    }));
  };

  return (
    <>
      <Row style={{ margin: "2rem 0rem" }}>
        <Col span={16}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              onClick={() =>
                // navigate(`/v2/verify`)

                (window.location.href = `/v2/calibrations`)
              }
              icon={<ArrowLeftOutlined />}
              type="link"
            ></Button>
            <div
              style={{
                display: "inline-block",
              }}
            >
              <Typography.Title level={4}>
                {calibrationDetail.data.exercise_name}
              </Typography.Title>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <Typography.Text>
                  #ID: {calibrationDetail.data.id}
                </Typography.Text>
                <br />
                <Typography.Text>
                  Record Date:{" "}
                  {calibrationDetail.data.record_time
                    ? calibrationDetail.data.record_time
                    : "N/A"}
                </Typography.Text>
              </div>
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div style={{ display: "flex", justifyContent: "end", gap: 12 }}>
            <Link
              to={`/v2/verify/${calibrationDetail.data.id}`}
              target="_blank"
            >
              <Button>
                Verify <ExportOutlined />
              </Button>
            </Link>
            <Link to={`/v2/label/${calibrationDetail.data.id}`} target="_blank">
              <Button>
                Label <ExportOutlined />
              </Button>
            </Link>
            <Link to={`/v2/calibrations/${nextCalibrationFile.data?.id}`}>
              <Button
                style={{
                  marginRight: "1rem",
                }}
                disabled={!nextCalibrationFile.data}
              >
                Next <ArrowRightOutlined />
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
      <div
        style={{
          // maxWidth: "800px",
          margin: "1rem 2rem",
        }}
      >
        <Row gutter={24}>
          <Col span={16}>
            <ReactPlayer
              ref={player}
              url={calibrationDetail.data.video_url}
              controls={true}
              playbackRate={playbackRate}
              playing={playing}
              onReady={
                (player) => {
                  setState({
                    ...state,
                    duration: player.getDuration(),
                  });
                  setPlaying(true);
                }
                // onReady
              }
              // onStart={onStart}
              // onPlay={onPlay}
              // onPause={onPause}
              // onReady={onReady}
              onProgress={onProgress}
              // width="100%"
              height="80vh"
              // height="360px"
              progressInterval={20}
            />
          </Col>
          <Col span={8}>
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane key="1" tab="Details">
                <Row
                  gutter={[12, 12]}
                  style={{
                    marginTop: "1rem",
                  }}
                >
                  <div className="control-label">
                    <div>
                      <span>Details</span>
                    </div>
                  </div>
                  <Row>
                    <Col span={12}>Ex Verification Status</Col>
                    <Col
                      span={12}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {calibrationDetail.data.ex_verification_status ===
                      "verified" ? (
                        <Tag color="green">VERIFIED</Tag>
                      ) : calibrationDetail.data.ex_verification_status ===
                        "archived" ? (
                        <Tag color="red">ARCHIVED</Tag>
                      ) : (
                        <Tag color="grey">UNVERIFIED</Tag>
                      )}
                    </Col>
                    <Divider />
                    <Col span={12}>Ex Verified By</Col>
                    <Col
                      span={12}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {calibrationDetail.data.ex_verified_by}
                    </Col>
                    <Divider />

                    <Col span={12}>Archived Reason</Col> 
                     <Col
                      span={12}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {calibrationDetail.data.archived_reason || "N/A"}
                    </Col>
                    <Divider />
                    <Col span={12}>Label Status</Col>
                    <Col
                      span={12}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {calibrationDetail.data.labelling_status ===
                      "labelled" ? (
                        <Tag color="green">LABELLED</Tag>
                      ) : calibrationDetail.data.labelling_status ===
                        "unlabelled" ? (
                        <Tag color="yellow">UNLABELLED</Tag>
                      ) : calibrationDetail.data.labelling_status ===
                        "unsure" ? (
                        <Tag color="orange">UNSURE</Tag>
                      ) : (
                        <Tag color="grey">UNLABELLED</Tag>
                      )}
                    </Col>
                    <Divider />
                    <Col span={12}>Labelled By</Col>
                    <Col
                      span={12}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {calibrationDetail.data.labelled_by}
                    </Col>
                    <Divider />
                    {/* <Col span={12}>Is Archived</Col> */}
                    {/* <Col
                      span={12}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {calibrationDetail.data.is_archived ? (
                        <Tag color="red">Archived</Tag>
                      ) : (
                        <Tag color="green">Not-Archived</Tag>
                      )}
                    </Col>
                    <Divider /> */}
                    {/* <Col span={12}>Archived By</Col>
                    <Col
                      span={12}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {calibrationDetail.data.archived_by}
                    </Col> */}
                    {/* <Divider /> */}
                    <Col span={12}>Exercise</Col>
                    <Col
                      span={12}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {calibrationDetail.data.exercise_name}
                    </Col>

                    <Divider />
                    <Col span={12}>Watch Hand</Col>
                    <Col
                      span={12}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {calibrationDetail.data.watch_hand}
                    </Col>
                    <Divider />
                    <Col span={12}>Form Quality</Col>
                    <Col
                      span={12}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {calibrationDetail.data.form_quality}
                    </Col>
                    <Divider />
                    <Col span={12}>Comment</Col>
                    <Col
                      span={12}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {calibrationDetail.data.comment}
                    </Col>
                    <Divider />
                    <Col span={12}>User</Col>
                    <Col
                      span={12}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {calibrationDetail.data.user_name}
                    </Col>
                    <Divider />
                    <Col span={12}>User ID</Col>
                    <Col
                      span={12}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {calibrationDetail.data.user_id}
                    </Col>
                    <Divider />
                  </Row>

                  <Col span={24}>
                    <div className="control-label">
                      <div>
                        <span>Label Chart</span>
                      </div>
                    </div>
                    <div>
                      <RepLabelChart
                        // state={state}
                        data={labels}
                        time={state.played * state.duration * 1000}
                        // time={state.played}
                        stage={state.currentStage || 0}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[8, 8]}>
                      <Col span={12}>
                        <Button
                          className="action-btn unsure"
                          onClick={() => resetVerificationMutation.mutate()}
                          loading={resetVerificationMutation.isLoading}
                        >
                          Reset Verification
                        </Button>
                      </Col>
                      <Col span={12}>
                        <Button
                          className="action-btn unsure"
                          onClick={() => resetLabelMutation.mutate()}
                          loading={resetLabelMutation.isLoading}
                        >
                          Reset Labels
                        </Button>
                      </Col>

                      <Col span={24}>
                        {/* <Button type="default" className="action-btn archive">
                          Archive File
                        </Button> */}
                        <Popover
                          trigger={"click"}
                          content={
                            <div>
                              <div className="control-label">
                                <span>Select a reason</span>
                              </div>
                              {/* <Input.TextArea
                    value={customForm}
                    onChange={(e) => setCustomForm(e.target.value)}
                  /> */}
                              <AutoComplete
                                style={{ width: "300px", marginRight: "10px" }}
                                options={[
                                  { value: "Bad Form", label: "Bad Form" },
                                  {
                                    value: "Poor Video Quality",
                                    label: "Poor Video Quality",
                                  },
                                  { value: "Too Short", label: "Too Short" },
                                  {
                                    value: "No User Present",
                                    label: "No User Present",
                                  },
                                  {
                                    value: "Multiple Users Present",
                                    label: "Multiple Users Present",
                                  },
                                  { value: "No Watch", label: "No Watch" },
                                  {
                                    value: "No Exercise",
                                    label: "No Exercise",
                                  },
                                  { value: "Other", label: "Other" },
                                ]}
                                onSelect={(value) => setRejectionReason(value)}
                                onChange={(value) => setRejectionReason(value)}
                                value={rejectionReason}
                              />

                              <Button
                                type="primary"
                                className="reject"
                                onClick={onReject}
                              >
                                Archive
                              </Button>
                            </div>
                          }
                        >
                          <Button type="primary" className="action-btn archive">
                            Archive
                          </Button>
                        </Popover>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane key="2" tab="History">
                <ChangeHistory changeHistory={calibrationDetail.data.history} />
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CalibrationDetail;
