import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDumbbell } from "@fortawesome/free-solid-svg-icons";

export const TrainLoadingIcon = () => (
  <FontAwesomeIcon
    icon={faDumbbell}
    beatFade
    style={{
      fontSize: "6em",
      color: "#4057e5"
    }}
  />
);
