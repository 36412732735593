import React, { useEffect, useState, useRef } from "react";
import {
  Input,
  Button,
  Card,
  Row,
  Col,
  List,
  Tag,
  PageHeader,
  Popover,
  Space,
  Drawer,
  notification,
  message,
  Select,
  Divider,
  Switch,
  Form,
} from "antd";
import {
  WorkoutFileService,
  ConstantItemService,
} from "../../services/dataService";
import { useQuery, useMutation, useQueryClient } from "react-query";
// import { Link } from "react-router-dom";
// import moment from "moment";
// import { labelKeys } from "../../constants";
// import { FilterOutlined, MoreOutlined, InboxOutlined } from "@ant-design/icons";
// import { fieldToName } from "../../../../constant";
import createPersistedState from "use-persisted-state";
const useEditExerciseState = createPersistedState("editExerciseState");
const { CheckableTag } = Tag;
const { Option } = Select;
const ExerciseCard = ({ item, onClick }) => {
  return (
    <Card hoverable onClick={() => onClick(item.workouts)}>
      <div style={{ width: "100%" }}>
        <div>
          <span>{item.exercise} </span>{" "}
          {!item.count ? (
            <Tag color="error" style={{ float: "right" }}>
              No Sets
            </Tag>
          ) : item.count < 3 ? (
            <Tag color="warning" style={{ float: "right" }}>
              {item.count} Sets
            </Tag>
          ) : (
            <Tag color="green" style={{ float: "right" }}>
              {item.count} Sets
            </Tag>
          )}
        </div>
      </div>
    </Card>
  );
};

const PseudoSelect = (props) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref && ref.current) {
      console.log(ref.current);
      ref.current.focus();
    }
  }, []);
  return (
    <Select
      {...props}
      autoFocus
      ref={(e) => (ref.current = e)}
      showSearch
      placeholder="Select an exercise"
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {props.exercise_data.data?.value?.items?.map((item) => (
        <Option key={item.label} value={item.label}>
          {item.name}
        </Option>
      ))}

      <Option value="lucy">Lucy</Option>
      <Option value="tom">Tom</Option>
    </Select>
  );
};
const EditExercise = (props) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const ref = useRef(null);
  const [exerciseList, updateExerciseList] = useState({})
  const [defaultExercise, setDetaultExercise] = useEditExerciseState(null);

  useEffect(() => {
    console.log("ref:", ref && ref.current);
    if (ref && ref.current) {
      ref.current.focus();
    }
  }, []);

  const exercise_data = useQuery(
    ["exercise_constants"],
    () => ConstantItemService.getAll(),
    {onSuccess: (data) => {
      const exercises = data.filter(item => item.key == 'exercise_list')[0]
      updateExerciseList(exercises)
      if (!defaultExercise) {
        setDetaultExercise(exercises.value.items[0].label)
      }
    }}
  );

  const changeExercise = useMutation(
    async (values) => {
      var data = {
        id: props.workoutFileId,
        exercise: values.new_exercise,
        is_verified: true,
        tags_dict: {"RIGHT_HAND": values.rightHand ? "True": "False"}
      };
      setDetaultExercise(values.new_exercise)
      return WorkoutFileService.update(data.id, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("workout_file");
        queryClient.invalidateQueries("workout_label");

        message.success("Updated Data!!");
        if (props.onClose) {
          props.onClose(true);
        }
      },
      onError: (error) => {
        message.success("Update Data Failed!!");
      },
    }
  );

  console.log("defaultExercise:", defaultExercise);
  return (
    <React.Fragment>
      <Drawer
        width={600}
        title={"Edit Exercise"}
        visible={props.visible}
        onClose={props.onClose ? props.onClose : () => {}}
        destroyOnClose={true}
      >
        <div style={{ margin: "10px 20px" }}>
          <Form
            form={form}
            layout="vertical"
            name="basic"
            // initialValues={{ remember: true }}
            initialValues={{ rightHand: props.rightHand, new_exercise: defaultExercise || props.exercise }}
            onFinish={(values) => changeExercise.mutate(values)}
          >
            <Form.Item
              label="New Exercise"
              name="new_exercise"
              rules={[{ required: true, message: "Please input your name!" }]}
            >
              <Select
                autoFocus
                ref={(e) => (ref.current = e)}
                showSearch
                placeholder="Select an exercise"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {exerciseList?.value?.items?.map((item) => (
                  <Option key={item.label} value={item.label}>
                    {item.name}
                  </Option>
                ))}
              </Select>
              {/* <PseudoSelect exercise_data={exercise_data}/> */}
            </Form.Item>

            <Form.Item
              name="rightHand"
              label="Is Right Hand"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>

            <Form.Item>
              <Button
                loading={changeExercise.loading}
                type="primary"
                htmlType="submit"
                style={{ marginRight: "20px" }}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default EditExercise;
