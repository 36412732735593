import React, { useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  CalibrationFileServices,
  KfoldService,
  MiscServices,
} from "../../../services/dataService";
import ReactPlayer from "react-player";
import createPersistedState from "use-persisted-state";
import {
  AutoComplete,
  Button,
  Col,
  Divider,
  Input,
  message,
  Popover,
  Radio,
  Row,
  Select,
  Slider,
  Tag,
  Typography,
} from "antd";
import { exNameMap } from "../../../constants";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./index.css";
import { useHotkeys } from "react-hotkeys-hook";
import ExerciseSelect from "../components/exerciseSelect";
import { useHistory } from "react-router-dom";
const playbackRateState = createPersistedState<number>("playbackRateState");

interface ControlConfig {
  played: number;
  seeking: boolean;
  seekingTime: number;
  duration: number;
}
const persistedControlConfig =
  createPersistedState<ControlConfig>("controlConfig");

interface ExVerificationState {
  search: string;
  filters: {
    exercise: string;
    ex_verification_status: string;
  };
}
const useExVerificationState = createPersistedState<ExVerificationState>(
  "exVerificationState"
);
const handOptions = [
  { label: "Left", value: "left" },

  { label: "Right", value: "right" },
  { label: "Unclear", value: "unclear" },
];
const KfoldVerifyDetail = ({ match }: any) => {
  let { id } = match.params;
  // const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const queryClient = useQueryClient();
  const filterState = {
    search: queryParams.get("search") || "",
    filters: {
      exercise: queryParams.get("exercise") || "",
      ex_verification_status: queryParams.get("ex_verification_status") || "",
      include_custom: queryParams.get("include_custom") === "true",
    },
  };

  const player = useRef(null);
  const [playbackRate, setPlaybackRate] = playbackRateState(3);
  const [playing, setPlaying] = React.useState(false);
  const [state, setState] = persistedControlConfig({
    played: 0,
    seeking: false,
    seekingTime: 0,
    duration: 0,
  });

  const exerciseRef = useRef(null);
  const formRef = useRef(null);
  const [exercise, setExercise] = React.useState("");
  const [comment, setComment] = React.useState("");
  const [watchHand, setWatchHand] = React.useState("left");
  const [form, setForm] = React.useState("good");
  const [rejectionReason, setRejectionReason] = React.useState("Bad Form");
  const history = useHistory();

  const nextFile = useQuery(
    ["kfold_file_next", id, filterState],
    () => KfoldService.getNextFile(id),
    {
      staleTime: Infinity,
    }
  );

  const kfoldFileDetail = useQuery(
    ["kfold_file", id],
    async () => KfoldService.getKfoldFiles(id),
    {
      onSuccess: (data) => {
        setExercise(data.calibration_file.exercise);
        setComment(data.calibration_file.comments);
        setWatchHand(data.calibration_file.watch_hand);
        setForm(data.calibration_file.form_quality);
      },
      staleTime: Infinity,
    }
  );

  const calibrationData = kfoldFileDetail.data?.calibration_file;

  // Pause Play hotkey
  useHotkeys(
    "space",
    (event) => {
      event.preventDefault();
      setPlaying(!playing);
    },
    {},
    [playing]
  );

  // Set focus on exercise select on "E" key press
  useHotkeys(
    "e",
    (event) => {
      event.preventDefault();
      if (exerciseRef.current) {
        (exerciseRef.current as any).focus();
      }
    },
    {},
    []
  );

  // Set focus on form select on "F" key press
  useHotkeys(
    "f",
    (event) => {
      event.preventDefault();
      if (formRef.current) {
        (formRef.current as any).focus();
      }
    },
    {},
    []
  );

  // Set Hotkey for skipping
  useHotkeys(
    "s",
    (event) => {
      event.preventDefault();
      onSkip();
    },
    {},
    [kfoldFileDetail.data]
  );
  useHotkeys(
    "n",
    (event) => {
      event.preventDefault();
      onSkip();
    },
    {},
    [kfoldFileDetail.data]
  );

  // Set Hotkey for verifying
  useHotkeys(
    "v",
    (event) => {
      event.preventDefault();
      onVerify();
    },
    {},
    [kfoldFileDetail.data]
  );

  const verifyMutation = useMutation(
    async (data: any) => KfoldService.validateFile(id, data),
    {
      onSuccess: () => {
        message.success("File Verified");
      },
    }
  );

  const rejectMutation = useMutation(
    async (data: any) => KfoldService.validateFile(id, data),
    {
      onSuccess: () => {
        message.success("File Archived");
        // let nextId = calibrationDetail.data.next_calibration_file;
        // navigate(`/v2/verify/${nextId}`);
        // history.push(`/v2/verify/${nextId}`);
      },
    }
  );
  const onVerify = async () => {
    let data = {
      comment: comment,
      form_quality: form,
      exercise: exercise || calibrationData.exercise,
      watch_hand: watchHand,
      ex_verification_status: "verified",
    };

    await verifyMutation.mutateAsync(data);
    await queryClient.invalidateQueries(["kfold_file", id]);
    onSkip();
  };

  const onSkip = () => {
    let nextId = nextFile.data?.id;
    if (!nextId) {
      message.info("No more files to verify");
      return;
    }
    // navigate(`/v2/verify/${nextId}`);
    history.push(`/v2/kfold/${nextId}${window.location.search}`);
  };

  const onReject = async () => {
    // Comment must be present
    let data = {
      comment: comment,
      form_quality: form,
      exercise: exercise,
      watch_hand: watchHand,
      ex_verification_status: "archived",
      archived_reason: rejectionReason,
    };
    await rejectMutation.mutateAsync(data);
    await queryClient.invalidateQueries(["kfold_file", id]);

    onSkip();
  };

  if (kfoldFileDetail.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Row style={{ margin: "2rem 0rem" }}>
        <Button
          onClick={() =>
            // navigate(`/v2/verify`)

            (window.location.href = `/v2/kfold`)
          }
          icon={<ArrowLeftOutlined />}
          type="link"
        ></Button>
        <div>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <Typography.Title
              level={4}
              style={{
                marginBottom: "0px",
              }}
            >
              {exNameMap[calibrationData.exercise as keyof typeof exNameMap] ||
                calibrationData.exercise}
            </Typography.Title>{" "}
            <Tag
              color={
                calibrationData.ex_verification_status === "verified"
                  ? "green"
                  : calibrationData.ex_verification_status === "unverified"
                  ? "orange"
                  : "red"
              }
              style={{
                height: "22px",
              }}
            >
              {calibrationData.ex_verification_status.toUpperCase()}
            </Tag>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <Typography.Text>#ID: {calibrationData.id}</Typography.Text>
            <Typography.Text>
              Record Date:{" "}
              {calibrationData.record_time
                ? calibrationData.record_time
                : "N/A"}
            </Typography.Text>
            {calibrationData.ex_verification_status == "archived" && (
              <Typography.Text>
                Archived Reason:{" "}
                {calibrationData.archived_reason
                  ? calibrationData.archived_reason
                  : "N/A"}
              </Typography.Text>
            )}
          </div>
        </div>
      </Row>
      <div
        style={{
          // maxWidth: "800px",
          margin: "1rem 2rem",
        }}
      >
        <Row gutter={24}>
          <Col span={16}>
            <ReactPlayer
              ref={player}
              url={calibrationData.video_url}
              controls={true}
              playbackRate={playbackRate}
              playing={playing}
              onReady={
                (player) => {
                  setState({
                    ...state,
                    duration: player.getDuration(),
                  });
                  setPlaying(true);
                }
                // onReady
              }
              // onStart={onStart}
              // onPlay={onPlay}
              // onPause={onPause}
              // onReady={onReady}
              // onProgress={onProgress}
              // width="100%"
              height="80vh"
              // height="360px"
              progressInterval={20}
            />
          </Col>
          <Col span={8}>
            <Row
              gutter={[24, 24]}
              style={{
                marginTop: "1rem",
              }}
            >
              <Col span={24}>
                <div className="control-label">
                  <div>
                    <span>Playback Rate: {playbackRate}x</span>
                  </div>
                  <Slider
                    defaultValue={2}
                    min={1}
                    max={5}
                    onChange={(value) => setPlaybackRate(value)}
                    value={playbackRate}
                  />
                </div>
              </Col>
              <Col span={24}>
                <div>
                  <div className="control-label">
                    <span>Exercise</span>
                  </div>
                  {/* <Select
                style={{
                  width: "300px",
                }}
                allowClear
                showSearch={true}
                value={exercise}
                ref={exerciseRef}
                onSelect={(value) => {
                  setExercise(value);
                }}
              >
                {exerciseMeta.data?.map((exercise: any) => (
                  <Select.Option
                    key={exercise.calibKey}
                    value={exercise.calibKey}
                  >
                    {exercise.name}
                  </Select.Option>
                ))}
              </Select> */}
                  <ExerciseSelect
                    value={exercise}
                    onChange={(value) => {
                      setExercise(value as string);
                    }}
                    selectRef={exerciseRef}
                  />
                </div>
              </Col>
              <Col span={24}>
                <div>
                  <div className="control-label">
                    <span>Watch Hand</span>
                  </div>
                  <Radio.Group
                    value={watchHand}
                    onChange={(e) => setWatchHand(e.target.value)}
                    options={handOptions}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </div>
              </Col>
              <Col span={24}>
                <div>
                  <div className="control-label">
                    <span>Form</span>
                  </div>
                  <Select
                    style={{
                      width: "300px",
                    }}
                    allowClear
                    showSearch={true}
                    value={form}
                    ref={formRef}
                    onSelect={(value) => {
                      setForm(value);
                    }}
                  >
                    <Select.Option value="excellent">Excellent</Select.Option>
                    <Select.Option value="good">Good</Select.Option>
                    <Select.Option value="bad">Bad</Select.Option>
                    <Select.Option value="not_clear">Not Clear</Select.Option>
                  </Select>
                </div>
              </Col>
              <Col span={24}>
                <div>
                  <div className="control-label">
                    <span>Comment</span>
                  </div>
                  <div>
                    {calibrationData.comments &&
                      calibrationData.user_comments.map((comment: any) => (
                        <div>
                          <Row>
                            <Col>
                              <Tag
                                color="blue"
                                style={{
                                  marginRight: "10px",
                                }}
                              >
                                {comment.user}
                              </Tag>
                              <span>{comment.comment}</span>
                            </Col>
                            <Divider />
                          </Row>
                        </div>
                      ))}
                  </div>
                  <Input.TextArea
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    style={{
                      width: "100%",
                    }}
                  />
                </div>
              </Col>
              <Col span={24}>
                <Button
                  type="primary"
                  className="action-btn verify"
                  onClick={onVerify}
                >
                  Verify
                </Button>
              </Col>
              <Col span={12}>
                <Popover
                  trigger={"click"}
                  content={
                    <div>
                      <div className="control-label">
                        <span>Select a reason</span>
                      </div>
                      {/* <Input.TextArea
                    value={customForm}
                    onChange={(e) => setCustomForm(e.target.value)}
                  /> */}
                      <AutoComplete
                        style={{ width: "300px", marginRight: "10px" }}
                        options={[
                          { value: "Bad Form", label: "Bad Form" },
                          {
                            value: "Poor Video Quality",
                            label: "Poor Video Quality",
                          },
                          { value: "Too Short", label: "Too Short" },
                          {
                            value: "No User Present",
                            label: "No User Present",
                          },
                          {
                            value: "Multiple Users Present",
                            label: "Multiple Users Present",
                          },
                          { value: "No Watch", label: "No Watch" },
                          { value: "No Exercise", label: "No Exercise" },
                          { value: "Other", label: "Other" },
                        ]}
                        onSelect={(value) => setRejectionReason(value)}
                        onChange={(value) => setRejectionReason(value)}
                        value={rejectionReason}
                      />

                      <Button
                        type="primary"
                        className="reject"
                        onClick={onReject}
                      >
                        Archive
                      </Button>
                    </div>
                  }
                >
                  <Button type="primary" className="action-btn archive">
                    Archive
                  </Button>
                </Popover>
              </Col>
              <Col span={12}>
                <Button
                  type="primary"
                  className="action-btn skip"
                  onClick={onSkip}
                >
                  Skip
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default KfoldVerifyDetail;
