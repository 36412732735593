import React from "react";
import { Menu, Grid } from "antd";
import { Drawer, Button } from "antd";
import Auth from "../../services/auth";
import { LogoutOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const { useBreakpoint } = Grid;

const LeftMenu = () => {
  const { md } = useBreakpoint();
  return (
    <Menu mode={md ? "vertical" : "inline"}>
      <Menu.Item key="mail">
        <a href="">Home</a>
      </Menu.Item>
      <SubMenu key="sub1" title={<span>Blogs</span>}>
        <MenuItemGroup title="Item 1">
          <Menu.Item key="setting:1">Option 1</Menu.Item>
          <Menu.Item key="setting:2">Option 2</Menu.Item>
        </MenuItemGroup>
        <MenuItemGroup title="Item 2">
          <Menu.Item key="setting:3">Option 3</Menu.Item>
          <Menu.Item key="setting:4">Option 4</Menu.Item>
        </MenuItemGroup>
      </SubMenu>
      <Menu.Item key="alipay">
        <a href="">Contact Us</a>
      </Menu.Item>
    </Menu>
  );
};

const RightMenu = () => {
  const { md } = useBreakpoint();
  var auth = new Auth();
  return (
    <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
      <Menu.Item key="1">
        <Link to="/dashboard/">Dashboard</Link>
      </Menu.Item>

      <Menu.Item key="2">
        <Link to="/buckets/">Buckets</Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link to="/bucket_maker/">Bucket Maker</Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Link to="/predicted/">Predicted</Link>
      </Menu.Item>
    </Menu>
  );
};

class Navbar extends React.Component {
  state = {
    current: "mail",
    visible: false
  };
  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };

  render() {
    return (
      <nav className="menuBar">
        <div className="logo">
          <a href="">logo</a>
        </div>
        <div className="menuCon">
          <div>
            <div className="rightMenu">
              <RightMenu />
            </div>
          </div>

          <Button className="barsMenu" type="primary" onClick={this.showDrawer}>
            <span className="barsBtn"></span>
          </Button>
          <Drawer
            title="Basic Drawer"
            placement="right"
            closable={false}
            onClose={this.onClose}
            visible={this.state.visible}
          >
            <LeftMenu />
            <RightMenu />
          </Drawer>
        </div>
        <Button
          ghost
          type="link"
          style={{ position: "absolute", bottom: "20px", color: "white" }}
          icon={<LogoutOutlined />}
        >
          Logout
        </Button>
      </nav>
    );
  }
}

export default Navbar;
