import { Col, Input, PageHeader, Row, Select, Tag } from "antd";
import React, { useEffect, useState } from "react";

import { useQuery, useQueryClient } from "react-query";
import CalibrationList from "../calibrationList";
import ExerciseSelect from "../components/exerciseSelect";
import createPersistedState from "use-persisted-state";

interface ExVerificationState {
  search: string;
  filters: {
    exercise: string;
    ex_verification_status: string;
    include_custom: boolean;
    page: number;
  };
}
const useExVerificationState = createPersistedState<ExVerificationState>(
  "exVerificationState"
);
const ExerciseVerificationList = () => {
  const [state, updateState] = useExVerificationState({
    search: "",
    filters: {
      exercise: "",
      ex_verification_status: "",
      include_custom: false,
      page: 1,
    },
  });

  useEffect(() => {
    // Load query params
    const urlParams = new URLSearchParams(window.location.search);
    const exercise = urlParams.get("exercise");
    const ex_verification_status = urlParams.get("ex_verification_status");
    const search = urlParams.get("search");
    if (exercise || ex_verification_status || search) {
      updateState({
        ...state,
        search: search || "",
        filters: {
          exercise: exercise || "",
          ex_verification_status: ex_verification_status || "",
          include_custom: false,
          page: 1,
        },
      });
    }
  }, []);

  const onPageChange = (page: number) => {
    updateState(prev => ({
      ...prev,
      filters: {
        ...state.filters,
        page,
      },
    })
    );

  }
  return (
    <>
      <PageHeader title="Exercise Verification" />

      <Row gutter={24} align="bottom">
        <Col span={12}>
          <Input.Search
            placeholder="Search"
            defaultValue={state.search}
            onChange={(e) => {
              updateState({
                ...state,
                search: e.target.value,
                filters: {
                  ...state.filters,
                  page: 1
                },
              });
            }}
          />
        </Col>
        <Col span={12}>
          <div style={{ marginLeft: "10px", display: "inline-block" }}>
            <div className="form-label">
              <div style={{ display: "inline-block" }}>Exercise</div>
            </div>
            <ExerciseSelect
              allowClear={true}
              value={state.filters.exercise}
              onChange={(value) => {
                updateState((prev) => ({
                  ...(prev || {}),
                  filters: {
                    ...state.filters,
                    exercise: value as string,
                    page: 1
                  },
                }));
              }}
              mode="tags"
            />
          </div>
          <div style={{ marginLeft: "10px", display: "inline-block" }}>
            <div className="form-label">
              <div style={{ display: "inline-block" }}>Verification Status</div>
            </div>
            <Select
              style={{ width: "150px" }}
              placeholder="Verification Status"
              value={state.filters.ex_verification_status}
              onChange={(value) => {
                updateState((prev) => ({
                  ...prev,
                  filters: {
                    ...state.filters,
                    ex_verification_status: value,
                    page: 1
                  },
                }));
              }}
            >
              <Select.Option value="">All</Select.Option>
              <Select.Option value="verified">Verified</Select.Option>
              <Select.Option value="unverified">Unverified</Select.Option>
            </Select>
          </div>
        </Col>
      </Row>
      

      <CalibrationList filters={state.filters} search={state.search}
        onPageChange={onPageChange}
       />
    </>
  );
};

export default ExerciseVerificationList;
