import React, { useEffect } from "react";
import * as d3 from "d3";
import { exercises, grouppings } from "../workoutFileAnalysis/constant";

let columns = [
  "rot-rate_x",
  "rot-rate_y",
  "rot-rate_z",
  "pitch",
  "roll",
  "yaw",
  "usrAcc_x",
  "usrAcc_y",
  "usrAcc_z",
  "gravity_x",
  "gravity_y",
  "gravity_z",
  //   "time",
];

// Generate a distinct color for each column
let columnColors = columns.map((column, idx) => {
  return d3.interpolateRainbow(idx / columns.length);
});
type SetPlotProps = {
  data: number[][];
  v1SetMap: any;
    v2SetMap: any;
};
const RawData = (props: SetPlotProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    // TODO: Get data from backend
    plotRawData();
  }, [props.data]);

  const onMouseOver = (d: any) => {
    // console.log("mouse over");
    let tip = d3.select("body").selectAll(".tooltip") as d3.Selection<
      HTMLDivElement,
      unknown,
      null,
      undefined
    >;
    // if (tip.empty()) {
    //   tip = d3
    //     .select("body")
    //     .append("div")
    //     .attr("class", "tooltip")
    //     .style("opacity", 0) ;
    // }
    // Show tooltip
    // d3.select(tooltipRef.current)
    tip
      .style("opacity", 1)
      .style("left", d3.event.pageX + 10 + "px")
      .style("top", d3.event.pageY + 10 + "px")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "10px")
      .style("pointer-events", "none")
      .html(
        `
            <div> Top Exercises </div>
            <div> ${Object.keys(d.data).map(
              (key) =>
                `<div> ${key}: ${Math.round(d.data[key] * 100) / 100} </div>`
            )} 
            </div>
            </div>
            `
      );
  };

  const onMouseMove = (d: any) => {
    // console.log("mouse move");
    // d3.select(tooltipRef.current)
    let tip = d3.select("body").selectAll(".tooltip") as d3.Selection<
      HTMLDivElement,
      unknown,
      null,
      undefined
    >;
    tip
      .style("left", d3.event.pageX + 10 + "px")
      .style("top", d3.event.pageY + 10 + "px");
  };

  const onMouseLeave = (d: any) => {
    // console.log("mouse leave");
    // d3.select(tooltipRef.current)
    let tip = d3.select("body").selectAll(".tooltip") as d3.Selection<
      HTMLDivElement,
      unknown,
      null,
      undefined
    >;
    tip.style("opacity", 0);
  };

  const plotRawData = () => {
    // set the dimensions and margins of the graph
    const margin = { top: 10, right: 30, bottom: 20, left: 50 },
      width = (props.data.length * 86) / 100 - margin.left - margin.right,
      height = 200 - margin.top - margin.bottom;

    d3.select(ref.current).selectAll("*").remove();
    // windowExPred is a list of dict with scores for exercise, plot stacked bar chart

    let svg = d3
      .select(ref.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom + 50)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // let svgElem = svg.select("g");

    // if (v1ExScores.length === 0 || v2ExScores.length === 0) {
    //   return;
    // }

    // if (v1ExScores[0].length === 0 || v2ExScores[0].length === 0) {
    //   return;
    // }
    // Add X axis
    let x = d3
      .scaleLinear()
      .domain(
        d3.extent(
          props.data.map((d, index) => {
            return index;
          })
        ) as any
      )
      .range([0, width - margin.left - margin.right - 100]);

    // Plot x Axis with marker every 2 seconds
    // svg
    //   .append("g")
    //   .attr("transform", `translate(${16},${height / 2})`)
    //   .call(
    //     d3
    //       .axisBottom(x)
    //       .tickValues(
    //         x.ticks().filter((d) => {
    //           return !(d % 2);
    //         })
    //       )
    //       .tickFormat((d: any) => {
    //         return `${d * 2}s`;
    //       })
    //   );
    let y = d3
      .scaleLinear()
      .domain([-5, 5])
      .range([height  - margin.top - margin.bottom, 0]);

    let exerciseColors = d3
      .scaleOrdinal()
      .domain(exercises)
      .range(
        exercises.map((exercise, idx) => {
          return d3.interpolateRainbow(idx / exercises.length);
        })
      );

    //   svgElem.attr("width", width).attr("height", height / 2);

    // Plot stacked bar chart with rounded corners

    // Plot raw data with line chart
    let columnIdx = 0;

    for (columnIdx = 0; columnIdx < columns.length; columnIdx++) {
      // Line chart
      svg
        .selectAll(".raw-data" + columnIdx)
        .data([props.data.map((d) => d[columnIdx])])
        .enter()
        .append("path")
        .attr("fill", "none")
        .attr("stroke", columnColors[columnIdx])
        .attr("stroke-width", 1.5)
        .attr(
          "d",
          d3
            .line<number>()
            .x((d, i) => {
              return x(i);
            })
            .y((d) => {
              return y(d);
            })
        );
    }
  };

  return (
    <div>
      <div ref={ref}></div>
    </div>
  );
};

export default RawData;
